import "./WarrantiesCard.scss";

import React from "react";

import { OriginIncome, ValidateOperations } from "Models/Operation";

import { convertToPeso } from "@helpers/helpers";
import { MCards, MTooltip } from "@inversiones-ma/finfast-react-controls";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";

import BtnSelectCard from "./BtnSelectCard";
import { IconCard } from "./IconCard";

interface Props {
  quoteData: ValidateOperations[];
  handlerSelect: (element: ValidateOperations) => void;
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
  warantySelected: ValidateOperations | undefined;
}

/**
 * componente creacion cards con comision
 */
function WarrantiesCard(props: Props) {
  return (
    <div className="">
      <div className="row justify-content-center mt-4">
        {props.quoteData && props.quoteData?.length > 0 && (
          <>
            {props.quoteData.map((element, index) => {
              const esAseguradora =
                element.idAseguradora !== null && element.idAseguradora > 0;

              const existeAseguradoraConDescuento =
                element.idAseguradora > 0 &&
                element.valoresComision?.descuento! > 0;
              const existeAseguradoraConIva =
                element.idAseguradora > 0 &&
                element.valoresComision?.ivaClp! > 0;
              const cftConDescuento =
                element.idAseguradora === 0 &&
                element.valoresComision?.descuento! > 0;

              return (
                element && (
                  <div
                    key={index}
                    className={
                      props.addOrEdit === NewCertificateModels.Step.verificar ||
                      props.origen === "addQuote" ||
                      props.origen === "cart"
                        ? " col-lg-6 col-12 mb-5"
                        : " col-lg-4 h-100 col-md-6 col-12 mb-5"
                    }
                  >
                    <MCards
                      className={"contenedor border-0 h-100"}
                      color="primary"
                      size="default"
                      key={element.emisor}
                    >
                      <div className="color-text-low action-card d-flex flex-column justify-content-between h-100">
                        {element.valoresComision?.porcentajeDescuento! > 1 && (
                          <div className="row mt-2">
                            <div className="col-8 col-sm-6 col-md-12 col-lg-8 infoDiscount">
                              <div className="bg-success rounded-pill discountMessage p-1">
                                <p className="text-white m-0 mx-2">
                                  <b>
                                    {element.valoresComision?.porcentajeDescuento!.toFixed(
                                      0
                                    )}
                                    % Descuento&nbsp;
                                    {/* {element.emisor.toUpperCase()} */}
                                  </b>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {esAseguradora && !cftConDescuento && (
                          <div className="row"></div>
                        )}
                        {!esAseguradora && !cftConDescuento && (
                          <div className="row mb-3"></div>
                        )}
                        <div className="row mt-3 align-items-center px-3">
                          <div className="col-7 col-lg-12 d-flex w-100 mb-3 align-items-center">
                            <div className="h-100 col-lg-2 col-2 pr-2">
                              {IconCard(element)}
                            </div>
                            <div className="px-2 py-2">
                              <h3 className="ff-kanit m-0 color-primary">
                                <strong className="fs-22 text-uppercase">
                                  {element.idAseguradora > 0
                                    ? "Póliza"
                                    : "Certificado"}{" "}
                                </strong>
                              </h3>
                              <div className="">
                                <span className="garantia">GARANTÍA</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row px-3 pb-3">
                          <div
                            className={
                              "col-12 fs-14 color-text  text-description m-0"
                            }
                          >
                            {element.descripcionEmisor}
                          </div>
                        </div>

                        {/* <div className="row text-end px-2">
                            {element.idAseguradora > 0 && (
                              <span
                                className="text-link fs-12 pb-1"
                                style={{
                                  textDecorationLine: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleOnClickDetallesCobertura(element);
                                }}
                              >
                                Detalle de coberturas{" "}
                                <i className="fa fa-angle-right"></i>
                              </span>
                            )}
                          </div> */}

                        <div className="row">
                          <div className="col-12">
                            <hr className="m-0" />
                          </div>
                          <div className="col-12 mt-2">
                            <div className="d-flex px-3 justify-content-between align-items-baseline">
                              <p className="fs-12 mb-0">Valor neto</p>
                              <p className="fs-16 mb-0">
                                <b>
                                  {convertToPeso(
                                    element.valoresComision?.comisionBruto
                                  )}
                                </b>
                              </p>
                            </div>
                          </div>
                          {!esAseguradora && !cftConDescuento && (
                            <div>
                              <br />
                            </div>
                          )}
                          {existeAseguradoraConDescuento && esAseguradora && (
                            <div className="col-12">
                              <div className="d-flex px-3 justify-content-between align-items-baseline">
                                <p className="fs-12 text-danger mb-1">
                                  Descuento
                                </p>
                                <p className="fs-16 text-danger mb-0">
                                  <b>
                                    -
                                    {convertToPeso(
                                      element.valoresComision?.descuento
                                    )}
                                  </b>
                                </p>
                              </div>
                            </div>
                          )}

                          {cftConDescuento && !esAseguradora && (
                            <div className="col-12">
                              <div className="d-flex px-3 justify-content-between align-items-baseline">
                                <p className="fs-12 text-danger mb-1">
                                  Descuento
                                </p>
                                <p className="fs-16 text-danger mb-0">
                                  <b>
                                    -
                                    {convertToPeso(
                                      element.valoresComision?.descuento
                                    )}
                                  </b>
                                </p>
                              </div>
                            </div>
                          )}

                          {existeAseguradoraConIva && esAseguradora ? (
                            <div className="col-12">
                              <div className="d-flex px-3 justify-content-between align-items-baseline">
                                <p className="fs-12 color-primary mb-1">IVA</p>
                                <p className="fs-16 color-primary mb-1">
                                  <b>
                                    {convertToPeso(
                                      element.valoresComision?.ivaClp
                                    )}
                                  </b>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <>
                              <b>
                                <br />
                              </b>
                            </>
                          )}

                          <div className="col-12">
                            <hr className="mt-0" />
                          </div>
                          <div className="col-12">
                            <div className="d-flex px-3 justify-content-between align-items-baseline">
                              <div className="d-flex align-items-baseline col-sm-8 col-lg-7">
                                <p className="fs-14">
                                  <b>Comisión a pagar</b>
                                </p>
                                <MTooltip
                                  iconClass="fas fa-question-circle fs-10"
                                  content={
                                    <h6 className="text-center">
                                      Este es el precio final de tu comisión.{" "}
                                      <br />
                                      {element.valoresComision?.ivaClp! > 0 &&
                                      element.valoresComision?.descuento! >
                                        0 ? (
                                        <>Incluye IVA y descuento</>
                                      ) : element.valoresComision?.ivaClp! >
                                        0 ? (
                                        <>Incluye IVA </>
                                      ) : element.valoresComision?.descuento! >
                                        0 ? (
                                        <>Incluye descuento </>
                                      ) : (
                                        <></>
                                      )}
                                    </h6>
                                  }
                                ></MTooltip>
                              </div>
                              <p className={"color-success fs-18"}>
                                <b>
                                  {convertToPeso(
                                    element.valoresComision?.comision
                                  )}
                                </b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <BtnSelectCard
                        quoteData={props.quoteData}
                        addOrEdit={props.addOrEdit}
                        handlerSelect={(e) => props.handlerSelect(e)}
                        element={element}
                        warantySelected={props.warantySelected}
                      />
                    </MCards>
                  </div>
                )
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default WarrantiesCard;
