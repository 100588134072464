import React from "react";

import { convertToCurrency } from "@helpers/helpers";
import { OperacionDTO } from "Models/Operation";
import dayjs from "dayjs";

interface PropsVerifyDataReissueForm {
  tenderData: OperacionDTO | undefined;
}

const VerifyDataReissueForm = ({ tenderData }: PropsVerifyDataReissueForm) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="rounded-card p-2">
            <div className="row">
              <div className="col-12">
                <h3 className="subtitle-mini">Datos licitación</h3>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">RUT mandante</span>
                          <br />
                          {tenderData?.rutUnidadMandante}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Nombre mandante</span>
                          <br />
                          {tenderData?.nombreOrganismoMandante}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Región</span>
                          <br />
                          {tenderData?.regionUnidadMandante}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Comuna</span>
                          <br />
                          {tenderData?.comunaUnidadMandante}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group ">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Dirección</span>
                          <br />
                          {tenderData?.direccionUnidadMandante}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="rounded-card p-2">
            <div className="row">
              <div className="col-12">
                <h3 className="subtitle-mini">Datos Garantía</h3>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group ">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Finalidad</span>
                          <br />
                          {tenderData?.finalidad}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary"></div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group ">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Desde</span>
                          <br />

                          {dayjs(tenderData?.fechaInicio).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group ">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Hasta</span>
                          <br />
                          {dayjs(tenderData?.fechaTermino).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group ">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Moneda</span>
                          <br />
                          {tenderData?.tipoMoneda}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group ">
                      <div className="col-10">
                        <p className="textolabel03">
                          <span className="textolabel02">Monto</span>
                          <br />
                          {convertToCurrency(
                            tenderData?.tipoMoneda,
                            tenderData?.montoCertificado
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="rounded-card-bg lborder-primary">
                  <div className="floating-label">
                    <div className="input-group ">
                      <div className="col-12">
                        <p className="textolabel03">
                          <span className="textolabel02">Glosa</span>
                          <br />
                          {tenderData?.glosa}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyDataReissueForm;
