import React from 'react';
import { useAppSelector } from '../../../../../scripts/redux/hooks';
import './ExternalInstabilityProblem.scss'
const ExternalInstabilityProblem = () => {

    const { externalInstabilityProblem } = useAppSelector((store) => store.account)
    if (externalInstabilityProblem?.status) {
        return (
            <div className='ExternalInstabilityProblem'>
                <section className="alertEmision">
                    <div className="alert-info" role="alert">
                        <div className="container d-flex align-items-center">
                            <div className="icon">
                            <i className="fas fa-info-circle" aria-hidden="true"></i>
                            </div>
                            <div className='p-2'>
                                <span className='fs-16 fw-700'>{externalInstabilityProblem.tittle}</span>
                                <p className='fs-14 fw-400' style={{ marginBottom: 0 + "px" } as React.CSSProperties}>
                                    {externalInstabilityProblem.message}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    } else { return null }
}

export default ExternalInstabilityProblem;
