import React from "react";
import "../../../Private/Home/Banner/HomeBannerCampaign.scss"

function CartBannerCampaign() {
  return (
    <div className="row beneficio-so pb-2">
      <div className="col-12 col-lg-12">
        <p className="p-0 m-0 p-custom">
          <span>Se ha aplicado tu
            <span className="color-success">
              <b> beneficio activo </b>
            </span>
            de certificado Seriedad de la Oferta costo $0
          </span>
        </p>
      </div>
    </div>
  );
}

export default CartBannerCampaign;