import { CalcularComisionOperacion } from 'Models/Operation';

import { MAxios } from '../../conf';

const GetValueCommission = async (
  fechaInicio: Date | string,
  fechaTermino: Date | string,
  finalidad: string,
  tipoMoneda: string,
  montoGarantia: number,
  codigoLicitacion: string | null,
  id: number = 0,
  rutUnidadMandante: string,
  rutCliente: string,
  
) => {

  let data: CalcularComisionOperacion = {
    fechaInicio,
    fechaTermino,
    finalidad,
    tipoMoneda,
    montoGarantia,
    rutProveedor: rutCliente,
    codigoLicitacion,
    idOperacion: id,
    rutMandante: rutUnidadMandante,
  };

  const endpoint = "CalculateCommission/GetValueCommission";

  // const res = await MAxiosFormData().post(endpoint, formData);
  const res = await MAxios().post(endpoint, data);
  return res.data;
};

const ApiCommission = {
  GetValueCommission,
};

export default ApiCommission;
