import { MAlert } from "@inversiones-ma/finfast-react-controls";
import React from "react";

function AlertErrorReemision() {
  return (
    <div className="col-12 col-md-12 center">
      <MAlert color="info" className="slim w-100">
        <p
          className="textAlert"
          style={{ fontSize: 16 + "px" } as React.CSSProperties}
        >
          {" "}
          En caso de error,{" "}
          <span className="ingresa-alert02 ps-1 ">
            el costo de endoso es 1 UF{" "}
          </span>
          <br />
          <span
            style={{ fontSize: 14 + "px" } as React.CSSProperties}
            className="textAlert2"
          >
            Cambios menores como:{" "}
            <span className="ingresa-alert02 ps-1 ">
              glosa, razón social o dirección, tendrán costo $0 en el primer
              endoso.
            </span>
          </span>
        </p>
      </MAlert>
    </div>
  );
}

export default AlertErrorReemision;
