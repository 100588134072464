export const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmL92cV2Ctz9+q99/KN9u
nq8r6TTNHSx1Mado+dooZzksco9uiLZfi5j7hW0VEAy9ysT9Y2uBHLY6Q8JFRqCo
bN6IdHqNnMwcF5DrXDSOsecXGfhf655eUUh4YVxgTu9PhDnhM1y2UDLqzU7Kj2Qm
dCxTVhqs2jn7rTzjv1BUDmJacRLswoqG8zoN1iQmTGGrDPNZ8SeEFxom0Xw72dAq
Jjn5Togb7lu2KW2Rl82UfEzS3KypGbrTcgkJqan5P/WGT04PVf4VZf/kA9YmZHtS
Jt+bbzPLlR7VOrd/7lgwkUMYTHQjjc5QzIu4xjzLagCEPS4sq0j9WwxgdWrQT4N9
7wIDAQAB
-----END PUBLIC KEY-----`;

const parameters = 
{
    CODE_RECAPTCHA: "6LeG_fobAAAAAKNEJ4BVBk0G4utCbg3-JXpsISBG",
}


export default parameters;