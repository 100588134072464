import { MAlert } from "@inversiones-ma/finfast-react-controls";
import React from "react";

interface PropsInfoInsurance {
  // props
}

const InfoInsurance = (props: PropsInfoInsurance) => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-9">
            <MAlert color="info">
              <span>
                Todas las <b>pólizas SURA</b> serán{" "}
                <b>visualizadas en UF en la Garantía emitida,</b> independiente
                del tipo de moneda que sea elegido.
              </span>
            </MAlert>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoInsurance;
