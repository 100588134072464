import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { loadFromSessionStorage, saveToSessionStorage } from "./localStorage";
import GlobalReducer from "../reducers/GlobalReducer";
import AccountReducer from "../reducers/AccountReducer";
import SessionReducer from "../reducers/SessionReducer";
import QuoteReducer from "@redux/reducers/QuoteReducer";
import NewCertificateReducer from "../reducers/NewCertificateReducer";
import FilterReducer from "../reducers/FilterReducer";
import StepReducer from "@redux/reducers/StepReducer";
import PreferenceReducer from "../reducers/PreferenceReducer";
import TenderReducer from "../reducers/TenderReducer";
import InsuranceLineReducer from "../reducers/InsuranceLineReducer";


const rootReducer = combineReducers({
  global: GlobalReducer,
  account: AccountReducer,
  session: SessionReducer,
  newCertificate: NewCertificateReducer,
  steps: StepReducer,
  quote: QuoteReducer,
  tender: TenderReducer,
  filter: FilterReducer,
  preference: PreferenceReducer,
  InsuranceLine: InsuranceLineReducer,
});

const store = configureStore({  
  reducer: rootReducer,
  preloadedState: loadFromSessionStorage(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

// listen for store changes and use saveToLocalStorage to
// save them to localStorage en cada cambio
// store.subscribe(() => saveToSessionStorage(store.getState()));
//save on close o refresh
window.onbeforeunload = () => {
  saveToSessionStorage(store?.getState());
};


export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
