import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { useForm } from "react-hook-form";
import imgSII from "@img/nuevos-clientes-sii.svg";
import imgAws from "@img/datos-pt-aws.svg";
import imgCmf from "@img/nos-respalda-cmf.svg";
import siiExplicacionCredenciales from "@img/sii-explicacion-credenciales.svg";
import {
  MButton,
  MInputPassword,
  MInputRut,
  MTooltip,
} from "@inversiones-ma/finfast-react-controls";
import ApiTributaryInformation from "@api/tributaryFolder/tributaryFolder";
import ApiProvider from "@api/proveedor/Proveedor";
import GenericBaseModel from "@api/GenericBaseModel";
import {
  CTEResponse,
  TributaryInformation,
  TypeCTESource,
} from "@redux/models/TributaryInformationModels";
import {
  loadSubscriptionStatus,
  loadSubscriptionStatusOk,
  requireSiiCredentialsOk,
  updateSiiData,
  updateSkipSiiData,
} from "@redux/actions/AccountActions";
import "./CartBannerSII.scss";
import { GetDatamartCertificateMessageFromCode } from "./GetDatamartCertificateMessageFromCode";
import PopupCertificate from "./PopupCertificate";
import { SubscriptionStatus } from "@redux/models/AccountModels";
import { encryptData } from "@helpers/helpers2";

interface Props {
  setShowMessageSii: Function;
  isUpdated: boolean;
  auxValue: boolean;
  setButtonOmit: Function;
}

interface IFormInput {
  rutEmpresa: string;
  contrasena: string;
}

const CartBannerSII = (props: Props) => {
  const dispatch = useAppDispatch();
  const { clientOmitSii, run } = useAppSelector((store) => store.account);

  const defaultData: IFormInput = {
    rutEmpresa: run as string,
    contrasena: "",
  };
  const [inRequest, setInRequest] = useState<boolean>(false);
  const [showModalError, setShowModalError] = useState<boolean>(false);
  const [propsModalError, setPropsModalError] = useState({
    icon: undefined,
    title: undefined,
    text: undefined,
  });

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: defaultData,
  });

  const { handleSubmit } = form;

  const onSubmit = (data: IFormInput) => {
    setInRequest(true);
    let dataSii: TributaryInformation = {
      password: encryptData(data.contrasena),
      eCTESource: TypeCTESource.garantias,
    };

    ApiTributaryInformation.saveAccountSII(dataSii)
      .then((res: GenericBaseModel<CTEResponse>) => {
        if (res.success) {
          dispatch(updateSiiData(false));
          ApiProvider.GetEstadoSubscripcionCte(run as string).then(
            (res: GenericBaseModel<SubscriptionStatus>) => {
              if (res.success) {
                dispatch(loadSubscriptionStatus(res.data));
                dispatch(requireSiiCredentialsOk(false));
              }
            }
          );
          props.setShowMessageSii(true);
        } else {
          const modalError: any = GetDatamartCertificateMessageFromCode(
            res.code
          );
          setPropsModalError(modalError);
          setShowModalError(true);
          setInRequest(false);
        }
      })
      .catch(() => {
        setInRequest(false);
      });
  };

  const handleSkip = () => {
    let infoOmite = {
      clientOmitSii: clientOmitSii,
      clientOmitSiiExtraInfo: true,
    };
    if (props.isUpdated) {
      loadSubscriptionStatusOk("OK");
    }
    dispatch(updateSkipSiiData(infoOmite));
    dispatch(requireSiiCredentialsOk(false));
  };
  return (
    <>
      <div className="col-12 col-lg-5 my-3 bg-white p-4 lborder-primary pb-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="cartBanner-sii sii-nuevos-clientes">
            {!props.isUpdated && (
              <h3 className="fw-900 fs-20 fw-bold">
                Vincula tus credenciales de SII
              </h3>
            )}
            <img src={imgSII} alt="sii" className="img-nuevosclientes-sii" />
            {props.isUpdated ? (
              <h3
                className="titles-left fs-24 mt-3 mb-4"
                style={{ fontWeight: "800" }}
              >
                Potencia tu experiencia con nosotros
              </h3>
            ) : (
              <h3
                className="titles-left fs-24 mt-3 mb-4"
                style={{ fontWeight: "800" }}
              >
                ¿Por que solicitamos tus credenciales de SII?
              </h3>
            )}
            {props.isUpdated ? (
              <p className={"mt-5"}>
                <strong>
                  Mantener tus credenciales actualizadas nos permitirá brindarte
                  un servicio ágil y ofrecerte las mejores ofertas disponibles.
                </strong>
                <br />
                <br />
                Para tu seguridad, tus datos serán encriptados y almacenados en
                servidores seguros de Amazon y utilizados solo con el objetivo
                de obtener tu carpeta tributaria y declaración jurada.
              </p>
            ) : (
              <p className={"mt-5"}>
                <strong>
                  Sabemos que podemos mejorar tu experiencia en nuestra
                  plataforma verificando tu información tributaria en línea, y
                  así poder ofrecerte un servicio más expedito y con ofertas
                  personalizadas.
                </strong>
                <br />
                <br />
                Aseguramos que tus datos serán encriptados y almacenados en
                servidores seguros de Amazon, y utilizados solo con el objetivo
                de obtener tu carpeta tributaria y declaración jurada.
              </p>
            )}
            <div className="row">
              <div className="col-12 col-sm-6 my-2">
                <img src={imgAws} alt="aws" className="icon-rspaldo" />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <img src={imgCmf} alt="cmf" className="icon-rspaldo" />
              </div>
            </div>
            <div className="col-12 py-3 pb-0">
              <h4 className="sii-tittle  fw-bold text-center">
                Ingresa tus credenciales de SII
                <span className="p-0 m-0">
                  <MTooltip
                    content={
                      <div className="row p-0 m-0">
                        <div className="col-7 fs-12 ps-0 pe-0 mt-1">
                          <h6 className="fw-bold">
                            ¿Cuales son mis credenciales de SII?
                          </h6>
                          <p className="fs-14 ">
                            Es la{" "}
                            <span className="color-primary fw-bold">
                              Clave Tributaria
                            </span>{" "}
                            con la que ingresas en la plataforma de Servicios de
                            Impuestos Internos
                          </p>
                        </div>
                        <div className="col-5 p-1 px-1 mb-0 my-0">
                          <img
                            className="pb-0 pt-0 mt-0"
                            src={siiExplicacionCredenciales}
                            alt="siiExplicacionCredenciales"
                            style={{ height: "150px", width: "120px" }}
                          />
                        </div>
                      </div>
                    }
                    iconClass={"fas fs-5 fas fa-question-circle pb-2"}
                  />
                </span>
              </h4>
              <div className="col">
                <div className="floating-label">
                  <MInputRut
                    form={form}
                    name="rutEmpresa"
                    label="RUT empresa"
                    className="form-control enabled-control info-form input-tag"
                    placeholder="RUT empresa"
                    rules={{
                      required: "El RUT de la empresa es requerido",
                    }}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col">
                <div className="floating-label">
                  <MInputPassword
                    label="Contraseña"
                    name="contrasena"
                    placeholder="Contraseña"
                    rules={{
                      required: "Debes ingresar la contraseña",
                    }}
                    form={form}
                  />
                </div>
              </div>
              <div className="row potegido-recordatorio">
                <div className="col-1 pt-1">
                  <span className="color-success">
                    <i className="fas fa-lock me-2"></i>
                  </span>
                </div>
                <div className="fs-14 col-10 pe-1 m-0 mb-3">
                  {props.isUpdated ? (
                    <span className="color-success">
                      Esta información quedará totalmente protegida para tu
                      seguridad
                    </span>
                  ) : (
                    <span className="color-success">
                      Tus claves de SII y certificado digital son encriptadas,
                      almacenadas y administradas a través de AWS KMS <br></br>{" "}
                      <a
                        className="color-success"
                        href="https://aws.amazon.com/es/kms/faqs/"
                      >
                        <strong>(Key Management Service, de Amazon).</strong>
                      </a>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row pt-0">
              <div className="col-12 text-center my-2">
                <MButton
                  type="submit"
                  className="sistema external w-100"
                  loading={inRequest}
                >
                  Vincular
                </MButton>
              </div>
              {clientOmitSii && (
                <div className="col-12 text-center my-2">
                  <MButton
                    type="button"
                    className="btn btn-primary sistema external w-100"
                    outline
                    onClick={handleSkip}
                  >
                    Omitir y continuar <i className="fas fa-arrow-right"></i>
                  </MButton>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      {
        <PopupCertificate
          {...propsModalError}
          showModal={showModalError}
          setShowModal={setShowModalError}
        />
      }
    </>
  );
};

export default CartBannerSII;
