import { MAlert } from "@inversiones-ma/finfast-react-controls";

import React from "react";

interface Props {
  tipoMoneda: String;
}

//En home el form el tipo de moneda (currency) entrega el ID 1:CLP, 2:UF, 3: USD
const CurrencyFeeAlert = (propsComponente: Props) => {
  if (
    propsComponente.tipoMoneda === "1" ||
    propsComponente.tipoMoneda === "CLP" ||
    propsComponente.tipoMoneda === "" ||
    propsComponente.tipoMoneda === undefined
  ) {
    return <></>;
  } else {
    return (
      <MAlert color="info" className="p-2 px-0">
        <div>
          <h5 className="m-0 p-0 fs-16">
            El tipo de moneda seleccionado tiene recargo
          </h5>
          <p className="m-0 p-0 fs-14">
            La compra de tu garantía en <b className=" fs-16">UF</b> o <b className=" fs-16">USD</b> tiene un recargo
            en el valor final del calculo de tu comisión.
          </p>
        </div>
      </MAlert>
    );
  }
};

export default CurrencyFeeAlert;
