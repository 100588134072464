import React, { useEffect, useState } from "react";
import { useAppDispatch } from "@redux/hooks";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import * as operation from "@app/operation/operation";
import { OriginIncome } from "Models/Operation";

interface PropsButtonAction {
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
  operationInRequest: boolean;
  disableBtn: () => boolean;
  closeModal?: () => void;
}

type MyFunctionType = {
  myFunction: () => void;
  textButton: string;
  classButton: string;
};

const ButtonAction = (props: PropsButtonAction) => {
  const dispatch = useAppDispatch();
  const [combinedFunction, setCombinedFunction] =
    useState<MyFunctionType | null>(null);
  const [isCreated, setIsCreated] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    if (!isCreated) {
      const actionEditClose = (): MyFunctionType => {        
        let myFunction = () => {};
        let classButton = "btn btn-auxiliar sistema w-100";
        let textButton = "Volver";

        if (
          props.addOrEdit === NewCertificateModels.Step.ingresar &&
          (props.origen === "cart" || props.origen === "addQuote")
        ) {
          textButton = "Cancelar";
          classButton = "btn btn-secondary outline sistema w-100";
          myFunction = props.closeModal!;
        }

        if (
          props.addOrEdit === NewCertificateModels.Step.ingresar &&
          props.origen !== "cart" &&
          props.origen !== "addQuote"
        ) {
          textButton = "Volver";
          classButton = "btn btn-auxiliar outline sistema w-100";
          myFunction = () => operation.handlerSendToSeleccionarDatos(dispatch)!;
        }

        return {
          myFunction,
          textButton,
          classButton,
        };
      };

      setCombinedFunction(actionEditClose);
      if (isMounted) {
        setIsCreated(true);
      }
    }

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [isCreated]);

  return (
    <>
      <div className="row justify-content-center align-items-center pb-4 mt-2">
        <div
          className={
            (props.addOrEdit === NewCertificateModels.Step.ingresar &&
            (props.origen === "addQuote" || props.origen === "cart")
              ? "col-md-6"
              : "col-md-3") + " pt-3 col-12 order-1 order-md-0 my-auto py-2"
          }
        >
          <MButton
            className={combinedFunction?.classButton}
            type="button"
            onClick={() => combinedFunction?.myFunction()}
          >
            {combinedFunction?.textButton === "Volver" && (
              <i className="fas fa-arrow-left me-3"></i>
            )}
            {combinedFunction?.textButton}
          </MButton>
        </div>

        <div
          className={
            (props.addOrEdit === NewCertificateModels.Step.ingresar &&
            (props.origen === "addQuote" || props.origen === "cart")
              ? "col-md-6"
              : "col-md-3") + " pt-3 col-12 order-0 order-md-1 mb-2"
          }
        >
          <MButton
            className="btn btn-auxiliar sistema w-100"
            type="submit"
            loading={props.operationInRequest}
            disabled={props.disableBtn()}
          >
            Confirmar datos
          </MButton>
        </div>
      </div>
    </>
  );
};

export default ButtonAction;
