import { MAxios } from "../conf";

const GetPaymentMethods = async () => {
  const endpoint = "PaymentMethods/GetPaymentEnabled";

  const res = await MAxios().get(endpoint);

  return res.data;
};

const ApiPayment = {
    GetPaymentMethods
};

export default ApiPayment;