import React, { useEffect, useState } from "react";
import imgMoneyMan from "@img/cross-selling/moneyMan-garantias-crossSelling.png";
import imgOkWoman from "@img/cross-selling/okWoman-garantias-crossSelling.png";
import { MButton, MLoadingLight } from "@inversiones-ma/finfast-react-controls";
import ApiMembresiaEnabledProducs, {
  ValidateProducts,
} from "@api/membresia/EnabledProducts";
import { useAppSelector } from "@redux/hooks";
import {
  convertToPeso,
  FinfastProductRedirectToProduct,
} from "@helpers/helpers";
import { finfast } from "@config/environment";
import "./CrossSellingFactoring.scss";

const CrossSellingFactoring = () => {
  const idProduct = 3;
  const { token } = useAppSelector((store) => store.account);

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [productStatus, setProductStatus] = useState<ValidateProducts | null>(
    null
  );

  const productOnClick = () => {
    if (productStatus?.actionButton === 0) {
      FinfastProductRedirectToProduct(idProduct, token, [productStatus]);
    } else if (productStatus?.actionButton === 5) {
      window.location.href = finfast() + "/PendingContract/" + idProduct;
    }
  };

  const newClientOnClick = () => {
    window.location.href = finfast() + "/NewClientFactoring";
  };

  useEffect(() => {
    setLoadingStatus(true);

    ApiMembresiaEnabledProducs.GetProductStatus(idProduct).then((resp) => {
      setLoadingStatus(false);

      if (resp.success) {
        setProductStatus(resp.data);
      }
    });
  }, []);

  if (loadingStatus) {
    return <MLoadingLight inContainer />;
  }

  if (
    productStatus === null ||
    productStatus?.statusMatriz !== "MatrizAprobada"
  ) {
    return <></>;
  }

  return (
    <>
      <div className="col-12 col-lg-10 cross-selling-banner-section text-center mx-auto">
        <h3>También te podría interesar</h3>
        {productStatus.actionButton === 1 && (
          <div className="row mx-auto my-5 cross-selling-banner">
            <div className="col-12 p-0 factoring-linea-aprobada banner-factoring">
              <div className="row mx-auto justify-content-center p-2 p-md-0">
                <div className="col-10 m-auto border-primary d-none d-md-inline-flex"></div>
                <div className="col-12 px-0 banner-content">
                  <div className="row align-items-center">
                    <div className="col-4 p-0 d-none d-md-inline">
                      <img src={imgMoneyMan} alt="factoring-corss-selling" />
                    </div>
                    <div className="col-12 col-md-8 text-center pt-2 pt-md-0 ">
                      <p className="color-auxiliar fw-bold fs-5">
                        <i className="fas fa-bolt me-3"></i>Activa tu línea
                        <i className="fas fa-bolt ms-3"></i>
                      </p>
                      <h4>Factoring</h4>
                      <h6>{productStatus.saleText.replace("<br/>", "")}</h6>
                      <hr className="my-2 my-md-3" />
                      <p className="color-primary">Línea aprobada de</p>
                      <h5>{convertToPeso(productStatus.montoLineaAprobado)}</h5>
                      <MButton
                        color="secondary-invested"
                        className="col-10 col-md-6 mb-2 mb-md-0"
                        onClick={newClientOnClick}
                      >
                        Activa tu línea
                      </MButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(productStatus.actionButton === 0 ||
          productStatus.actionButton === 5) && (
          <div className="row mx-auto my-5 cross-selling-banner ">
            <div className="col-12 p-0 factoring-habilitado banner-factoring">
              <div className="row mx-auto justify-content-center p-2 p-md-0">
                <div className="col-12 col-md-10 m-auto border-primary"></div>
                <div className="col-12 px-0 banner-content">
                  <div className="row align-items-center">
                    <div className="col-4 p-0">
                      <img src={imgOkWoman} alt="cross-Selling-Factoring" />
                    </div>
                    <div className="col-7 text-center pt-2 pt-md-0 px-0 px-md-3">
                      <h4>Factoring</h4>
                      <h6>100% ONLINE y sin papeleos</h6>
                      <hr />
                      <p className="color-primary mb-3">
                        Anticipa <span className="color-secondary">ahora</span>{" "}
                        <br />
                        el pago de tus facturas
                      </p>
                      <MButton
                        color="secondary-invested"
                        className="col-10 col-md-6 mb-2 mb-md-0"
                        onClick={productOnClick}
                      >
                        Ir a Factoring
                      </MButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <hr />
      </div>
    </>
  );
};

export default CrossSellingFactoring;
