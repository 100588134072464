import React from "react";
import { useAppSelector } from "@redux/hooks";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import dayjs from "dayjs";
import { convertToCurrency } from "@helpers/helpers";

const DatosGarantia = () => {
  const { tenderData } = useAppSelector((store) => store.newCertificate);

  return (
    <>
      <div className="col-12 pb-4">
        <MCards
          className="px-4 pb-4"
          style={{ boxShadow: "0px 0px 0px" }}
          color="successHome"
        >
          <div className="col-12 pt-4">
            <h3 className="subtitle-sub-mini color-secondary fw-bold fs-18 mb-0">
              Datos garantía
            </h3>
          </div>
          <div className="row d-flex pt-2">
            <div className="col-12 col-lg-6 pt-2 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">Finalidad</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {tenderData?.finalidad}
              </p>
            </div>
            <div className="col-sm-0 col-lg-6 pt-2 d-flex flex-row"></div>
            <div className="col-12 col-lg-6 pt-lg-1 pt-sm-0 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">Fecha inicial</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {dayjs(tenderData?.fechaInicio).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="col-12 col-lg-6 pt-1 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">Fecha término</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {dayjs(tenderData?.fechaTermino).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="col-12 col-lg-6 pt-1 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">Tipo moneda</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {tenderData?.tipoMoneda}
              </p>
            </div>
            <div className="col-12 col-lg-6 pt-1 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">Monto garantía</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {convertToCurrency(
                  tenderData?.tipoMoneda,
                  tenderData?.montoCertificado
                )}
              </p>
            </div>
            <div className="col-12 col-lg-12 pt-1">
              <h3 className="fw-bold fs-14 mb-0" style={{ height: "15px" }}>
                Glosa
              </h3>
              <p className="fs-14 mb-0 pt-2">{tenderData?.glosa}</p>
            </div>
          </div>
        </MCards>
      </div>
    </>
  );
};

export default DatosGarantia;
