import React from "react";
import MenuClient from "./partial/MenuClient";
import MenuPartialSelector from "./partial/MenuPartialSelector";
import HomeBannerNoEmitir from "../../pages/Private/Home/Banner/HomeBannerNoEmitir";
import { useAppSelector } from "@redux/hooks"
import ExternalInstabilityProblem from "../../pages/Private/Home/BannerExternalInstabilityProblem/ExternalInstabilityProblem";

const MasterPrivateNavbar = () => {
  const { enabledEmit } = useAppSelector((store) => store.account)

  return (
    <>
      <header className="fixed-top-main">
        <MenuClient />
        <MenuPartialSelector />

      </header>

      {!enabledEmit && <HomeBannerNoEmitir />}

      <ExternalInstabilityProblem />
    </>
  );
};

export default MasterPrivateNavbar;
