import { MAxios } from "../conf";

const GetUf = async () => {
    const endpoint = "Uf/GetUf";

    const res = await MAxios().get(endpoint);

    return res.data;
};

const GetUsd = async () => {
    const endpoint = "USD/GetUsd";

    const res = await MAxios().get(endpoint);

    return res.data;
};

const GetCurrency = async () => {
    const endpoint = "Currency/GetCurrency";

    const res = await MAxios().get(endpoint);

    return res.data;
};

const ApiCurrency = {
    GetUf
	, GetUsd
	, GetCurrency
};

export default ApiCurrency;