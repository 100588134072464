import { useAppSelector, useAppDispatch } from "@redux/hooks";
import React, { useState } from "react";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import { toDate } from "@helpers/helpers";
import ConfirmationMessage from "./ConfirmationMessage";
import ApiFilter from "@api/filter/Filter";
import { Filter } from "@redux/models/FilterModels";
import EditPopup from "./EditPopup";
import { loadFilters } from "@redux/actions/FilterActions";
import * as helpers2 from "@helpers/helpers2";

function MyFilters() {
  const dispatch = useAppDispatch();
  const { loginDate, loginDateClient } = useAppSelector((store) => store.account);

  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showPopUpEdit, setShowPopUpEdit] = useState(false);
  const [filter, setFilter] = useState<any | null>(null);
  const [filterIdDelete, setfilterIdDelete] = useState(0);

  const { filters } = useAppSelector((store) => store.filter);

  const currentDate:Date = loginDate && loginDateClient ? helpers2.getDate(loginDate, loginDateClient) : new Date();

  function handlerFilterEditOnClick(filter: any) {
    let newFilter = {
      codigoProducto: filter.codigoProducto
        ? filter.codigoProducto.split(",")
        : "",
      descripcion: filter.descripcion,
      excluir: filter.excluir ? filter.excluir.split(",") : "",
      preferencia: filter.preferencia ? filter.preferencia.split(",") : "",
      fechaCreacion: currentDate,
      habilitado: true,
      id: filter.id as number,
      nombreMandante: filter.nombreMandante
        ? filter.nombreMandante.split(",")
        : "",
      regionMandante: filter.regionMandante
        ? filter.regionMandante.split(",")
        : "",
      rubro: "",
      rutMandante: filter.rutMandante ? filter.rutMandante.split(",") : "",
      sectorMandante: "",
      tipoLicitacion: filter.tipoLicitacion
        ? filter.tipoLicitacion.split(",")
        : "",
      garantia: filter.garantia,
    };
    setFilter(newFilter);
    setShowPopUpEdit(true);
  }

  function handlerGridDeleteOnClick(idFilter: number) {
    setShowDeleteMessage(true);
    setfilterIdDelete(idFilter);
  }

  async function handlerGridToggleSwitchOnClick(filter: Filter) {
    let data = {
      ...filter,
      habilitado: !filter.habilitado,
    };

    let indexCurrentFilter = filters?.findIndex(
      (f) => f.id === data.id
    ) as number;
    let cloneFilters = [...(filters as Filter[])];
    cloneFilters[indexCurrentFilter] = data;

    dispatch(loadFilters(cloneFilters));

    ApiFilter.Save(JSON.stringify(data));
  }

  return (
    <>
      <MCards
        className="p-4"
        color="auxiliar"
        loadingText=""
        onClick={() => {}}
        size="default"
        softColor
        style={{}}
      >
        <div className="row py-3">
          <div className="col-12">
            <h3 className="subtitle-mini text-center">Mis filtros</h3>
          </div>
        </div>
        {filters?.map((e, index) => (
          <>
            <div key={index} className="row d-flex align-items-center pt-2 pb-2">
              <div className="col-12 col-md-6 text-center text-md-start">
                <p className={e.habilitado ? "nombrefiltro" : "nombrefiltro02"}>
                  {e.descripcion}
                </p>
                <p className={e.habilitado ? "fechafiltro" : "fechafiltro02"}>
                  Creado: {toDate(e.fechaCreacion)}
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div
                    className="col-4 text-center"
                    onClick={
                      e.habilitado
                        ? () => handlerFilterEditOnClick(e)
                        : () => {}
                    }
                  >
                    <span
                      className={
                        e.habilitado ? "iconofiltro01" : "iconofiltro02"
                      }
                      href="#."
                    >
                      <i className="fas fa-edit"></i>
                    </span>
                  </div>
                  <div
                    className="col-4 text-center"
                    onClick={
                      e.habilitado
                        ? () => handlerGridDeleteOnClick(e.id)
                        : () => {}
                    }
                  >
                    <span
                      className={
                        e.habilitado ? "iconofiltro01" : "iconofiltro02"
                      }
                      href="#."
                    >
                      <i className="fas fa-trash-alt"></i>
                    </span>
                  </div>
                  <div className="col-4 text-center pt-2">
                    <div className="acciones-tabla text-center">
                      <label className="switch">
                        <input
                          type="checkbox"
                          defaultChecked={e.habilitado}
                          onChange={() => handlerGridToggleSwitchOnClick(e)}
                        />
                        <span className="slider-switch round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        ))}
      </MCards>
      <ConfirmationMessage
        showModal={showDeleteMessage}
        setShowModal={setShowDeleteMessage}
        filterIdDelete={filterIdDelete}
      />

      <EditPopup
        showModal={showPopUpEdit}
        setShowModal={setShowPopUpEdit}
        filter={filter}
      />
    </>
  );
}

export default MyFilters;
