import React, { useEffect, useState } from "react";
import ApiCertificates from "@api/certificados/Certificados";
import "./Quotes.scss";
import CertificatesGrid from "./Partial/CertificatesGrid";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import * as LoadGrid from "@redux/actions/QuoteActions";
import NotResultsToday from "./Partial/NotResultsToday";
import NotResults from "./Partial/NotResults";
import MLoading from "./../../../controls/MLoading";
import * as helpers2 from "@helpers/helpers2";
import SuccessMessage from "../NewCertificate/partial/VerifyData/SuccessMessage";
import dayjs from "dayjs";
import Breadcrumbs from "../NewCertificate/partial/Breadcrumbs";

const Quotes = () => {
  const dispatch = useAppDispatch();
  const { firstGridData, secondGridData } = useAppSelector(
    (store) => store.quote
  );
  const { loginDate, loginDateClient } = useAppSelector(
    (store) => store.account
  );
  const { cart } = useAppSelector((store) => store.session);
  const currentDay: Date =
    loginDate && loginDateClient
      ? helpers2.getDateWithoutTime(loginDate, loginDateClient)
      : new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [firstGridDataFilter, setFirstGridDataFilter] = useState<any[]>([]);
  const [secondGridDataFilter, setSecondGridDataFilter] = useState<any[]>([]);
  const [countItems, setCountItems] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    callLoadToUpdateCertificates();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (firstGridData.length !== 0) {
      let tempFirstGridDataFilter = firstGridData.filter(
        (x) => !cart?.items.find((c) => c.id === x.id)
      );
      setFirstGridDataFilter(tempFirstGridDataFilter);
    }
    if (secondGridData.length !== 0) {
      let tempSecondGridDataFilter = secondGridData.filter(
        (x) => !cart?.items.find((c) => c.id === x.id)
      );
      setSecondGridDataFilter(tempSecondGridDataFilter);
    } else {
      setSecondGridDataFilter(secondGridData);
    }
  }, [cart, firstGridData, secondGridData]);

  const callLoadToUpdateCertificates = async () => {
    setIsLoading(true);
    await ApiCertificates.ListToUpdate().then((response) => {
      const toValidate = response.dataList;

      const firstGridData: any[] = toValidate.filter(
        (v: any) => dayjs(v.fechaIngreso).diff(currentDay, "day") === 0
      );
      const secondGridData: any[] = toValidate.filter(
        (v: any) => dayjs(v.fechaIngreso).diff(currentDay, "day") !== 0
      );
      setCountItems(firstGridData.length + secondGridData.length);
      dispatch(LoadGrid.setLoadFirstDataGrid(firstGridData));
      dispatch(LoadGrid.setLoadSecondDataGrid(secondGridData));
      dispatch(LoadGrid.setCurrentQuoteId(-1));
      setIsLoading(false);
    });
  };

  const handlerAddToCart = (value: boolean) => {
    setShowSuccessMessage(value);
  };

  return (
    <>
      <main className="bg-home">
        <section id="licitaciones" className="buscar-licitaciones">
          <div className="container">
            <div className="row">
              <Breadcrumbs
                paginaPrincipal="Comprar Garantías"
                paginaActual="Garantías cotizadas"
                paginaInicial="/buy-certificate"
              ></Breadcrumbs>
              <div className="col-12">
                <h2 className="titles-left">Cotizados</h2>
                <p>
                  En esta página podrás visualizar los certificados para tus
                  licitaciones que aún no has comprado.
                </p>
              </div>
            </div>
            {isLoading && <MLoading></MLoading>}
            {!isLoading && (
              <>
                {countItems === 0 && <NotResults />}
                {countItems >= 1 && (
                  <>
                    {firstGridDataFilter.length === 0 && <NotResultsToday />}

                    {firstGridDataFilter.length >= 1 && (
                      <>
                        <div className="row">
                          <div className="col-12">
                            <h3 className="subtitle-mini">Válidos por hoy</h3>
                          </div>
                          <div className="col-12">
                            <p>
                              En esta sección encontrarás los certificados
                              válidos con los datos del valor UF al día de hoy
                            </p>
                          </div>
                        </div>

                        <CertificatesGrid
                          dataSource={firstGridDataFilter}
                          isLoading={isLoading}
                          isValidToday={true}                 
                          AddToCart={(value) => handlerAddToCart(value)}
                        />
                      </>
                    )}
                    {secondGridDataFilter.length > 0 && (
                      <>
                        <div className="row">
                          <div className="col-12">
                            <h3 className="subtitle-mini">
                              Actualiza los datos
                            </h3>
                          </div>
                          <div className="col-12">
                            <p>
                              En esta sección encontrarás los certificados
                              cotizados previamente, para poder emitirlos debes
                              actualizar los datos
                            </p>
                          </div>
                        </div>
                        <CertificatesGrid
                          dataSource={secondGridDataFilter}
                          showDelete={false}
                          isLoading={isLoading}
                          AddToCart={(value) => handlerAddToCart(value)}
                        />
                        <br />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </section>
      </main>
      {/* <SuccessMessage
        showModal={showSuccessMessage}
        setShowModal={setShowSuccessMessage}
        close={() => setShowSuccessMessage(false)}
      /> */}
    </>
  );
};

export default Quotes;
