import * as GlobalModels from "@redux/models/GlobalModels";
import { sonRegionesIguales } from "./helpers";
import dayjs from "dayjs";
import parameters, { publicKey } from "@config/parameters";
import * as forge from 'node-forge'

export const getCommunes = (
  regionName: string,
  regions: GlobalModels.Region[] | undefined
): GlobalModels.Commune[] => {
  if (!regionName) return [];
  if (!regions || regions.length === 0) return [];
  const communes =
    regions.find((r) => sonRegionesIguales(regionName, r.name))?.communes ?? [];
  if (communes.length === 0) return [];
  return communes;
};

export const patternEmail = {
  value:
    /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Formato de correo inválido",
};

export const maxLengthGeneral = (value: number | undefined) => ({
  value: value,
  message: "Debes ingresar a lo más " + value + " caracteres",
});

export const vEmail = (requiredText: string | undefined, maxLength = 100) => ({
  required: requiredText ?? false,
  pattern: patternEmail,
  maxLength: maxLengthGeneral(maxLength),
});

export const comparableString = (value: string): string => {
  // eslint-disable-next-line
  let pattern: RegExp = /[!#%^*()_+=\[\]{};':"\\|<>\/?]+/;
  return value.toString().replace(pattern, "");
};

export const removeTimeFromDate = (date: Date): Date => {
  if (date === undefined || date === null) {
    return new Date(new Date().toDateString());
  } else if (typeof date === "string") {
    return new Date(new Date(date).toDateString());
  } else {
    return new Date(date.toDateString());
  }
};

export const getDate = (loginDate: Date, loginDateClient: Date): Date => {
  const loginDateStr: string = loginDate.toString().substring(0, 23);
  const loginDateClientStr: string = loginDateClient
    .toString()
    .substring(0, 23);
  const loginDateJs = dayjs(loginDateStr);
  const loginDateClientJs = dayjs(loginDateClientStr);
  const currentDateClientJs = dayjs(new Date());
  const diffMinutes: number = currentDateClientJs.diff(
    loginDateClientJs,
    "minute"
  );
  const currentDateJs = loginDateJs.add(diffMinutes, "minutes");
  return currentDateJs.toDate();
};

export const getDateWithoutTime = (
  loginDate: Date,
  loginDateClient: Date
): Date => {
  const date = getDate(loginDate, loginDateClient);
  return removeTimeFromDate(date);
};

export const getConfiguration = (
  listData: GlobalModels.Configuracion[] | undefined,
  key: string
) => {
  const list: GlobalModels.Configuracion[] = listData ?? [];

  let resp: GlobalModels.Configuracion = {
    bannerMulti2: "",
    fechaActualizacion: new Date(),
    llave: "No encontrado",
    valorAlfanumerico: null,
    valorBooleano: false,
    valorFecha: null,
    valorNumerico: null,
  };

  if (list) {
    const found = list.find((x) => x.llave === key);
    resp = { ...resp, ...found };
  }

  return resp;
};

export const encryptData = (dataClean: string) => {
  let rsa = forge.pki.publicKeyFromPem(publicKey);
  return window.btoa(rsa.encrypt(dataClean));
};
