import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useGliaInitialization = () => {
  const { run, email, name, executive, telephone } = useSelector(
    (state) => state.account
  );

  useEffect(() => {
    const installGlia = function (a) {
      (function (a, b, c, d) {
        let e = a.createElement(b),
          f = a.getElementsByTagName(b)[0];
        e.async = 1;
        e.src = c;
        e.type = "text/javascript";
        d && e.addEventListener("load", d);
        f.parentNode.insertBefore(e, f);
      })(document, "script", "https://api.glia.com/salemove_integration.js", a);
    };
    installGlia(function () {
      window.sm.getApi({ version: "v1" }).then(function (glia) {
        const _telephone = telephone ?? "Sin telefono";
        const _email = email ?? "Sin email";
        const _name = name ?? "Sin nombre";
        const _run = run ?? "Sin run";
        const executiveId = executive?.id ?? 0;

        glia
          .updateInformation({
            phone: _telephone,
            email: _email,
            name: _name,
            externalId: _run,
            customAttributes: {
              IdEjecutivo: executiveId.toString(),
            },
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    });
    //eslint-disable-next-line
  }, []);
};
