import { MAxios, MAxiosFormData } from "../conf";
import { DataNps } from "@redux/models/SessionModels"

const SetNps = async (data: DataNps) => {
	const endpoint = "Survey/Survey/SetSurvey";
	
    const res = await MAxios().post(endpoint, data);

    return res.data;
};

export async function SurveyShowSurvey() {
    const endpoint = "Survey/Survey/ShowSurvey";
	
    const res = await MAxios().post(endpoint);

    return res.data;
}

export async function UpdateNpsInteraccion(idProducto: string) {
	const endpoint = "Survey/Survey/UpdateNpsInteraccion";

	let formData = new FormData();
    formData.append("idProducto", idProducto);

    const res = await MAxiosFormData().post(endpoint, formData);

    return res.data;   
}

export async function GetSurvey(idProducto: string) {
	const endpoint = "Survey/Survey/GetSurvey";

	let formData = new FormData();
    formData.append("idProducto", idProducto);

    const res = await MAxiosFormData().post(endpoint, formData);

    return res.data;   
}

const ApiFactoringNps = {
	SetNps,
	SurveyShowSurvey,
    GetSurvey,
    UpdateNpsInteraccion
};
  
export default ApiFactoringNps;