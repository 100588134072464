import React from "react";
import { TDispatch } from "@redux/hooks";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";

export const handlerEditar = (
  rowvalue: any,
  iclass: string,
  action: any,
  disabled?: boolean,
  dispatch?: TDispatch
): JSX.Element => {
  if (dispatch)
    dispatch(
      NewCertificateActions.setCurrentStep(NewCertificateModels.Step.ingresar)
    );
  return (
    <button
      className="btn p-0 acciones-tabla iclass"
      onClick={() => action(rowvalue)}
      disabled={disabled}
    >
      <i className={iclass} aria-hidden="true"></i>
    </button>
  );
};

export const handlerEliminar = (
  rowvalue: any,
  iclass: string,
  action: any
): JSX.Element => {
  return (
    <button className="btn p-0 acciones-tabla" onClick={() => action(rowvalue)}>
      <i className={iclass} aria-hidden="true"></i>
    </button>
  );
};
