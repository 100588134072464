import React, { useEffect, useState } from "react";
import { AutoLogin } from "@app/auth/login";
import { getHelperVariableUrl } from "@helpers/helpers";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import MLoading from "../../controls/MLoading";
import * as AccountActions from "@redux/actions/AccountActions";
import * as GlobalActions from "@redux/actions/GlobalActions";
import * as SessionActions from "@redux/actions/SessionActions";

const LoadingScreen = () => {
  const dispatch = useAppDispatch();

  dispatch(AccountActions.cleanState());
  dispatch(GlobalActions.cleanState());
  dispatch(SessionActions.cleanState());

  const [hasParamUser, setHasParamUser] = useState<boolean>(false);
  const { token } = useAppSelector((store) => store.account);

  useEffect(() => {
    const paramUser = getHelperVariableUrl("user");

    if (paramUser !== null) {
      setHasParamUser(true);
      const AutologinCall = async () => {
        await AutoLogin(paramUser as string, token, dispatch);
      };
      AutologinCall();
    } else {
      setHasParamUser(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="anim-loading">
        <div className="lottie-animacion">
          <MLoading />
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
