import React, { useEffect, useState } from "react";
import { FinfastProductRedirectToProduct } from "@helpers/helpers";
import "./FinfastProductSelector.scss";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { ValidateProduct } from "@redux/models/SessionModels";
import GenericBaseModel from "@api/GenericBaseModel";
import ApiProductsMenu from "@api/garantias/product/product";
import { loadProducts } from "@redux/actions/SessionActions";
import { MMenuSelector } from "@inversiones-ma/finfast-react-controls";

interface FinfastProductSelectorProps {
  defaultName: string;
  idProduct: number;
}

const FinfastProductSelector = (props: FinfastProductSelectorProps) => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((store) => store.account);

  const [loading, setLoading] = useState<boolean>(false);
  const [productData, setProductData] = useState<any[] | undefined>(
    useAppSelector((store) => store.session.validateProducts)
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const productOnClick = (idProducto: Number) => {
    FinfastProductRedirectToProduct(idProducto, token, productData);
  };

  useEffect(() => {
    setLoading(true);

    if (!!!productData) {
      ApiProductsMenu.getProductsMenu().then(
        (resp: GenericBaseModel<ValidateProduct>) => {
          setLoading(false);
          if (resp.success) {
            let productResult = [];
            //Validamos a que no sea iguala al producto actual y que tenga actionButton === ACCEDER
            productResult = resp.dataList.filter(
              (p) => p.idProducto !== props.idProduct && p.actionButton === 0
            );

            setProductData(productResult);
            dispatch(loadProducts(productResult));
          } else {
            /* setHasError(true); */
          }
        }
      );
    } else {
      setLoading(false);
      setProductData(productData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MMenuSelector
      isOpen={isOpen}
      loading={loading}
      productData={productData}
      setIsOpen={setIsOpen}
      defaultName={props.defaultName}
      productOnClick={productOnClick}
    />
  );
};

export default FinfastProductSelector;
