// Definiciones de ambientes en base a los entornos ejecutados
// incluye las url de multi asociadas a cada ambiente

const envURLs = {
  finfastLocalhost: "http://localhost:3001",
  finfastProduction: "https://www.finfast.com",
  finfastQa: "https://finfasteastusfront-qa.azurewebsites.net",
  bkndLocalhost: "http://localhost:4000",
  bkndProduction: "https://finfasteastusapi.azurewebsites.net",
  bkndQa: "https://finfasteastusapi-qa.azurewebsites.net",
  //bkndQa: "https://finfasteastusapi-lrg.azurewebsites.net",
  //"https://finfasteastusapi-qa.azurewebsites.net",
  multi2Localhost: "http://localhost:3000",
  multi2Production: "https://beta.multigarantias.cl/",
  multi2Qa: "https://finfasteastusapi-qa.azurewebsites.net/",
  invalidEnvironment: "revisar.url.environment",
  products: {
    multigarantias: {
      production: "https://beta.multigarantias.cl/",
      qa: "https://finfasteastusapi-qa.azurewebsites.net/",
      development: "https://betaqa.multigarantias.cl/",
    },
    factoring: {
      production: "https://multigarantiasfactoringfront.azurewebsites.net/",
      qa: "https://multigarantiasfactoringfront.azurewebsites.net/",
      development: "https://multigarantiasfactoringfront.azurewebsites.net/",
    },
    seguros: {
      production: "https://seguros.finfast.com/",
      qa: "https://seguros.finfast.com/",
      development: "https://seguros.finfast.com/",
    },
  },
};
const env = () => {
  if (process.env.REACT_APP_SUBENVIRONMENT === "production") {
    envURLs.bkndLocalhost = envURLs.bkndProduction;
  }
  if (process.env.REACT_APP_SUBENVIRONMENT === "qa") {
    envURLs.bkndLocalhost = envURLs.bkndQa;
  }
  let url, front, multi2, finfast;
  if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      url = envURLs.bkndProduction;
      multi2 = envURLs.multi2Production;
      finfast = envURLs.finfastProduction;
    } else if (process.env.REACT_APP_ENVIRONMENT === "qa") {
      url = envURLs.bkndQa;
      multi2 = envURLs.multi2Qa;
      finfast = envURLs.finfastQa;
    } else {
      url = envURLs.invalidEnvironment;
    }
  } else if (process.env.NODE_ENV === "development") {
    url = envURLs.bkndLocalhost;
    multi2 = envURLs.multi2Localhost;
    finfast = envURLs.finfastLocalhost;
  } else {
    url = envURLs.invalidEnvironment;
  }
  return { url, front, multi2, finfast };
};

const urlProductByEnvironment = (product) => {
  if (process.env.REACT_APP_SUBENVIRONMENT === "production") {
    return product.url;
  }
  if (process.env.REACT_APP_SUBENVIRONMENT === "qa") {
    return product.urlQa;
  }
  if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      return product.url;
    } else if (process.env.REACT_APP_ENVIRONMENT === "qa") {
      return product.urlQa;
    } else {
      return product.urlLocalhost;
    }
  } else if (process.env.NODE_ENV === "development") {
    return product.urlLocalhost;
  } else {
    return envURLs.invalidEnvironment;
  }
};

const envProducts = (product) => {
  if (process.env.REACT_APP_SUBENVIRONMENT === "production") {
    return envURLs.products[product].production;
  }
  if (process.env.REACT_APP_SUBENVIRONMENT === "qa") {
    return envURLs.products[product].qa;
  }
  if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      return envURLs.products[product].production;
    } else if (process.env.REACT_APP_ENVIRONMENT === "qa") {
      return envURLs.products[product].qa;
    } else {
      return envURLs.invalidEnvironment;
    }
  } else if (process.env.NODE_ENV === "development") {
    return envURLs.products[product].development;
  } else {
    return envURLs.invalidEnvironment;
  }
};

// Corresponde a la url de multi2
// Ejemplo https://beta.multigarantias.cl/
export const multi2 = () => env().multi2;

//Corresponde a la url de la API (No incluye /api/)
// Ejemplo https://multigarantias2apidesa.azurewebsites.net
export const apiUrl = () => env().url;

// Corresponde a la url para redirección de multiproducto
// Ejemplo https://beta.multigarantias.cl/
export const urlProduct = (product) => envProducts(product);

export const urlProductEnvironment = (product) =>
  urlProductByEnvironment(product);

export const FinfastProductMainUrl = () => {
  if (process.env.REACT_APP_SUBENVIRONMENT === "production") {
    return "https://www.finfast.com/MyAccount?id=1";
  }
  if (process.env.REACT_APP_SUBENVIRONMENT === "qa") {
    return "https://www.finfastqa.com/MyAccount?id=1";
  }
  if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      return "https://www.finfast.com/MyAccount?id=1";
    } else if (process.env.REACT_APP_ENVIRONMENT === "qa") {
      return "https://www.finfastqa.com/MyAccount?id=1";
    } else {
      return "http://localhost:3001/MyAccount?id=1";
    }
  } else if (process.env.NODE_ENV === "development") {
    return "http://localhost:3001/MyAccount?id=1";
  } else {
    return "";
  }
};

  // Corresponde a la url de finfast
export const finfast = () => env().finfast;