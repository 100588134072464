import React, { useState } from "react";

import { ValidateOperations } from "Models/Operation";
import { GatewayCode, PaymentData } from "Models/Pay";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";

import Pay from "@api/pay/Pay";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import { loadIdTrxServipag } from "@redux/actions/SessionActions";
import { Cart, DiscountCode } from "@redux/models/SessionModels";

import { BuyCartHook } from "../Hook/BuyCartHook";
import { isEqual } from "lodash";
import { EnumOriginPaymentChannels } from "@redux/models/GlobalModels";

interface PropsPaymentHandler {
  cart: Cart | undefined;
  discountCode: DiscountCode | undefined;
  statePay: PaymentData;
  setValidations: React.Dispatch<
    React.SetStateAction<ValidateOperations | undefined>
  >;
  errorPay: boolean;
  setErrorPay: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentHandler = ({
  cart,
  discountCode,
  statePay,
  setValidations,
  errorPay,
  setErrorPay,
}: PropsPaymentHandler) => {
  const dispatch = useDispatch();
  const [request, setRequest] = useState<boolean>(false);

  const { resetStepToPay } = BuyCartHook();

  const handlerReset = () => {
    resetStepToPay();
  };

  debugger;
  const handlerPayOnClick = async (gatewayCode: GatewayCode) => {
    setRequest(true);

    const paymentFunctions = {
      Transbank: {
        eventInfo: {
          action: "Pagar",
          category: "Carrito",
          label: "Pagar con Transbank",
        },
        callFunction: () =>
          Pay.callTransbankPay(cart?.items, discountCode?.codigo),
      },
      Khipu: {
        eventInfo: {
          category: "Carrito",
          label: "Pagar con Khipu",
          action: "Pagar",
        },
        callFunction: () => Pay.callKhipuPay(cart?.items, ""),
      },
      Servipag: {
        eventInfo: {
          category: "Carrito",
          label: "Pagar con Servipag",
          action: "Pagar",
        },
        callFunction: () => Pay.callServipagPay(cart?.items, "email"),
      },
    };

    const { eventInfo, callFunction } = paymentFunctions[gatewayCode];

    ReactGA.event(eventInfo);

    try {
      const resp = await callFunction();
      debugger;
      if (resp !== null && resp.success) {
        if (gatewayCode === "Servipag") {
          const trxServipag = { id: resp.idTxPago };
          dispatch(loadIdTrxServipag(trxServipag));
        }
        const form = document.createElement("form");
        form.method = "post";
        form.action = resp.url;

        if (gatewayCode === "Servipag") {
          const hiddenFieldXml = document.createElement("input");
          hiddenFieldXml.type = "hidden";
          hiddenFieldXml.name = "xml";
          hiddenFieldXml.value = resp.xml;
          form.appendChild(hiddenFieldXml);

          const hiddenFieldError = document.createElement("input");
          hiddenFieldError.type = "hidden";
          hiddenFieldError.name = "error";
          form.appendChild(hiddenFieldError);
        } else if (gatewayCode === EnumOriginPaymentChannels.Transbank) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.id = "token_ws";
          hiddenField.name = "token_ws";
          hiddenField.value = resp.token;
          form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form.submit();
      } else {
        setErrorPay(true);
        setValidations(resp.validateOperation);
      }
    } catch (error) {
      console.error(`Error al consumir API para ${gatewayCode}:`, error);
      setErrorPay(true);
    } finally {
      setRequest(false);
    }
  };

  return (
    <>
      {isEqual(statePay.currentGatewayCode, EnumOriginPaymentChannels.KhipuDiferido) ? (
        <></>
      ) : (
        <div className="text-center button-carro-compras">
          <MButton
            type="submit"
            onClick={() =>
              handlerPayOnClick(statePay.currentGatewayCode as GatewayCode)
            }
            disabled={
              !cart?.items || !statePay.currentGatewayCode || request
                ? true
                : false || errorPay
            }
            loading={request}
          >
            Pagar
          </MButton>
          <MButton outline onClick={handlerReset}>
            Volver
          </MButton>
        </div>
      )}
    </>
  );
};

export default PaymentHandler;
