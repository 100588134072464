import React from 'react';

import {
  CreateOperation,
  OriginIncome,
  ValidateOperations,
} from 'Models/Operation';

import { convertErrorType } from '@helpers/QuoteEmit';
import { MCards } from '@inversiones-ma/finfast-react-controls';
import * as NewCertificateModels from '@redux/models/NewCertificateModels';
import { EnumPrioridad } from '@redux/models/QuoteModels';

import ErrorAlert from '../ErrorAlerts/ErrorAlert';
import ValidationContractStartDate
  from '../NewCertificate/partial/ValidationContractStartDate';
import HeadCard from './HeadCard';
import MainCard from './MainCard';

interface PropsOptionsCard {
  resultComission: ValidateOperations[];
  handlerSelect: (element: ValidateOperations) => void | undefined;
  addOrEdit: NewCertificateModels.Step;
  PrioridadCertificado?: EnumPrioridad | undefined;
  setPrioridadCertificado?: React.Dispatch<
    React.SetStateAction<EnumPrioridad | undefined>
  >;
  origen: OriginIncome;
  selectedOption: ValidateOperations | undefined;
  setValidationContractStartDate: React.Dispatch<React.SetStateAction<boolean | undefined>> | undefined;
  setContractStartDate: React.Dispatch<React.SetStateAction<Date | undefined>> | undefined;
  infoCommission: CreateOperation | undefined;
}

/**
 * componente creacion cards paso 1 emisión
 */

const OptionsCard = ({
  resultComission,
  handlerSelect,
  addOrEdit,
  origen,
  selectedOption,
  setValidationContractStartDate,
  infoCommission,
  setContractStartDate,
}: PropsOptionsCard) => {
  debugger;
  return (
    <MCards
      className="nonBorder p-3"
      size="default"
      style={{ boxShadow: "0px 0px 0px" }}
    >
      <div className="px-2 pt-2">
        <div className="row justify-content-center">
          <div className="col-12">
            <HeadCard
              resultComission={resultComission}
              origen={origen}
            />
            
            {resultComission.some(x => x.success === false) &&
              <ErrorAlert quoteData={convertErrorType(resultComission.filter(x => x.success === false))} />
            }

            <MainCard
              quoteData={resultComission!}
              handlerSelect={(element) => handlerSelect(element)}
              addOrEdit={addOrEdit}
              origen={origen}
              warantySelected={selectedOption}
            />
            {selectedOption && selectedOption?.idAseguradora > 0 
            && infoCommission && window.innerWidth > 900 && (
              <div className="pt-0">
                {
                  <ValidationContractStartDate
                    setValidationContractStartDate={setValidationContractStartDate!}
                    setContractStartDate={setContractStartDate!}
                    infoCommission={infoCommission as CreateOperation}
                  />
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </MCards>
  );
};

export default OptionsCard;
