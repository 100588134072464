import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import {
  CreateOperation,
  OperacionDTO,
  ValidateOperations,
} from 'Models/Operation';
import {
  FieldValues,
  UseFormReturn,
} from 'react-hook-form';

import ApiCommission from '@api/garantias/operation/Commission';
import { convertErrorType } from '@helpers/QuoteEmit';
import { useAppSelector } from '@redux/hooks';
import ComisionBaseModel, {
  EnumErrorType,
  EnumPrioridad,
} from '@redux/models/QuoteModels';

import ErrorAlert from '../../../ErrorAlerts/ErrorAlert';

interface PropsCommissionResult {
  tenderData: OperacionDTO | undefined;
  form: UseFormReturn<FieldValues, object>;
  resultComission: ValidateOperations[] | undefined;
  setResultComission: React.Dispatch<
    React.SetStateAction<ValidateOperations[]>
  >;
  isFormValid: boolean;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  setResultComissionError: any;
  setSelectCardcommission: React.Dispatch<React.SetStateAction<boolean>>;
  setInRequest: React.Dispatch<React.SetStateAction<boolean>>;
  infoCommission: CreateOperation | undefined;
  setPrioridadCertificado: React.Dispatch<
    React.SetStateAction<EnumPrioridad | undefined>
  >;
  setErrorQuote: React.Dispatch<React.SetStateAction<JSX.Element>>;
}

function CommissionResult(props: PropsCommissionResult) {
  const { run } = useAppSelector((store) => store.account);

  const { formState, trigger } = props.form;

  let callApiControl = setTimeout(() => false, 1000);

  const validacionYObtenerComision = async () => {
    let hasErr = false;

    const { errors, dirtyFields: df } = formState;

    if (df.fechaInicio) hasErr = hasErr || !(await trigger("fechaInicio"));
    if (df.fechaTermino) hasErr = hasErr || !(await trigger("fechaTermino"));
    if (df.finalidad) hasErr = hasErr || !(await trigger("finalidad"));
    if (df.montoCertificado)
      hasErr = hasErr || !(await trigger("montoCertificado"));
    if (
      !!props.infoCommission?.fechaInicio &&
      !!props.infoCommission?.fechaTermino &&
      !!props.infoCommission?.finalidad &&
      !!props.infoCommission?.montoCertificado &&
      !!props.infoCommission?.tipoMoneda &&
      !hasErr
    ) {
      props.setInRequest(true);
      props.setSelectCardcommission(false);

      //setTimeOut para evitar tantos llamados a la API
      callApiControl = setTimeout(() => {
        const montoCertificadoFormated =
          props.infoCommission!.montoCertificado!;
        ApiCommission.GetValueCommission(
          dayjs(props.infoCommission!.fechaInicio).format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
          dayjs(props.infoCommission!.fechaTermino).format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
          props.infoCommission!.finalidad!,
          props.infoCommission!.tipoMoneda!,
          montoCertificadoFormated,
          props.tenderData?.codigoExterno ?? props.infoCommission!.codigoExterno,
          props.tenderData?.id ?? 0,
          props.infoCommission!.rutUnidadMandante!,
          run!
        )
          .then((resCommission: ComisionBaseModel<ValidateOperations>) => {
            if (resCommission.success) {
              props.setPrioridadCertificado(resCommission.prioridad);
              if (resCommission.dataList.length > 0) {
                if (
                  resCommission.dataList.find(
                    (x) =>
                      x.errorType === null ||
                      x.errorType?.toUpperCase() !== EnumErrorType.DANGER ||
                      x.errorType?.toUpperCase() !== EnumErrorType.INFO
                  )
                ) {
                  props.setErrorQuote(<></>);
                }
              }
              props.setResultComission(resCommission.dataList);
              props.setIsFormValid(props.isFormValid && true);
              props.setSelectCardcommission(true);
            } else {
              props.setResultComissionError(resCommission.dataList);
              props.setErrorQuote(
                <ErrorAlert
                  quoteData={convertErrorType(resCommission.dataList)}
                />
              );
            }
          })
          .catch((ex) => {
            console.error(ex);
          })
          .finally(() => {
            props.setInRequest(false);
          });
      }, 500);
    } else {
      props.setInRequest(false);
    }
  };

  useEffect(() => {
    //Se validan que se encuentren todos los datos

    const validacion = async () => {
      await validacionYObtenerComision();
    };

    validacion();
    return () => {
      //Se reinicia el control
      clearTimeout(callApiControl);
    };
    // eslint-disable-next-line
  }, [props.infoCommission]);

  return <></>;
}

export default CommissionResult;
