import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import EnterData from "../../NewCertificate/partial/EnterData";
import VerifyData from "../../NewCertificate/partial/VerifyData";

interface PropsFormAddOperation {
  close: any;
}

const AutoScrollInPopUp = () => {
  useEffect(() => {
    let modal = document.querySelector(".modal");
    if (modal) {
      modal.scrollTop = 0;
    }
  }, []);
  return <></>;
};

function FormBuyCertificateByTender(props: PropsFormAddOperation) {
  const dispatch = useAppDispatch();
  const { currentStep } = useAppSelector((store) => store.newCertificate);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(NewCertificateActions.setSelectedData(undefined));
    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="p-4 modal-pago-certificado">
        {isLoaded && (
          <>
            {currentStep === NewCertificateModels.Step.ingresar && (
              <>
                <AutoScrollInPopUp />
                <EnterData
                  addOrEdit={NewCertificateModels.Step.ingresar}
                  close={props.close}
                  origen="tender"
                />
              </>
            )}
            {currentStep === NewCertificateModels.Step.verificar && (
              <>
                <AutoScrollInPopUp />
                <VerifyData
                  addOrEdit={NewCertificateModels.Step.verificar}
                  origen="tender"
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default FormBuyCertificateByTender;
