import dayjs from 'dayjs';
import { OperacionDTO } from 'Models/Operation';

import { Cart } from '@redux/models/SessionModels';

import {
  MAxios,
  MAxiosBlob,
  MAxiosFormData,
} from '../../conf';

const AddOperation = async (operation: OperacionDTO) => {
  let myOperation: any = {};
  myOperation["Id"] = operation.id;
  myOperation["CodigoExterno"] = operation.codigoExterno;
  myOperation["Finalidad"] = operation.finalidad;
  myOperation["Comision"] = operation.comision;
  myOperation["ComisionOriginal"] = operation.comisionOriginal;
  myOperation["Descuento"] = operation.descuento;
  myOperation["RutEmpresa"] = operation.rutEmpresa;
  myOperation["TipoMoneda"] = operation.tipoMoneda;
  myOperation["FechaInicio"] = dayjs(operation.fechaInicio).format(
    "YYYY-MM-DD"
  );
  myOperation["FechaTermino"] = dayjs(operation.fechaTermino).format(
    "YYYY-MM-DD"
  );
  myOperation["MontoCertificado"] = operation.montoCertificado;
  myOperation["CodigoOrganismoMandante"] = operation.codigoOrganismoMandante;
  myOperation["NombreOrganismoMandante"] = operation.nombreOrganismoMandante;
  myOperation["RutUnidadMandante"] = operation.rutUnidadMandante;
  myOperation["DireccionUnidadMandante"] = operation.direccionUnidadMandante;
  myOperation["ComunaUnidadMandante"] = operation.comunaUnidadMandante;
  myOperation["RegionUnidadMandante"] = operation.regionUnidadMandante;
  myOperation["Glosa"] = operation.glosa;
  myOperation["CarroDeCompras"] = operation.carroDeCompras;
  myOperation["IdAseguradora"] = operation.idAseguradora;
  myOperation["IdOperacionTipoDocumento"] = operation.idOperacionTipoDocumento;
  myOperation["FechaInicioContrato"] = operation.fechaInicioContrato;
  myOperation["IdFondo"] = operation.idFondo;


  const endpoint = "Operacion/Operation/AddOperation";

  const res = await MAxios().post(
    endpoint,
    myOperation,
  );
  return res.data;
};

const ListQuotes = async () => {
  const endpoint = "Operacion/Operation/ListQuotes";

  const res = await MAxios().post(endpoint);
  return res.data;
};

const DeleteOperation = async (id: number) => {
  const endpoint = "Operacion/Operation/DeleteOperation";
  let formData = new FormData();
  formData.append("operationsId", id.toString());

  const res = await MAxiosFormData().post(endpoint, formData);
  return res.data;
};

const AddToNotificacion = async () => {
  const endpoint = "Operacion/Operation/AddToNotificacion";
  const res = await MAxios().get(endpoint);
  return res.data;
};

const ValidateListOperation = async (
  operations: Cart["items"] | undefined
) => {
  const endpoint = "Linea/Line/GetLineAvailable";

  const res = await MAxios().post(endpoint, operations);

  return res.data;
};

const DeleteOfCart = async (
  idOperacion: number,
  isReissue: boolean | undefined
) => {
  const endpoint = "Operacion/Operation/DeleteOfCart";
  let formData = new FormData();
  formData.append("id", idOperacion.toString());
  formData.append("isReissue", isReissue ? isReissue.toString() : "false");

  const res = await MAxiosFormData().post(endpoint, formData);
  return res.data;
};

const ValidateDebt = async (
  arrTenders: OperacionDTO[] = []
) => {
  try {
    const endpoint = "Operacion/Operation/ValidateDebt";

    const idOperations: number[] = arrTenders.map(x => x.id);

    const res = await MAxios().post(endpoint, idOperations);

    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const ValidateDiscountOperation = async (
  code: string | undefined,
  operationsId: any
) => {
  const endpoint = "Descuento/TicketDescuento/ValidateCouponCode";

  let body = {
    code: code,
    id: operationsId,
  };

  const res = await MAxios().post(endpoint, body);

  return res.data;
};

const Recalculate = async (operacion: OperacionDTO[]) => {
  const endpoint = "Operacion/Operation/Recalculate";

  const res = await MAxios().post(endpoint, operacion);
  return res.data;
};

const ValidateOperationsInCart = async () => {
  const endpoint = "Operacion/Operation/ValidateInCart";
  const res = await MAxios().get(endpoint);
  return res.data;
};

const GetTermsConditions = async (idAseguradora : number) => {
  const endpoint = "Aseguradora/Aseguradora/GetTermsAndConditions";
  let formData = new FormData();
  formData.append("idAseguradora", idAseguradora.toString());

  const res = await MAxiosBlob().post(endpoint, formData);

  return res;
};

const ApiOperation = {
  AddOperation,
  DeleteOperation,
  ListQuotes,
  AddToNotificacion,
  ValidateListOperation,
  DeleteOfCart,
  ValidateDebt,
  ValidateDiscountOperation,
  Recalculate,
  ValidateOperationsInCart,
  GetTermsConditions
};

export default ApiOperation;
