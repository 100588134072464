import React, {
  useEffect,
  useState,
} from 'react';

import { OriginIncome } from 'Models/Operation';
import { useForm } from 'react-hook-form';

import {
  MButtonLoading,
  MPopup,
} from '@inversiones-ma/finfast-react-controls';
import * as NewCertificateActions from '@redux/actions/NewCertificateActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import * as NewCertificateModels from '@redux/models/NewCertificateModels';

import MAlertPopupNewCertificateErrorSolicitudEmision
  from '../../../../controls/alertpopups/MAlertPopupNewCertificateErrorSolicitudEmision';
import OptionsCard from '../../Cards/OptionsCard';
import AlertErrorReemision from './AlertErrorReemision';
import Breadcrumbs from './Breadcrumbs';
import DatosAseguradora from './VerifyData/DatosAseguradora';
import DatosGarantia from './VerifyData/DatosGarantia';
import DatosLicitacion from './VerifyData/DatosLicitacion';
import SuccessMessage from './VerifyData/SuccessMessage';
import TermsConditions from './VerifyData/TermsConditions';
import TitleVerifyData from './VerifyData/TitleVerifyData';
import { useHandleVerifyData } from './VerifyData/useHandleVerifyData';
import VerifyDataMobile from './VerifyDataMobile';

interface PropsVerifyData {
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
  AddToCart?: (added: boolean) => void;
  close?: () => void;
}

function VerifyData(props: PropsVerifyData) {
  const dispatch = useAppDispatch();

  const { cart } = useAppSelector((store) => store.session);
  const { tenderData, valueCommissionSelected } = useAppSelector(
    (store) => store.newCertificate
  );
  const form = useForm();
  const { handleSubmit, watch, setValue } = form;
  const { verified } = watch();

  const [inRequest, setInRequest] = useState(false);
  const [termAndConditions, setTermAndConditions] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showRepeatMessage, setShowRepeatMessage] = useState<boolean>(false);

  const { handlerLabel, LoadTermsConditions, AddOperation } =
    useHandleVerifyData(
      form,
      HandlerModal,
      props.addOrEdit,
      setShowSuccessMessage
    );

  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setResolution({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    
    if (
      props.addOrEdit === NewCertificateModels.Step.verificar &&
      props.origen === "newCerificate"
    ) {
      setValue("verified", true);
    }
    // eslint-disable-next-line
  }, []);

  const editOnClick = () => {
    dispatch(
      NewCertificateActions.setCurrentStep(NewCertificateModels.Step.ingresar)
    );
  };

  async function HandlerModal() {
    if (tenderData?.idAseguradora) {
      LoadTermsConditions(tenderData.idAseguradora);
    } else {
      setTermAndConditions(true);
    }
  }

  const addOnClick = async () => {
    setInRequest(true);
    //Se validad (temporalmente) que la licitacion no este repetida
    if (
      props.addOrEdit === NewCertificateModels.Step.ingresar ||
      props.origen === "tender"
    ) {
      let isRepeat = false;
      cart?.items &&
        cart?.items.forEach((item) => {
          if (
            item.rutUnidadMandante === tenderData?.rutUnidadMandante &&
            item.finalidad === tenderData.finalidad &&
            item.montoCertificado === tenderData.montoCertificado &&
            item.tipoMoneda === tenderData.tipoMoneda &&
            item.glosa === tenderData.glosa
          ) {
            setShowRepeatMessage(true);
            setInRequest(false);
            isRepeat = true;
          }
        });
      if (isRepeat) return;
    }

    let result: boolean = await AddOperation();
    if (result && props.origen === "verifyOperation") {
      // mostrar popup al añadir al carrito desde origen "newCertificate"
      setShowSuccessMessage(true);
    }

    if (result && (props.origen === "cart" || props.origen === "addQuote")) {
      if (props.close) {
        props.close();
      }
    }
    setInRequest(false);
  };
  return resolution.width > 900 ? (
    <>
      <div className="row">
        {props.addOrEdit === NewCertificateModels.Step.ingresar && (
          <Breadcrumbs
            paginaPrincipal="Comprar Garantías"
            paginaActual="Nueva Garantía"
            paginaInicial="/buy-certificate"
          ></Breadcrumbs>
        )}
        <TitleVerifyData addOrEdit={props.addOrEdit} />
      </div>
      <div className="col-12">
        <AlertErrorReemision />
      </div>

      <DatosLicitacion />
      <DatosGarantia />
      <DatosAseguradora />

      <OptionsCard
        resultComission={valueCommissionSelected!}
        handlerSelect={() => { }}
        addOrEdit={props.addOrEdit}
        origen={props.origen}
        selectedOption={undefined}
        setContractStartDate={undefined}
        infoCommission={undefined}
        setValidationContractStartDate={undefined}
      />

      <form onSubmit={handleSubmit(addOnClick)} className="verifyData">
        {props.addOrEdit === NewCertificateModels.Step.verificar &&
          (
            <div className="row justify-content-start mt-2 pt-3">
              <div className="col-12">
                <div className="form-horizontal text-center">
                  <div className="text-center center">
                    <div className="">{handlerLabel()}</div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          )}

        <div className="row justify-content-center py-3">
          <div
            className={
              (props.origen === "cart" || props.origen === "addQuote"
                ? "col-md-6"
                : "col-md-3") + " pt-3 col-12 order-1 order-md-0 my-auto py-2"
            }
          >
            <MButtonLoading
              type="button"
              className="btn btn-auxiliar sistema w-100"
              outline={true}
              onClick={editOnClick}
              disabled={inRequest}
            >
              Editar datos
            </MButtonLoading>
          </div>
          <div
            className={
              (props.origen === "cart" || props.origen === "addQuote"
                ? "col-md-6"
                : "col-md-3") + " pt-3 col-12 order-0 order-md-1 py-2"
            }
          >
            <MButtonLoading
              type="submit"
              className="btn btn-auxiliar sistema w-100"
              onClick={addOnClick}
              loading={inRequest}
              disabled={!verified}
            >
              Añadir al carrito
            </MButtonLoading>
          </div>
        </div>
      </form>
      <SuccessMessage
        showModal={showSuccessMessage}
        setShowModal={setShowSuccessMessage}
      />
      <MAlertPopupNewCertificateErrorSolicitudEmision
        showModal={showRepeatMessage}
        setShowModal={setShowRepeatMessage}
      />

      <MPopup
        size="lg"
        showClose={true}
        showModal={termAndConditions}
        setShowModal={setTermAndConditions}
      >
        <TermsConditions
          setShowModal={setTermAndConditions}
          showModal={termAndConditions}
          category={null}
        ></TermsConditions>
      </MPopup>
    </>
  ) : (
    <VerifyDataMobile
      addOrEdit={props.addOrEdit}
      // close={props.close}
      origen={props.origen}
      AddToCart={props.AddToCart}
    />
  );
}

export default VerifyData;
