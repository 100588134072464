import React from "react";
import { useAppSelector } from "@redux/hooks";

const SummaryCartError = () => {
  const enable = useAppSelector((store) => store.session.validateToPay);
  
  const { cart } = useAppSelector((store) => store.session);

  if(typeof enable === "undefined")
  {
    return <></>;  
  }

  if(cart?.items.length === 0)
  {
    return <></>;  
  }

  if(!enable){
    return (
      <div className="col-12 my-3">
        <div
          className="alert alert-danger d-flex align-items-start"
          role="alert"
        >
          <div className="icon">
            <i className="fa fa-exclamation-triangle"></i>
          </div>
          <div>
            <h5>El total de tu compra supera el máximo de línea disponible</h5>

            <p>
              Contáctate con tu ejecutiva para poder solicitar un aumento de
              línea.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}

export default SummaryCartError;