import React from "react";
import { convertToPeso } from "@helpers/helpers";
import { useLine } from "../../hooks/useLine";
import "./LineGuaranteesConstruction.scss";

export const LineGuaranteesConstruction = (): JSX.Element => {
  const {
    arrPairPurposeClassName,
    filteredLines,
    totalLine,
    sumPercentageReal,
    sumTotalPercentage,
  } = useLine();
  return (
    <div className="row mt-2 line-guarantees-cons">
      <div className="col-12">
        <h3 className="subtitle-mini mb-4">Certificados de garantías</h3>
      </div>
      <div key={2} className="accordion-item-home">
        <div className="accordion-body">
          <section className="line-available pb-1">
            <div className="row mb-1 px-2">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <p className="margin-bottom-parrafo">
                      Monto Total:&nbsp;
                      <b>
                        {convertToPeso(totalLine.available + totalLine.used)}
                      </b>
                    </p>
                  </div>
                  <div className="col-12 col-md-6 text-right-line">
                    <p>
                      Ticket máximo:&nbsp;
                      <b>
                        {convertToPeso(
                          filteredLines?.find(
                            (l) =>
                              l.name !== "Línea Total" &&
                              l.name !== "Seriedad de Oferta"
                          )?.maxTicket
                        )}
                      </b>
                    </p>
                  </div>
                </div>
                {Math.round(sumPercentageReal ?? 0) === sumTotalPercentage ? (
                  <div className="progress mb-3">
                    {filteredLines?.map((filteredLine, iFilteredLine) => {
                      const borderClassName =
                        arrPairPurposeClassName.find(
                          (p) => p.purpose === filteredLine.name
                        )?.className || "secondary";
                      let percentageReal =
                        ((filteredLine.montoLineaUtilizadoPorFinalidad) /
                          (totalLine.available + totalLine.used)) *
                        100;
                      return (
                        <div
                          key={iFilteredLine}
                          className={`progress-bar bg-${borderClassName}`}
                          role="progressbar"
                          style={{ width: `${percentageReal}%` }}
                          aria-valuenow={percentageReal}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="progress mb-3">
                    <div
                      key={1}
                      className={`progress-bar bg-${"primary"}`}
                      role="progressbar"
                      style={{ width: `${sumTotalPercentage}%` }}
                      aria-valuenow={sumTotalPercentage}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                )}

                <div className="row">
                  <div className="col-12 col-md">
                    <small>
                      Utilizado: <b>{convertToPeso(totalLine.used)}</b>
                    </small>
                  </div>
                  <div className="col-12 col-md text-end">
                    <small>
                      Disponible: <b>{convertToPeso(totalLine.available)}</b>
                    </small>
                  </div>
                </div>
                <div className="row pt-3">
                  {filteredLines?.map((filteredLine, iFilteredLine) => {
                    const borderClassName =
                      arrPairPurposeClassName.find(
                        (p) => p.purpose === filteredLine.name
                      )?.className || "secondary";
                    return (
                      <section
                        key={iFilteredLine}
                        className=" col-md-6 col-lg-3 py-0 px-2"
                      >
                        <div
                          className={`rounded-card-bg data-line border-color-${borderClassName} py-0 mb-2 px-1`}
                        >
                          <div key={iFilteredLine}>
                            <div className="data mb-2 pt-1 px-2">
                              <h4
                                className={
                                  "title mb-4 pb-2 " +
                                  (filteredLine.name.length < 28
                                    ? "line-break"
                                    : "")
                                }
                              >
                                {filteredLine.name}                                
                              </h4>
                              {filteredLine.name.length < 28 && (<p className="p-1"></p>)}
                              <div className="py-2">
                              <p className="fons-16">
                                Monto utilizado:{" "}
                                <b>
                                  {convertToPeso(
                                    filteredLine.montoLineaUtilizadoPorFinalidad
                                  )}
                                </b>
                              </p>
                              <p className="fons-16">
                                CFT emitidos:{" "}
                                <b>{filteredLine.countOperation}</b>
                              </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </section>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
