import React from 'react';

import {
  AddTendersAddOperation,
  CreateOperation,
} from 'Models/Operation';

import ApiConfiguration from '@api/configuration/Configuration';
import GenericBaseModel from '@api/GenericBaseModel';
import * as ApiLicitacion from '@api/licitacion/Licitacion';
import * as ApiMandante from '@api/mandante/Mandante';
import ApiProveedor from '@api/proveedor/Proveedor';
import ErrorGeneral from '@controls/ErrorGeneral';
import { useAppSelector } from '@redux/hooks';
import * as GlobalModels from '@redux/models/GlobalModels';
import * as SessionModels from '@redux/models/SessionModels';

export const useHandleEnterData = () => {
  const { arrVistaProveedorLineaAprobada } = useAppSelector(
    (store) => store.session
  );

  const ErrorTerm = (
    finalidad: string | undefined,
    codeError: string | undefined
  ) => {
    let vistaProveedorLineaArpobada = (
      arrVistaProveedorLineaAprobada as SessionModels.VistaProveedorLineaAprobada[]
    ).filter((v) => v.descripcionFinalidad === finalidad);
    return (
      <ErrorGeneral
        headerText={
          <span className="font-weight-bold">
            El plazo seleccionado es superior a los{" "}
            {vistaProveedorLineaArpobada[0].plazoAprobado} meses permitidos.
          </span>
        }
        resultTypeError={codeError}
        detailText={
          <p key="errorGeneralP">
            Estamos revisando el plazo que solicitas. Nuestro equipo se
            contactará contigo.
          </p>
        }
      ></ErrorGeneral>
    );
  };

  const ValidateMandante = async (rutUnidadMandante: string) => {
    try {
      const result = await ApiMandante.validateMandante(
        rutUnidadMandante
      ).catch((e) => console.error(e));

      if (result?.success) {
        return result;
      } else {
        console.error(result?.Message);
      }
      return result;
    } catch {
      console.error("error validacion mandante");
    }
  };

  const ValidateAnticipo = async (): Promise<GlobalModels.Configuracion> => {
    let dataConfiguration = [{ llave: "GarantiasValidacionAnticipo" }];
    let result: GlobalModels.Configuracion = {} as GlobalModels.Configuracion;

    await ApiConfiguration.GetConfiguration(dataConfiguration)
      .then((res: GenericBaseModel<GlobalModels.Configuracion>) => {
        result = res.dataList[0];
      })
      .catch((err) => {
        console.error("error", err);
      })
      .finally(() => {});

    return Promise.resolve(result);
  };

  const AddTenders = async (
    data: CreateOperation
  ): Promise<GenericBaseModel<AddTendersAddOperation>> => {
    let resultApi: GenericBaseModel<AddTendersAddOperation> =
      {} as GenericBaseModel<AddTendersAddOperation>;
debugger
    await ApiLicitacion.AddTenders(data)
      .then(
        (result: GenericBaseModel<AddTendersAddOperation>) => {
          resultApi = result;
        }
      )
      .catch((error) => {
        console.error("error AddTenders:", error);
      })
      .finally(() => {});

    return Promise.resolve(resultApi);
  };

  const GetLine = async (): Promise<
    GenericBaseModel<SessionModels.VistaProveedorLineaAprobada>
  > => {
    let resultApi: GenericBaseModel<SessionModels.VistaProveedorLineaAprobada> =
      {} as GenericBaseModel<SessionModels.VistaProveedorLineaAprobada>;

    await ApiProveedor.GetVistaProveedorLinea()
      .then(
        (
          response: GenericBaseModel<SessionModels.VistaProveedorLineaAprobada>
        ) => {
          resultApi = response;
        }
      )
      .catch((error) =>
        console.error("error api GetVistaProveedorLinea:", error)
      );

    return Promise.resolve(resultApi);
  };

  return {
    ErrorTerm,
    ValidateMandante,
    ValidateAnticipo,
    AddTenders,
    GetLine,
  };
};
