import { MAlert } from "@inversiones-ma/finfast-react-controls";
import React from "react";
import { LineInsuranceCarrier } from "@redux/models/SessionModels";
import { LineCardPolicy } from "./LineCardPolicy";
import MessageLinePolicy from "./MessageLinePolicy";
interface IProps {
  linesInsurance: LineInsuranceCarrier[];
}
const LinePolicy = ({ linesInsurance }: IProps) => {
  return (
    <div className="row">
      <div key={2} className="line-policy">
        <div className="col-12 mb-1">
          <h3 className="subtitle-mini">Pólizas de Garantía</h3>
        </div>
        <div className="accordion-body">
          <div className="row">
            <div className="pt-3">
              <MAlert color={"info"} className="px-1 py-1 p-5">
                <p
                  style={{ marginBottom: 0 + "px" } as React.CSSProperties}
                  className="fons-16"
                >
                  La línea de&nbsp;
                  <strong>Póliza de Garantía&nbsp;</strong>
                  está habilitada solo para la finalidad de&nbsp;
                  <strong>Fiel Cumplimiento.</strong>
                </p>
              </MAlert>
            </div>
            {linesInsurance.map((l) => {
              return (
                <LineCardPolicy
                  key={l.idFinalidad}
                  finalidad={l.descripcionFinalidad}
                  montoUtilizado={l.montoVigenteUtilizado}
                  montoDisponible={l.montoDisponible}
                  porcentajeUtilizadoTotal={l.porcentajeVigenteUtilizado}
                  montoLineaAprobado={l.montoLinea}
                  maxTicket={l.montoTicket}
                />
              );
            })}
            <MessageLinePolicy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinePolicy;
