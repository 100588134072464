import GenericBaseModel from '@api/GenericBaseModel';

export interface QuoteState {
  firstGridData: any[];
  secondGridData: any[];
  currentQuoteId: number;
}

export enum EnumMotivoResultCotizacion {
  EXCEDELINEA = "EXCEDELINEA",
  EXCEDETICKET = "EXCEDETICKET",
  MANDANTEBLOQUEADO = "MANDANTEBLOQUEADO",
  TIPOMONEDAINVALIDO = "TIPOMONEDAINVALIDO",
  PORCENTAJEUTILIZADO = "PORCENTAJEUTILIZADO",
  EXEDEPLAZO = "EXEDEPLAZO",
  SINMOTIVO = "SINMOTIVO",
  MANDANTEPRIVADO = "MANDANTEPRIVADO",
  MANDANTEEXCEDEMONTO = "MANDANTEEXCEDEMONTO",
  MESESMINIMOSEMISION = "MESESMINIMOSEMISION",
  FECHAINICIONOVALIDA = "FECHAINICIONOVALIDA",
  MONTOGARANTIANOPERMITIDO = "MONTOGARANTIANOPERMITIDO",
  FECHAINICIOMAYORA7DIASEMISION = "FECHAINICIOMAYORA7DIASEMISION",
  DIASMINIMOSCERTIFICADO = "DIASMINIMOSCERTIFICADO",
  EXCEDEDEUDACHILEAPOYA = "EXCEDEDEUDACHILEAPOYA",
  TIPOMONEDANOVALIDA = "TIPOMONEDANOVALIDA",
  EXEDEPLAZOCOBERTURA="EXEDEPLAZOCOBERTURA",
  PALABRAGLOSANOPERMITIDA = "PALABRAGLOSANOPERMITIDA"
}

export enum EnumResultCotizacion {
  BOTH = "BOTH",
  ONLYPOLICY = "ONLYPOLICY",
  ONLYCFT = "ONLYCFT",
  ERROR = "ERROR",
  SUCCES ="SUCCES"
}

export interface QuoteModel {
  fechaInicio: string | null;
  fechaTermino: string | null;
  montoCertificado: number | null;
  finalidad: string;
  tipoMoneda: string;
  rutMandante: string;
  codigoError: string;
}

export enum EnumErrorType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger"
 }

export enum EnumPrioridad {
  Finfast = "FINFAST",
  Error = "ERROR",
}


export interface ValoresComision {
  comision: number;
  comisionBruto: number;
  descuento: number;
  ivaClp: number;
  porcentajeDescuento: number;
}
export default interface QuoteBaseModel<T> extends GenericBaseModel<T> {
  // result: EnumResultCotizacion;
  prioridad: EnumPrioridad | undefined;
}

export default interface ComisionBaseModel<T> extends GenericBaseModel<T> {
  result: EnumResultCotizacion;
  prioridad: EnumPrioridad | undefined;
}
