import { MAxios, MAxiosFormData } from "../conf";

//Crud Filtros
const GetAll = async () => {
  const endpoint = "Filtro/Filter/GetAllByRut";

  const res = await MAxios().post(endpoint);
  return res.data;
};

const Save = async (dataJsonFiltro: any) => {
  const endpoint = "Filtro/Filter/Save";
  const res = await MAxios().post(endpoint, dataJsonFiltro);

  return res.data;
};
const UpdateSettingPreference = async (
  emailPreference: any,
  showPreference: any
) => {
  const endpoint = "Proveedor/Proveedor/UpdateSettingPreference";
  let formData = new FormData();
  formData.append("emailPreference", emailPreference);
  formData.append("showPreference", showPreference); //tienen que ser un boolean true or false

  const res = await MAxiosFormData().post(endpoint, formData);

  return res.data;
};

const Delete = async (idFilter: any) => {
  let formData = new FormData();

  const endpoint = "Filtro/Filter/Delete";

  formData.append("idFilter", idFilter);

  const res = await MAxiosFormData().post(endpoint, formData);
  return res.data;
};

const GetDataProvider = async () => {
  const endpoint = "Proveedor/Proveedor/GetProvider";

  const res = await MAxios().post(endpoint);
  return res.data;
};

const ApiFilter = {
  GetAll,
  Save,
  UpdateSettingPreference,
  Delete,
  GetDataProvider,
};

export default ApiFilter;
