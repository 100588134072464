import React, { useEffect, useState } from "react";
import ApiDownloadCertificates, {
  callOperacionOperationDownloadListExpiredPolicy,
} from "@api/certificados/DownloadCertificados";
import { useAppSelector } from "@redux/hooks";
import { MLoadingLight } from "@inversiones-ma/finfast-react-controls";
import MLoading from "../../../../../controls/MLoading";
import "../../MyCertificates.scss";
import PolicyNoData from "./../PolicyNoData";
import { Filtros, OperacionDTO } from "Models/Operation";

import {
  BaseQueryPaginador,
  BaseResponsePaginador,
  Paginador,
} from "Models/Paginador";
import ApiCertificates from "@api/certificados/Certificados";
import { ExpiredGridPolicyDesktop } from "./ExpiredGridPolicyDesktop";
import { ExpiredGridPolicyMobile } from "./ExpiredGridPolicyMobile";

function ExpiredCertificatesPolicy(props: Props) {
  //vars redux
  const { insuranceCarrier } = useAppSelector((store) => store.session);

  //states
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [warratyData, setWarratyData] = useState<Paginador<OperacionDTO>>();
  let [DownloadDocument, setDownloadDocument] = useState(false);
  let [DownloadDocumentID, setDownloadDocumentID] = useState("");
  const [onChangeNumberPage, setOnChangeNumberPage] = useState<number>(1);
  const [onChangeNumberRecords, setOnChangeNumberRecords] =
    useState<number>(10);
  const [responseStatus, setResponseStatus] = useState<string>();
  const [filters, setFilters] = useState<Filtros>({
    NroCertificado: "",
    IdLicitacion: "",
    Finalidad: "",
    RutMandante: "",
  });

  //const form = useForm<Filtros>();

  useEffect(() => {
    let isNumberRecordsChanged = false;
    let previousNumberRecords: number | undefined;

    if (isNaN(onChangeNumberRecords)) return;
    if (isNaN(onChangeNumberPage)) return;

    if (!isNaN(onChangeNumberRecords)) {
      if (previousNumberRecords !== onChangeNumberRecords) {
        isNumberRecordsChanged = true;
      }
    }

    if (isNumberRecordsChanged) {
      let QueryPaginador: BaseQueryPaginador<Filtros> = {
        page: onChangeNumberPage,
        pageSize: onChangeNumberRecords,
        Filtros: [filters],
      };

      callLoadExpiredCertificates(QueryPaginador);
    }
  }, [onChangeNumberPage, onChangeNumberRecords, filters]);

  function callLoadExpiredCertificates(Paginador: BaseQueryPaginador<Filtros>) {
    setIsLoading(true);
    setIsLoadingData(true);
    ApiCertificates.ListExpiredPolicy(2, Paginador)
      .then((response: BaseResponsePaginador<OperacionDTO>) => {
        if (response.success) {
          setWarratyData(response.paginador as any);
          setResponseStatus(response.status);
        }
      })
      .catch(() => {
        console.error("error carga api");
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingData(false);
      });
  }

  function handlerButtonDownloadExcelCurrentsExpired() {
    if (!downloadingExcel) {
      setDownloadingExcel(true);
      callOperacionOperationDownloadListExpiredPolicy(
        props.idOperationTypeDocument
      )
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "polizas_caducadas.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadingExcel(false);
        });
    }
  }

  async function handlerButtonDonwnloadCft(fieldKey: any) {
    if (!DownloadDocument) {
      setDownloadDocument((DownloadDocument = true));
      setDownloadDocumentID((DownloadDocumentID = fieldKey));
      ApiDownloadCertificates.DownloadPolicySuraAzure(fieldKey)
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "certificado_de_fianza_" + fieldKey + ".pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadDocument((DownloadDocument = !DownloadDocument));
          setDownloadDocumentID((DownloadDocumentID = ""));
        });
    }
  }

  return (
    <div className="pb-4">
      {warratyData && warratyData?.records.length > 0 && (
        <div className="row">
          <div className="boton-excel">
            <button
              type="button"
              className="col-12 col-lg-3 offset-lg-9  text-center  btn btn-primary outline sistema"
              onClick={handlerButtonDownloadExcelCurrentsExpired}
            >
              Descargar tabla en Excel
              {downloadingExcel && (
                <MLoadingLight style={{ marginLeft: "10px" }} />
              )}
              {!downloadingExcel && (
                <i
                  className="fas fa-file-excel"
                  style={{ marginLeft: "10px" }}
                ></i>
              )}{" "}
            </button>
          </div>
        </div>
      )}
      <div className="row">
        {warratyData ? (
          <div>
            <>
              <div className="col-12 d-none d-md-block d-lg-block pt-0">
                <ExpiredGridPolicyDesktop
                  handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                  insuranceCarrier={insuranceCarrier}
                  isLoadingData={isLoadingData}
                  setOnChangeNumberPage={setOnChangeNumberPage}
                  setOnChangeNumberRecords={setOnChangeNumberRecords}
                  warratyData={warratyData}
                />
              </div>
              <div className="movil d-block d-md-none pb-3 pt-2">
                <ExpiredGridPolicyMobile
                  handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                  insuranceCarrier={insuranceCarrier}
                  setOnChangeNumberPage={setOnChangeNumberPage}
                  setOnChangeNumberRecords={setOnChangeNumberRecords}
                  warratyData={warratyData}
                  DownloadDocument={DownloadDocument ?? ""}
                  DownloadDocumentID={DownloadDocumentID ?? ""}
                />
              </div>
            </>
            {warratyData?.records.length === 0 && !isLoading && (
              <PolicyNoData
                title="¡Tu historial esta limpio!"
                description="Actualmente no tienes ninguna póliza caduca."
              />
            )}
          </div>
        ) : (
          <div className="col-12">
            <MLoading modal={false} />
          </div>
        )}
      </div>
    </div>
  );
}

interface Props {
  idOperationTypeDocument: number;
}

export default ExpiredCertificatesPolicy;
