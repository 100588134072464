import React from "react";
import finfastLogo from '@img/finfast-logo.svg'

const Footer = () => {
  return (
    <footer className="footer-cliente-logeado">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-3 alineacion-text-footer">
                    <img src={finfastLogo} alt="Logo Multi" className="footer-img-interna" />
                </div>
                <div className="col-12  col-lg-4 alineacion-text-footer">

                    <a href="https://goo.gl/maps/zwC1vg5N9d849hmU8" target="_blank" rel="noopener noreferrer" className="item-footer">
                        <i className="fa fa-map-marker-alt"></i>&nbsp;
                        Av. Providencia 1208, Piso 18, Providencia, Santiago. Chile
                    </a>
                </div>
                <div className="col-12 col-lg-2 alineacion-text-footer">
                    <a href="tel:+56226569677" className="item-footer">
                        <i className="fa fa-phone"></i> +56 2 2656 9677
                    </a>
                </div>
                <div className="col-12 col-lg-3 alineacion-text-footer">
                    <a href="mailto:contacto@finfast.com" className="item-footer">
                        <i className="fa fa-envelope"></i> contacto@finfast.com
                    </a>
                </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
