import { createSlice } from "@reduxjs/toolkit";

import {
  cleanState,
  loadPaymentMethods,
  loadRegions,
  setCurrentDate,
  loadDataSurvey,
  loadTypeTender,
  loadConfiguration
} from "../actions/GlobalActions";
import { GlobalState } from "../models/GlobalModels";

const inicialState: GlobalState = {
  loaded: false,
  regions: undefined,
  currentDate: undefined,
  paymentMethods: undefined,
  surveyNps: undefined,
  typeTender: undefined,
  configuracion: undefined
};

const slice = createSlice({
  name: "global",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRegions, (state, action) => {
        state.regions = action.payload;
      })
      .addCase(setCurrentDate, (state, action) => {
        state.currentDate = action.payload;
      })
      .addCase(cleanState, (state) => {
        state.loaded = inicialState.loaded;
        state.regions = inicialState.regions;
        state.currentDate = inicialState.currentDate;
      })
      .addCase(loadPaymentMethods, (state, action) => {
        state.paymentMethods = action.payload;
      })

      .addCase(loadTypeTender, (state, action) => {
        state.typeTender = action.payload;
      })
      .addCase(loadDataSurvey, (state, action) => {
        state.surveyNps = action.payload;
      })
      .addCase(loadConfiguration, (state, action) => {
        state.configuracion = action.payload
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
