import React, { useState } from "react";
import QuestionAnswer from "./partial/QuestionAnswer";
import "./Questions.scss";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import { arrCategory } from "../../../../scripts/js/faq";

const Questions = () => {
  const [categoria, setCategoria] = useState("garantias");

  const handlerClick = (newcategoria: string) => {
    if (categoria === newcategoria) {
      setCategoria("");
    } else {
      setCategoria(newcategoria);
    }
  };

  const handlerClickMenu = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
  };

  return (
    <>
      {arrCategory.map((x, index) => {
        return (
          <div className="col-md-4 pb-4 d-none d-md-block" key={index}>
            <MCards
              key={x.categoryCode}
              color="primary"
              className={
                categoria === x.categoryCode
                  ? "rounded-card-preguntas-frecuentes color-text-low action-card active"
                  : "rounded-card-preguntas-frecuentes color-text-low action-card"
              }
              onClick={() => handlerClick(x.categoryCode)}
            >
              <div className="row">
                <div className="col-3 my-auto">
                  <img
                    src={x.img}
                    alt="icon-garantias"
                    className="icon-comprar"
                  />
                </div>
                <div className="col-9 my-auto">
                  <a href="#0" onClick={handlerClickMenu}>
                    {x.description}{" "}
                    {categoria === x.categoryCode ? (
                      <i className="fa fa-angle-down"></i>
                    ) : (
                      <i className="fa fa-angle-right"></i>
                    )}
                  </a>
                </div>
              </div>
            </MCards>
          </div>
        );
      })}
      <div className="row preguntas-frecuentes-content d-none d-md-block">
        <div className="col-12">
          <QuestionAnswer category={categoria} />
        </div>
      </div>

      {/* MOBILE */}
      {arrCategory.map((x, index) => {
        return (
          <div
            key={index}
            className="row preguntas-frecuentes-content d-block d-md-none"
          >
            <div className="col-12 mb-3">
              <a
                
                style={{ color: "black" }}
                className={
                  categoria === x.categoryCode
                    ? "desplegable-card"
                    : "desplegable-card collapsed"
                }
                data-bs-toggle="collapse"
                href="#0"
                onClick={(e)=>e.preventDefault()}
                // href="#multiCollapseExample1"
                role="button"
                aria-expanded={categoria === x.categoryCode ? true : false}
                aria-controls={`multiCollapse${index}`}
              >
                <MCards
                  key={x.categoryCode}
                  color="primary"
                  className="rounded-card-preguntas-frecuentes color-text-low action-card"
                  onClick={() => handlerClick(x.categoryCode)}
                >
                  <div className="row">
                    <div className="col-3 my-auto">
                      <img
                        src={x.img}
                        alt="icon-garantias"
                        className="icon-comprar"
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <span className="desplegable-movil">
                        {x.description}
                        {""}
                        {categoria === x.categoryCode ? (
                          <i className="fa fa-angle-down"></i>
                        ) : (
                          <i className="fa fa-angle-right"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </MCards>
                <div
                  className={
                    categoria === x.categoryCode
                      ? "collapse multi-collapse my-2 show"
                      : "collapse multi-collapse my-2"
                  }
                  id={`multiCollapse${index}`}
                >
                  <QuestionAnswer category={x.categoryCode} />
                </div>
              </a>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Questions;
