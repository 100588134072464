import "./Home.scss";
import React from "react";
import HomeQuote from "./partial/HomeQuote";
import HomeLines from "./partial/HomeLines";
import HomeActions from "./partial/HomeActions";
import { useHomeData } from "./hooks/UseHomeData";
import { useAppSelector } from "@redux/hooks";
import HomeBannerAbril2023 from "./Banner/HomeBannerAbril2023";
import { BloquedEmit } from "./partial/MonthlyBilling/BloquedEmit";
import HomeBannerSII from "../SII/Clientes_Nuevos/Home/HomeBannerSII";
import HomeBannerSIIClientesAntiguos from "../SII/Clientes_Antiguos/Home/HomeBannerSIIClientesAntiguos";

const Home = () => {
  const { billing } = useAppSelector((store) => store.session);

  // Custom Hook con la lógica del Home
  useHomeData();

  return (
    <>
      <main className="bg-home">
        {/* <AlertFacturacion /> */}
        {billing && billing.bloquedEmit && <BloquedEmit />}
        <div className="container">
          {/* <HomeBanner /> */}
          {/* Mensaje de facturación Habilitar / Deshabilitar */}
          {/* {billing !== undefined && <MonthlyBilling />} */}
          <div className="row py-5">
            <div className="col-12">
              <h2 className="titles-left">Sistema Emisión de Garantías</h2>
              <p>
                Bienvenido a tu nueva experiencia de emisión de Garantías
                Finfast, donde podrás revisar tu línea disponible actual,
                cotizar y emitir en un solo lugar.
              </p>
            </div>
            <HomeBannerAbril2023 />
            <HomeActions />
            {/* <InsuranceLine /> */}
            <HomeLines />
            <HomeBannerSII />
            <HomeBannerSIIClientesAntiguos showInHome={true} />
            <HomeQuote />
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
