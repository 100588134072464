import './HomeCart.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { OperacionDTO } from 'Models/Operation';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';

import { MStepper } from '@inversiones-ma/finfast-react-controls';
import {
  cleanDiscountValue,
  loadDiscountCode,
  setSelectedIssuer,
} from '@redux/actions/SessionActions';
import {
  cleanState,
  loadStepData,
} from '@redux/actions/StepActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import { DiscountCode } from '@redux/models/SessionModels';
import {
  StepPay,
  StepState,
} from '@redux/models/StepModels';

import {
  arrStep,
  arrStepByCostCero,
} from '../../../../scripts/js/menu';
import Breadcrumbs from '../NewCertificate/partial/Breadcrumbs';
import CartStep1 from './CartStep/CartStep1';
import CartStep2 from './CartStep/CartStep2';
import CartStep3 from './CartStep/CartStep3';
import PaymentError from './CartStep/PaymentError';
import { BuyCartHook } from './partial/Hook/BuyCartHook';

interface MatchParams {
  children: string;
  type: any;
}

const HomeCart = () => {
  const dispatch = useAppDispatch();
  const { cart } = useAppSelector((store) => store.session);
  const { stepState } = useAppSelector((store) => store.steps);
  const { enabledEmit } = useAppSelector((store) => store.account);
  const match = useRouteMatch<MatchParams>();
  const history = useHistory();
  const [isReissueByCostCero, setIsReissueByCostCero] = useState<boolean>(false);

  const { resetStepToPay } = BuyCartHook();

  useEffect(() => {
    const emisorPred = cart?.items?.find(item => item.idAseguradora !== null);
    const cartItemsLength = cart?.items?.length || 0;

    if (cartItemsLength === 1) {
      dispatch(setSelectedIssuer(emisorPred ? "finfast" : "sura"));
    } else if (cartItemsLength > 1) {
      dispatch(setSelectedIssuer(emisorPred ? "finfast" : "sura"));
    } else {
      dispatch(setSelectedIssuer("sura"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  


  let comisionCertificado: number | undefined = (cart?.items ?? []).reduce(
    (sum: number, value: OperacionDTO) =>
      typeof value.comision === "number" ? sum + value.comision : sum,
    0
  );


  useEffect(() => {
    if (!enabledEmit) {
      history.push("/home");
    }

    setIsReissueByCostCero(
      cart?.items.length === 1 && comisionCertificado === 0
    );

    dispatch(cleanState());
    const dicountClean: DiscountCode = {
      codigo: undefined,
      typeDiscount: undefined,
      valDiscount: undefined,
    };
    dispatch(loadDiscountCode(dicountClean));
    dispatch(cleanDiscountValue(undefined));

    if (match) {
      const { children } = match.params;

      if (!!children && children.toLowerCase() === "step3") {
        const steps: StepState = {
          step: 3,
          subStep: StepPay.successPay,
        };
        dispatch(loadStepData(steps));
      } else if (!!children && children.toLowerCase() === "paymenterror") {
        const steps: StepState = {
          step: 3,
          subStep: StepPay.errorPay,
        };
        dispatch(loadStepData(steps));
      } else {
        resetStepToPay()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="bg-home home-cart">
      <div className="container">
        <div className="row">
          <Breadcrumbs
            paginaPrincipal="Carrito"
            paginaActual=""
            paginaInicial="/homecart"
          ></Breadcrumbs>
          <div className="col-12">
            <h2 className="titles-left">Tu carro de compras</h2>
            <p>
              En esta página podrás ver tus Garantías cotizadas que están listas para pagar.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {isReissueByCostCero ? (
              <MStepper
                steps={arrStepByCostCero}
                currentStep={stepState?.step}
              ></MStepper>
            ) : (
              <MStepper
                steps={arrStep}
                currentStep={stepState?.step}
              ></MStepper>
            )}
          </div>

          {stepState?.step === 1 && <CartStep1 />}
          {stepState?.step === 2 && cart?.items.length && <CartStep2 />}
          {stepState?.step === 3 &&
            stepState?.subStep === StepPay.successPay && (<CartStep3 />)}
          {stepState?.step === 3 && stepState?.subStep === StepPay.errorPay && (
            <PaymentError />
          )}
        </div>
      </div>
    </main>
  );
};

export default HomeCart;
