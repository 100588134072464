import React, { useState } from "react";
import Pay from "@api/pay/Pay";
import { useAppSelector } from "@redux/hooks";
import { PaymentMetadata } from "@redux/models/SessionModels";
import {
  MButton,
  MAlert,
  MPopup,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import PayError from "./PayError";
import { patternEmail } from "@helpers/helpers2";
import ReactGA from "react-ga4";
interface Props {
  ClickHandler: () => void;
  errorPay: boolean;
  setErrorPay: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IFormInput {
  email: string;
}

const DeferredPayment = (props: Props) => {
  const { cart } = useAppSelector((store) => store.session);
  const enable = useAppSelector((store) => store.session.validateToPay);

  const [request, setRequest] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);

  const form = useForm<IFormInput>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const handlerUnselected = () => {
    setEmail(false);
    props.ClickHandler();
  };

  const khipuDeferred = async (data: IFormInput) => {
    setRequest(true);

    ReactGA.event({
      action: "Pagar",
      category: "Carrito",
      label: "Pago con Khipu Diferido",
    });

    await Pay.callKhipuPay(cart?.items, data.email)
      .then((resp: PaymentMetadata) => {
        if (resp !== null && resp.success) {
          setEmail(true);
        } else {
          setEmail(false);
          props.setErrorPay(true);
        }
      })
      .catch((error) => console.error("error callKhipuPay: ", error))
      .finally(() => {
        setRequest(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(khipuDeferred)}>
        <div className="col-12 my-3">
          <h3 className="parrafo-pago-terceros">
            Enviar correo electronico para pago por terceros
          </h3>

          <div className={errors?.email ? "error" : ""}>
            <input
              type="input"
              className="mail-confirmacion-pago"
              {...register("email", {
                required: true,
                pattern: {
                  value: patternEmail.value,
                  message: patternEmail.message,
                },
              })}
              placeholder="mailgenerico@mail.com"
            ></input>
            {/* <span className={"error "}>
              {errors?.email &&
                (patternEmail.message ?? "Este campo es requerido")}
            </span> */}
          </div>
          {email && (
            <div className="my-3 col-12 offset-md-1 col-md-10">
              <MAlert
                color="success"
                title="Tu correo electrónico ha sido enviado con éxito."
              ></MAlert>
            </div>
          )}
          <div className="text-center button-carro-compras">
            <MButton
              type="submit"
              disabled={!cart?.items || !enable ? true : false}
              loading={request}
            >
              Enviar Correo
            </MButton>
            <MButton type="button" outline onClick={handlerUnselected}>
              Volver
            </MButton>
          </div>
        </div>
      </form>
      {/* <MPopup
        showClose={true}
        setShowModal={setErrorPay}
        showModal={errorPay}
        size="md"
      >
        <PayError ClickHandler={() => setErrorPay(false)}></PayError>
      </MPopup> */}
    </>
  );
};

export default DeferredPayment;
