import React, { useEffect, useState } from "react";

function MToggleSwitch( {fieldKey , defaultValue , onChange, disable} : any){
    const [checked, setChecked] = useState(defaultValue)

    useEffect(() => {
        setChecked(defaultValue)
    }, [defaultValue])
    const handlerToggleSwitchOnChange = ( e: any) => {
        setChecked(!checked);
        if (!!onChange) {
            onChange(e, defaultValue)
        }
    }
    return (
        <>
            <input type="checkbox" defaultChecked={checked} id={'toggleSwitch' + fieldKey}
                value={0}
                checked={checked}
                disabled={disable}
                onChange={e => handlerToggleSwitchOnChange(!!e.target.checked)} />
            <span className="slider-switch round"></span>
        </>
    )
}

export default MToggleSwitch;