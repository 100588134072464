import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "@redux/hooks";
import MLoading from "../../../../controls/MLoading";
import Pay from "@api/pay/Pay";
import { PaymentMetadata } from "@redux/models/SessionModels";
import "../Home.scss";

const PaymentVerification = () => {
  const { trxServipag } = useAppSelector((store) => store.session);

  const history = useHistory();

  useEffect(() => {
    const argUrl = window.location.search;

    const params = new URLSearchParams(argUrl);

    const previewValidation = params.get("previewValidation") as string;

    setTimeout(() => {
      Pay.callValidatePayServipag(trxServipag?.id, previewValidation).then(
        (resp: PaymentMetadata) => {
          if (resp.success) {
            history.push("/homecart/step3");
          } else {
            history.push("/homecart/PaymentError");
          }
        }
      );
    }, 60000);
  });

  return (
    <div className="container">
      <div className="loading-servipag-center">
        <MLoading modal={false} />
      </div>
    </div>
  );
};

export default PaymentVerification;
