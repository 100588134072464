import React from "react";
import { MButton } from "@inversiones-ma/finfast-react-controls";

interface PropsDuplicateCft {
  showModal?: boolean;
  setShowModal?: any;
}

const DuplicateCft = (props: PropsDuplicateCft) => {
  return (
    <>
      <div className="msg-validation-matriz error">
        <i className="fa fa-exclamation-triangle"></i>
        <h4 className="titles-center">
          YA EMITISTE UN CERTIFICADO
          <br />
          CON LOS MISMOS DATOS
        </h4>
        <p className="py-2">
          Prueba modificando unos de los siguientes campos:
        </p>
        <div className="container listaerror">
          <div className="row align-items-center">
            <div className="col-10 col-md-6">
              <ul className="textoerror">
                <li>RUT mandante</li>
                <li>Finalidad</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 order-1 order-md-0 my-auto py-2">
            <MButton
              type="button"
              className="w-100"
              outline
              onClick={() => props.setShowModal(false)}
            >
              Cancelar
            </MButton>
          </div>
          <div className="col-12 col-md-4 order-0 order-md-1 py-2">
            <MButton
              type="button"
              className="w-100"
              onClick={() => props.setShowModal(false)}
            >
              Modificar datos
            </MButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default DuplicateCft;