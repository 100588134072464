import React from 'react';

import {
  MGrid,
  MGridColumn,
} from '@inversiones-ma/finfast-react-controls';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';

import { OperacionDTO } from '../../../../../../Models/Operation';
import {
  convertToCurrency,
  convertToPeso,
} from '../../../../../../scripts/helpers/helpers';
import {
  setSelectedIssuer,
} from '../../../../../../scripts/redux/actions/SessionActions';
import * as RenderButton from '../ActionColumnRender';
import { items } from '../BuyCart';
import GridMobile from './GridMobile';

interface IProps {
    cart?: OperacionDTO[];
    title: string;
    issuer: string;
    setEdit: (edit: boolean) => void;
    setStateItem: any;
    stateItem: Partial<items>;
    typeGrid: string;
    deleteShow: Function
    editShow: Function
    resetStepToPay: Function
}


function GridCart({ cart, title, issuer, setEdit, setStateItem, stateItem, typeGrid, deleteShow, editShow, resetStepToPay }: IProps) {
    const dispatch = useAppDispatch();
    const { selectedIssuer } = useAppSelector((store) => store.session);

    const handleEmisor = (row: OperacionDTO) => {
        if (row.idAseguradora === null || row.idAseguradora === 0) {
            if (row.idFondo === 7) {
                return "FOGAPE"
            }
            return "FinFast";
        } else {
            if (row.idAseguradora === 2) {
                return "Sura";
            }

            if (row.emisor) {
                return row.emisor;
            }
        }
    };

    const handleOptionChange = (selectOrigen: string) => {
        dispatch(setSelectedIssuer(selectOrigen));
        resetStepToPay();
    };

    return (
        <>
            <div className="col-12 d-none d-md-block d-lg-block">
                <div className="rounded-card-bg  px-md-3 py-md-1 m-2">
                    <div className="col-12 my-2">
                        {typeGrid === 'both' && <>
                            <input
                                type="radio"
                                name={"select"}
                                value={issuer}
                                defaultChecked={issuer === selectedIssuer ? true : false}
                                onChange={(e: any) => handleOptionChange(issuer)}
                            />
                        </>
                        }
                        <label className='subtitle-mini fs-16 mx-1'>{title}</label>
                        <MGrid
                            dataSource={cart}
                            keyExpr="id"
                            showMoreSize={10}
                            className="head-table-color"
                        >
                            <MGridColumn
                                label="Nº Garantía"
                                cellRender={(id: any, key: any, row: OperacionDTO) => (
                                    <>
                                        {row.nroCertificado}
                                        <br />
                                        {handleEmisor(row)}
                                    </>
                                )}
                            ></MGridColumn>
                            <MGridColumn
                                dataField="nombreOrganismoMandante"
                                label="Mandante"
                            ></MGridColumn>
                            <MGridColumn
                                dataField="finalidad"
                                label="Finalidad"
                            ></MGridColumn>

                            <MGridColumn
                                dataField="comision"
                                label="Comisión"
                                cellRender={(_k: any, value: any, row: any) => {
                                    return <> {convertToPeso(value)} </>;
                                }}
                            ></MGridColumn>
                            <MGridColumn
                                dataField="montoCertificado"
                                label="Monto"
                                cellRender={(_k: any, value: any, row: OperacionDTO) => {
                                    return <>{convertToCurrency(row.tipoMoneda, value) + " " + row.tipoMoneda}</>;
                                }}
                            ></MGridColumn>
                            <MGridColumn
                                dataField="editar"
                                label="Editar"
                                className="text-center"
                                cellRender={(_k: any, _v: any, row: any) => {
                                    return (
                                        <>
                                            {RenderButton.handlerEditar(
                                                row,
                                                "fas fa-edit",
                                                editShow,
                                                false,
                                                dispatch)
                                            }
                                        </>
                                    );
                                }} />
                            <MGridColumn
                                dataField="eliminar"
                                label="Eliminar"
                                className="text-center"
                                headerClassName="text-center"
                                cellRender={(_k: any, _v: any, row: any) => {
                                    return RenderButton.handlerEliminar(
                                        row,
                                        "fas fa-trash-alt",
                                        deleteShow
                                    );
                                }} />
                        </MGrid>
                    </div>
                </div>
            </div>
            <div className="movil d-block d-md-none">
                <GridMobile
                    cart={cart}
                    title={title}
                    issuer={issuer}
                    setEdit={setEdit}
                    setStateItem={setStateItem}
                    stateItem={stateItem}
                    typeGrid={typeGrid}
                    resetStepToPay={resetStepToPay}
                    handleEmisor={handleEmisor}
                />
            </div>
        </>
    )
}

export default GridCart