import {
  MLoadingLight,
  MShowMorePaginated,
} from "@inversiones-ma/finfast-react-controls";
import React from "react";
import { Paginador } from "../../../../../../Models/Paginador";
import { OperacionDTO } from "../../../../../../Models/Operation";
import { convertToCurrency, toDate } from "@helpers/helpers";

interface Iprops {
  paginadorData: Paginador<OperacionDTO>;
  setOnChangeNumberPage: (b: number) => any;
  setOnChangeNumberRecords: (b: number) => any;
  handlerButtonDownloadCft: Function;
  handlerButtonDownloadInvoice: Function;
  DownloadDocument: any;
  DownloadDocumentID: any;
  DownloadInvoice: any;
}
function VistaMovil({
  paginadorData,
  setOnChangeNumberPage,
  setOnChangeNumberRecords,
  handlerButtonDownloadCft,
  handlerButtonDownloadInvoice,
  DownloadDocument,
  DownloadDocumentID,
  DownloadInvoice,
}: Iprops) {
  return (
    <div>
      {" "}
      {paginadorData?.records.length > 0 && (
        <MShowMorePaginated
          onChangeNumberPage={setOnChangeNumberPage}
          onChangeNumberRecords={setOnChangeNumberRecords}
          paginated={paginadorData}
          children={paginadorData.records.map((element, index) => (
            <div key={index} className="card-movil-tabla px-0 shadow">
              <div className="detalles-movil-tabla" key={element.id}>
                <div className="col-12">
                  <h4>Certificado</h4>
                  <p>{element.nroCertificado}</p>
                </div>
                <div className="col-12">
                  <h4>ID Licitación</h4>
                  <p>{element.codigoExterno}</p>
                </div>

                <div className="col-12">
                  <h4>Mandante</h4>
                  <p>{element.nombreOrganismoMandante}</p>
                </div>
                <div className="col-12">
                  <h4>Finalidad</h4>
                  <p>{element.finalidad}</p>
                </div>
                <div className="col-12">
                  <h4>Moneda</h4>
                  <p>{element.tipoMoneda}</p>
                </div>
                <div className="col-12">
                  <h4>Monto</h4>
                  <p>
                    {convertToCurrency(
                      element.tipoMoneda,
                      element.montoCertificado
                    )}                    
                  </p>
                </div>
                <div className="col-12">
                  <h4>Término</h4>
                  <p>{toDate(element.fechaTermino)}</p>
                </div>
              </div>
              <div className="detalle-botones">
                <div className="movil-tablas-garantias-mis-cert">
                  {element.codigoDec5 && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary sistema external fs-14"
                        aria-expanded="false"
                        onClick={() =>
                          handlerButtonDownloadCft(element.nroCertificado)
                        }
                      >
                        Certificado <br />{" "}
                        {DownloadDocument &&
                          element.nroCertificado === DownloadDocumentID && (
                            <>
                              <MLoadingLight />
                            </>
                          )}
                        {element.nroCertificado &&
                          element.nroCertificado !== DownloadDocumentID && (
                            <i className="fas fa-file-alt  fa-2x icono"></i>
                          )}
                      </button>
                    </>
                  )}
                  {!element.codigoDec5 && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary disabled sistema fs-14"
                        aria-expanded="false"
                      >
                        Certificado <br />{" "}
                        <i className="fas fa-file-alt fa-2x"></i>
                      </button>
                    </>
                  )}

                  <button
                    type="button"
                    className="btn btn-primary disabled sistema fs-14"
                    aria-expanded="false"
                  >
                    Factura certificado <br />{" "}
                    <i className="fas fa-ban fa-2x"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        />
      )}
    </div>
  );
}

export default VistaMovil;
