import React from 'react';

import { PaymentData } from 'Models/Pay';

import imgWebpay from '@img/pago-tarjeta-01.png';

interface PropsPaymentMethodPolicy {
  statePay: PaymentData;
  handlerGatewayOnClick: (gatewayCode: string) => void;
}

const PaymentMethodPolicy: React.FC<PropsPaymentMethodPolicy> = ({ statePay, handlerGatewayOnClick }) => {

  const pay = statePay.gateways!.find(x=> x.descripcion === 'Transbank');

  return (
    <li
      className={`pago-tarjeta ${statePay.currentGatewayCode === 'Transbank' ? 'active' : ''}`}
      onClick={() => handlerGatewayOnClick(pay!.descripcion)}
    >
      <a
        href="#0"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <img src={imgWebpay} alt="Transbank" />
      </a>
    </li>
  );
};

export default PaymentMethodPolicy;
