import React, { useEffect, useState } from "react";
import { PaymentData } from "Models/Pay";
import imgWebpay from "@img/pago-tarjeta-01.png";
import imgServipag from "@img/pago-tarjeta-02.png";
import imgKhipu from "@img/pago-tarjeta-03.png";
import imgKhipuDiferido from "@img/pago-tarjeta-04.png";
import { PaymentMethods } from "@redux/models/GlobalModels";

interface PropsPaymentMethod {
  statePay: PaymentData;
  handlerGatewayOnClick: (gatewayCode: string) => void;
  selected: string;
}

const PaymentMethod = ({
  statePay,
  handlerGatewayOnClick,
  selected,
}: PropsPaymentMethod) => {
 
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods[]>([]);

  useEffect(() => {
    let filterPaymentMethods = [];

    for (let i = 0; i < statePay.gateways!.length; i++) {
      const element = statePay.gateways![i];
      let exists = image(element.descripcion);
      if (exists.type !== "img") {
        //delete statePay.gateways![i];
        continue;
      }
      else{
        filterPaymentMethods.push(element);
      }
    }

    setPaymentMethods(filterPaymentMethods);  
  }, [statePay]);

  const image = (medioPago: string) => {
    switch (medioPago) {
      case "Transbank":
        return <img src={imgWebpay} alt="transbank" />;
      case "Servipag":
        return <img src={imgServipag} alt="Servipag" />;
      case "Khipu":
        return <img src={imgKhipu} alt="khipu" />;
      case "KhipuDiferido":
        return <img src={imgKhipuDiferido} alt="khipu diferido" />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {paymentMethods
        ?.filter((x) => x.estado === true)
        ?.map((element, index) => {          
          return (
            <li
              key={index}
              className={`pago-tarjeta ${
                element?.descripcion === selected ? "active" : ""
              }`}
              onClick={() => handlerGatewayOnClick(element.descripcion)}
            >
              <a
                href="#0"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {image(element.descripcion)}
              </a>
            </li>
          );
        })}
    </>
  );
};

export default PaymentMethod;
