import { setCurrentTender } from './../actions/TenderActions';
import { TenderState } from './../models/TenderModels';
import { createSlice } from "@reduxjs/toolkit";

import {
  loadTendersAdjudicadas
  , loadTendersPublicadas
  , loadFilteredTendersAdjudicadas
  , loadFilteredTendersPublicadas
} from '../actions/TenderActions';

const inicialState: TenderState = {
    tendersPublicadas: undefined,
    tendersAdjudicadas: undefined,
    filteredTendersPublicadas: undefined,
    filteredTendersAdjudicadas: undefined,
    currentTender: undefined
};
const slice = createSlice({
    name: "tender",
    initialState: inicialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(loadTendersAdjudicadas, (state, action) => {
        state.tendersAdjudicadas = action.payload;
      })
      .addCase(loadTendersPublicadas, (state, action) => {
        state.tendersPublicadas = action.payload;
      })
      .addCase(loadFilteredTendersAdjudicadas, (state, action) => {
        state.filteredTendersAdjudicadas = action.payload;
      })
      .addCase(loadFilteredTendersPublicadas, (state, action) => {
        state.filteredTendersPublicadas = action.payload;
      })
      .addCase(setCurrentTender, (state, action) => {
        state.currentTender = action.payload;
      })
    }
})
export default slice.reducer;