import React from "react";
import ImgNotResults from "@img/mis-polizas-img-icon.svg";

interface PropsMessageNotData {
  title: string;
  description: string;
}

const PolicyNoData = (props: PropsMessageNotData) => {
  return (
    <>
      <div className="col-12 bg-white p-2 p-md-5 sin-certificados-content my-4">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <img src={ImgNotResults} alt="" />
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <div className="col-12 my-3">
              <h3 className="titles-left">
                {props.title} <br />
                {props.description}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyNoData;