

export const arrTerms = [
  {
    id: "1",
    question: `I. Cobertura`,
    answer: `
        Podrás cubrir garantías para finalidades de seriedad de la oferta, fiel cumplimiento, correcta ejecución, fiel cumplimiento y correcta ejecución y anticipo, éste último deberá ser solicitado a tu ejecutiva asignada.
        `,
    categoryCode: "termsAndConditions",
  },
  {
    id: "2",
    question: `II. Deducibles`,
    answer: `
    Para línea estándar el plazo será sin límites y línea de construcción será de 32 meses con posible aumento, para eso deberás solicitarlo a tu ejecutiva asignada.
        `,
    categoryCode: "termsAndConditions",
  },
  {
    id: "3",
    question: `III. Ámbito territorial y Jurisdicción`,
    answer: `
        El plazo será de 31 meses siendo posible ampliarlo, deberás ser solicitado a tu ejecutiva asignada.
        `,
    categoryCode: "termsAndConditions",
  },
  {
    id: "4",
    question: `IV. Requisitos especiales de cobertura`,
    answer: `
        El día de la fecha ingresada como término del certificado. Tienes la posibilidad de solicitar la ampliación del plazo a través de una reemisión si así lo requieres. 
        `,
    categoryCode: "termsAndConditions",
  },
  {
    id: "5",
    question: `V. Exclusiones`,
    answer: `
        Llamamos reemisión a las modificaciones solicitadas por el cliente sobre un certificado ya emitido. Se puede modificar: finalidad, plazo, dirección del mandante, rut mandante, nombre mandante, dirección cliente y monto, estos cambios tienen un costo asociado. 
        `,
    categoryCode: "termsAndConditions",
  },
  {
    id: "6",
    question: `VI. Anexos`,
    answer: `
        Los medios de pago por los cuales podrás pagar tus certificados son, WEBPAY, SERVIPAG, KIPHU, KIPHU (PAGO POR TERCEROS).
        `,
    categoryCode: "termsAndConditions",
  }
];
