import React from "react";
import SimpleLoading from "@controls/SimpleLoading";
import { convertToPeso } from "@helpers/helpers";
import { MAlert, MCards } from "@inversiones-ma/finfast-react-controls";
import { Collapse } from "reactstrap";
import { ValidateOperations } from "Models/Operation";
import ReissueCardData from "./ReissueCardData";

interface PropsReissueCardView {
  inRequest: boolean;
  setInRequest: React.Dispatch<React.SetStateAction<boolean>>;
  resultComission: ValidateOperations | undefined;
  showComsissionResult: boolean;
}

const ReissueCardView = (props: PropsReissueCardView) => {
  return (
    <>
      {props.inRequest ? (
        <>
          <div className="center">
            <div className="col-12">
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{
                  height: "190px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
              >
                <SimpleLoading className="orange"></SimpleLoading>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!props.inRequest && !props.showComsissionResult ? (
            <MAlert color={"danger"}>
              <span className="ingresa-alert02">
                <b>
                  Por favor contacta a tu ejecutiva para continuar con esta
                  emisión.
                </b>
              </span>
            </MAlert>
          ) : (
            <Collapse isOpen={!props.inRequest && props.showComsissionResult}>
              <ReissueCardData
                comision={props?.resultComission?.valoresComision?.comision}
              />             
            </Collapse>
          )}
        </>
      )}
    </>
  );
};

export default ReissueCardView;
