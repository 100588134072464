import { MAxios, MAxiosFormData } from "../conf";
import dayjs from "dayjs";

const DownloadTermAndConditionsInsurance = async (idAseguradora: number) => {};

const ValidationContractStartDate = async (  
  fechaContrato: string,
  fechaInicio: string
) => {
  let myOperation: any = {};
  myOperation["fechaInicioContrato"] = fechaContrato;
  myOperation["fechaInicioOperacion"] = fechaInicio;

  const endpoint = "Operacion/Operation/ValidateContractStartDate";

  const res = await MAxios().post(
    endpoint,
    myOperation
  );

  return res.data;
};

const ApiInsurance = {
  DownloadTermAndConditionsInsurance,
  ValidationContractStartDate,
};

export default ApiInsurance;
