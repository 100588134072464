import React from "react";
import { MButton, MPopup } from "@inversiones-ma/finfast-react-controls";
import cmf from "@img/CMF-logo-small.png";
import aws from "@img/aws-logo-small.png";
import siiFinfast from "@img/SII-Finfast.png";

interface PropsModalSII {
    setShowModal:Function;
    showModal: boolean;
}

const ModalSII = (props: PropsModalSII) => {
  return (
    <>
      <MPopup
        size="lg"
        showClose={true}
        showModal={props.showModal}
        setShowModal={props.setShowModal}
      >
        <div className="text-center">
          <div className="col-12 mb-3">
            <img src={siiFinfast} alt="SII y Finfast" />
          </div>
          <div className="col-12">
            <h4 className="titles-center">
              ¿Por qué es necesario vincular tu cuenta de sii?
            </h4>
            <p className="text-start mt-5">
              La vinculación de tu cuenta de SII nos permitirá visualizar tus
              facturas y datos generales para entregarte una evaluación más
              precisa y ofertas personalizadas.
              <br />
            </p>
            <p className="text-start fw-bold mt-3">
              Importante: Vincular tu cuenta de SII NO otorgará permisos a
              FinFast para modificar o realizar acciones en tu nombre.
            </p>
            <div className="row mx-auto justify-content-md-center mt-3">
              <div className="col-12 col-md-auto text-start text-md-center ">
                <small className="color-gris87 me-2">
                  Datos protegidos por
                </small>
                <img
                  src={aws}
                  className="d-inline mt-2 mt-md-0"
                  alt="aws"
                />
              </div>
              <div className="col-12 col-md-auto mt-3 mt-md-0 text-start text-md-center">
                <small className="color-gris87 me-2">Nos respalda</small>
                <img
                  src={cmf}
                  className="d-inline mt-2 mt-md-0"
                  alt="cmf"
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center g-3 my-4">
            <div className="col-12 col-md-4 order-first order-md-2">
              <MButton
                type="button"
                className="btn btn-primary sistema w-100"
                onClick={() => props.setShowModal()}      
              >
                Entendido
              </MButton>
            </div>
          </div>
        </div>
      </MPopup>
    </>
  );
};

export default ModalSII;
