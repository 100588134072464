import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./BuyCertificate.scss";
import imgNewCertificate from "@img/nuevo-certificado-garantias-icon.svg";
import imgSearchTender from "@img/buscar-licitaciones-icon.svg";
import imgQuotedCertificates from "@img/certificados-cotizados-icon.svg";
import { Step } from "@redux/models/NewCertificateModels";
import { setCurrentStep } from "@redux/actions/NewCertificateActions";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";

function BuyCertificate({ history }: RouteComponentProps) {
  const dispatch = useAppDispatch();
  const { enabledEmit } = useAppSelector((store) => store.account);

  useEffect(() => {
    if (!enabledEmit) {
      history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClicNewCertificate = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(NewCertificateActions.cleanQuote(undefined));
    dispatch(setCurrentStep(Step.seleccionar));
    history.push("/new-certificate");
  };

  const onClickSearchTender = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    history.push("/search-tenders");
  };

  const onClickQuotedCertificates = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    history.push("/quotes");
  };

  return (
    <main className="bg-compra-cetificado">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="titles-center">Comprar Garantía</h2>
            <p>
              En esta sección puedes cotizar, comprar y modificar tu
              Garantía.
            </p>
          </div>

          <section className="do-something">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="subtitle-section pb-3">
                  ¿Qué quieres hacer hoy?
                </h3>
              </div>
              <div className="col-md-4 pb-4">
                <MCards
                  color="primary"
                  className="color-text-low action-card card-comprar-cetificado cursor-pointer"
                  onClick={onClicNewCertificate}
                  disabled={false}
                >
                  <div className="row">
                    <div className="col-2 my-auto pl-2">
                      <img
                        src={imgNewCertificate}
                        alt="icon-comprar"
                        className="icon-comprar"
                      />
                    </div>
                    <div className="col-10 my-auto">
                      <a href="#0" onClick={onClicNewCertificate}>
                        Nueva Garantía <i className="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                </MCards>
              </div>
              <div className="col-md-4 pb-4">
                <MCards
                  color="primary"
                  className="color-text-low action-card card-comprar-cetificado cursor-pointer"
                  onClick={onClickSearchTender}
                >
                  <div className="row">
                    <div className="col-2 my-auto pl-2">
                      <img
                        src={imgSearchTender}
                        alt="icon-comprar"
                        className="icon-comprar"
                      />
                    </div>
                    <div className="col-10 my-auto">
                      <a href="#0" onClick={onClickSearchTender}>
                        Buscar licitaciones{" "}
                        <i className="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                </MCards>
              </div>
              <div className="col-md-4 pb-4">
                <MCards
                  color="primary"
                  className="color-text-low action-card card-comprar-cetificado cursor-pointer"
                  onClick={onClickQuotedCertificates}
                >
                  <div className="row">
                    <div className="col-2 my-auto pl-2">
                      <img
                        src={imgQuotedCertificates}
                        alt="icon-comprar"
                        className="icon-comprar"
                      />
                    </div>
                    <div className="col-10 my-auto">
                      <a href="#0" onClick={onClickQuotedCertificates}>
                        Garantías cotizadas{" "}
                        <i className="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                </MCards>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}

export default withRouter(BuyCertificate);
