import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import imgCotizarCertificadosGarantias from "@img/cotizar_certificados-garantias.svg";
import imgComprarCertificadosGarantias from "@img/comprar_certificado-garantias.svg";
import imgModificarCertificadosGarantias from "@img/modificar_certificado-garantias.svg";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import { scroller } from "react-scroll";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setCurrentStep } from "@redux/actions/NewCertificateActions"
import { Step } from "@redux/models/NewCertificateModels"

function HomeActions({ history }: RouteComponentProps) {
  const { enabledEmit } = useAppSelector((store) => store.account);
  const dispatch = useAppDispatch();

  const onClickQuote = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    scroller.scrollTo("quote-section", {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: -80,
    });
  };

  const onClickBuyCertificate = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(setCurrentStep(Step.seleccionar));

    history.push("/buy-certificate");
  };

  const onClickModifyCertificate = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    history.push("/my-certificates");
  };

  return (
    <section className="do-something">
      <div className="row">
        <div className="col-12">
          <h3 className="subtitle-section pb-3">¿Qué quieres hacer hoy?</h3>
        </div>
        <div className="col-md-4 pb-4">
          <MCards
            color="primary"
            className="color-text-low action-card cursor-pointer"
            onClick={onClickQuote}
          >
            <div className="row">
              <div className="col-2 my-auto pl-2">
                <img
                  src={imgCotizarCertificadosGarantias}
                  alt="icon-comprar"
                  className="icon-comprar"
                />
              </div>
              <div className="col-10 my-auto">
                <a href="#0" onClick={onClickQuote}>
                  Cotizar una Garantía <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </MCards>
        </div>
        <div className="col-md-4 pb-4">
          <MCards
            color="primary"
            className={`color-text-low action-card cursor-pointer${!enabledEmit ? " disabled-card" : ""}`}
            onClick={onClickBuyCertificate}
          >
            <div className="row">
              <div className="col-2 my-auto pl-2">
                <img
                  src={imgComprarCertificadosGarantias}
                  alt="icon-comprar"
                  className="icon-comprar"
                />
              </div>
              <div className="col-10 my-auto">
                <a href="#0" onClick={onClickBuyCertificate}>
                  Comprar Garantía <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </MCards>
        </div>
        <div className="col-md-4 pb-4">
          <MCards
            color="primary"
            className={`color-text-low action-card cursor-pointer${!enabledEmit ? " disabled-card" : ""}`}
            onClick={onClickModifyCertificate}
          >
            <div className="row">
              <div className="col-2 my-auto pl-2">
                <img
                  src={imgModificarCertificadosGarantias}
                  alt="icon-comprar"
                  className="icon-comprar"
                />
              </div>
              <div className="col-10 my-auto">
                <a href="#0" onClick={onClickModifyCertificate}>
                  Modificar una Garantía <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </MCards>
        </div>
      </div>
    </section>
  );
}

export default withRouter(HomeActions);
