import React from "react";
import "./Questions.scss";
import Questions from "./Questions";

const QuestionsHome = () => {
  return (
    <>
      <main className="bg-home">
        <section className="preguntas-frecuentes-content-do-something">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      <span className="migas02">Preguntas frecuentes</span>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-12 text-center">
                <h2 className="titles-center">Preguntas frecuentes</h2>
                <p>
                  En esta sección podrás encontrar respuestas a las preguntas
                  frecuentes
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="subtitle-section pb-3">
                  ¿En qué te podemos ayudar hoy?
                </h3>
              </div>
            </div>

            <div className="row justify-content-center justify-content-md-start">
              <Questions></Questions>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default QuestionsHome;