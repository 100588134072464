import ReactGA from "react-ga4";
import {useEffect} from "react";
import {loadLines, loadLinesInsurance, loadInsuranceCarrier} from "@redux/actions/SessionActions";
import ApiHome from "@api/garantias/home";
import ApiServer from "@api/server/Server";
import ApiBilling from "@api/billing/Billing";
import {Step} from "@redux/models/NewCertificateModels";
import {useAppDispatch, useAppSelector} from "@redux/hooks";
import {loadBillingState} from "@redux/actions/BillingActions";
import useHandleApiResponse from "@redux/hooks/useHandleApiResponse";
import {setCurrentStep} from "@redux/actions/NewCertificateActions";
import ApiProvider from "@api/proveedor/Proveedor";
import {loadSubscriptionStatus} from "@redux/actions/AccountActions";

export const useHomeData = () => {
    const dispatch = useAppDispatch();

    const {token, run} = useAppSelector((store) => store.account);

    // Refactorización de código
    const handleApiResponse = useHandleApiResponse();

    const getApprovedLines = async () => {
        const resp = await ApiHome.getApprovedLines();
        handleApiResponse(resp, loadLines);
    };

    const getLinesInsurance = async () => {
        const resp = await ApiHome.getLinesInsurance();
        handleApiResponse(resp, loadLinesInsurance);
    };

    const getAllInsuranceCarrier = async () => {
        const resp = await ApiHome.getAllInsuranceCarrier();
        handleApiResponse(resp, loadInsuranceCarrier);
    };

    const getServerTime = async () => {
        const resp = await ApiServer.getServerTime();
        if (resp.success) {
            let serverDate = new Date(resp.dataList.time);
            ReactGA.initialize("UA-20409274-8");
            ReactGA.event({
                category: "Inicio",
                action: `Usuario ingresó a inicio el día ${serverDate.toLocaleDateString()}`,
            });
        }
    };

    const getSubscriptionStatus = async () => {
        const resp = await ApiProvider.GetEstadoSubscripcionCte(run as string);
        if (resp !== undefined) {
            handleApiResponse(resp, loadSubscriptionStatus, true);
        }
    }

    useEffect(() => {
        dispatch(setCurrentStep(Step.cotizar));
    }, [dispatch]);

    useEffect(() => {
        // Deuda técnica de crear una función particular para este llamado
        Promise.all([ApiBilling.callDataInvoice()])
            .then((resp: any) => {
                dispatch(loadBillingState(resp[0]));
            })
            .catch((err) => {
                console.error("Ha ocurrido un error en la solicitud HTTP:" + err.message);
            });

        if (token && run) {
            getApprovedLines();
            getLinesInsurance();
            getAllInsuranceCarrier();
            getServerTime();
            getSubscriptionStatus();
            
        }
    }, [token, run]);
};
