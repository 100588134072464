export interface InsuranceLineState {
  aseguradora: Aseguradora[];
}

export interface Aseguradora {
  id: number;
  razonSocial: string;
  rut: string;
  rutCuerpo: number;
  rutDigito: string;
  direccion: string;
  idRegion: number;
  idComuna: number | null;
  telefono1: string;
  telefono2: string;
  celular: string;
  habilitado: boolean;
  descripcion: string;
  superaPorcentajeLineaCFT: boolean;
}

export enum AseguradoraTypes {  
  cesce = 1, //"Cesce Chile Aseguradora SA",
  sura = 2, 
  finfast = 0,
  fogape = 3,
}

export const ValoresOfertaLineaCesce = [
  { idNivelVenta: 1, montoUF: 500 },
  { idNivelVenta: 2, montoUF: 500 },
  { idNivelVenta: 3, montoUF: 500 },
  { idNivelVenta: 4, montoUF: 500 },
  { idNivelVenta: 5, montoUF: 500 },
  { idNivelVenta: 7, montoUF: 1000 },
  { idNivelVenta: 8, montoUF: 1000 },
  { idNivelVenta: 9, montoUF: 1000 },
  { idNivelVenta: 10, montoUF: 2500 },
  { idNivelVenta: 11, montoUF: 2500 },
  { idNivelVenta: 12, montoUF: 2500 },
  { idNivelVenta: 13, montoUF: 2500 },
];
