import { MAxios } from "../conf";

const getServerTime = async () => {
  let endpoint: string = "Server/Time/GetServerTime";
  let res = MAxios()
    .get(endpoint)
    .then((res) => res.data);
  return res;
};

const ApiServer = {
  getServerTime,
};

export default ApiServer;
