import React from "react";
import { MPopup, MButton } from "@inversiones-ma/finfast-react-controls";
import checkImage from "@img/icon-check-confirmacion.svg";
import { useAppDispatch } from "@redux/hooks";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import { useHistory } from "react-router-dom";

interface PropsSuccessMessage {
  showModal: boolean;
  setShowModal: any;
  close?: any;
}
function SuccessMessage(props: PropsSuccessMessage) {
  const dispatch = useAppDispatch();

  const onClickContinueShopping = () => {
    dispatch(NewCertificateActions.setSelectedData(undefined));
    dispatch(NewCertificateActions.setTenderData(undefined));
    dispatch(
      NewCertificateActions.setCurrentStep(
        NewCertificateModels.Step.seleccionar
      )
    );
    if (props.close) {
      props.close();
    }
  };

  const history = useHistory();

  return (
    <MPopup
      size="lg"
      showModal={props.showModal}
      setShowModal={props.setShowModal}
      showClose={false}
    >
      <div className="text-center">
        <img
          src={checkImage}
          alt="datos confirmados"
          className="icon-pop-up-el"
        />
        <h4 className="titles-center">
          Datos <br /> confirmados
        </h4>
        <p className="py-3">
          Tu certificado se ha añadido al carrito de compras
        </p>

        <div className="row justify-content-center">
          <div className="col-12 col-md-4 order-1 order-md-0 my-auto py-2">
            <MButton
              outline={true}
              type="button"
              onClick={onClickContinueShopping}
              className="w-100"
            >
              Seguir comprando
            </MButton>
          </div>
          <div className="col-12 col-md-4 order-0 order-md-1 py-2">
            <MButton
              type="button"
              className="w-100"
              onClick={() => history.push("/homecart")}
            >
              Ir al carrito de compras
            </MButton>
          </div>
        </div>
      </div>
    </MPopup>
  );
}

export default SuccessMessage;
