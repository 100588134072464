import { MAxios, MAxiosBlob } from "../conf";

export async function callOperacionOperationDownloadListCurrents() {
  const endpoint = "Operacion/Operation/DownloadListCurrents";
  const res = await MAxiosBlob().post(endpoint);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

export async function callOperacionOperationDownloadListCurrentsPolicy() {
  const endpoint = "Operacion/Operation/DownloadListCurrentsPolicy";

  const res = await MAxiosBlob().post(endpoint);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

export async function callOperacionOperationDownloadListExpired(
  idOperationTypeDocument: number
) {
  const endpoint = "Operacion/Operation/DownloadListExpired";

  let formData = new FormData();
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString()
  );

  const res = await MAxiosBlob().post(endpoint, formData);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

export async function callOperacionOperationDownloadListExpiredPolicy(
  idOperationTypeDocument: number
) {
  const endpoint = "Operacion/Operation/DownloadListExpiredPolicy";

  let formData = new FormData();
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString()
  );

  const res = await MAxiosBlob().post(endpoint, formData);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

export async function callOperacionOperationDownloadListPendingPolicy(
  idOperationTypeDocument: number
) {
  const endpoint = "Operacion/Operation/DownloadListPendingPolicy";
  idOperationTypeDocument=2;
  let formData = new FormData();
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString()
  );

  const res = await MAxiosBlob().post(endpoint, formData);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

export async function callOperacionOperationDownloadListAccident() {
  const endpoint = "Operacion/Operation/DownloadListAccident";

  const res = await MAxiosBlob().post(endpoint);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

export async function callOperacionOperationDownloadListCanceled() {
  const endpoint = "Operacion/Operation/DownloadListCanceled";

  const res = await MAxiosBlob().post(endpoint);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

// Download CFT
export async function CallOperationDownloadCertificate(
  data: any,
) {
  let formData = new FormData();
  formData.append("certificateNumber", data);
  const endpoint = "Certificado/Certificado/DownloadCertificate";

  const res = await MAxiosBlob().post(endpoint, formData);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}
// Download invoice
export async function callInvoiceDownload(data: any) {
  let formData = new FormData();
  formData.append("certificateNumber", data);
  const endpoint = "Factura/Factura/DownloadInvoice";

  const res = await MAxiosBlob().post(endpoint, formData);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

export async function DownloadReInvoice(data: any) {
  let formData = new FormData();
  formData.append("idOperacion", data);
  const endpoint = "Factura/Factura/DownloadReInvoice";

  const res = await MAxiosBlob().post(endpoint, formData);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

// Download Policy
// export async function CallOperationDownloadPolicy(data: number) {
//   let formData = new FormData();
//   formData.append("idOperacion", data.toString());
//   const endpoint = "Certificado/Certificado/DownloadPolicy";

//   const res = await MAxiosBlob().post(endpoint, formData);
//   if (res.status !== 200) {
//     console.error("error");
//   } else {
//     return res;
//   }
// }

export async function DownloadPolicySuraAzure(item: string){
  let formData = new FormData();
  formData.append("item",item.toString());
  const endpoint = "Seguro/Policy/DownloadPolicySuraAzure";

  const res= await MAxiosBlob().post(endpoint,formData);
  if(!res){
    console.error("error");
  }
  else{
    return res;
  }
}

export async function DownloadLetterAuthenticaticity(nroPoliza: string) {
  let formData = new FormData();
  formData.append("NroPoliza", nroPoliza);
  const endpoint = "Aseguradora/Aseguradora/DownloaPolicyAuthenticityLetter";

  const res = await MAxiosBlob().post(endpoint, formData);
  if (res.status !== 200) {
    console.error("error");
  } else {
    return res;
  }
}

const ApiDownloadCertificates = {
  callOperacionOperationDownloadListCurrents,
  callOperacionOperationDownloadListCurrentsPolicy,
  callOperacionOperationDownloadListAccident,
  callOperacionOperationDownloadListCanceled,
  callOperacionOperationDownloadListExpired,
  callOperacionOperationDownloadListExpiredPolicy,
  callOperacionOperationDownloadListPendingPolicy,
  CallOperationDownloadCertificate,
  callInvoiceDownload,
  DownloadReInvoice,
  DownloadPolicySuraAzure,
  DownloadLetterAuthenticaticity,
};

export default ApiDownloadCertificates;
