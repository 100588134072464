import React from "react";
import ApiManualFactoring from "@api/tributaryFolder/tributaryFolder";

const DigitalCertificateManual = () => {
  const onClickAqui = (event: any) => {
    event.preventDefault();
    ApiManualFactoring.DonwloadManualFactoring();
  };

  return (
    <b>
      Para asegurar la correcta configuración de tu certificado, te recomendamos
      descargar nuestro manual de configuración{" "}
      <a
        className="text-link"
        onClick={onClickAqui}
        href="#0"
      >
        Aquí
      </a>
    </b>
  );
};

export default DigitalCertificateManual;
