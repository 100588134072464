import React from "react";
import { useAppSelector } from "@redux/hooks";
import MLoading from "@controls/MLoading";
import { LineInsuranceCarrier } from "@redux/models/SessionModels";
import "./HomeLinesConstruction.scss";
import LineGuarantees from "./Lines/LineGuarantees";
import LinePolicy from "./Lines/LinePolicy";
import "./Line.scss";

function HomeLinesStandart() {
  const { lines, linesInsurance } = useAppSelector((store) => store.session);

  // Si no hay lineas no devolver nada
  if (lines && lines.length === 0) {
    return <></>;
  }
  
  const showLinesInsurance = (linesInsurance: LineInsuranceCarrier[]) => {
    return (
      <div className="rounded-card-bg p-4">
        <LinePolicy
          linesInsurance={linesInsurance}
        />
      </div>

    );
  };

  const showLinesCFT = () => {
    return (
      <div className="rounded-card-bg p-4 mb-4">
        <LineGuarantees />
      </div>
    );
  };

  if (lines || linesInsurance) {
    return (
      <section className="line-available pb-4 home-garantias-polizas">
        {lines && lines.length > 0 && showLinesCFT()}
        {linesInsurance &&
          linesInsurance.length > 0 &&
          showLinesInsurance(linesInsurance)}
      </section>
    );
  } else {
    return (
      <section className="line-available pb-4">
        <div className="row mb-4">
          <div className="col-12">
            <MLoading modal={false} />
          </div>
        </div>
      </section>
    );
  }

}

export default HomeLinesStandart;
