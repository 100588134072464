import React from 'react'
import { convertToCurrency, toDate } from '@helpers/helpers'
import { MLoadingLight, MShowMorePaginated } from '@inversiones-ma/finfast-react-controls'
import { InsuranceCarrier } from '@redux/models/SessionModels';


interface IProps {
    setOnChangeNumberPage: React.Dispatch<React.SetStateAction<number>>;
    setOnChangeNumberRecords: React.Dispatch<React.SetStateAction<number>>;
    warratyData: any;
    insuranceCarrier: InsuranceCarrier[] | undefined;
    handlerButtonDonwnloadCft: any;
    DownloadDocument: boolean;
    DownloadDocumentID: string;
}

export const ExpiredGridPolicyMobile = ({ handlerButtonDonwnloadCft, insuranceCarrier, setOnChangeNumberPage
    , setOnChangeNumberRecords, warratyData, DownloadDocument, DownloadDocumentID }: IProps) => {
    return (
        <MShowMorePaginated
            onChangeNumberPage={setOnChangeNumberPage}
            onChangeNumberRecords={setOnChangeNumberRecords}
            paginated={warratyData}
        >
            {warratyData?.records.map((element: any, index: any) => (
                <div key={index} className="card-movil-tabla px-0 shadow ">
                    <div className="detalles-movil-tabla">
                        <div className="col-12">
                            <h4>Póliza</h4>
                            <p>{element.nroCertificado}</p>
                        </div>
                        <hr />
                        <div className="col-12">
                            <h4>Folio</h4>
                            <p>{element.folioInterno}</p>
                        </div>
                        <hr />

                        <div className="col-12">
                            <h4>ID Licitación</h4>
                            <p>{element.codigoExterno}</p>
                        </div>
                        <hr />

                        <div className="col-12">
                            <h4>Mandante</h4>
                            <p>{element.nombreOrganismoMandante}</p>
                        </div>
                        <hr />

                        <div className="col-12">
                            <h4>Aseguradora</h4>
                            <p>
                                {
                                    insuranceCarrier?.find(
                                        (item) => item.id === element.idAseguradora
                                    )?.razonSocial
                                }
                            </p>
                        </div>
                        <hr />

                        <div className="col-12">
                            <h4>Finalidad</h4>
                            <p>{element.finalidad}</p>
                        </div>
                        <hr />

                        <div className="col-12">
                            <h4>Monto</h4>
                            <p>
                                {convertToCurrency(
                                    element.tipoMoneda,
                                    element.montoCertificado
                                )} {" " + element.tipoMoneda}
                            </p>
                        </div>
                        <hr />

                        <div className="col-12">
                            <h4>Término</h4>
                            <p>{toDate(element.fechaTermino)}</p>
                        </div>
                    </div>
                    <div className="detalle-botones">
                        <div className="certificado">
                            <div className="movil-tablas-garantias-mis-cert">
                                {element.codigoDec5 && (
                                    <button
                                        type="button"
                                        className="btn btn-primary sistema disabled external fs-14"
                                        aria-expanded="false"
                                        onClick={() =>
                                            handlerButtonDonwnloadCft(
                                                element.codigoDec5
                                            )
                                        }
                                    >
                                        Póliza <br />
                                        {DownloadDocument &&
                                            element.nroCertificado ===
                                            DownloadDocumentID && (
                                                <>
                                                    <MLoadingLight />
                                                </>
                                            )}
                                        {element.nroCertificado &&
                                            element.nroCertificado !==
                                            DownloadDocumentID && (
                                                <i className="fas fa-file-alt fa-2x icono"></i>
                                            )}
                                    </button>
                                )}

                                {!element.codigoDec5 && (
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-primary disabled sistema fs-14"
                                            aria-expanded="false"
                                        >
                                            Póliza <br />
                                            <i className="fas fa-file-alt fa-2x"></i>
                                        </button>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </MShowMorePaginated>
    )
}
