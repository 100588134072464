import React from "react";
import DigitalCertificateManual from "./DigitalCertificateManual";

export const GetDatamartCertificateMessageFromCode = (code: string) => {
  switch (code) {
    case "F0070":
      return {
        title: "CREDENCIALES INCORRECTAS",
        text: "Por favor revisa que los datos ingresados sean los correctos.",
      };
    case "F0073":
      return {
        title: "EL CERTIFICADO CARGADO NO ESTÁ VIGENTE",
        text: "Por favor intenta agregar un nuevo Certificado Digital.",
      };
    case "F0075":
      return {
        title: "La autenticación de certificado se encuentra deshabilitada",
        text: (
          <>
            Por favor habilita la autenticación de tu Certificado Digital
            directamente en SII accediendo con tu Rut empresa y clave empresa.
            <br /> <br />
            <DigitalCertificateManual />
          </>
        ),
      };
    case "F0172":
    case "F0173":
      return {
        title: "credenciales sii incorrectas",
        text: "Verifica que los datos ingresados son los correctos.",
      };
    case "F0174":
    case "F0175":
      return {
        title: "credenciales sii no autorizadas",
        text: (
          <>
            Los datos ingresados no tienen permisos para operar. <br /> Ingresa
            a SII para autorizar al usuario ingresado, o bien, ingresa otras
            credenciales.
            <br />
            <br />
            <DigitalCertificateManual />
          </>
        ),
      };
    case "F0176":
    case "F0177":
      return {
        icon: "alert",
        title: "Ocurrió un error al validar tus credenciales de sii",
        text: (
          <>
            Por favor intenta nuevamente. <br /> Si el problema persiste,
            contacta a tu ejecutivo.
          </>
        ),
      };
    default:
      return {
        icon: "alert",
        title: "HA OCURRIDO UN ERROR AL INTENTAR VERIFICAR EL CERTIFICADO",
        text: "Por favor intenta nuevamente. Si el problema persiste contacta a tu ejecutiva.",
      };
  }
};
