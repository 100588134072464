import React from "react";

import { ErrorQuoteEmit, ValidateOperations } from "Models/Operation";

import { MAlert } from "@inversiones-ma/finfast-react-controls";
import { EnumErrorType } from "@redux/models/QuoteModels";

interface ErrorAlertProps {
  quoteData: ErrorQuoteEmit[];
  slim?: boolean;
}

const ErrorAlert = ({ quoteData, slim }: ErrorAlertProps) => {
  if (quoteData.length === 0 || quoteData.find((x) => x.errorType === null)) {
    return <></>;
  }
  let errorType: any = quoteData[0].errorType?.toLocaleLowerCase();
  let title: any = quoteData[0].mensaje;
  let message: any = quoteData[0].descripcionError;
  return errorType === EnumErrorType.DANGER ||
    errorType === EnumErrorType.INFO ? (
    <MAlert color={errorType} className={slim ? "slim" : ""}>
      <div className="d-flex flex-column">
        {title && (
          <div className="fw-bold">{title && quoteData[0].mensaje}</div>
        )}
        <div>{message}</div>
      </div>
    </MAlert>
  ) : (
    <></>
  );
};

export default ErrorAlert;
