import { MAxios } from "../conf";
import {
  BillingInformation,
  TransferInformation,
  Billing,
} from "@redux/models/BillingModel";

const callDataInvoice = async () => {
  const endpoint = "Factura/Factura/DataInvoice";

  const res = await MAxios().post(endpoint);
  if (res.data.success) {
    if (res.data.dataList && res.data.dataList.length > 0) {
      const billinginfo: BillingInformation =
        res.data.dataList[0].informacionFactura;
      const transferinfo: TransferInformation =
        res.data.dataList[0].datosTransferencia;
      const billing: Billing = {
        bloquedEmit: res.data.bloquedEmit,
        billingInformation: billinginfo,
        transferInformation: transferinfo,
      };
      return billing;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

const ApiBilling = {
  callDataInvoice,
};

export default ApiBilling;
