import React from "react";

import { AddTendersAddOperation, OperacionDTO } from "Models/Operation";
import { FieldValues, UseFormReturn } from "react-hook-form";

import ApiOperation from "@api/garantias/operation/Operation";
import GenericBaseModel from "@api/GenericBaseModel";
import { MInputCheckBox } from "@inversiones-ma/finfast-react-controls";
import * as SessionActions from "@redux/actions/SessionActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";

import { BuyCartHook } from "../../../Cart/partial/Hook/BuyCartHook";

export const useHandleVerifyData = (
  form: UseFormReturn<FieldValues, object>,
  handlerModal: () => void,
  addOrEdit: NewCertificateModels.Step,
  setShowSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();
  const { tenderData } = useAppSelector((store) => store.newCertificate);
  let innerWidthUse: number = window.innerWidth;

  const { resetStepToPay } = BuyCartHook();

  const handlerLabel = () => {
    // console.log('tender', tenderData);
    let check: JSX.Element = labelFinfast();
    if (tenderData?.idAseguradora === 2) {
      check = labelSura();
    } else {
      check = labelFinfast();
    }

    const paddingRight = innerWidthUse < 991 ? "14%" : "0";

    return (
      <div style={{ paddingRight: paddingRight }}>
        <MInputCheckBox
          name="verified"
          form={form}
          className=" pt-2"
          label={check}
        />
      </div>
    );
  };

  const labelSura = () => {
    return (
      <>
        {innerWidthUse > 991 ? (
          <label className="p-2 p-lg-0 mx-0 ps-0">
            Acepto los{" "}
            <label
              style={{ cursor: "pointer" }}
              className="simLink center"
              onClick={() => handlerModal()}
            >
              terminos y condiciones
            </label>{" "}
            y confirmo <br />
            <div style={{ paddingLeft: "14%" }}>
              que he verificado la información proporcionada en este formulario.
            </div>
          </label>
        ) : (
          <label className="p-2 p-lg-0 mx-0 ps-0">
            Acepto los{" "}
            <label
              style={{ cursor: "pointer" }}
              className="simLink center"
              onClick={() => handlerModal()}
            >
              terminos y condiciones
            </label>{" "}
            y
            <div style={{ paddingLeft: "19.5%" }}>
              {" "}
              confirmo que he verificado la información proporcionada en este
              formulario.
            </div>
          </label>
        )}
      </>
    );
  };

  const labelFinfast = () => {
    return (
      <>
        <label
          style={{ cursor: "pointer" }}
          className="simLink center"
          onClick={() => handlerModal()}
        >
          He verificado que los datos son correctos. <br /> Acepto terminos y
          condiciones
        </label>
      </>
    );
  };

  const LoadTermsConditions = async (idAseguradora: number) => {
    await ApiOperation.GetTermsConditions(idAseguradora)
      .then((response) => {
        if (response.data.type === "text/plain") {
          return;
        }
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => console.error("error api LoadTermsConditions:", error));
  };

  const AddOperation = async (): Promise<boolean> => {
    let result: boolean = false;
    debugger;
    await ApiOperation.AddOperation(tenderData!)
      .then((response: GenericBaseModel<AddTendersAddOperation>) => {
        if (response.success) {
          debugger;
          const newItem: OperacionDTO = {
            ...response.data.operacion,
            campaignName: response.data.campaignName,
            campaignAmount: response.data.campaignAmount,
            fechaInicioContrato: tenderData?.fechaInicioContrato,
            isReissue: false,
          };

          // acá se debe escribir el carro de compra
          resetStepToPay();
          dispatch(SessionActions.addItemToCart(newItem));
          // dispatch(SessionActions.loadDiscountValue(undefined));
          // dispatch(SessionActions.cleanDiscountZone());
          result = true;
        } else {
          console.error(response.message);
        }
      })
      .catch((error) => {
        console.error("error api AddOperation:", error);
        result = false;
      })
      .finally(() => {
        dispatch(SessionActions.loadDiscountValue(undefined));
        dispatch(SessionActions.cleanDiscountZone());
      });

    return Promise.resolve(result);
  };

  return {
    handlerLabel,
    LoadTermsConditions,
    AddOperation,
  };
};
