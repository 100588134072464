import {
  MLoadingLight,
  MShowMorePaginated,
} from "@inversiones-ma/finfast-react-controls";
import React from "react";
import { convertToCurrency, toDate } from "@helpers/helpers";
import { OperacionDTO } from "../../../../../../Models/Operation";
import { Paginador } from "../../../../../../Models/Paginador";

interface Iprops {
  Paginador: Paginador<OperacionDTO>;
  setOnChangeNumberPage: (b: number) => any;
  setOnChangeNumberRecords: (b: number) => any;
  handlerButtonDonwnloadCft: Function;
  handlerButtonDownloadInvoice: Function;
  handlerValidatePurpose: Function;
  DownloadDocument: boolean;
  DownloadDocumentID: string;
  loading: boolean;
  handlerGridButtonReissueOnClick: Function;
  handlerButtonDonwnloadReInvoice: Function;
}
function VistaMovil({
  handlerGridButtonReissueOnClick,
  loading,
  Paginador,
  setOnChangeNumberPage,
  setOnChangeNumberRecords,
  handlerButtonDonwnloadCft,
  handlerButtonDownloadInvoice,
  handlerValidatePurpose,
  DownloadDocument,
  DownloadDocumentID,
  handlerButtonDonwnloadReInvoice
}: Iprops) {
  return (
    <div>
      {Paginador?.records.length > 0 && (
        <MShowMorePaginated
          onChangeNumberPage={setOnChangeNumberPage}
          onChangeNumberRecords={setOnChangeNumberRecords}
          paginated={Paginador}
          children={Paginador.records.map((element, index) => (
            <div className="card-movil-tabla px-0 shadow " key={element.id}>
              <div className="detalles-movil-tabla">
                <div className="col-12">
                  <h4>Certificado</h4>
                  <p>{element.nroCertificado}</p>
                </div>
                <div className="col-12">
                  <h4>ID Licitación</h4>
                  <p>{element.codigoExterno}</p>
                </div>

                <div className="col-12">
                  <h4>Mandante</h4>
                  <p>{element.nombreOrganismoMandante}</p>
                </div>
                <div className="col-12">
                  <h4>Finalidad</h4>
                  <p>{element.finalidad}</p>
                </div>
                <div className="col-12">
                  <h4>Moneda</h4>
                  <p>{element.tipoMoneda}</p>
                </div>
                <div className="col-12">
                  <h4>Monto</h4>
                  <p>
                    {convertToCurrency(
                      element.tipoMoneda,
                      element.montoCertificado
                    )}
                  </p>
                </div>
                <div className="col-12">
                  <h4>Término</h4>
                  <p>{toDate(element.fechaTermino)}</p>
                </div>
              </div>
              <div className="detalle-botones">
                <div className="certificado">
                  <div className="movil-tablas-garantias-mis-cert">
                    {element.codigoDec5 && (
                      <button
                        type="button"
                        className="btn btn-primary sistema external fs-14"
                        aria-expanded="false"
                        onClick={() =>
                          handlerButtonDonwnloadCft(element.nroCertificado)
                        }
                      >
                        Certificado <br />
                        {DownloadDocument &&
                          element.nroCertificado === DownloadDocumentID && (
                            <>
                              <MLoadingLight />
                            </>
                          )}
                        {element.nroCertificado &&
                          element.nroCertificado !== DownloadDocumentID && (
                            <i className="fas fa-file-alt fa-2x icono"></i>
                          )}
                      </button>
                    )}
                    {!element.codigoDec5 && (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary disabled sistema fs-14"
                          aria-expanded="false"
                        >
                          Certificado <br />
                          <i className="fas fa-file-alt fa-2x"></i>
                        </button>
                      </>
                    )}
                    {element.tieneFactura && (
                      <button
                        type="button"
                        className="btn btn-primary sistema fs-14"
                        onClick={() =>
                          handlerButtonDownloadInvoice(element.nroCertificado)
                        }
                      >
                        Factura certificado <br />{" "}
                        <i className="fas fa-file-download fa-2x"></i>
                      </button>
                    )}
                    {!element.tieneFactura && (
                      <button
                        type="button"
                        className="btn btn-primary disabled sistema fs-14"
                        aria-expanded="false"
                      >
                        Factura certificado <br />{" "}
                        <i className="fas fa-file-download fa-2x"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div className="movil-tablas-garantias-mis-cert">
                  <button
                    className="btn btn-primary  sistema fs-14"
                    onClick={() =>
                      handlerGridButtonReissueOnClick(element.nroCertificado)
                    }
                  >
                    <i
                      className="fas fa-file-export fa-2x icono test"
                      aria-hidden="true"
                    ></i>
                    Reemitir
                  </button>
                  <button
                    type="button"
                    className= {element.tieneReemision ? "btn btn-primary sistema fs-14" : "btn btn-primary disabled sistema fs-14"}
                    aria-expanded="false"
                    onClick={() => handlerButtonDonwnloadReInvoice(element.id, element)}
                  >
                    Factura reemisión <br />{" "}
                    <i className="fas fa-2x fa-file-download "></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        />
      )}
    </div>
  );
}

export default VistaMovil;
