import React from "react";
import { useHistory } from "react-router-dom";

interface PropsBreadcrumbs {
  paginaPrincipal: string;
  paginaActual: string;
  paginaInicial: string;  
  onclick ?: Function;
}

const Breadcrumbs = (props: PropsBreadcrumbs) => {
  const history = useHistory();
  // let valueAux = paginaPrincipal === "Carrito" ? "" : ">";
  return (
    <>
      <div className="col-12">
        <nav
          aria-label="breadcrumb"
          style={{ "--bs-breadcrumb-divider": props.paginaPrincipal !== "Carrito" ? "'>'" : "null"} as React.CSSProperties}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <span
                className="migas02"
                onClick={() => history.push(props.paginaInicial)}
              >
                {props.paginaPrincipal}
              </span>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span className="migas02">{props.paginaActual}</span>
            </li>
          </ol>
        </nav>
      </div>
    </>
  );
};

export default Breadcrumbs;
