import { Filtros } from "../../../Models/Operation";
import { BaseQueryPaginador } from "../../../Models/Paginador";
import { MAxios } from "../conf";

const ListCurrents = async (Paginador: BaseQueryPaginador<Filtros>) => {
  const endpoint =
    "Operacion/Operation/ListCurrents?page=" +
    Paginador.page +
    "&pageSize=" +
    Paginador.pageSize;

  const formData = new FormData();
  formData.append(
    "RutMandante",
    Paginador.Filtros[0].RutMandante?.toString() as string
  );
  formData.append(
    "NroCertificado",
    Paginador.Filtros[0].NroCertificado?.toString() as string
  );
  formData.append(
    "IdLicitacion",
    Paginador.Filtros[0].IdLicitacion?.toString() as string
  );
  formData.append(
    "Finalidad",
    Paginador.Filtros[0].Finalidad?.toString() as string
  );

  //aseguradora || CFT
  let idOperationTypeDocument = 1;
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString() as string
  );

  const res = await MAxios().post(endpoint, formData);
  return res.data;
};

const ListCurrentPolicy = async (Paginador: BaseQueryPaginador<Filtros>) => {
  const endpoint =
    "Operacion/Operation/ListCurrents?page=" +
    Paginador.page +
    "&pageSize=" +
    Paginador.pageSize;

  const formData = new FormData();
  formData.append(
    "RutMandante",
    Paginador.Filtros[0].RutMandante?.toString() as string
  );
  formData.append(
    "NroCertificado",
    Paginador.Filtros[0].NroCertificado?.toString() as string
  );
  formData.append(
    "IdLicitacion",
    Paginador.Filtros[0].IdLicitacion?.toString() as string
  );
  formData.append(
    "Finalidad",
    Paginador.Filtros[0].Finalidad?.toString() as string
  );

  //aseguradora || CFT
  let idOperationTypeDocument = 2;
  let idAseguradora = 2;
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString() as string
  );
  formData.append("idAseguradora", idAseguradora.toString() as string);

  const res = await MAxios().post(endpoint, formData);
  return res.data;
};

const ListExpired = async (
  idOperationTypeDocument: number,
  paginador: BaseQueryPaginador<Filtros>
) => {
  const endpoint =
    `Operacion/Operation/ListExpired/?page=` +
    paginador.page +
    "&pageSize=" +
    paginador.pageSize;

  const formData = new FormData();
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString()
  );
  formData.append(
    "RutMandante",
    paginador.Filtros[0].RutMandante?.toString() as string
  );
  formData.append(
    "NroCertificado",
    paginador.Filtros[0].NroCertificado?.toString() as string
  );
  formData.append(
    "IdLicitacion",
    paginador.Filtros[0].IdLicitacion?.toString() as string
  );
  formData.append(
    "Finalidad",
    paginador.Filtros[0].Finalidad?.toString() as string
  );

  const res = await MAxios().post(endpoint, formData);
  return res.data;
};

const ListExpiredPolicy = async (
  idOperationTypeDocument: number,
  paginador: BaseQueryPaginador<Filtros>
) => {
  const endpoint =
    `Operacion/Operation/ListExpired/?page=` +
    paginador.page +
    "&pageSize=" +
    paginador.pageSize;

  const formData = new FormData();
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString()
  );
  formData.append(
    "RutMandante",
    paginador.Filtros[0].RutMandante?.toString() as string
  );
  formData.append(
    "NroCertificado",
    paginador.Filtros[0].NroCertificado?.toString() as string
  );
  formData.append(
    "IdLicitacion",
    paginador.Filtros[0].IdLicitacion?.toString() as string
  );
  formData.append(
    "Finalidad",
    paginador.Filtros[0].Finalidad?.toString() as string
  );

  const res = await MAxios().post(endpoint, formData);
  return res.data;
};

const ListPendingPolicy = async (
  idOperationTypeDocument: number,
  paginador: BaseQueryPaginador<Filtros>
) => {
  const endpoint =
    `Operacion/Operation/ListPending/?page=` +
    paginador.page +
    "&pageSize=" +
    paginador.pageSize;

  const formData = new FormData();
  idOperationTypeDocument = 2;
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString()
  );
  formData.append(
    "RutMandante",
    paginador.Filtros[0].RutMandante?.toString() as string
  );
  formData.append(
    "NroCertificado",
    paginador.Filtros[0].NroCertificado?.toString() as string
  );
  formData.append(
    "IdLicitacion",
    paginador.Filtros[0].IdLicitacion?.toString() as string
  );
  formData.append(
    "Finalidad",
    paginador.Filtros[0].Finalidad?.toString() as string
  );

  const res = await MAxios().post(endpoint, formData);
  return res.data;
};

const ListAccident = async (Paginador: BaseQueryPaginador<Filtros>) => {
  const endpoint =
    "Operacion/Operation/ListAccident?page=" +
    Paginador.page +
    "&pageSize=" +
    Paginador.pageSize;
  const formData = new FormData();
  formData.append(
    "RutMandante",
    Paginador.Filtros[0].RutMandante?.toString() as string
  );
  formData.append(
    "NroCertificado",
    Paginador.Filtros[0].NroCertificado?.toString() as string
  );
  formData.append(
    "IdLicitacion",
    Paginador.Filtros[0].IdLicitacion?.toString() as string
  );
  formData.append(
    "Finalidad",
    Paginador.Filtros[0].Finalidad?.toString() as string
  );

  //aseguradora || CFT
  let idOperationTypeDocument = 1;
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString() as string
  );

  const res = await MAxios().post(endpoint, formData);
  return res.data;
};

const ListCanceled = async (Paginador: BaseQueryPaginador<Filtros>) => {
  const endpoint =
    "Operacion/Operation/ListCanceled?page=" +
    Paginador.page +
    "&pageSize=" +
    Paginador.pageSize;
  const formData = new FormData();
  formData.append(
    "RutMandante",
    Paginador.Filtros[0].RutMandante?.toString() as string
  );
  formData.append(
    "NroCertificado",
    Paginador.Filtros[0].NroCertificado?.toString() as string
  );
  formData.append(
    "IdLicitacion",
    Paginador.Filtros[0].IdLicitacion?.toString() as string
  );
  formData.append(
    "Finalidad",
    Paginador.Filtros[0].Finalidad?.toString() as string
  );

  //aseguradora || CFT
  let idOperationTypeDocument = 1;
  formData.append(
    "idOperationTypeDocument",
    idOperationTypeDocument.toString() as string
  );

  const res = await MAxios().post(endpoint, formData);
  return res.data;
};

const ListToUpdate = async () => {
  const endpoint = "/Operacion/Operation/ListQuotes";

  const res = await MAxios().post(endpoint);

  return res.data;
};

const ApiCertificates = {
  ListCurrents,
  ListExpired,
  ListAccident,
  ListCanceled,
  ListToUpdate,
  ListCurrentPolicy,
  ListExpiredPolicy,
  ListPendingPolicy,
};

export default ApiCertificates;
