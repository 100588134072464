import React, {
  useEffect,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import GenericBaseModel from '@api/GenericBaseModel';
import ApiProviderCampaign from '@api/providerCampaign/providerCampaign';
import checkVerde from '@img/check-icon-verde.svg';
import {
  MAlert,
  MButton,
  MTitle,
} from '@inversiones-ma/finfast-react-controls';
import {
  loadCart,
  loadProviderCampaign,
} from '@redux/actions/SessionActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import {
  Cart,
  ProviderCampaign,
} from '@redux/models/SessionModels';

import ApiOperation
  from '../../../../../scripts/api/garantias/operation/Operation';
import CrossSellingFactoring from '../partial/CrossSellingFactoring';
import { BuyCartHook } from '../partial/Hook/BuyCartHook';

const CartStep3 = () => {
  const today = new Date();
  let day = today.getDate();

  const dispatch = useAppDispatch();
  const { email } = useAppSelector((store) => store.account);
  const { cart } = useAppSelector((store) => store.session);
  const history = useHistory();
  const [cantidadComprada, setCantidadComprada] = useState<number | undefined>(0);
  const [isPolicy, setIsPolicy] = useState<boolean>(false);

  const { resetStepToPay } = BuyCartHook();


  useEffect(() => {
    const hasPolicy = cart!.items!.every(item => item.idAseguradora === 2);
    if (hasPolicy) {
      setIsPolicy(true);
    }
  }, []);


  const loadCartHome = async () => {
    const resp = await ApiOperation.ValidateOperationsInCart();
    const cart = {
      items: resp.dataList,
      discountCode: undefined,
    } as Cart | undefined;
    dispatch(loadCart(cart));
    return cart?.items.length
  };

  useEffect(() => {
    loadCartHome().then((result) => {
      let inCart: number = result ?? 0;

      setCantidadComprada(cart?.items?.length || 0);

      if (inCart === 0) {
        let newCart = { items: [], discountCode: undefined };
        dispatch(loadCart(newCart));
      } else {
        resetStepToPay()
      }


    })


    //resetaer state proveedor campania
    ApiProviderCampaign.GetProviderCampaign()
      .then((resp: GenericBaseModel<ProviderCampaign>) => {
        if (resp.success) {
          dispatch(loadProviderCampaign(resp.data));
        }
      })
      .catch((error) => console.error("error GetProviderCampaign: ", error))
      .finally(() => { });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerReturn = () => {
    resetStepToPay()
    history.push("/home");
  };

  const handlerMyCertificatesOnClick = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    history.push("/my-certificates");
  };

  return (
    <>
      <div className="row p-2">
        <div className="rounded-card-bg p-3 mb-5 mx-3">
          <div className="col-12 text-center">
            <img
              src={checkVerde}
              alt="chek-pago"
              className="check-pago-completado"
            />
            <MTitle hr position="center">
              GRACIAS POR TU COMPRA
            </MTitle>
            <div className="col-12 text-center">

              <p className="centraP fs-16">Tu pago ha sido realizado con éxito</p>
              <h4 className=" text-center ">
              </h4>
              {isPolicy ?
                (<>
                  <h4 className="text-center text-color">
                    En un plazo máximo de 24h recibirás tus garantías al correo:
                  </h4>
                </>) : <>

                  <h4 className="text-center text-color">
                    En los próximos <strong>15 minutos</strong> recibirás tu Garantía al correo:
                  </h4>
                </>
              }
              <div className="mail-confirmacion-pago mt-3">
                <p>{email}</p>
              </div>

              {isPolicy &&

                <div className=" col-12 offset-lg-2 col-lg-8 my-4 text-center">
                  <p>
                    La facturación de tu <b>Póliza de Garantías</b> será
                    emitida y {" "}
                    <br />
                    enviada por la <b>compañía aseguradora</b> .
                  </p>
                </div>
              }
              <div className="col-12 offset-lg-1 col-lg-10 my-4">
                {!isPolicy && (
                  <MAlert color="info" className="slim info">
                    <div className="text-start">
                      <p>
                        Recuerda que siempre puedes descargar{" "}
                        {cantidadComprada && cantidadComprada > 1
                          ? "tus certificados o pólizas "
                          : "tu certificado o póliza"}{" "}
                        desde{" "}
                        <a href="#0" onClick={handlerMyCertificatesOnClick}>
                          <b>Mis Garantías</b>
                        </a>
                        . En caso de no{" "}
                        {cantidadComprada && cantidadComprada > 1
                          ? "recibirlos "
                          : "recibirlo "}
                        en el tiempo indicado, contáctate con tu ejecutiva.
                      </p>
                    </div>
                  </MAlert>
                )}
              </div>
              <CrossSellingFactoring />

              <MButton className="" color="primary" onClick={handlerReturn}>
                Volver al inicio
              </MButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartStep3;
