import { TributaryInformation } from "@redux/models/TributaryInformationModels";
import { MAxios, MAxiosBlob } from "../conf";

const saveAccountSII = async (data: TributaryInformation) => {
    const endpoint = "TributaryFolder/TributaryFolder/SaveAccountSIIUnique";
    return await MAxios().post(endpoint, data).then(res => res.data);
}
const DonwloadManualFactoring = async () => {
    let name = "Manual Factoring Finfast.pdf";
    const endpoint = "Factoring/NewClient/NewClient/DonwloadManualFactoring";
  
    const res = await MAxiosBlob().post(endpoint);
  
    if (res.status === 200) {
        if ((res as any).size === 0 || (res as any).type === "text/plain" || (res as any).type === "") {
        return false;
      } else {
        let blob = new Blob([res?.data]);
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        return true;
      }
    }
    else{
      return false;
    }
  }
const ApiTributaryFolder = {
    saveAccountSII,
    DonwloadManualFactoring,
}

export default ApiTributaryFolder;