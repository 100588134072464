import React from 'react';

import { OperacionDTO } from 'Models/Operation';

import { useAppSelector } from '@redux/hooks';

import BuyCart from '../partial/BuyCart';
import CostZeroPayment from '../partial/CostZeroPayment';
import SummaryPay from '../partial/payment/SummaryPay';

const CartStep2 = () => {
  const { idBillingType } = useAppSelector((store) => store.account);
  const { cart, providerCampaign, selectedIssuer } = useAppSelector((store) => store.session);
  const montoComision: number | undefined = cart?.items?.reduce((sum: number, value: OperacionDTO) => typeof value.comision == "number" ? sum + value.comision : sum, 0);
  const isCampaign: boolean = cart?.items.length === 1 ? (montoComision === 0 && cart?.items[0].idCampania !== null && !!providerCampaign) : false

  return (
    <>
      <BuyCart />

      <>
        {(idBillingType === 2 || isCampaign) && selectedIssuer !== "sura" ? (
          <CostZeroPayment />
        ) : (
          <SummaryPay />
        )}
      </>

    </>
  );
};

export default CartStep2;