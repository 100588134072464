import './MyCertificates.scss';

import React from 'react';

import {
  myCertificateTabs,
  myCertificateTabsPolicy,
} from '@app/arrayData';
import { enumOperationTypeDocument } from '@app/enumerator';
import { MLoadingLight } from '@inversiones-ma/finfast-react-controls';
import { useAppSelector } from '@redux/hooks';

import { UserGuide } from '../Home/hooks/UserGuide';
import Breadcrumbs from '../NewCertificate/partial/Breadcrumbs';
import MyCertificateContainer from './Partial/MyCertificateContainer';

function MyCertificates() {
  const { linesInsurance } = useAppSelector((store) => store.session);
  const { handleDownloadUserGuide, DownloadDocument } = UserGuide();
  return (
    <>
      <main className="bg-home" id="my-certificates">
        <div className="container">
          <div className="row">
            <Breadcrumbs
              paginaPrincipal="Mis Garantías"
              paginaActual=""
              paginaInicial=""
            />
            <div className="col-12">
              <h2 className="titles-left">Mis Garantías</h2>
              <p>
                Aquí podrás visualizar tus certificados y pólizas adquiridas. ¿Dudas sobre cómo reemitir tu Garantía? &nbsp;
                <span className="text-link text-decoration-underline" onClick={() => handleDownloadUserGuide("REEMISION")}>Descarga nuestro manual aquí.</span>
                <MLoadingLight style={{ marginLeft: "10px" }} loading={DownloadDocument} />
              </p>
            </div>
          </div>
          <MyCertificateContainer
            title={"Mis Certificados"}
            idOperationTypeDocument={enumOperationTypeDocument.Operation}
            tabsDefinition={myCertificateTabs}
          />

          {
            linesInsurance &&
            linesInsurance.length > 0 &&
            <MyCertificateContainer
              title={"Mis Pólizas"}
              idOperationTypeDocument={enumOperationTypeDocument.Policy}
              tabsDefinition={myCertificateTabsPolicy}
            />
          }

        </div>
      </main>
    </>
  );
}

export default MyCertificates;
