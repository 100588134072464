import React, { useEffect, useState } from "react";
import ApiCertificates from "@api/certificados/Certificados";
import ApiDownloadCertificates from "@api/certificados/DownloadCertificados";
import { MLoadingLight } from "@inversiones-ma/finfast-react-controls";
import MLoading from "../../../../../controls/MLoading";
import MessageNotData from "../MessageNotData";
import SuccessMessage from "../../../NewCertificate/partial/VerifyData/SuccessMessage";
import {
  BaseQueryPaginador,
  BaseResponsePaginador,
  Paginador,
} from "../../../../../../Models/Paginador";
import { useForm } from "react-hook-form";
import VistaMovil from "./VistaMovil";
import VistaEscritorio from "./VistaEscritorio";
import { CertificatesFilters } from "../../CertificatesFilters";
import MessageCleanHistory from "../MessageCleanHistory";
import { Filtros, OperacionDTO } from "Models/Operation";

interface Props {
  idOperationTypeDocument: number;
}

export type Inputs = {
  nroCertificado: string;
  rutMandante: string;
  idLicitacion: string;
  finalidad: string;
};

function AccidentCertificates(props: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [downloadingExcel, setDownloadingExcel] = useState(false);
  let [DownloadDocument, setDownloadDocument] = useState(false);
  let [DownloadDocumentID, setDownloadDocumentID] = useState("");
  let [DownloadInvoice, setDownloadInvoice] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [paginadorData, setPaginadorData] = useState<Paginador<OperacionDTO>>();
  const [responseStatus, setResponseStatus] = useState<string>();
  const [onChangeNumberPage, setOnChangeNumberPage] = useState<number>(1);
  const [onChangeNumberRecords, setOnChangeNumberRecords] =
    useState<number>(10);

  const [filters, setFilters] = useState<Filtros>({
    RutMandante: null,
    NroCertificado: null,
    IdLicitacion: null,
    Finalidad: null,
  });

  const form = useForm<Filtros>();

  function callLoadAccidentCertificates(
    Paginador: BaseQueryPaginador<Filtros>
  ) {
    setIsLoadingData(true);
    ApiCertificates.ListAccident(Paginador)
      .then((response: BaseResponsePaginador<OperacionDTO>) => {
        if (response) {         
          setPaginadorData(response.paginador);
          setResponseStatus(response.status);
        }
      })
      .catch((error) => {
        console.error("error api ListAccident: ", error);
      })
      .finally(() => {
        setIsLoadingData(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (isNaN(onChangeNumberRecords)) return;
    if (isNaN(onChangeNumberPage)) return;

    let QueryPaginador: BaseQueryPaginador<Filtros> = {
      page: onChangeNumberPage,
      pageSize: onChangeNumberRecords,
      Filtros: [{ ...filters }],
    };
    callLoadAccidentCertificates(QueryPaginador);

  }, [filters, onChangeNumberPage, onChangeNumberRecords]);

  function HandlerSubmit(data: Filtros) {
    setLoading(true);
    setFilters({ ...data });
    let QueryPaginador: BaseQueryPaginador<Filtros> = {
      page: 1,
      pageSize: 10,
      Filtros: [{ ...data }],
    };
    callLoadAccidentCertificates(QueryPaginador);
  }

  async function handlerButtonDonwnloadCft(fieldKey: any) {
    setDownloadDocument(true);
    setDownloadDocumentID(fieldKey);
    try {
      const response =
        await ApiDownloadCertificates.CallOperationDownloadCertificate(
          fieldKey
        );
      const blob = new Blob([response?.data]);
      if (blob.type === "text/plain") {
        return;
      }
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "certificado_de_fianza_" + fieldKey + ".pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      setDownloadDocument(false);
      setDownloadDocumentID("");
    } catch (error) {
      console.error("Error al descargar el certificado:", error);
      setDownloadDocument(false);
      setDownloadDocumentID("");
    }
  }

  async function handlerButtonDonwnloadInvoice(fieldKey: any) {
    if (!DownloadInvoice) {
      setDownloadInvoice((DownloadInvoice = true));
      setDownloadDocumentID((DownloadDocumentID = fieldKey));
      ApiDownloadCertificates.callInvoiceDownload(fieldKey)
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "factura_" + fieldKey + ".pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadInvoice((DownloadInvoice = false));
          setDownloadDocumentID((DownloadDocumentID = ""));
        });
    }
  }

  function handlerButtonDownloadExcelAccident() {
    if (!downloadingExcel) {
      setDownloadingExcel(true);
      ApiDownloadCertificates.callOperacionOperationDownloadListAccident()
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "certificados_siniestrados.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadingExcel(false);
        });
    }
  }
  return (
    <>
      {paginadorData && paginadorData?.records.length !== 0 && (
        <div className="row">
          <div className="boton-excel">
            <button
              type="button"
              className="col-12 col-lg-3 offset-lg-9  text-center  btn btn-primary outline sistema"
              onClick={handlerButtonDownloadExcelAccident}
            >
              Descargar tabla en Excel
              {downloadingExcel && (
                <MLoadingLight style={{ marginLeft: "10px" }} />
              )}
              {!downloadingExcel && (
                <i
                  className="fas fa-file-excel"
                  style={{ marginLeft: "10px" }}
                ></i>
              )}
            </button>
          </div>
        </div>
      )}
      <div className="row">
        <div>
          {paginadorData ? (
            <>
              {paginadorData.records.length === 0 ? (
                responseStatus === "204" ? (
                  <CertificatesFilters
                    form={form as any}
                    onSubmit={HandlerSubmit}
                    loading={loading}
                  />
                ) : (
                  ""
                )
              ) : (
                <CertificatesFilters
                  form={form as any}
                  onSubmit={HandlerSubmit}
                  loading={loading}
                />
              )}

              {paginadorData && (
                <>
                  <div className="col-12 d-none d-md-block d-lg-block  pb-4">
                    <VistaEscritorio
                      setOnChangeNumberPage={setOnChangeNumberPage}
                      setOnChangeNumberRecords={setOnChangeNumberRecords}
                      handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                      handlerButtonDonwnloadInvoice={
                        handlerButtonDonwnloadInvoice
                      }
                      DownloadDocument={DownloadDocument}
                      DownloadDocumentID={DownloadDocumentID}
                      DownloadInvoice={DownloadInvoice}
                      accidentCertificates={paginadorData as any}
                      loading={isLoadingData}
                    />
                  </div>
                  <div className="movil d-block d-md-none  pb-4">
                    <VistaMovil
                      paginadorData={paginadorData as any}
                      setOnChangeNumberPage={setOnChangeNumberPage}
                      setOnChangeNumberRecords={setOnChangeNumberRecords}
                      handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                      handlerButtonDonwnloadInvoice={
                        handlerButtonDonwnloadInvoice
                      }
                      DownloadDocument={DownloadDocument}
                      DownloadDocumentID={DownloadDocumentID}
                    />
                  </div>
                </>
              )}
              {responseStatus === "Succes" &&
                paginadorData.records.length === 0 && (
                  <MessageCleanHistory
                    title="¡Tu historial esta limpio!"
                    description="Actualmente no tienes ningún certificado Siniestrado."
                    buttonBuy={false}
                  />
                )}
              {responseStatus === "204" && (
                <MessageNotData
                  title="¡Ups! No hemos encontrado nada"
                  description=""
                  buttonBuy={false}
                />
              )}
            </>
          ) : (
            <div className="col-12">
              <MLoading modal={false} />
            </div>
          )}
        </div>
      </div>
      {/* <SuccessMessage
        showModal={showSuccessMessage}
        setShowModal={setShowSuccessMessage}
        close={() => setShowSuccessMessage(false)}
      /> */}
    </>
  );
}

export default AccidentCertificates;
