// import { types } from "../types/types";
import { createAction } from '@reduxjs/toolkit';

import { Configuracion, PaymentMethods, Region, SurveyNps, TypeTender } from '../models/GlobalModels';


export const loadRegions = createAction('GLOBAL_LOAD_REGIONS', (regions: Region[]) => {
    return {
        payload: regions
    }
});

export const setCurrentDate = createAction('GLOBAL_SET_CURRENT_DATE', (currentDate: string) => {
    return {
        payload: currentDate
    }
});

export const loadPaymentMethods = createAction('GLOBAL_LOAD_PAYMENT_METHODS', (paymentMethods: PaymentMethods[]) => {
    return {
        payload: paymentMethods
    }
});
export const loadTypeTender = createAction('GLOBAL_LOAD_TYPE_TENDER', (typeTender: TypeTender[]) => {
    return {
        payload: typeTender
    }
});

export const loadDataSurvey = createAction('GLOBAL_LOAD_SURVEY', (dataSurvey: SurveyNps | undefined) => {
    return {
        payload: dataSurvey
    }
});

export const loadConfiguration = createAction('GLOBAL_LOAD_CONFIGURATION', (dataConfiguration: Configuracion[] | undefined) => {
    return {
        payload: dataConfiguration
    }
});



export const cleanState = createAction('GLOBAL_CLEAN_STATE');