import { Filter } from '@redux/models/FilterModels';
import { createAction } from '@reduxjs/toolkit';

export const loadFilters = createAction('SESSION_FILTERS', (filtersData: Filter[]) => {
    return {        
        payload: filtersData
    }
});

export const setSelectedDataFilter = createAction('SESSION_FILTER_SET_SELECTED_DATA', (selectedDataFilter?: Filter) => {
	return {
		payload:selectedDataFilter
	}
});
