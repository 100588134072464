import { convertToPeso } from "@helpers/helpers";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import React from "react";

interface PropsReissueCardData {
  comision: number | null | undefined;
}

const ReissueCardData = ({ comision }: PropsReissueCardData) => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 sm-5">
          <MCards color="primary">
            <div className="floating-label">
              <div className="input-group">
                <div className="col-12">
                  <p className="textolabel04 text-center mt-2">
                    <span className="textolabel03">
                      <b>Total Reemisión a pagar</b>
                    </span>
                    <br />
                    {convertToPeso(comision)}
                  </p>
                </div>
              </div>
            </div>
          </MCards>
        </div>
      </div>
    </>
  );
};

export default ReissueCardData;
