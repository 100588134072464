export const myCertificateTabs = [
    { id: "VIGENTES", descripcion: "Vigentes" },
    { id: "CADUCOS", descripcion: "Caduco" },
    { id: "SINIESTRADOS", descripcion: "Siniestrados" },
    { id: "ANULADOS", descripcion: "Anulados" },
]

export const myPurposeTabs = [
    { id: "SERIEDAD DE OFERTA", descripcion: "Seriedad de Oferta" },
    { id: "FIEL CUMPLIMIENTO", descripcion: "Fiel Cumplimiento" },
    { id: "CORRECTA EJECUCION", descripcion: "Correcta Ejecución" },
    { id: "ANTICIPO", descripcion: "Anticipo" },
    { id: "FIEL CUMPLIMIENTO Y CORRECTA EJECUCION", descripcion: "Fiel Cumplimiento y Correcta Ejecución" },
]

export const myCertificateTabsPolicy = [
    { id: "PENDIENTES", descripcion: "Pendientes" },
    { id: "VIGENTES", descripcion: "Vigentes" },
    { id: "CADUCOS", descripcion: "Caduco" },
]

