import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import * as SessionActions from "@redux/actions/SessionActions";
import * as GlobalActions from "@redux/actions/GlobalActions";
import * as AccountActions from "@redux/actions/AccountActions";
import MLoading from "../../controls/MLoading";
import { finfast } from "@config/environment";
import ApiOperation from "@api/garantias/operation/Operation";
import ReactGA from "react-ga4";
import ApiMembresiaAccount from "@api/membresia/Account";

function Logout() {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((store) => store.account);

  useEffect(() => {
    ReactGA.event({
      action: "El usuario cerro la sesion",
      category: "Garantias logout",
      label: "",    
    });
    ReactGA.event({
      category: "Garantias",
      action: "El usuario cerro la sesion",
    });
    
    ApiOperation.AddToNotificacion();
    ApiMembresiaAccount.KillToken(token!);
    
    dispatch(SessionActions.cleanState());
    dispatch(GlobalActions.cleanState());
    dispatch(AccountActions.cleanState());
    sessionStorage.clear();
    localStorage.clear()

    window.location.href = finfast() + "/logout";
    // eslint-disable-next-line
  }, []);

  return (
    <div className="anim-loading">
      <div className="lottie-animacion">
        <MLoading />
      </div>
    </div>
  );
}

export default Logout;
