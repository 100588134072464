import React, { useEffect, useState } from "react";
import ApiDownloadCertificates, {
  callOperacionOperationDownloadListExpired,
} from "@api/certificados/DownloadCertificados";
import { MLoadingLight } from "@inversiones-ma/finfast-react-controls";
import ApiCertificates from "@api/certificados/Certificados";
import "../../MyCertificates.scss";
import MessageNotData from "../MessageNotData";
import {
  BaseQueryPaginador,
  BaseResponsePaginador,
  Paginador,
} from "../../../../../../Models/Paginador";
import { Filtros, OperacionDTO } from "../../../../../../Models/Operation";
import VistaEscritorio from "./VistaEscritorio";
import { CertificatesFilters } from "../../CertificatesFilters";
import { useForm } from "react-hook-form";
import VistaMovil from "./VistaMovil";
import MLoading from "../../../../../controls/MLoading";
import MessageCleanHistory from "../MessageCleanHistory";

interface Props {
  idOperationTypeDocument: number;
}
function ExpiredCertificates(props: Props) {
  const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  let [DownloadDocument, setDownloadDocument] = useState<boolean>(false);
  let [DownloadDocumentID, setDownloadDocumentID] = useState<string>("");
  const [DownloadInvoice, setDownloadInvoice] = useState<boolean>(false);
  const [paginadorData, setPaginadorData] = useState<Paginador<OperacionDTO>>();
  const [responseStatus, setResponseStatus] = useState<string>();
  const [onChangeNumberPage, setOnChangeNumberPage] = useState<number>(1);
  const [onChangeNumberRecords, setOnChangeNumberRecords] =
    useState<number>(10);
  const [filters, setFilters] = useState<Filtros>({
    NroCertificado: "",
    IdLicitacion: "",
    Finalidad: "",
    RutMandante: "",
  });
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

  const form = useForm<Filtros>();

  useEffect(() => {
    setShowModalEdit(true);
  }, []);

  function HandlerSubmit(data: Filtros) {
    setLoading(true);
    setFilters({ ...data });
    let QueryPaginador: BaseQueryPaginador<Filtros> = {
      page: 1,
      pageSize: 10,
      Filtros: [{ ...data }],
    };
    callLoadExpiredCertificates(QueryPaginador);
  }

  useEffect(() => {
    let isNumberRecordsChanged = false;
    let previousNumberRecords: number | undefined;

    if (isNaN(onChangeNumberRecords)) return;
    if (isNaN(onChangeNumberPage)) return;

    if (!isNaN(onChangeNumberRecords)) {
      if (previousNumberRecords !== onChangeNumberRecords) {
        isNumberRecordsChanged = true;
      }
    }

    if (isNumberRecordsChanged) {
      let QueryPaginador: BaseQueryPaginador<Filtros> = {
        page: onChangeNumberPage,
        pageSize: onChangeNumberRecords,
        Filtros: [filters],
      };

      callLoadExpiredCertificates(QueryPaginador);
    }

  }, [onChangeNumberPage, onChangeNumberRecords, filters]);

  function callLoadExpiredCertificates(Paginador: BaseQueryPaginador<Filtros>) {
    setIsLoadingData(true);
    setLoading(true);
    ApiCertificates.ListExpired(1, Paginador)
      .then((response: BaseResponsePaginador<OperacionDTO>) => {
        if (response.success) {
          setPaginadorData(response.paginador as any);
          setResponseStatus(response.status);
        }
      })
      .catch(() => {
        console.error("error carga api");
      })
      .finally(() => {
        setIsLoadingData(false);
        setLoading(false);
      });
  }

  function handlerButtonDownloadExcelCurrentsExpired() {
    if (!downloadingExcel) {
      setDownloadingExcel(true);
      callOperacionOperationDownloadListExpired(props.idOperationTypeDocument)
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "certificados_caducados.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadingExcel(false);
        });
    }
  }

  async function handlerButtonDonwnloadInvoice(fieldKey: any) {
    if (!DownloadInvoice) {
      setDownloadInvoice(true);
      setDownloadDocumentID((DownloadDocumentID = fieldKey));

      ApiDownloadCertificates.callInvoiceDownload(fieldKey)
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "factura_" + fieldKey + ".pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadInvoice(false);
          setDownloadDocumentID((DownloadDocumentID = ""));
        });
    }
  }

  async function handlerButtonDonwnloadCft(fieldKey: any) {
    if (!DownloadDocument) {
      setDownloadDocument((DownloadDocument = true));
      setDownloadDocumentID((DownloadDocumentID = fieldKey));
      ApiDownloadCertificates.CallOperationDownloadCertificate(fieldKey)
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "certificado_de_fianza_" + fieldKey + ".pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadDocument((DownloadDocument = !DownloadDocument));
          setDownloadDocumentID((DownloadDocumentID = ""));
        });
    }
  }

  return (
    <div>
      {paginadorData && (
        <div className="row">
          <div className="boton-excel">
            <button
              type="button"
              className="col-12 col-lg-3 offset-lg-9  text-center  btn btn-primary outline sistema"
              onClick={handlerButtonDownloadExcelCurrentsExpired}
            >
              Descargar tabla en Excel
              {downloadingExcel && (
                <MLoadingLight style={{ marginLeft: "10px" }} />
              )}
              {!downloadingExcel && (
                <i
                  className="fas fa-file-excel"
                  style={{ marginLeft: "10px" }}
                ></i>
              )}{" "}
            </button>
          </div>
        </div>
      )}
      <div className="row">
        <div>
          {paginadorData ? (
            <>
              {paginadorData.records.length === 0 ? (
                responseStatus === "204" ? (
                  <CertificatesFilters
                    form={form as any}
                    onSubmit={HandlerSubmit}
                    loading={loading}
                  />
                ) : (
                  ""
                )
              ) : (
                <CertificatesFilters
                  form={form as any}
                  onSubmit={HandlerSubmit}
                  loading={loading}
                />
              )}

              {paginadorData && (
                <>
                  <div className="col-12 d-none d-md-block d-lg-block  pb-4">
                    <VistaEscritorio
                      expiredCertificates={paginadorData as any}
                      setOnChangeNumberPage={setOnChangeNumberPage}
                      setOnChangeNumberRecords={setOnChangeNumberRecords}
                      handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                      handlerButtonDonwnloadInvoice={
                        handlerButtonDonwnloadInvoice
                      }
                      DownloadDocument={DownloadDocument}
                      DownloadDocumentID={DownloadDocumentID}
                      DownloadInvoice={DownloadInvoice}
                      loading={isLoadingData}
                    />
                  </div>
                  <div className="movil d-block d-md-none  pb-4">
                    <VistaMovil
                      paginadorData={paginadorData as any}
                      setOnChangeNumberPage={setOnChangeNumberPage}
                      setOnChangeNumberRecords={setOnChangeNumberRecords}
                      handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                      handlerButtonDonwnloadInvoice={
                        handlerButtonDonwnloadInvoice
                      }
                      DownloadDocument={DownloadDocument}
                      DownloadDocumentID={DownloadDocumentID}
                    />
                  </div>
                </>
              )}
              {responseStatus === "Succes" &&
                paginadorData.records.length === 0 && (
                  <MessageCleanHistory
                    title="¡Tu historial esta limpio!"
                    description="Actualmente no tienes ningún certificado Caducado."
                    buttonBuy={false}
                  />
                )}
              {responseStatus === "204" && (
                <MessageNotData
                  title="¡Ups! No hemos encontrado nada"
                  description=""
                  buttonBuy={false}
                />
              )}
            </>
          ) : (
            <>
              {" "}
              <div className="col-12">
                <MLoading modal={false} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExpiredCertificates;
