import React, { useEffect } from "react";
import ApiReissue from "@api/reissue/Reissue";
import dayjs from "dayjs";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { ValidateOperations } from "Models/Operation";
import GenericBaseModel from "@api/GenericBaseModel";

interface PropsCommissionResult {
  fechaInicio: string;
  fechaTermino: string;
  finalidad: string;
  montoCertificado: number;
  tipoMoneda: string;
  nroCertificado: string;
  nombreOrganismoMandante: string;
  regionUnidadMandante: string;
  comunaUnidadMandante: string;
  direccionUnidadMandante: string;
  form: UseFormReturn<FieldValues, object>;
  resultComission: ValidateOperations | undefined;
  setResultComission: React.Dispatch<
    React.SetStateAction<ValidateOperations | undefined>
  >;
  setBlockButton: React.Dispatch<React.SetStateAction<boolean>>;
  preDataTender: any;
  type: string;
  setInRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setShowComissionResult: React.Dispatch<React.SetStateAction<boolean>>;
}

function CommissionReissue(props: PropsCommissionResult) {
  const { formState, trigger } = props.form;

  let callApiControl = setTimeout(() => false, 3000);

  const validacionYObtenerComision = async () => {
    let hasErr = false;
    let sameData = false;
    const { errors, dirtyFields: df } = formState;

    if (df.fechaInicio) hasErr = hasErr || !(await trigger("fechaInicio"));
    if (df.fechaTermino) hasErr = hasErr || !(await trigger("fechaTermino"));
    if (df.finalidad) hasErr = hasErr || !(await trigger("finalidad"));
    if (df.montoCertificado)
      hasErr = hasErr || !(await trigger("montoCertificado"));
    if (df.tipoMoneda) hasErr = hasErr || !(await trigger("tipoMoneda"));

    if (props.type === "editar") {
      if (
        props.montoCertificado.toString() ===
          props.preDataTender.montoCertificado.toString() &&
        props.tipoMoneda === props.preDataTender.tipoMoneda
      ) {
        props.setBlockButton(false);
        sameData = true;
      }
    }
    if (
      !!props.fechaInicio &&
      !!props.fechaTermino &&
      !!props.finalidad &&
      !!props.montoCertificado &&
      !!props.tipoMoneda &&
      !hasErr &&
      !sameData
    ) {
      props.setInRequest(true);
      props.setBlockButton(true);

      callApiControl = setTimeout(() => {
        ApiReissue.GetCommission(
          props.nroCertificado,
          props.finalidad,
          props.tipoMoneda,
          props.montoCertificado.toString(),
          dayjs(props.fechaInicio).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          dayjs(props.fechaTermino).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          props.nombreOrganismoMandante,
          props.regionUnidadMandante,
          props.comunaUnidadMandante,
          props.direccionUnidadMandante
        )
          .then((resCommission: GenericBaseModel<ValidateOperations>) => {
            if (resCommission.success) {
              props.setResultComission(resCommission.data);
              props.setBlockButton(false);
              props.setShowComissionResult(true);
            } else {
              props.setResultComission(undefined);
              props.setShowComissionResult(false);
            }
          })
          .catch((error) => {
            props.setResultComission(undefined);
            props.setShowComissionResult(false);
          })
          .finally(() => {
            props.setInRequest(false);
          });
      }, 3000);
    } else {
      props.setShowComissionResult(false);
      props.setInRequest(false);
    }
  };

  useEffect(() => {
    //Se validan que se encuentren todos los datos
    const validacion = async () => {
      await validacionYObtenerComision();
    };
    validacion();

    return () => {
      //Se reinicia el control
      clearTimeout(callApiControl);
    };
    // eslint-disable-next-line
  }, [
    props.fechaInicio,
    props.fechaTermino,
    props.finalidad,
    props.montoCertificado,
    props.tipoMoneda,
  ]);

  return <></>;
}

export default CommissionReissue;
