import { MButton } from "@inversiones-ma/finfast-react-controls";
import { useAppSelector } from "@redux/hooks";
import React from "react";
import HomeLinesConstruction from "./HomeLinesConstruction";
import HomeLinesStandart from "./HomeLinesStandart";
import "./HomeLines.scss";
import { useHistory } from "react-router-dom";

interface PropsHomeLines {
  // props
}

const HomeLines = (props: PropsHomeLines) => {

  const { idClassification } = useAppSelector((store) => store.account);
  const history = useHistory();

  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-12">
          <h3 className="subtitle-section pb-3">
            Estas son tus líneas disponibles
          </h3>
        </div>
      </div>
      <div color="primary" className={`HomeLines`}>
        <div className="row">
          <div className="col-12">
            <section id="home-section">
              {idClassification === 1 && <HomeLinesStandart />}
              {idClassification === 2 && <HomeLinesConstruction />}
            </section>
          </div>
        </div>
      </div>
      <div className="row pt-2">
        <div className="col-12 pt-1">
          <MButton
            outline={true}
            onClick={() => history.push("/my-certificates")}
          >
            Ver mis garantías
            <i className="fas fa-file-contract ms-3" />
          </MButton>
        </div>
      </div>
    </div>
  );
};

export default HomeLines;
