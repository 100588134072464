import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import { setCurrentTender } from "@redux/actions/TenderActions";
import { useForm } from "react-hook-form";
import {
  MAlert,
  MButton,
  MInputGeneric,
} from "@inversiones-ma/finfast-react-controls";
import ApiMandante from "@api/mandante/Mandante";
import ApiLicitacion from "@api/licitacion/Licitacion";
import GenericBaseModel from "@api/GenericBaseModel";
import SectionSelectData from "./SelectData/SectionSelectData";
import MLoading from "../../../../controls/MLoading";
import nuevocertificado from "@img/icons/nuevocertificado-icon.svg";
import Breadcrumbs from "./Breadcrumbs";

function SelectData() {
  const dispatch = useAppDispatch();
  const { currentTender } = useAppSelector((store) => store.tender);
  const arrContentType = {
    Tender: "Tender",
    Empty: "Empty",
  };

  const form = useForm();
  const { handleSubmit } = form;

  const { linesInsurance } = useAppSelector((store) => store.session);
  const [searchType, setSearchType] = useState<NewCertificateModels.SearchType>(
    NewCertificateModels.SearchType.IdLicitacion
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [codigoExterno, setCodigoExterno] = useState<string>("");
  const [showContent, setShowContent] = useState<boolean>(false);
  const [contentType, setContentType] = useState<string>("");
  const [isLoadingTender, setIsLoadingTender] = useState<boolean>(false);
  const [isLoadingNewTender, setIsLoadingNewTender] = useState<boolean>(false);

  // campos del formulario
  const [nombreOrganismoMandante, setNombreOrganismoMandante] =
    useState<string>("");
  const [rutUnidadMandante, setRutUnidadMandante] = useState<string>("");
  const [regionUnidadMandante, setRegionUnidadMandante] = useState<string>("");
  const [comunaUnidadMandante, setComunaUnidadMandante] = useState<string>("");
  const [direccionUnidadMandante, setDireccionUnidadMandante] =
    useState<string>("");

  // mostrar u ocultar campos
  const [showNombreOrganismoMandante, setShowNombreOrganismoMandante] =
    useState<boolean>(true);
  const [showRutUnidadMandante, setShowRutUnidadMandante] =
    useState<boolean>(true);
  const [showRegionUnidadMandante, setShowRegionUnidadMandante] =
    useState<boolean>(true);
  const [showComunaUnidadMandante, setShowComunaUnidadMandante] =
    useState<boolean>(true);
  const [showDireccionUnidadMandante, setShowDireccionUnidadMandante] =
    useState<boolean>(true);

  const makeNewTender = async () => {
    setIsLoadingNewTender(true);
    await ApiLicitacion.createTenderForIssuance()
      .then((result: GenericBaseModel<IApiResponseTender>) => {
        if (result.success) {
          let tender = result.data;
          let tenderCode = tender.codigoExterno;

          let dataToSend = {
            searchType: NewCertificateModels.SearchType.IdLicitacion,
            searchTerm,
            codigoExterno: tenderCode,
            carroDeCompras: true,
            licitacionValida: false,
          } as NewCertificateModels.SelectedData;

          // guardar datos e ir a paso 2
          dispatch(NewCertificateActions.setSelectedData(dataToSend));

          // enviar al siguiente paso
          dispatch(
            NewCertificateActions.setCurrentStep(
              NewCertificateModels.Step.ingresar
            )
          );
        } else {
          console.error(result.message);
        }
      })
      .finally(() => {
        setIsLoadingNewTender(false);
      });
  };

  useEffect(() => {
    if (currentTender) {
      setSearchType(NewCertificateModels.SearchType.IdLicitacion);
      setCodigoExterno(currentTender.codigoExterno);
      let data = {
        searchTerm: currentTender.codigoExterno,
      };
      setSearchTerm(data.searchTerm);
      dispatch(setCurrentTender(undefined));
      onSubmitSearchTerm(data);
    }
    // eslint-disable-next-line
  }, []);

  const processResponseTender = (result: IApiResponseMandante) => {
    if (result.success) {
      if (result.data !== null) {
        let tender = result.data;
        //Se ingresa el código externo obtenido
        setCodigoExterno(result.codigoExterno);

        let tenderFormated = {
          nombreMandante: tender.nombreOrganismo.trim(),
          // nombreOrganismo
          rutMandante: tender.rutUnidad.trim(),
          regionMandante: tender.regionUnidad.trim(),
          comunaMandante: tender.comunaUnidad.trim(),
          direccionMandante: tender.direccionUnidad.trim(),
        };

        setNombreOrganismoMandante(tenderFormated.nombreMandante);
        setRutUnidadMandante(tenderFormated.rutMandante);
        setRegionUnidadMandante(tenderFormated.regionMandante);
        setComunaUnidadMandante(tenderFormated.comunaMandante);
        setDireccionUnidadMandante(tenderFormated.direccionMandante);

        if (tenderFormated.nombreMandante === "")
          setShowNombreOrganismoMandante(false);

        if (tenderFormated.rutMandante === "") setShowRutUnidadMandante(false);

        if (tenderFormated.regionMandante === "")
          setShowRegionUnidadMandante(false);

        if (tenderFormated.comunaMandante === "")
          setShowComunaUnidadMandante(false);

        if (tenderFormated.direccionMandante === "")
          setShowDireccionUnidadMandante(false);

        if (
          tenderFormated.nombreMandante === "" &&
          tenderFormated.rutMandante === "" &&
          tenderFormated.regionMandante === "" &&
          tenderFormated.comunaMandante === "" &&
          tenderFormated.direccionMandante === ""
        ) {
          makeNewTender();
        } else {
          setContentType(arrContentType.Tender);
        }
      } else {
        makeNewTender();
      }
      setShowContent(true);
    } else {
      makeNewTender();
    }
  };

  const onClickIdLicitacion = () => {
    setSearchType(NewCertificateModels.SearchType.IdLicitacion);
  };

  const onClickOrdenDeCompra = () => {
    setSearchType(NewCertificateModels.SearchType.OrdenDeCompra);
  };

  const onSubmitSearchTerm = async (data: any) => {
    setShowContent(false);
    if (isLoadingTender) return;
    const internalSearchTerm = data.searchTerm;
    setSearchTerm(data.searchTerm);
    setIsLoadingTender(true);
    switch (searchType) {
      case NewCertificateModels.SearchType.IdLicitacion:
        await ApiMandante.getMandanteByTender(internalSearchTerm).then(
          (result: IApiResponseMandante) => {
            setIsLoadingTender(false);
            processResponseTender(result);
          }
        );
        break;
      case NewCertificateModels.SearchType.OrdenDeCompra:
        await ApiMandante.getMandanteByPurchaseOrder(internalSearchTerm).then(
          (result: IApiResponseMandante) => {
            setIsLoadingTender(false);
            processResponseTender(result);
          }
        );
        break;
      default:
        console.error("Termino de busqueda inválido");
    }
  };
 
  const handlerButtonContinue = async (data?: any) => {
    if (searchTerm && contentType !== arrContentType.Empty) {
      
      let dataToSend = {
        searchType,
        searchTerm,
        codigoExterno,
        nombreOrganismoMandante: undefined,
        rutUnidadMandante: undefined,
        regionUnidadMandante: undefined,
        comunaUnidadMandante: undefined,
        direccionUnidadMandante: undefined,
        carroDeCompras: true,
      } as NewCertificateModels.SelectedData;

      const {
        checkNombreOrganismoMandante,
        checkRutUnidadMandante,
        checkRegionUnidadMandante,
        checkComunaUnidadMandante,
        checkDireccionUnidadMandante,
      } = data;

      if (checkNombreOrganismoMandante) {
        dataToSend = { ...dataToSend, nombreOrganismoMandante };
      }

      if (checkRutUnidadMandante) {
        dataToSend = { ...dataToSend, rutUnidadMandante };
      }

      if (checkRegionUnidadMandante) {
        dataToSend = { ...dataToSend, regionUnidadMandante };
      }

      if (checkComunaUnidadMandante) {
        dataToSend = { ...dataToSend, comunaUnidadMandante };
      }

      if (checkDireccionUnidadMandante) {
        dataToSend = { ...dataToSend, direccionUnidadMandante };
      }

      dataToSend = { ...dataToSend, licitacionValida: true };
      // guardar datos para el siguiente paso
      dispatch(NewCertificateActions.setSelectedData(dataToSend));

      // enviar al siguiente paso
      dispatch(
        NewCertificateActions.setCurrentStep(NewCertificateModels.Step.ingresar)
      );
    } else {
      setIsLoadingNewTender(true);
      await ApiLicitacion.createTenderForIssuance()
        .then((result: GenericBaseModel<IApiResponseTender>) => {
          if (result.success) {
            let tender = result.data;
            let tenderCode = tender.codigoExterno;

            let dataToSend = {
              searchType: NewCertificateModels.SearchType.IdLicitacion,
              searchTerm,
              codigoExterno: tenderCode,
              carroDeCompras: true,
            } as NewCertificateModels.SelectedData;

            // guardar datos e ir a paso 2
            dispatch(NewCertificateActions.setSelectedData(dataToSend));

            // enviar al siguiente paso
            dispatch(
              NewCertificateActions.setCurrentStep(
                NewCertificateModels.Step.ingresar
              )
            );
          } else {
            console.error(result.message);
          }
        })
        .finally(() => {
          setIsLoadingNewTender(false);
        });
    }
  };

  const handlerLinkWithoutLinkOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(NewCertificateActions.cleanQuote(undefined));
    makeNewTender();
  };

  useEffect(() => {
    setShowContent(false);
    setSearchTerm("");
  }, [searchType]);

  return (
    <>
      <div className="row">
        <Breadcrumbs
          paginaPrincipal="Comprar Garantías"
          paginaActual="Nueva Garantía"
          paginaInicial="/buy-certificate"
        ></Breadcrumbs>
        <div className="col-12">
          <h2 className="titles-left">Nueva garantía</h2>
          <p>Ingresa los datos para emitir tu Garantía</p>
        </div>
      </div>

      <div className="row px-2 py-3">
        <div className="rounded-card-bg p-4">
          <div className="col-12 bg-white sin-certificados-content">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-12 ">
                    <h3 className="subtitle-section">
                      Ingresa los datos para tu Garantía
                    </h3>
                  </div>
                </div>
                {linesInsurance && linesInsurance.length > 0 && (
                  <div className="row">
                    <div className="col-12 ">
                      <MAlert color={"info"} className="px-1 py-1">
                        <p
                          style={
                            { marginBottom: 0 + "px" } as React.CSSProperties
                          }
                        >
                          En caso de que quieras{" "}
                          <strong>emitir una Póliza de Garantía</strong> es
                          necesario que ingreses el{" "}
                          <strong>
                            ID de tu licitación del sector público vigente.
                          </strong>
                        </p>
                      </MAlert>
                    </div>
                  </div>
                )}

                <div className="generictab">
                  <div className="txt-tab02">
                    <p>Ver por:</p>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 col-lg-8 tabs-02">
                      <ul
                        className="nav nav-tabs02 tabs-garantias"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          className="nav-item col-md factura"
                          role="presentation"
                        >
                          <button
                            onClick={onClickIdLicitacion}
                            className="nav-link active"
                            id="tab_1"
                            data-bs-toggle="tab"
                            data-bs-target="#content_1"
                            type="button"
                            role="tab"
                            aria-controls="content_1"
                            aria-selected="true"
                          >
                            Id Licitación
                          </button>
                        </li>
                        <li
                          className="nav-item col-md operacion"
                          role="presentation"
                        >
                          <button
                            onClick={onClickOrdenDeCompra}
                            className="nav-link"
                            id="tab_2"
                            data-bs-toggle="tab"
                            data-bs-target="#content_2"
                            type="button"
                            role="tab"
                            aria-controls="content_2"
                            aria-selected="false"
                          >
                            Orden de compra
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <form onSubmit={handleSubmit(onSubmitSearchTerm)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="floating-label">
                            <MInputGeneric
                              form={form}
                              name="searchTerm"
                              type="text"
                              label={
                                searchType ===
                                  NewCertificateModels.SearchType.IdLicitacion
                                  ? "Ej: 4809-4-LE2"
                                  : "Ej: 1009566-166-AG20"
                              }
                              rules={{ required: "Este campo es requerido" }}
                              maxLength={20}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 pt-3">
                          <MButton
                            className="sistema w-100"
                            outline={true}
                            loading={isLoadingTender}
                            type="submit"
                          >
                            Ingresar
                          </MButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-12 col-md-10 sm-2 py-3">
                  <div className="rounded-card-bg lborder-primary">
                    <div className="floating-label">
                      <div className="input-group">
                        <div className="col-12">
                          <p className="fs-6 m-3">
                            Si no tienes un id licitación o una orden de compra{" "}
                            <span
                              onClick={handlerLinkWithoutLinkOnClick}
                              className="color-secondary fw-bold text-decoration-underline texto-alert-newCertificate"
                            >
                              Ingresa aquí
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <img src={nuevocertificado} alt="Nuevo certificado" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showContent && (
        <>
          {contentType === arrContentType.Tender && (
            <SectionSelectData
              nombreOrganismoMandante={nombreOrganismoMandante}
              rutUnidadMandante={rutUnidadMandante}
              regionUnidadMandante={regionUnidadMandante}
              comunaUnidadMandante={comunaUnidadMandante}
              direccionUnidadMandante={direccionUnidadMandante}
              showNombreOrganismoMandante={showNombreOrganismoMandante}
              showRutUnidadMandante={showRutUnidadMandante}
              showRegionUnidadMandante={showRegionUnidadMandante}
              showComunaUnidadMandante={showComunaUnidadMandante}
              showDireccionUnidadMandante={showDireccionUnidadMandante}
              handlerButtonContinue={handlerButtonContinue}
            ></SectionSelectData>
          )}
          {contentType === arrContentType.Empty && (
            <div className="row mt-2 show" id="select-data">
              <div className="col-12 text-center">
                <hr />
                <h5 className="mt-3">
                  No se registran datos asociados al Id ingresado.
                </h5>
                <p>Para generar tu certificado haz clic en continuar.</p>
              </div>
              <div className="col">
                <div className="row my-3">
                  <div className="col-12  text-center">
                    <button
                      className="btn col-lg-4 Mbutton-blue w-100"
                      type="button"
                      onClick={() => {
                        handlerButtonContinue();
                      }}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {isLoadingNewTender && <MLoading></MLoading>}
    </>
  );
}

export default SelectData;

export interface IApiMandante {
  codigoOrganismo: string;
  nombreOrganismo: string;
  rutUnidad: string;
  codigoUnidad?: any;
  nombreUnidad: string;
  direccionUnidad: string;
  comunaUnidad: string;
  regionUnidad: string;
  ingresadoPorCliente: boolean;
  idSector?: any;
  habilitado: boolean;
  montoMaximo?: any;
  montoDisponible?: any;
}

export interface IApiResponseMandante extends GenericBaseModel<IApiMandante> {
  codigoExterno: string;
  lineAmountAvailable: number;
}

export interface IApiResponseTender {
  codigoExterno: string;
  nombre: string;
  descripcion: string;
  codigoEstado: number | null;
  estado: string;
  mandante: string;
  obras: number | null;
  montoEstimado: number | null;
  sOMonto: string;
  sOTipoMoneda: string;
  fCMonto: string;
  fCTipoMoneda: string;
  adjudicacionNumero: string;
  adjudicacionNumeroOferentes: number | null;
  adjudicacionUrlActa: string;
  cantidadItem: number | null;
  fechaCarga: string;
  fechaActualizacion: string | null;
  actualizada: boolean;
  ingresadoPorCliente: boolean | null;
  fechaEstimadaAdjudicacion: string | null;
  fechaFinal: string | null;
  fechaPublicacion: string | null;
  idTipoLicitacion: number | null;
  fechaCierre: string | null;
  urlDetalleAdquisicion: string;
}
