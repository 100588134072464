import React, { useEffect, useState } from "react";
import { MPopup } from "@inversiones-ma/finfast-react-controls";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import * as SessionActions from "@redux/actions/SessionActions";
import Nps from "../../pages/Private/Nps/Nps";

const GlobalNps = () => {
  const dispatch = useAppDispatch();

  const { survey } = useAppSelector((store) => store.session);
  const { surveyNps } = useAppSelector((store) => store.global);
  const [showNps, setShowNps] = useState<boolean>(false);
  const handlerCloseNps = () => {
    dispatch(SessionActions.loadSurvey(false));
    setShowNps(false);
  };

  useEffect(() => {
    if (survey) {
      if (surveyNps?.showPopupTimeSeg !== undefined) {
        setTimeout(
          () => {
            setShowNps(true);
          },
          surveyNps?.showPopupTimeSeg === undefined
            ? 0
            : surveyNps.showPopupTimeSeg * 1000
        );
      }
    }
  }, [survey, surveyNps?.showPopupTimeSeg]);

  return (
    <MPopup
      showClose={true}
      showModal={showNps}
      setShowModal={handlerCloseNps}
      size="md"
    >
      <Nps ClickHandler={handlerCloseNps} />
    </MPopup>
  );
};

export default GlobalNps;
