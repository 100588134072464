import React from "react";

function HomeBannerNoEmitir() {
  return (
    <section className="alertEmision">
      <div className="alert-danger" role="alert">
        <div className="container d-flex align-items-center">
          <div className="icon">
            <i className="fas fa-exclamation-triangle"></i>
          </div>

          <div>
            <h5>Importante</h5>

            <p style={{ marginBottom: 0 + "px" } as React.CSSProperties}>
              Por el momento no podrás <b>Emitir</b> ni <b>Reemitir</b> garantías hasta que
              regularices tu situación con nuestro <b>Servicio de Garantías.</b><br/>Para
              mayor información puedes contactarte con uno de nuestros
              ejecutivos llamando al{" "}
              <a href="tel:+56226569677">
                <b>+56 2 2656 9677</b>{" "}
              </a>{" "}
              o escríbenos a nuestro correo{" "}
              <a href="mailto:contacto@finfast.com">
                <b>contacto@finfast.com</b>{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeBannerNoEmitir;
