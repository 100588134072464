/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
} from 'react';

import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import {
  CreateOperation,
  ErrorQuoteEmit,
  OperacionDTO,
  OriginIncome,
  ValidateOperations,
} from 'Models/Operation';
import {
  FieldValues,
  UseFormReturn,
} from 'react-hook-form';

import GenericBaseModel from '@api/GenericBaseModel';
import * as operation from '@app/operation/operation';
import * as helpers from '@helpers/helpers';
import * as helpers2 from '@helpers/helpers2';
import {
  calculateDaysBetweenDates,
  calculateValueInCLPText,
  getValueToShowText,
  sumar31Meses,
  validarMontoCertVSLinea,
} from '@helpers/QuoteEmit';
import * as validation from '@helpers/validationsRFH';
import {
  MAlert,
  MCards,
  MInputDate,
  MInputGeneric,
  MInputNumber,
  MInputRadioButtons,
  MInputRut,
  MInputSelect,
  MInputTextArea,
  MLoadingLight,
} from '@inversiones-ma/finfast-react-controls';
import * as NewCertificateActions from '@redux/actions/NewCertificateActions';
import {
  setCurrentStep,
  setTenderData,
  setValueCommissionSelected,
} from '@redux/actions/NewCertificateActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import {
  Commune,
  Region,
} from '@redux/models/GlobalModels';
import * as NewCertificateModels from '@redux/models/NewCertificateModels';
import {
  SelectedData,
  Step,
} from '@redux/models/NewCertificateModels';
import { EnumPrioridad } from '@redux/models/QuoteModels';
import {
  LineInsuranceCarrier,
  Purpose,
  VistaProveedorLineaAprobada,
} from '@redux/models/SessionModels';

import { currencyItems } from '../../../../../../scripts/js/menu';
import ErrorAlert from '../../../ErrorAlerts/ErrorAlert';
import { UserGuide } from '../../../Home/hooks/UserGuide';
import CurrencyFeeAlert
  from '../../../Home/partial/CurrencyFeeAlert/CurrencyFeeAlert';
import LabelValue from '../../../Home/partial/LabelValue';
import ValidationContractStartDate from '../ValidationContractStartDate';
import ButtonAction from './ButtonAction';
import CommissionResult from './CommissionResult';
import { useHandleEnterData } from './useHandleEnterData';
import WarrantiesCardView from './WarrantiesCardView';

interface PropsEnterDataForm {
  form: UseFormReturn<FieldValues, object>;
  setErrorDuplicateCft: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorValidMandate: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalRestricted: React.Dispatch<React.SetStateAction<boolean>>;
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
  close?: () => void;
  showReturn?: boolean;
}
const EnterDataForm = ({
  form,
  setErrorDuplicateCft,
  setErrorValidMandate,
  setShowModalRestricted,
  addOrEdit,
  close,
  origen,
}: PropsEnterDataForm) => {
  const dispatch = useAppDispatch();

  const { selectedData, tenders, tenderData, newCertificateFromQuote } =
    useAppSelector((store) => store.newCertificate);

  const { regions } = useAppSelector((store) => store.global);
  const { purposes, arrVistaProveedorLineaAprobada, uf, usd, linesInsurance } = useAppSelector(
    (store) => store.session
  );
  const { loginDate, loginDateClient, run, executive } = useAppSelector((store) => store.account);

  const { AddTenders, ValidateMandante, GetLine, ValidateAnticipo } = useHandleEnterData();

  const internalSelectedData = { ...selectedData, ...tenderData };

  const currentDay: Date =
    loginDate && loginDateClient
      ? helpers2.getDateWithoutTime(loginDate, loginDateClient)
      : new Date();

  const [errorQuote, setErrorQuote] = useState(<></>);

  const [alertAnticipo, setAlertAnticipo] = useState<boolean>(false);
  const [commissionInRequest, setCommissionInRequest] =
    useState<boolean>(false);
  const [selectCardcommission, setSelectCardcommission] =
    useState<boolean>(false);
  const [lastErrors, setLastErrors] = useState("{}");
  const [arrComuna, setArrComuna] = useState<Commune[]>(
    helpers2.getCommunes(
      internalSelectedData?.regionUnidadMandante as string,
      regions
    )
  );

  useEffect(() => {
    if (origen === "cart") {
      let emisor: string;
      if (tenderData?.idFondo === 7) {
        emisor = "Fogape";
      } else if (tenderData?.idAseguradora && tenderData?.idAseguradora > 0) {
        emisor = "Sura";
      } else {
        emisor = "Finfast";
      }
      const operationValid: ValidateOperations =
      {
        emisor: emisor,
        mensaje: '',
        codigoError: '',
        success: false,
        descripcionEmisor: '',
        idAseguradora: 0,
        valoresComision: undefined,
        idFondo: undefined
      }
        ;

      handlerSelect(operationValid)
    }
  }, [origen, commissionInRequest])

  //calculo comision
  const [infoCommission, setInfoCommission] = useState<
    CreateOperation | undefined
  >(undefined);
  const [resultComission, setResultComission] = useState<ValidateOperations[]>(
    []
  );
  const [prioridadCertificado, setPrioridadCertificado] = useState<
    EnumPrioridad | undefined
  >(undefined);

  //optionCard
  const [selectedOption, setSelectedOption] = useState<
    ValidateOperations | undefined
  >(undefined);

  //formulario
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [blockButton, setBlockButton] = useState<boolean>(false);

  const [operationInRequest, setOperationInRequest] = useState<boolean>(false);
  const plazoMaxAprobado =
    arrVistaProveedorLineaAprobada && arrVistaProveedorLineaAprobada.length > 0
      ? arrVistaProveedorLineaAprobada[1].plazoAprobado
      : 0;

  //linea
  const [dataLinea, setDataLinea] = useState<VistaProveedorLineaAprobada[]>();

  //mandante
  const [lastMandanteSearched, setLastMandanteSearched] = useState<string>("");
  const [validMandante, setValidMandante] = useState<boolean>(true);

  //alert error mandante
  const [validMandanteAlert, setValidMandanteAlert] = useState<
    ErrorQuoteEmit[]
  >([]);
  const [validMonth, setValidMonth] = useState<ErrorQuoteEmit[]>([]);
  const [validCertMount, setValidMount] = useState<ErrorQuoteEmit[]>([]);
  const [validatingMandante, setValidatingMandante] = useState<boolean>(false);
  const [messageValidMandante, setMessageValidMandante] = useState<string>(
    "Para emitir esta garantía contáctese con tu ejecutiva"
  );

  //anticipo min fecha
  const [minValueAnticipo, setMinValueAnticipo] = useState<number>(0);
  const [minDateDinamic, setMinDateDinamic] = useState(currentDay);
  const [minEndDateDinamic, setMinEndDateDinamic] = useState<
    Date | undefined
  >();

  //fecha contrato poliza
  const [validationContractStartDate, setValidationContractStartDate] =
    useState<boolean | undefined>(false);
  const [contractStartDate, setContractStartDate] = useState<Date | undefined>();
  const [valueToShow, setValueToShow] = useState<string | undefined>("");
  const [daysDifference, setDaysDifference] = useState<number>(0);
  const [valueInCLP, setValueInCLP] = useState<string | undefined>("");

  const { handleDownloadUserGuide, DownloadDocument } = UserGuide();

  const {
    formState: { errors },
    formState,
    getValues,
    handleSubmit,
    setValue,
    watch,
    setError,
  } = form;


  const {
    rutUnidadMandante,
    nombreOrganismoMandante,
    regionUnidadMandante,
    comunaUnidadMandante,
    direccionUnidadMandante,
    fechaInicio,
    fechaTermino,
    finalidad,
    montoCertificado,
    tipoMoneda,
  } = watch();

  useEffect(() => {
    if (tipoMoneda)
      setValueToShow(getValueToShowText(tipoMoneda, uf?.value, usd?.value));
  }, [tipoMoneda]);


  useEffect(() => {
    if (
      fechaInicio === undefined ||
      fechaInicio === "" ||
      fechaTermino === undefined ||
      fechaTermino === ""
    ) {
      return;
    }
    if (sumar31Meses(fechaInicio, fechaTermino, plazoMaxAprobado)) {
      //crea objeto para alert mandante
      let newAlert: ErrorQuoteEmit[] = [
        {
          errorType: "danger",
          mensaje:
            "El plazo seleccionado es mayor a los " +
            plazoMaxAprobado +
            " meses permitidos.",
          descripcionError:
            "Contacta a tu ejecutivo " +
            executive?.name +
            " a su teléfono " +
            executive?.phone +
            " o a su correo " +
            executive?.email +
            " para evaluar la emisión.",
          codigoError: "",
          success: false,
        },
      ];

      setValidMonth(newAlert);
    } else {
      setValidMonth([]);
    }
  }, [fechaInicio, fechaTermino]);

  useEffect(() => {
    if (montoCertificado === undefined || montoCertificado === "") {
      return;
    }
    if (
      !validarMontoCertVSLinea(
        montoCertificado,
        finalidad,
        purposes,
        arrVistaProveedorLineaAprobada,
        linesInsurance as LineInsuranceCarrier[]
      )
    ) {
      //crea objeto para alert mandante
      let newAlert: ErrorQuoteEmit[] = [
        {
          errorType: "danger",
          mensaje: "",
          descripcionError:
            "Monto supera el máximo disponible en tu ticket/línea.",
          codigoError: "",
          success: false,
        },
      ];
      setValidMount(newAlert);
    } else {
      setValidMount([]);
    }
  }, [montoCertificado, commissionInRequest]);

  useEffect(() => {
    let idLicitacion = '';

    if (origen === "newCerificate" && newCertificateFromQuote === undefined && selectedData?.licitacionValida)
      idLicitacion = selectedData?.codigoExterno!;

    if (origen === "newCerificate" && newCertificateFromQuote && newCertificateFromQuote.idLicitacion) {
      idLicitacion = newCertificateFromQuote?.idLicitacion!;
    }

    let dataCommision = {
      rutUnidadMandante,
      regionUnidadMandante,
      fechaInicio,
      fechaTermino,
      finalidad,
      montoCertificado,
      tipoMoneda,
      direccionUnidadMandante,
      nombreOrganismoMandante,
      comunaUnidadMandante,
      codigoExterno: idLicitacion
    } as CreateOperation;

    setInfoCommission(dataCommision);
    let comunas = helpers.getComunas(regionUnidadMandante, regions);
    setArrComuna(comunas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio, fechaTermino, finalidad, montoCertificado, tipoMoneda]);

  useEffect(() => {
    loadDataLine();

    if (!!internalSelectedData?.rutUnidadMandante) loadDataMandante();

    if (new Date(internalSelectedData?.fechaInicio!) < currentDay) {
      setValue("fechaInicio", currentDay);
      changeMinDate();
    }
    handleAnticipo();
    //carga datos desde cotizacion
    if (newCertificateFromQuote) {
      const rutMandante =
        newCertificateFromQuote.tenderData &&
          newCertificateFromQuote.tenderData.rutUnidadMandante
          ? newCertificateFromQuote.tenderData.rutUnidadMandante
          : newCertificateFromQuote.rutMandante;
      if (rutMandante) {
        loadDataMandante(rutMandante);
      }
      setValue("rutUnidadMandante", rutMandante);
      setValue(
        "nombreOrganismoMandante",
        newCertificateFromQuote?.tenderData?.nombreOrganismoMandante ?? ""
      );
      setValue(
        "regionUnidadMandante",
        newCertificateFromQuote?.tenderData?.regionUnidadMandante ?? ""
      );
      setValue(
        "direccionUnidadMandante",
        newCertificateFromQuote?.tenderData?.direccionUnidadMandante ?? ""
      );
      setValue(
        "comunaUnidadMandante",
        newCertificateFromQuote?.tenderData?.comunaUnidadMandante ?? ""
      );
      setValue("fechaInicio", newCertificateFromQuote?.fechaInicio);
      setValue("fechaTermino", newCertificateFromQuote?.fechaTermino);

      setSelectedOption(newCertificateFromQuote?.selectedOption);
    }

    validarAnticipo(fechaInicio, fechaTermino, finalidad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //FRL: 29-03-21 Ejecución al detectar errores en la edición y enviar correo de ser necesario
    const hasErrors = !!errors && Object.entries(errors).length !== 0;

    if (hasErrors && addOrEdit === NewCertificateModels.Step.verificar) {
      //Se envía genera control para envíar correo
      operation.sendMailErrorValidationControl(
        form,
        { ...selectedData, ...tenderData },
        resultComission,
        lastErrors,
        setLastErrors
      );
    }
  }, [formState]);

  useEffect(() => {
    let comunas = helpers.getComunas(regionUnidadMandante, regions);
    setArrComuna(comunas);
  }, [regionUnidadMandante]);

  useEffect(() => {
    let operationValid: ValidateOperations[] = resultComission.filter(x => x.success === true);
    if (operationValid.length === 1) {
      const [validOperation] = operationValid;
      setSelectedOption(validOperation);
      dispatch(setValueCommissionSelected([validOperation]));
    }
  }, [resultComission]);

  useEffect(() => {
    if (fechaTermino && fechaInicio)
      setDaysDifference(() => calculateDaysBetweenDates(fechaInicio, fechaTermino)!);
  }, [fechaTermino, fechaInicio]);

  const handleAnticipo = async () => {
    const respAnticipo = await ValidateAnticipo();
    setMinValueAnticipo(respAnticipo?.valorNumerico! ?? 3);
  };

  const validarAnticipo = (
    fechaInicio: Date,
    fechaTermino: Date,
    finalidad: string
  ) => {
    if (finalidad === "Anticipo") {
      let minPeriod = minValueAnticipo as number;
      let data = dayjs(fechaInicio);
      let newEndDate = dayjs(data).add(minPeriod, "month").toDate();

      let fecTermino = new Date(fechaTermino);

      if (fecTermino < newEndDate) {
        setValue("fechaTermino", newEndDate);
      }

      setMinEndDateDinamic(newEndDate);
      setAlertAnticipo(true);
    } else {
      setMinEndDateDinamic(minDateDinamic);
      setAlertAnticipo(false);
    }
  };

  const changeMinDate = () => {
    const { fechaInicio, fechaTermino } = getValues();

    const newFechaInicio = new Date(fechaInicio);
    const newFechaTermino = fechaTermino ? new Date(fechaTermino) : currentDay;

    if (newFechaInicio > newFechaTermino) {
      setValue("fechaTermino", newFechaInicio);
    }

    setMinDateDinamic(fechaInicio);
  };

  function handlerSelect(element: ValidateOperations) {
    let option: ValidateOperations[] = [];
    option.push(element);
    setSelectedOption(element);
    setBlockButton(false);
    dispatch(setValueCommissionSelected(option));
  }

  const loadDataMandante = async (rut?: string) => {
    if (rutUnidadMandante !== lastMandanteSearched || rut) {
      setLastMandanteSearched(rutUnidadMandante ?? rut);

      if (isEqual(run?.toLowerCase(), rut?.toLowerCase())) {
        setError(
          "rutUnidadMandante",
          { message: "Por favor, ingrese otro rut para el mandante" },
          { shouldFocus: true }
        );
        setValidatingMandante(false);
        setValidMandante(false);
        return;
      }

      setValidatingMandante(true);
      const resp = await ValidateMandante(rutUnidadMandante ?? rut);
      setValidatingMandante(false);
      if (resp?.success) {
        setValidMandante(true);
        setErrorValidMandate(false);
        //reinicia validacion mandante
        setValidMandanteAlert([]);
      } else {
        setMessageValidMandante("RUT no habilitado");
        setValidMandante(false);

        //crea objeto para alert mandante
        let newAlert: ErrorQuoteEmit[] = [
          {
            errorType: "danger",
            mensaje: resp.message,
            descripcionError:
              "Contacta a tu ejecutivo " +
              executive?.name +
              " a su teléfono " +
              executive?.phone +
              " o a su correo " +
              executive?.email +
              " para evaluar la emisión.",
            codigoError: "",
            success: false,
          },
        ];

        setValidMandanteAlert(newAlert);

        operation.sendMailErrorValidation(
          form,
          selectedData as SelectedData,
          resultComission,
          "mandanteInhabilitado",
          "emisión",
          resp?.message ?? "error"
        );
      }
      //trigger("rutUnidadMandante");
    }
  };

  const loadDataLine = async () => {
    let response: GenericBaseModel<VistaProveedorLineaAprobada> =
      {} as GenericBaseModel<VistaProveedorLineaAprobada>;

    response = await GetLine();

    if (response.success) {
      // Se ocupa para saber el monto maximo aprobado
      const dataLineaResp = response.dataList;
      setDataLinea(dataLineaResp);
    }
  };

  const handlerResultComission = (objResult: any) => {
    const result = objResult;
    if (result) {
      if (result.resultTypeError === "G0001" || result.resultTypeError !== undefined) {
        setIsFormValid(isFormValid && false);
        setBlockButton(true);
        return;
      }
    }
    setBlockButton(false);
  };

  const disableBtn = () => {
    let disable =
      !validMandante ||
      //!isFormValid ||
      blockButton ||
      commissionInRequest ||
      !selectedOption ||
      (selectedOption?.idAseguradora > 0 && !validationContractStartDate);

    return disable;
  };

  const onSubmit = async (data: CreateOperation) => {
    setOperationInRequest(true);
    setErrorQuote(<></>);
    let idLicitacion = "";
    if (origen === "addQuote" || origen === "cart")
      idLicitacion = tenderData?.codigoExterno!;

    if (origen === "newCerificate" && newCertificateFromQuote === undefined)
      idLicitacion = selectedData?.codigoExterno!;

    if (origen === "newCerificate" && newCertificateFromQuote !== undefined)
      idLicitacion = newCertificateFromQuote?.idLicitacion!;

    //let r = selectedPrioridadCertificado;
    const cft: CreateOperation = {
      codigoExterno: idLicitacion,
      rutUnidadMandante: data.rutUnidadMandante,
      nombreOrganismoMandante: data.nombreOrganismoMandante,
      regionUnidadMandante: data.regionUnidadMandante,
      comunaUnidadMandante: data.comunaUnidadMandante,
      direccionUnidadMandante: data.direccionUnidadMandante,
      finalidad: data.finalidad,
      fechaInicio: dayjs(data.fechaInicio).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      fechaTermino: dayjs(data.fechaTermino).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
      tipoMoneda: data.tipoMoneda,
      montoCertificado: data.montoCertificado,
      glosa: data.glosa,
      idFondo: selectedOption ? selectedOption.idFondo : null,
      idAseguradora: selectedOption ? selectedOption.idAseguradora : null,
      id: tenderData?.id ?? 0,
      codigoLicitacion: data.codigoLicitacion ?? data.codigoExterno
    };

    const resp = await AddTenders(cft);
    if (resp.success) {
      dispatch(NewCertificateActions.cleanQuote(undefined));

      let tender = operation.prepareOperacionToList(
        {
          ...resp.data.operacion,
          campaignName: resp.data.campaignName,
          campaignAmount: resp.data.campaignAmount,
          carroDeCompras: true,
          fechaInicioContrato: contractStartDate,
        },
        tenders,
        selectedOption
      );
      dispatch(setTenderData(tender as OperacionDTO));
      dispatch(setCurrentStep(Step.verificar));
    } else {
      setSelectCardcommission(false);
      setBlockButton(true);
      // if (resp.code === EnumMotivoResultCotizacion.PALABRAGLOSANOPERMITIDA) {
      //   setTipoResultado(EnumResultCotizacion.ERROR);
      // }
      if (resp.code === "G0500") {
        setShowModalRestricted(true);
      }
      if (resp.code === "G0001") {
      }
      if (resp.code === "G0010" || resp.code === "G0011") {
        setErrorDuplicateCft(true);
      }
    }
    setOperationInRequest(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${!validMandante ? "disabledForm" : ""}`}
      >
        <div className="center">
          <div className="col-12">
            <MCards className="nonBorder px-1 mb-4 p-md-4" size="default">
              <div className="row justify-content-center pt-3">
                <div className="row">
                  <div className="col-12">
                    <h3 className="subtitle-mini">Datos Licitación</h3>
                  </div>
                  <p>¿Dudas sobre cómo emitir tu Garantía? <span className="text-link text-decoration-underline" onClick={() => handleDownloadUserGuide("EMISION")}>Descarga nuestro manual aquí.</span>
                    <MLoadingLight style={{ marginLeft: "10px" }} loading={DownloadDocument} />
                  </p>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 sm-2">
                    <MInputRut
                      form={form}
                      name="rutUnidadMandante"
                      label="RUT Mandante"
                      className="form-control enabled-control info-form input-tag"
                      placeholder="RUT Mandante"
                      loading={validatingMandante}
                      onBlur={loadDataMandante}
                      rules={{
                        required: "El RUT del mandante es requerido",
                        validate: () => validMandante || messageValidMandante,
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 sm-2">
                    <MInputGeneric
                      form={form}
                      name="nombreOrganismoMandante"
                      label="Nombre Mandante"
                      type="text"
                      className="form-control info-form input-tag"
                      placeholder="Nombre mandante"
                      disabled={!validMandante}
                      maxLength={100}
                      rules={{
                        required: "Este campo es requerido",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 sm-2">
                    <MInputSelect
                      form={form}
                      name="regionUnidadMandante"
                      className="form-select info-form input-tag"
                      items={regions as Region[]}
                      valueItem="name"
                      label="Región"
                      descriptionItem="name"
                      orderBy={{
                        by: "name",
                        desc: false,
                        isString: true,
                      }}
                      disabled={!validMandante}
                      rules={{
                        required: "Este campo es requerido",
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 sm-2">
                    <MInputSelect
                      form={form}
                      name="comunaUnidadMandante"
                      className="form-select info-form input-tag"
                      items={arrComuna}
                      valueItem="name"
                      descriptionItem="name"
                      orderBy={{
                        by: "name",
                        desc: false,
                        isString: true,
                      }}
                      label="Comuna"
                      disabled={!validMandante}
                      rules={{
                        required: "Este campo es requerido",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <MInputGeneric
                      name="direccionUnidadMandante"
                      className="form-control info-form input-group-border-right input-tag"
                      label="Calle y Número"
                      form={form}
                      disabled={!validMandante}
                      placeholder="Riquelme #1131"
                      rules={{
                        required: "Debes ingresar la dirección",
                      }}
                    />
                  </div>
                  <ErrorAlert quoteData={validMandanteAlert} />
                </div>
              </div>
            </MCards>
          </div>
        </div>
        <div className="center">
          <div className="col-12">
            <MCards className="nonBorder px-1 mb-4 p-md-4" size="default">
              <div className="row justify-content-center pt-3">
                <div className="row">
                  <div className="col-12">
                    <h3 className="subtitle-mini">Datos Garantía</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 sm-2">
                    <MInputSelect
                      form={form}
                      name="finalidad"
                      className="floating-select  default"
                      items={purposes as Purpose[]}
                      valueItem="name"
                      descriptionItem="name"
                      label="Finalidad"
                      disabled={!validMandante}
                      rules={{
                        required: "Este campo es requerido",
                      }}
                      // loading={!dataFinalidad}
                      selectedDefaultValue={true}
                    />
                  </div>
                  {alertAnticipo && (
                    <MAlert color="info">
                      El rango de fecha seleccionado para esta finalidad debe
                      ser superior a los 3 meses.
                    </MAlert>
                  )}
                </div>
                <div className="row">
                  <div
                    className={
                      daysDifference && daysDifference !== 0
                        ? "col-12 col-md-6 sm-2 pt-2"
                        : "col-12 col-md-6 sm-2"
                    }
                  >
                    <MInputDate
                      form={form}
                      name="fechaInicio"
                      label="Desde"
                      placeholder="Desde"
                      min={currentDay}
                      onChange={changeMinDate}
                      rules={validation.vBeginDateEmision(
                        finalidad,
                        currentDay
                      )}
                      disabled={!finalidad || !validMandante}
                    />
                  </div>
                  <div className="col-12 col-md-6 sm-2">
                    {daysDifference && daysDifference !== 0 ? (
                      <LabelValue
                        text={"Plazo"}
                        value={
                          daysDifference === 1
                            ? "1 día"
                            : daysDifference + " días"
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <MInputDate
                      form={form}
                      name="fechaTermino"
                      label="Hasta"
                      placeholder="Hasta"
                      min={minEndDateDinamic}
                      rules={validation.vEndDateEmision(
                        fechaInicio,
                        finalidad,
                        dataLinea,
                        currentDay
                      )}
                      disabled={!finalidad || !validMandante}
                      onChange={() =>
                        setDaysDifference(
                          calculateDaysBetweenDates(fechaInicio, fechaTermino)!
                        )
                      }
                    />
                  </div>

                  <ErrorAlert quoteData={validMonth} />
                </div>
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 sm-2">
                    <div className="col-lg-10 w-100 input-moneda pb-2">
                      {valueToShow && (
                        <LabelValue
                          text={"Valor actual"}
                          value={valueToShow}
                          isCurrency={true}
                        />
                      )}
                      <label className="mb-0 label-input"> Tipo de moneda:</label>
                      <MInputRadioButtons
                        className="form-check-label "
                        form={form}
                        labelPosition="left"
                        name="tipoMoneda"
                        descriptionItem="Moneda"
                        items={currencyItems}
                        direction="row"
                        disabled={!finalidad || !validMandante}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 sm-2">
                    {valueToShow && (
                      <LabelValue
                        text={"Conversión a CLP"}
                        value={valueInCLP!}
                        isCurrency={true}
                      />
                    )}
                    <MInputNumber
                      label="Monto Garantía"
                      labelSubText=""
                      form={form}
                      name="montoCertificado"
                      className="form-control info-form input-tag"
                      placeholder="Ingresa monto de la garantía"
                      mode={tipoMoneda}
                      disabled={!!!tipoMoneda || !!!finalidad || !validMandante}
                      //   rules={validation.vMontoCertificadoEmision(
                      //     dataOrganismoMandante,
                      //     finalidad,
                      //     tipoMoneda,
                      //     dataLinea,
                      //     uf?.value,
                      //     usd?.value
                      //   )}
                      onChange={() =>
                        setValueInCLP(
                          calculateValueInCLPText(
                            montoCertificado,
                            tipoMoneda,
                            uf?.value,
                            usd?.value
                          )
                        )
                      }
                      loading={!!!purposes}

                    />
                  </div>

                  <ErrorAlert quoteData={validCertMount} />
                </div>
                <div className="row">
                  <div className="col">
                    <MInputTextArea
                      form={form}
                      name="glosa"
                      rules={validation.vGlosa()}
                      label="Glosa"
                      maxLength={1000}
                      className="floating-input floating-textarea default mt-2"
                      disabled={!montoCertificado || !validMandante}
                    ></MInputTextArea>
                    <CurrencyFeeAlert tipoMoneda={tipoMoneda} />
                  </div>
                </div>
              </div>
            </MCards>
          </div>
        </div>
        {!operationInRequest ? errorQuote : <></>}
        <WarrantiesCardView
          commissionInRequest={commissionInRequest}
          selectCardcommission={selectCardcommission}
          resultComission={resultComission}
          handlerSelect={(element) => handlerSelect(element)}
          addOrEdit={addOrEdit}
          prioridadCertificado={prioridadCertificado}
          setPrioridadCertificado={setPrioridadCertificado}
          origen={origen}
          selectedOption={selectedOption}
          setValidationContractStartDate={setValidationContractStartDate}
          setContractStartDate={setContractStartDate}
          infoCommission={infoCommission as CreateOperation}
        />

        {selectedOption && selectedOption?.idAseguradora > 0 && window.innerWidth <= 991 && (
          <div className="pt-3">
            {
              <MCards
                className="nonBorder p-3"
                size="default"
                style={{ boxShadow: "0px 0px 0px" }}
              >
                <ValidationContractStartDate
                  setValidationContractStartDate={setValidationContractStartDate}
                  setContractStartDate={setContractStartDate}
                  infoCommission={infoCommission as CreateOperation}
                />
              </MCards>
            }
          </div>
        )}
        {validMandante && (
          <CommissionResult
            tenderData={tenderData}
            form={form}
            resultComission={resultComission}
            isFormValid={isFormValid}
            setIsFormValid={setIsFormValid}
            setResultComissionError={handlerResultComission}
            setSelectCardcommission={setSelectCardcommission}
            setInRequest={setCommissionInRequest}
            infoCommission={infoCommission}
            setResultComission={setResultComission}
            setPrioridadCertificado={setPrioridadCertificado}
            setErrorQuote={setErrorQuote}
          />
        )}

        <ButtonAction
          addOrEdit={addOrEdit}
          origen={origen}
          operationInRequest={operationInRequest}
          disableBtn={disableBtn}
          closeModal={close}
        />
      </form>
    </>
  );
};

export default EnterDataForm;
