import { OperacionDTO } from 'Models/Operation';

import { createAction } from '@reduxjs/toolkit';

import {
  Activity,
  ApprovedLine,
  Cart,
  Certificate,
  Currency,
  DiscountCode,
  DiscountValue,
  InsuranceCarrier,
  Line,
  LineInsuranceCarrier,
  ProviderCampaign,
  TrxServipag,
  ValidateProduct,
  ValueCurrency,
  VistaProveedorLineaAprobada,
} from '../models/SessionModels';

export const cleanState = createAction("SESSION_CLEAN_STATE");

export const cleanDiscountZone = createAction("SESSION_CLEAN_DISCOUNT");

export const cleanDiscountValue = createAction("SESSION_CLEAN_DISCOUNTVALUE");

export const loadDiscountRequest = createAction("SESSION_LOAD_DISCOUNT_REQUEST",
  (inRequest: boolean) => {
    return {
      payload: inRequest
    }
  });

export const loadDiscountSuccess = createAction("SESSION_LOAD_DISCOUNT_SUCCESS",
  (isSuccess: boolean) => {
    return {
      payload: isSuccess
    }
  });

export const loadDiscountError = createAction("SESSION_LOAD_DISCOUNT_ERROR",
  (isError: boolean) => {
    return {
      payload: isError
    }
  });

export const loadDiscountColor = createAction("SESSION_LOAD_DISCOUNT_COLOR",
  (color: string) => {
    return {
      payload: color
    }
  });

export const loadDiscountMessage = createAction("SESSION_LOAD_DISCOUNT_MESSAGE",
  (message: string) => {
    return {
      payload: message
    }
  });

export const loadLinesInsurance = createAction("SESSION_LOAD_LINES_INSURANCE",
  (linesInsurance: LineInsuranceCarrier[] | undefined) => {
    return {
      payload: linesInsurance,
    }
  }
)

export const loadInsuranceCarrier = createAction("SESSION_LOAD_INSURANCE_CARRIER",
  (insuranceCarrier: InsuranceCarrier[] | undefined) => {
    return {
      payload: insuranceCarrier,
    }
  }
)

export const loadLines = createAction(
  "SESSION_LOAD_LINES",
  (lines: Line[] | undefined) => {
    return {
      payload: lines,
    };
  }
);

export const loadVistaProveedorLineaAprovada = createAction(
  "SESSION_LOAD_VISTA_PROVEEDOR_LINEA_APROBADA",
  (
    arrVistaProveedorLineaAprobada: VistaProveedorLineaAprobada[] | undefined
  ) => {
    return {
      payload: arrVistaProveedorLineaAprobada,
    };
  }
);

export const loadActivities = createAction(
  "SESSION_LOAD_ACTIVITIES",
  (activities: Activity[]) => {
    return {
      payload: activities,
    };
  }
);

export const loadUf = createAction(
  "SESSION_LOAD_UF",
  (value: number, date: Date) => {
    return {
      payload: {
        value: value,
        date: date,
      } as ValueCurrency,
    };
  }
);

export const loadUsd = createAction(
  "SESSION_LOAD_USD",
  (value: number, date: Date) => {
    return {
      payload: {
        value: value,
        date: date,
      } as ValueCurrency,
    };
  }
);

export const loadCurrencies = createAction(
  "SESSION_LOAD_CURRENCIES",
  (currencies: Currency[]) => {
    return {
      payload: currencies,
    };
  }
);

export const loadProducts = createAction(
  "SESSION_LOAD_PRODUCTS",
  (validateProducts: ValidateProduct[] | undefined) => {
    return {
      payload: validateProducts,
    };
  }
);

export const loadCurrentCertificates = createAction(
  "SESSION_LOAD_CURRENT_CERTIFICATES",
  (currentCertificates: Certificate[] | []) => {
    return {
      payload: currentCertificates,
    };
  }
);

export const loadExpiredCertficates = createAction(
  "SESSION_LOAD_EXPIRED_CERTIFICATES",
  (expiredCertificates: Certificate[] | []) => {
    return {
      payload: expiredCertificates,
    };
  }
);

export const loadAccidentCertificates = createAction(
  "SESSION_LOAD_ACCIDENT_CERTIFICATE",
  (accidentCertificates: Certificate[] | []) => {
    return {
      payload: accidentCertificates,
    };
  }
);

export const loadCanceledCertificates = createAction(
  "SESSION_LOAD_CANCELED_CERTIFICATE",
  (canceledCertificates: Certificate[] | []) => {
    return {
      payload: canceledCertificates,
    };
  }
);

export const loadValidatePay = createAction(
  "SESSION_LOAD_VALIDATEPAY",
  (validate: boolean | undefined) => {
    return {
      payload: validate,
    };
  }
);

export const loadApprovedLine = createAction(
  "SESSION_LOAD_APPROVEDLINE",
  (approvedLine: ApprovedLine[] | undefined) => {
    return {
      payload: approvedLine,
    };
  }
);

export const loadDiscountCode = createAction(
  "SESSION_LOAD_DISCOUNTCODE",
  (discount: DiscountCode | undefined) => {
    return {
      payload: discount,
    };
  }
);

export const loadDiscountValue = createAction(
  "SESSION_LOAD_DISCOUNTVALUE",
  (discountValue: DiscountValue | undefined) => {
    return {
      payload: discountValue,
    };
  }
);

export const loadCart = createAction(
  "SESSION_LOAD_ITEMSCART",
  (itemsCart: Cart | undefined) => {
    return {
      payload: itemsCart,
    };
  }
);
export const cleanStateCart = createAction("SESSION_CLEAR_CART");


export const updateCartToPay = createAction(
  "SESSION_UPDATE_CART_TO_PAY",
  (item: OperacionDTO[]) => {
    return {
      payload: item,
    };
  }
)

export const cleanCartToPay = createAction("CLEAN_CART_TO_PAY")

export const setSelectedIssuer = createAction(
  "SESSION_SELECTED_ISSUER",
  (issuer: string) => {
    return {
      payload: issuer,
    };
  }
);
export const addItemToCart = createAction(
  "SESSION_ADD_ITEM_TO_CART",
  (item: OperacionDTO) => {
    return {
      payload: item,
    };
  }
);

export const replaceItemsToCart = createAction(
  "SESSION_REPLACE_ITEMS_TO_CART",
  (items: any) => {
    return {
      payload: items,
    };
  }
);

export const loadSurvey = createAction("SESSION_SURVEY", (item: boolean) => {
  return {
    payload: item,
  };
});

export const loadIdTrxServipag = createAction('SESSION_LOAD_TRX_SERVIPAG', (trxServipag: TrxServipag) => {
  return {
    payload: trxServipag
  }
});

export const loadProviderCampaign = createAction('SESSION_LOAD_PROVIDERCAMPAIGN', (providerCampaign: ProviderCampaign) => {
  return {
    payload: providerCampaign
  }
});

export const cleanStateProviderCampaign = createAction('PROVIDERCAMPAIGN_CLEAN_STATE');