import React, { useState } from "react";
import { arrFaqs } from "../../../../../scripts/js/faq";
interface PropsCategory {
  category: string;
}

const QuestionAnswer = (props: PropsCategory) => {
  const [stateCollapse, setstateCollapse] = useState<string>("");

  const handlerClick = (newStateCollapse: string, index: number, e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    let statecollapse = `${newStateCollapse}${index}`
    if (stateCollapse === statecollapse) {
      setstateCollapse("");
    }
    else {
      setstateCollapse(statecollapse);
    }
  }

  let arr = [];
  arr = arrFaqs.filter((x) => x.categoryCode === props.category);
  return (
    <>
      <div
        className="accordion accordion-flush"
        //id="accordionFlush"
        id={`accordionFlush${props.category}`}
      >
        {arr?.map((x, index) => {
          return (
            <div key={index} className="accordion-item">
              <h2
                className="accordion-header"
                id={`flush-heading${props.category}${index}`}
              >
                <button
                  onClick={() => handlerClick(props.category, index)}
                  className={
                    stateCollapse === `${props.category}${index}`.toString()
                      ? "accordion-button"
                      : "accordion-button collapsed"
                  }

                  //"accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse${props.category}${index}`}
                  aria-expanded={
                    stateCollapse === `${props.category}${index}`.toString()
                      ? "true"
                      : "false"
                  }
                  aria-controls={`flush-collapse${props.category}${index}`}
                >
                  {x.question}
                </button>
              </h2>
              <div
                id={`flush-collapse${props.category}${index}`}
                className={
                  stateCollapse === `${props.category}${index}`.toString()
                    ? "accordion-collapse collapse show"
                    : "accordion-collapse collapse"
                }
                aria-labelledby={`flush-heading${props.category}${index}`}
                data-bs-parent={`#accordionFlush${props.category}`}
              //data-bs-parent="#accordionFlush"
              >
                <div className="accordion-body">{x.answer}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuestionAnswer;
