import React from "react";
import MasterPrivateNavbar from "./MasterPrivate/MasterPrivateNavbar";
import Footer from "./partial/Footer";
import GlobalNps from "./partial/GlobalNps";
import { useGliaInitialization } from "../../hooks/useGliaInitialization";

const MasterPrivate = ({ children }) => {
  useGliaInitialization();

  return (
    <>
      <div className="view-container">
        <MasterPrivateNavbar />
        <div className="content-container">
          <GlobalNps />
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MasterPrivate;
