import * as _ from "lodash";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import { SelectedData, Step } from "@redux/models/NewCertificateModels";
import { FieldValues, UseFormReturn } from "react-hook-form/dist/types";
import ApiMail from "@api/mail/Mail";
import * as helpers from "@helpers/helpers";
import { TDispatch } from "@redux/hooks";
import { OperacionDTO } from "Models/Operation";

export const handlerSendToSeleccionarDatos = (dispatch: TDispatch) => {  
  dispatch(NewCertificateActions.setCurrentStep(Step.seleccionar));
  dispatch(NewCertificateActions.setSelectedData(undefined));
  dispatch(NewCertificateActions.setTenderData(undefined));
  dispatch(NewCertificateActions.setQuote(undefined));
};

export const prepareOperacionToList = (
  operacion: OperacionDTO,
  tenders: any[],
  infoWarantySelected: any
) => {
  //Se normalizan y duplican algunos valores para coincidir con el modelo
  const operacionNormalizada = {
    ...operacion,
    rutMandante: operacion.rutUnidadMandante,
    nombreMandante: operacion.nombreOrganismoMandante,
    comision: operacion.comision,
    discount: operacion.descuento ?? 0,
    // selected: true,
    // edited: true,
    fechaInicio: operacion.fechaInicio?.replace("Z", ""),
    fechaTermino: operacion.fechaTermino?.replace("Z", ""),
    //tipoGarantia: infoWarantySelected.tipoGarantia,
  };
  // Si una licitación ya ha sido editada se debe tomar todos los valores en el popup

  //Se controla que no se repita el código externo y lo actulice en el listado
  let arrLicitaciones = tenders;

  //Se obtiene el index en el arreglo
  const indexTender = _.findIndex(
    arrLicitaciones,
    (x) => x.codigoExterno === operacionNormalizada.codigoExterno
  );

  //En caso de encontrarlo, reemplazarlo
  if (indexTender !== -1) {
    arrLicitaciones[indexTender] = operacionNormalizada;
    NewCertificateActions.setTenders(arrLicitaciones);
  }
  //Se finaliza con la operación normalizada
  return operacionNormalizada;
};

//Envío de correo luego del control
export const sendMailErrorValidation = (
  form: UseFormReturn<FieldValues, object>,
  selectedData: SelectedData,
  resultComission: any,
  cause: string,
  origin: string,
  error: string
) => {
  const formValues = {
    codigoExterno: selectedData?.codigoExterno,
    ...form.getValues(),
    ...resultComission,
  };

  let aditionalInfo =
    " " + helpers.generateAdicionalInfoErrorEmision(formValues);

  ApiMail.setMailErrorValidation(cause, origin, error, aditionalInfo);
};

// Control de los errores previo a envíar el correo
export const sendMailErrorValidationControl = (
  form: UseFormReturn<FieldValues, object>,
  selectedData: any,
  resultComission: any,
  lastErrors: string,
  setLastErrors: React.Dispatch<React.SetStateAction<string>>
) => {
  const {
    formState,
    formState: { errors },
  } = form;

  const erroresFormated = JSON.stringify({
    montoCertificado: {
      type: formState.errors.montoCertificado?.type,
      message: formState.errors.montoCertificado?.message,
    },
    fechaTermino: {
      type: formState.errors.fechaTermino?.type,
      message: formState.errors.fechaTermino?.message,
    },
  });
  if (erroresFormated !== lastErrors) {
    //Se actualizan los errores para diferenciar en el próximo renderizado
    setLastErrors(erroresFormated);

    //Se envía correo al existir error en el monto de certificado
    if (errors.montoCertificado && errors.montoCertificado.type !== "required")
      sendMailErrorValidation(
        form,
        selectedData,
        resultComission,
        "excedeLinea",
        "emisión",
        errors.montoCertificado.message
      );
    //Se envía correo al existir error en la fecha de término
    if (errors.fechaTermino && errors.fechaTermino.message.includes(" "))
      sendMailErrorValidation(
        form,
        selectedData,
        resultComission,
        "finalidadExcedePlazo",
        "emisión",
        errors.fechaTermino.message
      );
  }
};
