import React from "react";
import {MAlert} from "@inversiones-ma/finfast-react-controls";

const DisclaimerForContributingProfile = () => {
    return (
        <MAlert color={"info"}>
            <p className="m-0">Tu perfil no esta habilitado para realizar compras, por favor contacta a tu administrador
                de cuenta o envía una notificación al pagador.</p>
        </MAlert>
    )
}

export default DisclaimerForContributingProfile;