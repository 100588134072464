import React, { useState } from "react";
import { useAppSelector } from "@redux/hooks";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import ModalSii from "./ModalSII";
import { finfast } from "@config/environment";
import icon from "@img/icon-blanco-exclamacion.svg";


import "./SIIClientesAntiguos.scss";
import { getConfiguration } from "@helpers/helpers2";
import { Configuracion } from "@redux/models/GlobalModels";
import { toDate } from "@helpers/helpers";

interface Props {
  showInHome: boolean;
}

const HomeBannerSIIClientesAntiguos = (props: Props) => {
  const { requireSiiCredentials, isNewClient } = useAppSelector(
    (store) => store.account
  );

  const { configuracion } = useAppSelector((store) => store.global);

  const llaveSii: Configuracion = getConfiguration(
    configuracion,
    "fechaMaxSiiClientesAntiguos"
  );
  const showBannerOld = requireSiiCredentials && !isNewClient;
  const idProduct = 1;
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const handleFinfast = () => {
    window.location.href =
      finfast() + `/MyAccount?origen=sii_data&product=${idProduct}`;
  };

  const handleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <>
      {showBannerOld ? (
        <div className="SIIClientesAntiguos">
          <div className="row mx-auto justify-content-center pt-3 pb-4">
            <div className="col-12 banner-solicitar-sii banner-aviso-sii-obligatorio">
              <div className="banner-solicitar-sii-bg1">
                <div className="row justify-content-around align-items-center gx-1 banner-aviso-sii-obligatorio-bg2 p-2">
                  <div
                    className={
                      props.showInHome
                        ? "col-12 col-md-auto text-center color-white d-none d-md-flex"
                        : "col-3 col-md-2 text-center"
                    }
                  >
                    {props.showInHome ? (
                      <i className="fas fa-exclamation-circle fa-2x"></i>
                    ) : (
                      <img src={icon} alt="icon"></img>
                    )}
                  </div>
                  <div
                    className={
                      props.showInHome
                        ? "col-12 col-lg-6 col-xl-auto text-center text-lg-start"
                        : "col-12 col-md-6 text-center text-lg-start"
                    }
                  >
                    <h4 className="color-white">Aviso importante</h4>
                    <p className="fw-md-bold">
                      A partir del {toDate(llaveSii.valorFecha)} será
                      obligatorio que vincules tu cuenta de SII para operar en
                      FinFast.{" "}
                    </p>
                  </div>

                  {props.showInHome ? (
                    <>
                      <div className="col-12 col-lg-2 text-center mt-3 mt-lg-0">
                        <MButton
                          type="button"
                          className="btn btn-primary sistema col-12 col-lg-10"
                          onClick={handleFinfast}
                        >
                          Vincular cuenta
                        </MButton>
                      </div>
                      <div className="col col-lg-auto text-center my-2 my-lg-0">
                        <span
                          onClick={handleInfo}
                          className="text-link"
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                        >
                          ¿Porqué pedimos esto?
                        </span>

                        {/* <a className="text-link" onClick={handleInfo}>
                          ¿Porqué pedimos esto?
                        </a> */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col text-center mt-3 mt-lg-0">
                        <MButton
                          type="button"
                          className="btn btn-primary sistema col-12 col-lg-10"
                          onClick={handleFinfast}
                        >
                          Vincular cuenta
                        </MButton>
                        <span
                          onClick={handleInfo}
                          className="text-link"
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                        >
                          ¿Porqué pedimos esto?
                        </span>
                        {/* <a className="text-link" onClick={handleInfo}>
                          ¿Porqué pedimos esto?
                        </a> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {showInfo && <ModalSii setShowModal={setShowInfo} showModal={showInfo} />}
    </>
  );
};

export default HomeBannerSIIClientesAntiguos;
