import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as createHistory from "history";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import ReactGA from "react-ga4";

ReactGA.initialize("G-Q3WB4TST5K");

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const history = createHistory.createBrowserHistory();

ReactDOM.render(
  <BrowserRouter basename={(baseUrl as string) || undefined}>
    <App history={history} />
  </BrowserRouter>,
  rootElement
);

unregister();
