import React, {
  useEffect,
  useState,
} from 'react';

import { OperacionDTO } from 'Models/Operation';
import { useHistory } from 'react-router-dom';

import ApiOperation from '@api/garantias/operation/Operation';
import GenericBaseModel from '@api/GenericBaseModel';
import Pay from '@api/pay/Pay';
import ApiReissue from '@api/reissue/Reissue';
import { convertToPeso } from '@helpers/helpers';
import {
  MAlert,
  MButton,
} from '@inversiones-ma/finfast-react-controls';
import { setCurrentStep } from '@redux/actions/NewCertificateActions';
import { loadStepData } from '@redux/actions/StepActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import { Step } from '@redux/models/NewCertificateModels';
import { ValidateDebt } from '@redux/models/SessionModels';
import {
  StepPay,
  StepState,
} from '@redux/models/StepModels';

import DisclaimerForContributingProfile
  from './DisclaimerForContributingProfile';
import SummaryCartError from './SummaryCartError';

interface Props {
  showMessageSii: boolean;
}

const SummaryCart = (props: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [request, setRequest] = useState<boolean>(false);
  const { cartToPay, selectedIssuer } = useAppSelector((store) => store.session);

  const enable = true;
  const { paymentMethods } = useAppSelector((store) => store.global);
  const { discountValue } = useAppSelector((store) => store.session);
  const { idRoL } = useAppSelector((store) => store.account);
  const { idBillingType } = useAppSelector((store) => store.account);
  const [errorPay, setErrorPay] = useState(false);
  // eslint-disable-next-line
  const [reissueCostCero, setReissueCostCero] = useState<boolean>(false);

  const montoComision: number | undefined = cartToPay?.items?.reduce(
    (sum: number, value: OperacionDTO) =>
      typeof value.comision == "number" ? sum + value.comision : sum,
    0
  );

  const montoComisionOriginal: number | undefined = cartToPay?.items?.reduce(
    (sum: number, value: OperacionDTO) =>
      typeof value.comisionOriginal == "number"
        ? sum + value.comisionOriginal
        : sum,
    0
  );

  const [state, setState] = useState({
    itemsGrid: [] as any | undefined,
    itemsCount: 0,
    currentSumCommission: 0,
    currentSumSubTotal: 0,
    currentSumDiscount: 0,
    currentSumDiscountLine: 0,
    currentTotalPay: 0,
    showGridPopupDelete: false,
    itemToDelete: {},
    showGridPopupDetail: false,
    dataPayment: paymentMethods?.filter((x) => !x.estado),
    itemToDetail: {},
    couponCode: "",
    discountCLP: null,
    discountPercent: null,
    discountTicket: 0,
    discountErrorMessage: "",
    currentArrCampaign: [],
    itemLineAvailable: [],
    stateitemLineAvailable: true,
    timeDiscountHH: 1,
    showPopupErrorRevisarCaso: false,
    showPopupErrorApiEquifax: false,
    blockedByDebt: false,
  });

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let reissueCostCero = montoComision === 0 && cartToPay?.items.every((item) => item.isReissue !== null);
    setReissueCostCero(reissueCostCero ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartToPay]);

  const loadItems = () => {
    state.itemsGrid = cartToPay?.items.map((item: OperacionDTO) => {
      return {
        ...item,
        comision: item.comisionOriginal || item.comision,
      };
    });
    setState(state);
  };

  const handlerReissueByCostZero = async () => {
    setRequest(true);
    await ApiReissue.ReissueByCostZero(cartToPay?.items)
      .then((resp: GenericBaseModel<any>) => {
        if (resp.success) {
          const steps: StepState = {
            step: 3,
            subStep: StepPay.successPay,
          };
          dispatch(loadStepData(steps));
        } else {
        }
      })
      .finally(() => {
        setRequest(false);
      });
  };

  const handlerContinuePayClick = async () => {
    setRequest(true);
    loadItems();

    await ApiOperation.ValidateDebt(cartToPay?.items)
      .then((resp: GenericBaseModel<ValidateDebt>) => {
        if (resp?.success) {
          // SGC 19-07-2021 - si showPopupErrorRevisarCaso = FALSE y showPopupErrorApiEquifax = FALSE, entonces tiene matriz aprobada y por lo tanto, habilita botón pagar.
          if (!resp.data.showPopupErrorRevisarCaso && !resp.data.showPopupErrorApiEquifax) {
            state.showPopupErrorRevisarCaso = resp.data.showPopupErrorRevisarCaso;
            state.showPopupErrorApiEquifax = resp.data.showPopupErrorApiEquifax;
          }
          // SGC 19-07-2021 - si showPopupErrorApiEquifax = FALSE (no hay error de api), pero NO tiene matriz aprobada, entonces NO deja pagar y BLOQUEA BOTON PARA EMITIR.
          else if (!resp.data.showPopupErrorApiEquifax) {
            state.showPopupErrorRevisarCaso = resp.data.showPopupErrorRevisarCaso;
            state.showPopupErrorApiEquifax = resp.data.showPopupErrorApiEquifax;
            state.blockedByDebt = true;
            // sessionGeneral.add("blockedByDebt", true);
          } else {
            // SGC 19-07-2021 - si showPopupErrorApiEquifax = TRUE (hay error de api), Y showPopupErrorRevisarCaso = TRUE (matriz NO aprobada), entonces NO deja pagar.
            // SGC 19-07-2021 - importante: NO BLOQUEA BOTON PARA poder volver intentar Emitir
            state.showPopupErrorRevisarCaso = resp.data.showPopupErrorRevisarCaso;
            state.showPopupErrorApiEquifax = resp.data.showPopupErrorApiEquifax;
            state.blockedByDebt = false; // SGC 19-07-2021 - boton lo deja habilitado
          }
        }
        setState(state);
        // if (!state.blockedByDebt) {
        if (!resp.data.showPopupErrorApiEquifax && !resp.data.showPopupErrorRevisarCaso) {
          const steps2: StepState = {
            step: 2,
            subStep: undefined,
          };
          dispatch(loadStepData(steps2));
        } else {
          setErrorPay(true);
        }
      })
      .finally(() => {
        setRequest(false);
      });
  };

  const handleResetStep = () => {
    dispatch(setCurrentStep(Step.ingresar));
    history.push("/buy-certificate");
  };

  // const handlerPayOnClick = async () => {
  //   setRequest(true);

  //   if (idBillingType === 2) {
  //     await Pay.monthlyPay(cartToPay?.items)
  //       .then((resp: any) => {
  //         if (resp !== null && resp.success) {
  //           const steps: StepState = {
  //             step: 3,
  //             subStep: StepPay.successPay,
  //           };
  //           dispatch(loadStepData(steps));
  //         }
  //       })
  //       .catch((error) => console.error("error monthlyPay: ", error))
  //       .finally(() => {
  //         setRequest(false);
  //       });
  //   } else {
  //     await Pay.callPaymentCostZero(cartToPay?.items)
  //       .then((resp: any) => {
  //         if (resp !== null && resp.success) {
  //           const steps: StepState = {
  //             step: 3,
  //             subStep: StepPay.successPay,
  //           };
  //           dispatch(loadStepData(steps));
  //         }
  //       })
  //       .catch((error) => console.error("error callPaymentCostZero: ", error))
  //       .finally(() => {
  //         setRequest(false);
  //       });
  //   }
  // };

  return (
    <>
      <div className="col-12 col-lg-5 my-1">
        <div className="rounded-card-bg px-4 py-3 my-2">
          <div className="row">
            <div className="col-12">
              <h3 className="subtitle-mini text-center">Resumen de Compra</h3>
            </div>
            {discountValue?.discountTicket && (
              <div className="col-12 resumenConDescuento">
                <p className="">
                  <span className="">Total comisión&nbsp;</span>
                  <div className="float-end  textDiscount">
                    {discountValue?.discountTicket
                      ? convertToPeso(montoComisionOriginal)
                      : convertToPeso(montoComision)}
                  </div>
                </p>
                <p className="text-danger">
                  <span className="">Descuento aplicado&nbsp;</span>
                  <div className="float-end text-danger textDiscount">
                    {"-" + convertToPeso(discountValue?.discountTicket)}
                  </div>
                </p>
              </div>
            )}
            <hr className="px-2" />
            <div className="col-6">
              <h3 className="subtitle-mini text-start">Total a Pagar</h3>
              <p></p>
            </div>
            <div className="col-6">
              <h3 className="subtitle-mini text-end">
                {convertToPeso(montoComision)}
              </h3>
              <p></p>
            </div>
            <div className="col-12 text-center">
              <p>*Precio válido solo durante el día de la emisión</p>
            </div>
            <div>
              {errorPay && (
                <MAlert
                  color="info"
                  className="slim info col-11 justify-content-center  fs-16 py-0 py-2"
                >
                  <p className="fw-bold color-info p-0 m-0 ">
                    Para emitir una o varias garantías del carrito, te
                    recomendamos comunicarte con tu ejecutiva quien podrá
                    brindarte la asistencia necesaria.
                  </p>
                </MAlert>
              )}
            </div>
            {(idRoL === undefined || idRoL === null || idRoL === 1) && (
              <div className="col-12">
                <div className="msj-detalle-carrido">
                  <p>
                    Una vez realizada la compra, tus Garantías estarán
                    disponibles dentro de 15 minutos
                  </p>
                </div>
              </div>
            )}
            {props.showMessageSii && (
              <MAlert
                color="success"
                style={{ fontWeight: "bold" }}
                className="mt-4 mb-0"
              >
                <span
                  className="font-weight-bold"
                  style={{ margin: "0px" } as React.CSSProperties}
                >
                  Se ha vinculado tu cuenta del SII con éxito
                </span>
              </MAlert>
            )}
            <SummaryCartError />

            {idRoL && idRoL === 2 && <DisclaimerForContributingProfile />}
            <div className="">
              <div className="text-center button-carro-compras">

                {selectedIssuer === "sura" && <MAlert color="info" className='p-3'>
                  <span>Todas las <b>pólizas SURA</b> serán <b>visualizadas en UF en la Garantía emitida,</b> independiente del tipo de moneda que sea elegido.</span>
                </MAlert>}

                {reissueCostCero &&
                  (idRoL === undefined || idRoL === 1 || idRoL === null) &&
                  cartToPay?.items?.length! > 0 ? (
                  <MButton
                    onClick={handlerReissueByCostZero}
                    type="button"
                    disabled={!cartToPay?.items || !enable ? true : false}
                    loading={request}
                  >
                    Finalizar reemisión
                  </MButton>
                ) : (
                  <>
                    {
                      <>
                        {(idRoL === undefined ||
                          idRoL === 1 ||
                          idRoL === null) && (
                            <MButton
                              onClick={handlerContinuePayClick}
                              type="button"
                              disabled={
                                !cartToPay?.items.length || !enable
                                  ? true
                                  : false || errorPay
                              }
                              loading={request}
                            >
                              Continuar a medio de pago
                            </MButton>
                          )}
                        <MButton outline onClick={handleResetStep}>
                          Seguir comprando
                        </MButton>
                      </>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryCart;
