import React from "react";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";

interface PropsTitleVerifyData {
  addOrEdit: NewCertificateModels.Step;
}

const TitleVerifyData = (props: PropsTitleVerifyData) => {
  return (
    <>
      <div className="col-12">
        <h2
          className={
            props.addOrEdit === NewCertificateModels.Step.ingresar
              ? "titles-left"
              : "titles-center pt-4"
          }
        >
          Confirmar Datos
        </h2>
        {props.addOrEdit === NewCertificateModels.Step.verificar && (
          <h3 className="subtitle-section">
            <p>Confirma que los datos ingresados sean correctos.</p>
          </h3>
        )}
      </div>
    </>
  );
};

export default TitleVerifyData;
