import { Cart } from "@redux/models/SessionModels";
import { MAxios, MAxiosFormData } from "../conf";

export async function GetOperationByCft(nroCft: string) {
  const endpoint = "Certificado/Certificado/GetCft";

  let formData = new FormData();
  formData.append("nroCertificado", nroCft);

  const res = await MAxiosFormData().post(endpoint, formData);

  return res.data;
}

export async function GetCommission(
  nroCertificado: string,
  finalidad: string,
  tipoMoneda: string,
  montoCertificado: string,
  fechaInicio: Date,
  fechaTermino: Date,
  nombreOrganismoMandante: string,
  regionUnidadMandante: string,
  comunaUnidadMandante: string,
  direccionUnidadMandante: string
) {
  const endpoint = "Reemision/Reemision/GetCommission";
  let montoCft = parseFloat(montoCertificado);

  let data = {
    NroCertificado: nroCertificado,
    Finalidad: finalidad,
    TipoMoneda: tipoMoneda,
    MontoCertificado: montoCft,
    fechaInicio: new Date(fechaInicio).toISOString().split("T")[0],
    fechaTermino: new Date(fechaTermino).toISOString().split("T")[0],
    nombreOrganismoMandante,
    regionUnidadMandante,
    comunaUnidadMandante,
    direccionUnidadMandante,
  };

  const res = await MAxios().post(endpoint, data);

  return res.data;
}

export async function SendReissueRequest(dataReissue: any) {
  const endpoint = "Reemision/Reemision/SendReissueRequest";

  let data = {
    idOperacion: dataReissue.idOperacion,
    nombreMandante: dataReissue.nombreOrganismoMandante,
    regionMandante: dataReissue.regionUnidadMandante,
    comunaMandante: dataReissue.comunaUnidadMandante,
    direccionMandante: dataReissue.direccionUnidadMandante,
    fechaTermino: new Date(dataReissue.fechaTermino)
      .toISOString()
      .split("T")[0],
    tipoMoneda: dataReissue.tipoMoneda,
    montoCertificado: dataReissue.montoCertificado,
    glosa: dataReissue.glosa,
  };

  const res = await MAxios().post(endpoint, data);

  return res.data;
}

export async function ValidateAmount(
  nroCertificado: string,
  finalidad: string,
  tipoMoneda: string,
  montoCertificado: string
) {
  const endpoint = "Reemision/Reemision/ValidateAmount";

  let montoCft = parseFloat(montoCertificado);

  let data = {
    NroCertificado: nroCertificado,
    Finalidad: finalidad,
    TipoMoneda: tipoMoneda,
    MontoCertificado: montoCft,
  };

  const res = await MAxios().post(endpoint, data);

  return res.data;
}

export async function ReissueByCostZero(operations: Cart["items"] | undefined) {
  // let dataReissue: any = [];
  // operations?.items.forEach((x) => {
  //   dataReissue.push({
  //     idOperacion: x?.idOperacion,
  //     finalidad: x?.finalidad,
  //     nombreMandante: x?.nombreOrganismoMandante,
  //     regionMandante: x.regionUnidadMandante,
  //     comunaMandante: x.comunaUnidadMandante,
  //     direccionMandante: x.direccionUnidadMandante,
  //     glosa: x.glosa,
  //     isReissue: x.isReissue,
  //   });
  // });

  const endpoint = "Reemision/Reemision/ReissueByCostZero";

  const res = await MAxios().post(endpoint, operations);

  return res.data;
}

const ApiReissue = {
  GetOperationByCft,
  GetCommission,
  SendReissueRequest,
  ValidateAmount,
  ReissueByCostZero,
};

export default ApiReissue;
