import React from "react";
import { convertToCurrency, toDate } from "@helpers/helpers";
import { MShowMorePaginated } from "@inversiones-ma/finfast-react-controls";
import { InsuranceCarrier } from "@redux/models/SessionModels";


interface IProps {
  setOnChangeNumberPage: React.Dispatch<React.SetStateAction<number>>;
  setOnChangeNumberRecords: React.Dispatch<React.SetStateAction<number>>;
  warratyData: any;
  insuranceCarrier: InsuranceCarrier[] | undefined;
  handlerButtonDonwnloadCft: any;
}

export const CurrentCertificatePolicyMobile = ({
  handlerButtonDonwnloadCft,
  insuranceCarrier,
  setOnChangeNumberPage,
  setOnChangeNumberRecords,
  warratyData,
}: IProps) => {
  return (
    <MShowMorePaginated
      onChangeNumberPage={setOnChangeNumberPage}
      onChangeNumberRecords={setOnChangeNumberRecords}
      paginated={warratyData}
    >
      {warratyData.records.map((element: any, index: any) => (
        <div key={index} className="card-movil-tabla px-0 shadow ">
          <div className="detalles-movil-tabla">
            <div className="col-12">
              <h4>Póliza</h4>
              <p>{element.nroCertificado}</p>
            </div>
            <hr />
            <div className="col-12">
              <h4>Folio</h4>
              <p>{element.folioInterno}</p>
            </div>
            <hr />
            <div className="col-12">
              <h4>ID Licitación</h4>
              <p>{element.codigoExterno}</p>
            </div>
            <hr />
            <div className="col-12">
              <h4>Mandante</h4>
              <p>{element.nombreOrganismoMandante}</p>
            </div>
            <hr />

            <div className="col-12">
              <h4>Aseguradora</h4>
              <p>
                {
                  insuranceCarrier?.find(
                    (item) => item.id === element.idAseguradora
                  )?.razonSocial
                }
              </p>
            </div>
            <hr />

            <div className="col-12">
              <h4>Finalidad</h4>
              <p>{element.finalidad}</p>
            </div>
            <hr />

            <div className="col-12">
              <h4>Monto</h4>
              <p>
                {convertToCurrency(
                  element.tipoMoneda,
                  element.montoCertificado
                )}
                {" " + element.tipoMoneda}
              </p>
            </div>
            <hr />

            <div className="col-12">
              <h4>Término</h4>
              <p>{toDate(element.fechaTermino)}</p>
            </div>
          </div>
          <div className="">
            <div className="">
              <div className="">
                {element.codigoDec5 && (
                  <button
                    type="button"
                    className="btn btn-primary w-100 sistema fs-18"
                    onClick={() =>
                      handlerButtonDonwnloadCft(element.codigoDec5)
                    }
                  >
                    Póliza
                    <i className="fas fa-file-alt ms-3"></i>
                  </button>
                )}

                {!element.codigoDec5 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary w-100 sistema fs-18 disabled"
                      aria-expanded="false"
                    >
                      Póliza
                      <i className="fas fa-file-alt ms-3"></i>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </MShowMorePaginated>
  );
};
