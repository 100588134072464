import { loadBillingState } from '@redux/actions/BillingActions';
import {
  createSlice,
  current,
} from '@reduxjs/toolkit';

import {
  addItemToCart,
  cleanCartToPay,
  cleanDiscountValue,
  cleanDiscountZone,
  cleanState,
  cleanStateProviderCampaign,
  loadAccidentCertificates,
  loadActivities,
  loadApprovedLine,
  loadCanceledCertificates,
  loadCart,
  loadCurrencies,
  loadCurrentCertificates,
  loadDiscountCode,
  loadDiscountColor,
  loadDiscountError,
  loadDiscountMessage,
  loadDiscountRequest,
  loadDiscountSuccess,
  loadDiscountValue,
  loadExpiredCertficates,
  loadIdTrxServipag,
  loadInsuranceCarrier,
  loadLines,
  loadLinesInsurance,
  loadProducts,
  loadProviderCampaign,
  loadSurvey,
  loadUf,
  loadUsd,
  loadValidatePay,
  loadVistaProveedorLineaAprovada,
  replaceItemsToCart,
  setSelectedIssuer,
  updateCartToPay,
} from '../actions/SessionActions';
import {
  Purpose,
  SessionState,
} from '../models/SessionModels';

const inicialState: SessionState = {
  cart: {
    // se modifica al mismo tiempo
    items: [],
    discountCode: undefined,
  },
  cartToPay: {
    // se modifica al mismo tiempo
    items: [],
    discountCode: undefined,
  },
  discountZone: {
    color: "#414042",
    inRequest: false,
    isSuccess: false,
    isError: false,
    message: ""
  },
  lines: undefined,
  linesInsurance: undefined,
  insuranceCarrier: undefined,
  validateProducts: undefined,
  validateToPay: undefined,
  approvedLine: undefined,
  discountCode: undefined,
  discountValue: undefined,
  currencies: undefined,
  uf: undefined,
  usd: undefined,
  currentCertificates: [], // se modifica al mismo tiempo
  accidentCertficates: [],
  expiredCertificates: [],
  canceledCertificates: [],
  survey: false,
  trxServipag: undefined,
  billing: undefined,
  providerCampaign: undefined,
  selectedIssuer: 'finfast'
};

const slice = createSlice({
  name: "session",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cleanState, (state) => {
        state.cart = inicialState.cart;
        state.lines = inicialState.lines;
        state.validateProducts = inicialState.validateProducts;
      })
      .addCase(loadLines, (state, action) => {
        state.lines = action.payload;
        state.purposes = action.payload
          ?.filter((l) => l.name !== "Línea Total")
          .map((l) => {
            return {
              id: l.idPurpose,
              name: l.name,
            } as Purpose;
          });
      })
      .addCase(loadLinesInsurance, (state, action) => {
        state.linesInsurance = action.payload;
      })
      .addCase(loadInsuranceCarrier, (state, action) => {
        state.insuranceCarrier = action.payload;
      })
      .addCase(loadActivities, (state, action) => {
        state.activities = action.payload;
      })
      .addCase(loadVistaProveedorLineaAprovada, (state, action) => {
        state.arrVistaProveedorLineaAprobada = action.payload;
      })
      .addCase(loadUf, (state, action) => {
        state.uf = action.payload;
      })
      .addCase(loadUsd, (state, action) => {
        state.usd = action.payload;
      })
      .addCase(loadCurrencies, (state, action) => {
        state.currencies = action.payload;
      })
      .addCase(addItemToCart, (state, action) => {
        const currentState = current(state);
        const cartItems = currentState.cart?.items || [];
        if (cartItems.length) {
          const filteredItems = cartItems.filter((i) => i.id !== action.payload.id);
          state.cart = {
            items: [...filteredItems, action.payload],
            discountCode: currentState.cart?.discountCode,
          };
        } else {
          state.cart = {
            items: [action.payload],
            discountCode: currentState.cart?.discountCode,
          };
        }
      })

      .addCase(replaceItemsToCart, (state, action) => {
        const currentState = current(state);
        state.cart = {
          items: action.payload,
          discountCode: currentState.cart?.discountCode,
        };

      })
      .addCase(cleanCartToPay, (state, action) => {
        state.cartToPay = undefined;
      })

      .addCase(setSelectedIssuer, (state, action) => {
        state.selectedIssuer = action.payload;
      })

      .addCase(updateCartToPay, (state, action) => {
        const currentState = current(state);
        state.cartToPay = undefined;
        state.cartToPay = {
          items: action.payload,
          discountCode: currentState.cart?.discountCode,
        }
      })
      .addCase(loadBillingState, (state, action) => {
        state.billing = action.payload;
      })
      .addCase(loadCart, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(loadApprovedLine, (state, action) => {
        state.approvedLine = action.payload;
      })
      .addCase(loadDiscountCode, (state, action) => {
        state.discountCode = action.payload;
      })
      .addCase(loadDiscountValue, (state, action) => {
        state.discountValue = action.payload;
      })
      .addCase(loadCurrentCertificates, (state, action) => {
        state.currentCertificates = action.payload;
      })
      .addCase(loadExpiredCertficates, (state, action) => {
        state.expiredCertificates = action.payload;
      })
      .addCase(loadCanceledCertificates, (state, action) => {
        state.canceledCertificates = action.payload;
      })
      .addCase(loadAccidentCertificates, (state, action) => {
        state.accidentCertficates = action.payload;
      })
      .addCase(loadProducts, (state, action) => {
        state.validateProducts = action.payload;
      })
      .addCase(loadValidatePay, (state, action) => {
        state.validateToPay = action.payload;
      })
      .addCase(loadSurvey, (state, action) => {
        state.survey = action.payload;
      })
      .addCase(loadIdTrxServipag, (state, action) => {
        state.trxServipag = action.payload;
      })
      .addCase(loadProviderCampaign, (state, action) => {
        state.providerCampaign = action.payload;
      })
      .addCase(cleanStateProviderCampaign, (state) => {
        state.providerCampaign = inicialState.providerCampaign;
      })
      .addCase(cleanDiscountValue, (state, action) => {
        state.discountValue = inicialState.discountValue;
      })
      .addCase(cleanDiscountZone, (state) => {
        state.discountZone = inicialState.discountZone
      })
      .addCase(loadDiscountRequest, (state, action) => {
        state.discountZone.inRequest = action.payload
      })
      .addCase(loadDiscountSuccess, (state, action) => {
        state.discountZone.isSuccess = action.payload
      })
      .addCase(loadDiscountError, (state, action) => {
        state.discountZone.isError = action.payload
      })
      .addCase(loadDiscountColor, (state, action) => {
        state.discountZone.color = action.payload
      })
      .addCase(loadDiscountMessage, (state, action) => {
        state.discountZone.message = action.payload
      })
      .addDefaultCase((_state) => { });
  },
});

export default slice.reducer;
