import React from "react";
// import bannerInterno from "@img/banner/so-abr_banner-interno.png";
import landingBajada from "@img/banner/Banner-marketplace-costoFijo-Desktop.svg";
import landingBajadaTablet from "@img/banner/Banner-marketplace-costoFijo-Desktop.svg";
import landingMobile from "@img/banner/Banner-marketplace-costoFijo-Mobile.svg";
import "./HomeBannerAbril2023.scss";

const HomeBannerAbril2023 = () => {
  return (
    <>
      <div className="homebannerabril2023">
        {/* <div className="row mx-auto banner-interno-size soAbril2023-interno-bg align-content-center mb-5 mt-4 mt-lg-0 gy-4 gy-md-0 gx-4"> */}
        <div className="row mb-3 mt-3 w-100 ms-1 soAbril2023-interno-bg">
          {/* <div className="col-2 ps-0 my-auto d-none d-lg-block">
            <img src={bannerInterno} alt="banner interno" className="d-none d-lg-block" />
          </div> */}
          <div className="w-100 SO-abril2023 m-0 p-0">
            {/* <h3>
              Ahorra en minutos con tu <br />
              seriedad de oferta{" "}
            </h3> */}
            <img
              src={landingBajada}
              alt="landing bajada"
              className="d-none d-lg-block w-100 m-0 p-0"
            />
            <img
              src={landingBajadaTablet}
              alt="landing tablet"
              className="d-none d-md-block d-lg-none w-100 m-0 p-0"
            />
            <img
              src={landingMobile}
              alt="landing mobile"
              className="d-block d-md-none m-0 p-0 w-100"
            />
          </div>
          {/* <div className="col-md-6 col-lg-4 my-auto SO-abril2023 mt-4 mt-lg-0 pt-1">
            <div className="low-cost text-center">
              <h5>0,7 UF</h5>
              <span className="mt-1">Costo Fijo</span>
            </div>
            <div className="variable py-1 text-center">+ variable</div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default HomeBannerAbril2023;
