import React, { useState } from "react";

import { isEqual } from "lodash";
import { OperacionDTO, ValidateOperations } from "Models/Operation";
import { PaymentData } from "Models/Pay";

import { convertToPeso } from "@helpers/helpers";
import { MAlert } from "@inversiones-ma/finfast-react-controls";
import { useAppSelector } from "@redux/hooks";

import DeferredPayment from "../DeferredPayment";
import ErrorAlert from "../ErrorAlert";
import PaymentHandler from "./PaymentHandler";
import PaymentMethod from "./PaymentMethod";
import PaymentMethodPolicy from "./PaymentMethodPolicy";
import { EnumOriginPaymentChannels } from "@redux/models/GlobalModels";

const SummaryPay = () => {
  const { cartToPay } = useAppSelector((store) => store.session);
  const { paymentMethods } = useAppSelector((store) => store.global);
  const { discountCode } = useAppSelector((store) => store.session);

  const [errorPay, setErrorPay] = useState<boolean>(false);
  const [validations, setValidations] = useState<ValidateOperations>();
  const [statePay, setStatePay] = useState<PaymentData>({
    gateways: paymentMethods,
    currentGatewayCode: "",
    currentKhipuDiferidoMailTo: null,
    currentKhipuDiferidoMailToError: true,
    currentKhipuDiferidoMailToErrorMessage: "",
    showValidateKhipuDiferido: false,
  });

  const comisionCertificado: number | undefined = cartToPay?.items?.reduce(
    (sum: number, value: any) =>
      typeof value.comision == "number" ? sum + value.comision : sum,
    0
  );

  const cartHavePolicy = cartToPay?.items.some(
    (x: OperacionDTO) => x.idAseguradora !== null && x.idAseguradora! > 0
  );

  const handlerGatewayOnClick = (gatewayCode: string) => {
    debugger;
    statePay.currentGatewayCode = gatewayCode;
    setStatePay({ ...statePay });
    setErrorPay(false);
  };

  return (
    <>
      <div className="col-12 col-lg-5 my-3">
        <div className="rounded-card-bg p-5">
          <div className="row">
            <div className="col-12">
              <h3 className="subtitle-mini text-center">
                Selecciona medio de pago
              </h3>
              <hr />
            </div>
            <div className="col-6">
              <h3 className="subtitle-mini text-start">Total a pagar</h3>
              <p></p>
            </div>
            <div className="col-6">
              <h3 className="subtitle-mini text-end">
                {convertToPeso(comisionCertificado)}
              </h3>
              <p></p>
            </div>
            {/* medios de pago */}
            <div className="col-12 text-center">
              <ul className="listado-pago-tarjetas">
                {cartHavePolicy ? (
                  <PaymentMethodPolicy
                    statePay={statePay}
                    handlerGatewayOnClick={(e) => handlerGatewayOnClick(e)}
                  />
                ) : (
                  <PaymentMethod
                    statePay={statePay}
                    handlerGatewayOnClick={(e) => handlerGatewayOnClick(e)}
                    selected={statePay.currentGatewayCode}
                  />
                )}
              </ul>
              {isEqual(statePay.currentGatewayCode, EnumOriginPaymentChannels.KhipuDiferido) && (
                <DeferredPayment
                  ClickHandler={() => handlerGatewayOnClick("")}
                  setErrorPay={setErrorPay}
                  errorPay={errorPay}
                ></DeferredPayment>
              )}
            </div>

            {errorPay && (
              <MAlert
                color={"danger"}
                className={`alert slim  info col-12 justify-content-center  fs-14"`}
              >
                <div className="row m-0 p-1">
                  <p className="fs-14">
                    Ocurrio un error. Si el problema persiste, contacta a tu
                    ejecutivo.
                  </p>
                </div>
              </MAlert>
            )}

            {validations?.errorType && (
              <ErrorAlert ValidateOperations={validations} />
            )}

            <PaymentHandler
              cart={cartToPay}
              discountCode={discountCode}
              statePay={statePay}
              setValidations={setValidations}
              setErrorPay={setErrorPay}
              errorPay={errorPay}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryPay;
