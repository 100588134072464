import { MAxios, MAxiosFormData, InjectTokenHeader } from "../conf";

export const AnonymousLogin = async (token) => {
  const endpoint = "membresia/Account/AnonymousLogin";

  const tokenHeader = token ? InjectTokenHeader(token) : {};

  const res = await MAxios().post(endpoint, {}, tokenHeader);

  return res.data;
};

export const autoLogin = async (token) => {
  let formData = new FormData();
  formData.append("token", token);

  const endpoint = "membresia/AutoAccount/AutoLogin";

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

const KillToken = async (token) => {
  const endpoint = "Membresia/Account/KillToken";

  let formData = new FormData();
  formData.append("token", token);
  const res = await MAxiosFormData().post(endpoint, formData);
 
  return res.data;
};

/**
 * token: token expirado
 */
const RefreshToken = async (token) => {
  const endpoint = "Membresia/Account/RefreshToken";

  let formData = new FormData();
  formData.append("token", token);
  const res = await MAxiosFormData().post(endpoint, formData);
 
  return res.data;
}

const ApiMembresiaAccount = {
  autoLogin,
  KillToken,
  RefreshToken
};

export default ApiMembresiaAccount;
