import React, { useEffect, useState } from "react";
import {
  MPopup,
  MButton,
  MInputGeneric,
  MInputCheckBox,
  MTooltip,
} from "@inversiones-ma/finfast-react-controls";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useForm } from "react-hook-form";
import * as FilterActions from "@redux/actions/FilterActions";
import { Filter } from "@redux/models/FilterModels";
import "../Filter.scss";
import ApiFilter from "@api/filter/Filter";
import MAlertPopupFilterEdit from "../../../../controls/alertpopups/MAlertPopupFilterEdit";
import MTagBox from "./MTagBox";
import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.common.css";
import ReactGA from "react-ga4";
interface PropsEditFilter {
  showModal: boolean;
  setShowModal: any;
  filter: any | null;
}
function EditPopup(props: PropsEditFilter) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { run } = useAppSelector((store) => store.account);
  const [inRequest, setInRequest] = useState(false);
  const dispatch = useAppDispatch();
  const { regions } = useAppSelector((store) => store.global);
  const { typeTender } = useAppSelector((store) => store.global);
  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    setValue("descripcion", props.filter?.descripcion);
    setValue("preferencia", props.filter?.preferencia);
    setValue("excluir", props.filter?.excluir);
    setValue("nombreMandante", props.filter?.nombreMandante);
    setValue("rutMandante", props.filter?.rutMandante);
    setValue("codigoProducto", props.filter?.codigoProducto);
    setValue("tipoLicitacion", props.filter?.tipoLicitacion);
    setValue("regionMandante", props.filter?.regionMandante);
    if (props.filter?.garantia) {
      setValue("conGarantia", true);
      setValue("sinGarantia", false);
    } else {
      if (props.filter?.garantia === false) {
        setValue("conGarantia", false);
        setValue("sinGarantia", true);
      } else {
        setValue("conGarantia", false);
        setValue("sinGarantia", false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  function GetAllFiltersByRut() {
    ApiFilter.GetAll().then((response) => {
      dispatch(FilterActions.loadFilters(response.dataList));
    });
  }
  const { handleSubmit, reset, setValue } = form;
  const onSubmit = (data: any, e: any) => {
    setInRequest(true);
    let garantia = null;

    if (data.conGarantia === data.sinGarantia) garantia = null;
    else garantia = data.conGarantia && !data.sinGarantia;

    let editFilter: Filter = {
      codigoProducto: data.codigoProducto ? data.codigoProducto.join() : "",
      descripcion: data.descripcion,
      excluir: data.excluir ? data.excluir.join() : "",
      preferencia: data.preferencia ? data.preferencia.join() : "",
      fechaCreacion: props.filter?.fechaCreacion as Date,
      habilitado: props.filter?.habilitado as boolean,
      id: props.filter?.id as number,
      nombreMandante: data.nombreMandante ? data.nombreMandante.join() : "",
      regionMandante: data.regionMandante ? data.regionMandante.join() : "",
      rubro: props.filter?.rubro as string,
      rutMandante: data.rutMandante ? data.rutMandante.join() : "",
      rutProveedor: run as string,
      sectorMandante: props.filter?.sectorMandante as string,
      tipoLicitacion: data.tipoLicitacion ? data.tipoLicitacion.join() : "",
      garantia: garantia,
    };

    ApiFilter.Save(JSON.stringify(editFilter)).then((resp) => {
      setInRequest(false);
      if (resp.success) {
        props.setShowModal(false);
        setShowSuccessMessage(true);
        reset();
        GetAllFiltersByRut();
        ReactGA.event({
		  action: "Editar filtro",
          category: "Filtros",
          label: "Se ha editado un filtro de licitación",          
        });
      }
    });
  };
  return (
    <>
      <MPopup
        size="lg"
        showModal={props.showModal}
        setShowModal={props.setShowModal}
        showClose={true}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="parentFilter ps-4 pe-4"
        >
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="subtitle-mini text-center">Editar filtro</h3>
              <p className="text-center">
                Antes de comenzar, escoge un nombre para tu filtro
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-6 sm-2">
              <div className="input-group">
                <div className="col-12">
                  <div className="floating-label">
                    <MInputGeneric
                      form={form}
                      name="descripcion"
                      label="Nombre Filtro"
                      type="text"
                      maxLength={200}
                      rules={{
                        required: "Este campo es requerido",
                      }}
                    ></MInputGeneric>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row align-items-center">
            <div className="col-12 py-3">
              <h3 className="subtitle-mini">Configura los filtros</h3>
              <p>
                Mientras más campos uses, más especificos serán tus
                resultados.Ingresa a lo menos un campo
              </p>
            </div>
          </div>
          <div className="row align-items-center py-3">
            <div className="col-12">
              <h3 className="subtitle-mini">Filtrar por palabra</h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    name="preferencia"
                    placeholder="Ej: Palabra1, Palabra2d"
                    form={form}
                    maxItems={4}
                    maxLength={100}
                    validate={true}
                    acceptCustomValue={true}
                    value={props.filter?.preferencia}
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="Incluidas"
                    isRut={false}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    name="excluir"
                    placeholder="Ej: palabra1, palabra2"
                    className=""
                    form={form}
                    maxItems={4}
                    maxLength={100}
                    validate={true}
                    acceptCustomValue={true}
                    value={props.filter?.excluir}
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="Excluidas"
                    isRut={false}
                  />
                  <span className="tooltip-filter ">
                    <MTooltip
                      iconClass="fas fa-question-circle fs-6"
                      content={
                        <h6>
                          Indica que palabras NO quieres que contengan las
                          licitaciones consideradas en los filtros.
                        </h6>
                      }
                    ></MTooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center py-3">
            <div className="col-12">
              <h3 className="subtitle-mini">
                Filtrar por producto o tipo de licitación
              </h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    name="codigoProducto"
                    placeholder="Ej: Codigo1, Codigo2"
                    form={form}
                    maxItems={4}
                    maxLength={100}
                    validate={{
                      minLength: (value: any) =>
                        !(value.length > 0 && value.length < 6) ||
                        "El valor es muy corto",
                      maxLength: (value: any) =>
                        !(value.length > 0 && value.length > 25) ||
                        "El valor es muy largo",
                    }}
                    acceptCustomValue={true}
                    value={props.filter?.codigoProducto}
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="Código del Producto"
                    isRut={false}
                  />
                  <span className="tooltip-filter ">
                    <MTooltip
                      iconClass="fas fa-question-circle fs-6"
                      content={
                        <h6 className="tooltip-filter">
                          Indica un producto por código especifico
                        </h6>
                      }
                    ></MTooltip>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    placeholder=""
                    acceptCustomValue={false}
                    name="tipoLicitacion"
                    form={form}
                    maxItems={4}
                    maxLength={200}
                    validate={false}
                    value={props.filter?.tipoLicitacion}
                    dataSource={typeTender}
                    valueExpr="id"
                    displayExpr="descripcion"
                    label="Tipo de Licitación"
                    isRut={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center py-3">
            <div className="col-12">
              <h3 className="subtitle-mini">Filtrar por mandante</h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    name="rutMandante"
                    placeholder="Ej: rut1, rut2"
                    form={form}
                    maxItems={4}
                    maxLength={100}
                    validate={true}
                    acceptCustomValue={true}
                    value={props.filter?.rutMandante}
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="RUT Mandante"
                    isRut={true}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    name="nombreMandante"
                    placeholder="Ej: nombre1, nombre"
                    form={form}
                    maxItems={4}
                    maxLength={200}
                    validate={true}
                    acceptCustomValue={true}
                    value={props.filter?.nombreMandante}
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="Nombre Mandante"
                    isRut={false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    name="regionMandante"
                    form={form}
                    maxItems={4}
                    maxLength={200}
                    acceptCustomValue={false}
                    dataSource={regions}
                    valueExpr="id"
                    displayExpr="name"
                    value={props.filter?.regionMandante}
                    placeholder={undefined}
                    className={"test"}
                    validate={false}
                    isRut={false}
                    label="Región"
                  ></MTagBox>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 py-3">
              <h3 className="subtitle-mini">Buscar según Garantía</h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="input-group">
                  <div className="col-8 my-auto">
                    <p className="text-tipolicitacion-edit">
                      Licitación con Garantía
                    </p>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <MInputCheckBox name="conGarantia" form={form} label="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="input-group">
                  <div className="col-8 my-auto">
                    <p className="text-tipolicitacion-edit">
                      Licitación sin Garantía
                    </p>
                  </div>
                  <div className="col-4">
                    <div className="col-4">
                      <div className="">
                        <MInputCheckBox
                          name="sinGarantia"
                          form={form}
                          label=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center py-3">
            <div className="col-md-3 pt-3">
              <MButton
                className="btn btn-primary sistema w-100"
                type="submit"
                loading={inRequest}
              >
                Editar Filtro
              </MButton>
            </div>
          </div>
        </form>
      </MPopup>
      <MAlertPopupFilterEdit
        showModal={showSuccessMessage}
        setShowModal={setShowSuccessMessage}
      />
    </>
  );
}

export default EditPopup;
