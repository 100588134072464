import React, { useState } from "react";
import { MPopup, MButton } from "@inversiones-ma/finfast-react-controls";
import iconFilterDelete from "@img/icon-filter-delete.svg";
import DeleteMessage from "./DeleteMessage";
import ApiFilter from "@api/filter/Filter";
import { useAppDispatch } from "@redux/hooks";
import { loadFilters } from "@redux/actions/FilterActions";
import ReactGA from "react-ga4";

interface PropsSuccessMessage {
  showModal: boolean;
  setShowModal: any;
  filterIdDelete: number;
}
const ConfirmationMessage = ({
  showModal,
  setShowModal,
  filterIdDelete,
}: PropsSuccessMessage) => {
  const dispatch = useAppDispatch();

  const [ConfirmationDelete, setConfirmationDelete] = useState(false);
  const [StateDelete, setStateDelete] = useState(false);

  async function GetAllFiltersByRut() {
    await ApiFilter.GetAll().then((response) => {
      dispatch(loadFilters(response.dataList));
    });
  }
  async function HandlerDeleteFilter() {
    setStateDelete(true);
    let resp = await ApiFilter.Delete(filterIdDelete);
    if (await resp) {
      setConfirmationDelete(true);
      ReactGA.event({
        action: "Eliminar",
        category: "Filtros",
        label: "Eliminar un filtro",
      });
      setConfirmationDelete(true);
      setStateDelete(false);
    }
    setShowModal(false);
    GetAllFiltersByRut();
  }

  return (
    <>
      <MPopup
        size="md"
        showModal={showModal}
        setShowModal={setShowModal}
        showClose={true}
      >
        <div className="text-center parentFilter">
          <img
            src={iconFilterDelete}
            alt="datos confirmados"
            className="icon-pop-up-el"
          />

          <h4 className="titles-center m-title-conf">
            ¿estás seguro de querer
            <br />
            eliminar este filtro?
          </h4>
          <h6 className="py-3 m-text-conf">
            Esta acción es permanente y no se <br />
            puede deshacer
          </h6>

          <div className="row justify-content-center">
            <div className="col-12 col-md-4 order-1 order-md-0 my-auto py-2">
              <MButton
                outline={true}
                type="button"
                onClick={() => setShowModal(false)}
                className="w-100"
              >
                Cancelar
              </MButton>
            </div>
            <div className="col-12 col-md-4 order-0 order-md-1 py-2">
              <MButton
                type="button"
                className="w-100"
                loading={StateDelete}
                onClick={() => HandlerDeleteFilter()}
              >
                Eliminar
              </MButton>
            </div>
          </div>
        </div>
      </MPopup>
      <DeleteMessage
        showModal={ConfirmationDelete}
        setShowModal={setConfirmationDelete}
      ></DeleteMessage>
    </>
  );
};

export default ConfirmationMessage;
