import { createSlice } from "@reduxjs/toolkit";
import {
  cleanState,
  setCurrentQuoteId,
  setLoadFirstDataGrid,
  setLoadSecondDataGrid,
} from "../actions/QuoteActions";
import { QuoteState } from "@redux/models/QuoteModels";

const inicialState: QuoteState = {
  firstGridData: [],
  secondGridData: [],
  currentQuoteId: -1,
};

const slice = createSlice({
  name: "quote",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cleanState, (state) => {
        state.firstGridData = inicialState.firstGridData;
        state.secondGridData = inicialState.secondGridData;
      })
      .addCase(setLoadFirstDataGrid, (state, action) => {
        state.firstGridData = action.payload;
      })
      .addCase(setLoadSecondDataGrid, (state, action) => {
        state.secondGridData = action.payload;
      })
      .addCase(setCurrentQuoteId, (state, action) => {
        state.currentQuoteId = action.payload;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
