import React from "react";
import { MAlert } from "@inversiones-ma/finfast-react-controls";

const AlertReissue = () => {
  return (
    <>
      <div className="col-12 col-md-10 center">
        <MAlert color="info" className="slim">
          <p
            className="textAlert"
            style={{ fontSize: 14 + "px" } as React.CSSProperties}
          >
            <span className="ingresa-alert02">
              El costo de reemisión es desde 1 UF{" "}
            </span>
            <br />
            <span
              style={{ fontSize: 12 + "px" } as React.CSSProperties}
              className="textAlert2"
            >
              Cambios menores como:{" "}
              <span className="ingresa-alert02 ps-1 ">
                glosa, razón social o dirección, en la primera reemisión es $0
              </span>
            </span>
          </p>
        </MAlert>
      </div>
    </>
  );
};

export default AlertReissue;
