import { PreferencesState } from '@redux/models/PreferencesModel';
import { createAction } from '@reduxjs/toolkit';

export const loadPreferences = createAction('LOAD_PREFERENCES', (PreferencesData: PreferencesState ) => {
    return {
        payload: PreferencesData
    }
});



export const cleanState = createAction('PREFERENCES_CLEAN_STATE');


