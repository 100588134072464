import React from 'react'
import { MAlert } from '@inversiones-ma/finfast-react-controls';
import parse from 'html-react-parser'
import { ValidateOperations } from 'Models/Operation';

interface Iprops {
    ValidateOperations?: ValidateOperations;
}

function ErrorAlert(props: Iprops) {

    function GetTypeColor(){
        switch (props.ValidateOperations?.errorType) {
            case 'E001': {
                return "info";
            }
            case 'E002': {
                return "danger";

            }
            case 'E003': {
                return "warning";

            }
        }
    }
    return (
        <div>
            <MAlert
                color={GetTypeColor() as "success" | "info" | "warning" | "danger"}
                className={`alert slim  info col-11 justify-content-center  fs-16 py-0 py-2"`}
            >
                <p className="fw-bold  p-0 m-0 ">
                    {parse(props.ValidateOperations?.descripcionError as string)}
                </p>
            </MAlert>
        </div>
    )
}

export default ErrorAlert