import {
  MGridColumn,
  MGridPaginated,
  MLoadingLight,
  MTooltip,
} from "@inversiones-ma/finfast-react-controls";
import React from "react";
import { Paginador } from "../../../../../../Models/Paginador";
import { OperacionDTO } from "../../../../../../Models/Operation";
import {
  convertToCurrency,
  toDate,
} from "../../../../../../scripts/helpers/helpers";

interface Iprops {
  paginadorData: Paginador<OperacionDTO>;
  setOnChangeNumberPage: (b: number) => any;
  setOnChangeNumberRecords: (b: number) => any;
  handlerButtonDownloadCft: Function;
  handlerButtonDownloadInvoice: Function;
  DownloadDocument: any;
  DownloadDocumentID: any;
  DownloadInvoice: any;
  loading: boolean;
}

function VistaEscritorio({
  paginadorData,
  setOnChangeNumberPage,
  setOnChangeNumberRecords,
  handlerButtonDownloadCft,
  handlerButtonDownloadInvoice,
  DownloadDocument,
  DownloadDocumentID,
  DownloadInvoice,
  loading,
}: Iprops) {
  return (
    <>
      {paginadorData && (
        <MGridPaginated
          onChangeNumberPage={setOnChangeNumberPage}
          onChangeNumberRecords={setOnChangeNumberRecords}
          paginated={paginadorData}
          keyExpr="nroCertificado"
          key="nroCertificado"
          showMoreSize={10}
          onChangeShowMore={() => {}}
          className="head-table-color"
          isLoadingPaginated={loading}
        >
          <MGridColumn
            dataField="nroCertificado"
            label="Certificado"
          ></MGridColumn>
          <MGridColumn
            dataField="codigoExterno"
            label="Id Licitación"
          ></MGridColumn>
          <MGridColumn
            dataField="nombreOrganismoMandante"
            label="Mandante"
            headerClassName="center-header"
            className="text-center"
          ></MGridColumn>
          <MGridColumn dataField="finalidad" label="Finalidad"></MGridColumn>
          <MGridColumn dataField="tipoMoneda" label="Moneda"></MGridColumn>
          <MGridColumn
            dataField="montoCertificado"
            label="Monto"
            cellRender={(_k: any, value: any, row: any): any => {
              return convertToCurrency(row.tipoMoneda, value);
            }}
          ></MGridColumn>
          <MGridColumn
            className="col "
            dataField="fechaTermino"
            label="Término"
            cellRender={(_k: any, value: any) => {
              return <> {toDate(value)} </>;
            }}
          ></MGridColumn>
          <MGridColumn
            className="col text-center"
            dataField="codigoDec5"
            label="Certificado"
            headerClassName="center-header"
            cellRender={(_k: any, value: any) => {
              return (
                <>
                  {DownloadDocument && _k === DownloadDocumentID && (
                    <>
                      <MLoadingLight />
                    </>
                  )}
                  {value && _k !== DownloadDocumentID && (
                    <>
                      <button
                        className="btn px-3 descarga-factura-tabla"
                        onClick={() => handlerButtonDownloadCft(_k)}
                      >
                        <i
                          className="fas fa-file-alt fs-5"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </>
                  )}
                  {!value && (
                    <>
                      <i
                        className="fas fa-file-alt fs-5 disabled"
                        aria-hidden="true"
                      ></i>
                    </>
                  )}
                </>
              );
            }}
          ></MGridColumn>
          <MGridColumn
            className="col text-center"
            dataField="tieneFactura"
            label="Factura Certificado"
            headerClassName="center-header"
            cellRender={(_k: any, value: any) => {
              return (
                <>
                  {DownloadInvoice && _k === DownloadDocumentID && (
                    <>
                      <MLoadingLight />
                    </>
                  )}
                  {value && (
                    <button
                      type="button"
                      className="btn btn-primary sistema fs-14"
                      onClick={() => handlerButtonDownloadInvoice(_k)}
                    >
                      <i
                        className="fas fa-file-download fs-5 boton-certificado"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  {value === false && (
                    <>
                      <button className="btn px-3 descarga-factura-tabla">
                        <MTooltip
                          title="Pendiente de Facturación"
                          content=""
                          iconClass="fas fa-ban cursor-default fs-5"
                        />
                      </button>
                    </>
                  )}
                </>
              );
            }}
          ></MGridColumn>
        </MGridPaginated>
      )}
    </>
  );
}

export default VistaEscritorio;
