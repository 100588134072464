import { MAxios } from "../conf";

export const getMandanteByTender = async (licitacionCodigoExterno: string) => {
  let formData = new FormData();
  formData.append("CodigoExterno", licitacionCodigoExterno);

  const endpoint = "Mandante/Mandante/GetMandanteFromLicitacionCodigoExterno";

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

export const getMandanteByPurchaseOrder = async (ordenCompraCodigo: string) => {
  let formData = new FormData();
  formData.append("Codigo", ordenCompraCodigo);

  const endpoint = "Mandante/Mandante/GetMandanteFromOrdenCompraCodigo";

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

export const validateMandante = async (rutMandante: string) => {
  let formData = new FormData();
  formData.append("rut", rutMandante);

  const endpoint = "Mandante/Mandante/ValidateMandante";

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

const ApiMandante = {
  getMandanteByTender,
  getMandanteByPurchaseOrder,
  validateMandante,
};

export default ApiMandante;
