import { createAction } from '@reduxjs/toolkit';
import { Aseguradora } from '../models/InsuranceLineModels';


export const setInsurancePolice = createAction('LOAD_DATA_INSURANCE_POLICE', (dataLine: Aseguradora[]) => {
    return {
        payload: dataLine
    }
});



export const cleanState = createAction('iNSURANCE_POLICE_CLEAN_STATE');