import React, { useState } from "react";
import {
  MGrid,
  MGridColumn,
  MPopup,
  MShowMore,
} from "@inversiones-ma/finfast-react-controls";
import MLoading from "../../../../controls/MLoading";
import { toDate, convertToCurrency } from "@helpers/helpers";
import { useAppDispatch } from "@redux/hooks";
import { setCurrentQuoteId } from "@redux/actions/QuoteActions";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import "./QuotedCertificates.scss";
import MAlertPopupDeleteQuote from "../Modals/MAlertPopupDeleteQuote";
import FormAddOperation from "../Forms/FormAddOperation";
import ReactGA from "react-ga4";

interface PropsCertificatesGrid {
  dataSource: any[];
  showDelete?: any | undefined;
  isLoading: boolean;
  isValidToday?: boolean;
  AddToCart?: (added: boolean) => void;
}

function CertificatesGrid(props: PropsCertificatesGrid) {
  const dispatch = useAppDispatch();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false);

  function handlerButtonGridAddOnClick(fieldKey: number) {
    ReactGA.event({
      action: "Actualizar informacion cotizacion",
      category: "Garantias Certificados",
      label: "actualizar informacion",
    });

    dispatch(
      NewCertificateActions.setCurrentStep(NewCertificateModels.Step.ingresar)
    );
    dispatch(setCurrentQuoteId(fieldKey));
    setShowAddModal(true);
  }

  function handlerButtonGridDeleteOnClick(fieldKey: number) {
    ReactGA.event({
      action: "eliminar cotizacion",
      category: "Garantias Certificados",
      label: "eliminar cotizacion",
    });

    dispatch(setCurrentQuoteId(fieldKey));
    setShowDeleteModal(true);
  }

  const handlerClose = () => {
    setShowAddModal(false);
  };

  return (
    <div>
      {props.isLoading && (
        <div className="col-12">
          <MLoading modal={false} />
        </div>
      )}

      {!props.isLoading && (
        <div className="col-12 d-none d-md-block d-lg-block mb-5">
          {props.dataSource && props.dataSource.length > 0 && (
            <MGrid
              dataSource={props.dataSource}
              className="table tabla-cesion-01"
              keyExpr="id"
              showMoreSize={10}
            >
              <MGridColumn
                dataField="nroCertificado"
                label="Certificado"
              ></MGridColumn>
              <MGridColumn
                dataField="codigoExterno"
                label="Id Licitación"
              ></MGridColumn>
              <MGridColumn
                dataField="nombreOrganismoMandante"
                label="Mandante"
              ></MGridColumn>
              <MGridColumn
                dataField="finalidad"
                label="Finalidad"
              ></MGridColumn>
              <MGridColumn
                className="col"
                dataField="fechaTermino"
                label="Fecha de cierre"
                cellRender={(_k: any, value: any) => {
                  return <> {toDate(value)} </>;
                }}
              ></MGridColumn>
              <MGridColumn
                dataField="tipoMoneda"
                label="Moneda"
                cellRender={(k_: any, value: any): any => {
                  return <> {value} </>;
                }}
              ></MGridColumn>
              <MGridColumn
                dataField="montoCertificado"
                label="Monto"
                cellRender={(_k: any, value: any, row: any): any => {
                  return convertToCurrency(row.tipoMoneda, value);
                }}
              ></MGridColumn>
              {props.showDelete && (
                <MGridColumn
                  className="col"
                  label="Eliminar"
                  cellRender={(k: any, value: any) => {
                    return (
                      <>
                        <button
                          onClick={() => handlerButtonGridDeleteOnClick(k)}
                          className="btn px-3 colorButton"
                        >
                          <i
                            className="fas fa-trash-alt fs-5 deleteCertificate"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </>
                    );
                  }}
                ></MGridColumn>
              )}
              <MGridColumn
                className="col"
                label="Emitir CFT"
                cellRender={(k: any, value: any) => {
                  return (
                    <>
                      {
                        <button
                          onClick={() => handlerButtonGridAddOnClick(k)}
                          className="btn px-3 colorButton"
                        >
                          <i
                            className="fas fa-plus-circle fs-5 addCertificate"
                            aria-hidden="true"
                          ></i>
                        </button>
                      }
                    </>
                  );
                }}
              ></MGridColumn>
            </MGrid>
          )}
        </div>
      )}
      <div className="movil d-block d-md-none">
        {props.dataSource && props.dataSource.length > 0 && (
          <MShowMore
            size={3}
            children={props.dataSource.map((element: any) => (
              <div
                className="card-movil-tabla px-0 pb-0 shadow rounded"
                key={element.id}
              >
                <div className="detalles-movil-tabla">
                  <div className="col-12">
                    <h4>Código</h4>
                    <p>{element.nroCertificado}</p>
                  </div>
                  <div className="col-12">
                    <h4>Mandante</h4>
                    <p>{element.nombreOrganismoMandante}</p>
                  </div>
                  <div className="col-12">
                    <h4>Finalidad</h4>
                    <p>{element.finalidad}</p>
                  </div>
                  <div className="col-12">
                    <h4>Finalidad</h4>
                    <p>{element.finalidad}</p>
                  </div>
                  <div className="col-12">
                    <h4>Fecha cierre</h4>
                    <p>{toDate(element.fechaTermino)}</p>
                  </div>
                  <div className="col-12">
                    <h4>Moneda</h4>
                    <p>{element.tipoMoneda}</p>
                  </div>
                  <div className="col-12">
                    <h4>Monto</h4>
                    <p>
                      {convertToCurrency(
                        element.tipoMoneda,
                        element.montoCertificado
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="movil-tablas-garantias">
                    {props.isValidToday && (
                      <button
                        type="button"
                        className="btn btn-primary outline py-2 w-100 rounded btn-rounded"
                        aria-expanded="false"
                        onClick={() => handlerButtonGridAddOnClick(element.id)}
                      >
                        Emitir CFT
                      </button>
                    )}
                    {!props.isValidToday && (
                      <div className="btn-group w-100">
                        <button
                          type="button"
                          className="btn btn-primary sistema external py-2 w-100"
                          aria-expanded="false"
                          onClick={() =>
                            handlerButtonGridDeleteOnClick(element.id)
                          }
                        >
                          Eliminar
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary sistema outline external py-2 w-100"
                          aria-expanded="false"
                          onClick={() =>
                            handlerButtonGridAddOnClick(element.id)
                          }
                        >
                          Emitir CFT
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          />
        )}
      </div>
      <MAlertPopupDeleteQuote
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
      <MPopup
        showClose={true}
        setShowModal={setShowAddModal}
        showModal={showAddModal}
        size="lg"
      >
        <FormAddOperation close={handlerClose} AddToCart={props.AddToCart} />
      </MPopup>
    </div>
  );
}

export default CertificatesGrid;
