import React from "react";

export const BloquedEmit = () => {
  return (
    <section>
      <div className="alert alert-danger rounded-0" role="alert">
        <div className="container d-flex align-items-center">
          <div className="icon" style={{ marginRight: "20px" }}>
            <i
              className="fas fa-exclamation-circle"
              aria-hidden="true"
              style={{ fontSize: "36px" }}
            ></i>
          </div>
          <div>
            <h5 className="mb-1">Importante</h5>
            <p className="mb-1">
              Por el momento no podrás <strong>Emitir</strong> ni{" "}
              <strong>Reemitir</strong> garantías hasta que regularices tu
              situación con nuestro <strong>Servicio de Garantías</strong>
            </p>
            <p className="m-0">
              Para mayor información puedes contactarte con uno de nuestros
              ejecutivos llamando al{" "}
              <a href="tel:+56226569677">
                <b>+56 2 2656 9677</b>{" "}
              </a>{" "}
              o escríbenos a nuestro correo{" "}
              <a href="mailto:contacto@finfast.com">
                <b>contacto@finfast.com.</b>{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
