import {MAxios, MAxiosFormData} from "../conf";

const GetProvider = async () => {
    const endpoint = "Proveedor/Proveedor/GetProvider";

    const res = await MAxios().post(endpoint);

    return res.data;
};

const UpdateAddress = async (provider: any) => {
    const endpoint = "Proveedor/Proveedor/UpdateAddress";

    let formData = new FormData();
    formData.append("comuna", provider.comuna);
    formData.append("direccion", provider.direccion);
    formData.append("numeroDireccion", provider.numeroDireccion);
    formData.append("complementoDireccion", provider.complementoDireccion);
    formData.append("correoFacturacion", provider.correoFacturacion);

    const res = await MAxiosFormData().post(endpoint, formData);

    return res.data;
};

const GetVistaProveedorLinea = async () => {
    const endpoint = "VistaProveedorLineaAprobada/GetVistaProveedorLinea";

    const res = await MAxios().post(endpoint);

    return res.data;
}

const GetEstadoSubscripcionCte = async (rutProvider: string) => {
    const endpoint = `Proveedor/Proveedor/GetEstadoSubscripcionCte?rutProvider=${rutProvider}`;

    const res = await MAxios().get(endpoint);
    
    return res.data;
};

const ApiProvider = {
    GetProvider,
    UpdateAddress,
    GetVistaProveedorLinea,
    GetEstadoSubscripcionCte
};

export default ApiProvider;