import React, { useEffect, useState } from "react";
import ApiCertificates from "@api/certificados/Certificados";
import ApiDownloadCertificates from "@api/certificados/DownloadCertificados";
import { loadCurrentCertificates } from "@redux/actions/SessionActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { MLoadingLight, MTooltip } from "@inversiones-ma/finfast-react-controls";
import MLoading from "../../../../../controls/MLoading";
import MessageNotData from "../MessageNotData";
import CertificateReissue from "../../../Reissue/CertificateReissue";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import ApiReissue from "@api/reissue/Reissue";
import GenericBaseModel from "@api/GenericBaseModel";
import SuccessMessage from "../../../NewCertificate/partial/VerifyData/SuccessMessage";
import {
  BaseQueryPaginador,
  BaseResponsePaginador,
  Paginador,
} from "../../../../../../Models/Paginador";
import { Filtros, OperacionDTO } from "../../../../../../Models/Operation";
import { useForm } from "react-hook-form";
import VistaMovil from "./VistaMovil";
import VistaEscritorio from "./VistaEscritorio";
import { CertificatesFilters } from "../../CertificatesFilters";
import MessageCleanHistory from "../MessageCleanHistory";

interface Props {
  idOperationTypeDocument: number;
}

function CurrentCertificates(props: Props) {
  const { enabledEmit } = useAppSelector((store) => store.account);
  const { currentCertificates, lines } = useAppSelector(
    (store) => store.session
  );

  const arrIdFondosPermitidos: Array<number | null> = [5, null];
  const [reissue, setReissue] = useState<boolean>(false);
  const [cft, setCft] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);
  let [DownloadDocument, setDownloadDocument] = useState<boolean>(false);
  let [DownloadDocumentID, setDownloadDocumentID] = useState<string>("");
  let [DownloadInvoice, setDownloadInvoice] = useState<boolean>(false);
  let [DownloadReInvoice, setDownloadReInvoice] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [responseStatus, setResponseStatus] = useState<string>();
  const [paginadorData, setPaginadorData] = useState<Paginador<OperacionDTO>>();
  const [onChangeNumberPage, setOnChangeNumberPage] = useState<number>(1);
  const [onChangeNumberRecords, setOnChangeNumberRecords] =
    useState<number>(10);
  let [loadReissue, setLoadReissue] = useState<boolean>(false);
  let [reissueID, setReissueID] = useState<string>("");

  const [filters, setFilters] = useState<Filtros>({
    RutMandante: null,
    NroCertificado: null,
    IdLicitacion: null,
    Finalidad: null,
  });

  const dispatch = useAppDispatch();
  const form = useForm<Filtros>();

  function callLoadCurrentCertificates(Paginador: BaseQueryPaginador<Filtros>) {
    setIsLoadingData(true);
    ApiCertificates.ListCurrents(Paginador)
      .then((response: BaseResponsePaginador<OperacionDTO>) => {
        if (response) {
          setPaginadorData(response.paginador);
          setResponseStatus(response.status);
        }
      })
      .catch((error) => {
        console.error("error api ListCurrents: ", error);
      })
      .finally(() => {
        setIsLoadingData(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (isNaN(onChangeNumberRecords)) return;
    if (isNaN(onChangeNumberPage)) return;

    let QueryPaginador: BaseQueryPaginador<Filtros> = {
      page: onChangeNumberPage,
      pageSize: onChangeNumberRecords,
      Filtros: [{ ...filters }],
    };

    callLoadCurrentCertificates(QueryPaginador);
  }, [filters, onChangeNumberPage, onChangeNumberRecords]);

  function HandlerSubmit(data: Filtros) {
    setLoading(true);
    setFilters({ ...data });
    let QueryPaginador: BaseQueryPaginador<Filtros> = {
      page: 1,
      pageSize: 10,
      Filtros: [{ ...data }],
    };
    callLoadCurrentCertificates(QueryPaginador);
  }

  async function handlerButtonDonwnloadCft(fieldKey: string) {
    if (!DownloadDocument) {
      setDownloadDocument(true);
      setDownloadDocumentID(fieldKey);
      try {
        const response =
          await ApiDownloadCertificates.CallOperationDownloadCertificate(
            fieldKey
          );
        const blob = new Blob([response?.data]);

        if (blob.type === "text/plain") {
          return;
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "certificado_de_fianza_" + fieldKey + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error("Error al descargar el certificado:", error);
      } finally {
        setDownloadDocument(false);
        setDownloadDocumentID("");
      }
    }
  }

  async function handlerButtonDownloadInvoice(fieldKey: any) {
    if (!DownloadInvoice) {
      setDownloadInvoice((DownloadInvoice = true));
      setDownloadDocumentID((DownloadDocumentID = fieldKey));
      await ApiDownloadCertificates.callInvoiceDownload(fieldKey)
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "factura_" + fieldKey + ".pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadInvoice((DownloadInvoice = false));
          setDownloadDocumentID((DownloadDocumentID = ""));
        });
    }
  }

  async function handlerButtonDownloadExcelCurrents() {
    if (!downloadingExcel) {
      setDownloadingExcel(true);
      await ApiDownloadCertificates.callOperacionOperationDownloadListCurrents()
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "certificados_vigentes.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadingExcel(false);
        });
    }
  }

  async function handlerGridButtonReissueOnClick(fieldKey: string) {
    if (!loadReissue) {
      setLoadReissue((loadReissue = true));
      setReissueID((reissueID = fieldKey));

      await ApiReissue.GetOperationByCft(fieldKey).then(
        (response: GenericBaseModel<any>) => {
          if (response.success) {
            dispatch(NewCertificateActions.setTenderData(response.data));

            let stepReissue = {
              action: NewCertificateModels.ActionStepReissue.agregar,
              stepReissue: NewCertificateModels.StepReissue.seleccionar,
            } as NewCertificateModels.ReissueStep;

            dispatch(NewCertificateActions.setCurrentStepReissue(stepReissue));
            setCft(fieldKey);
            setReissue(true);
          }

          setLoadReissue((loadReissue = false));
          setReissueID((reissueID = ""));
        }
      );
    }
  }

  async function handlerButtonDonwnloadReInvoice(fieldKey: any, row: any) {
    if (!DownloadReInvoice) {
      setDownloadReInvoice((DownloadReInvoice = true));
      // setDownloadDocumentID((DownloadDocumentID = fieldKey));

      await ApiDownloadCertificates.DownloadReInvoice(row.id)
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "factura_reemision_" + fieldKey + ".pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadReInvoice((DownloadReInvoice = false));
          setDownloadDocumentID((DownloadDocumentID = ""));
        });
    }
  }

  async function hanlerSetReissue(field: string) {
    if (field !== "") {
      let newList = currentCertificates.filter(function (value) {
        return value.nroCertificado !== field;
      });

      dispatch(loadCurrentCertificates(newList));
      setShowSuccessMessage(true);
    }

    setReissue(false);
  }

  const handlerValidatePurpose = (
    row: any,
    fieldKey: string,
    iclass: string,
    btnClass: string,
    text: string | null
  ) => {
    let index = lines?.findIndex((x) => x.name === row.finalidad) as number;

    // Desabilita reemision si id fondo no es de patrimonio
    // if (!arrIdFondosPermitidos.includes(row.idFondo)) {
    //   return (

    //     <button className={`${btnClass} ${text && "disabled"}`}>
    //       <MTooltip
    //         iconClass={`${iclass} disabled`}
    //         trigger="hover"
    //         content={
    //           <h6>
    //             Para reemitir este certificado contacta a tu ejecutiva.
    //           </h6>
    //         }
    //       ></MTooltip>
    //     </button>
    //   );
    // }
    //
    if (index < 0 || !enabledEmit) {
      return (
        <button className={`${btnClass} ${text && "disabled"}`}>
          {text}
          <i className={`${iclass} disabled`} aria-hidden="true"></i>
        </button>
      );
    } else {
      return (
        <button
          className={btnClass}
          onClick={() => handlerGridButtonReissueOnClick(fieldKey)}
        >
          {text}
          {loadReissue && row.nroCertificado === reissueID ? (
            <div>
              <MLoadingLight />
            </div>
          ) : (
            <i className={iclass} aria-hidden="true"></i>
          )}
        </button>
      );
    }
  };

  return (
    <>
      {paginadorData && paginadorData?.records.length !== 0 && (
        <div className="row">
          <div className="boton-excel">
            <button
              type="button"
              className="col-12 col-lg-3 offset-lg-9  text-center  btn btn-primary outline sistema"
              onClick={handlerButtonDownloadExcelCurrents}
            >
              Descargar tabla en Excel
              {downloadingExcel && (
                <MLoadingLight style={{ marginLeft: "10px" }} />
              )}
              {!downloadingExcel && (
                <i
                  className="fas fa-file-excel"
                  style={{ marginLeft: "10px" }}
                ></i>
              )}
            </button>
          </div>
        </div>
      )}
      <div className="row">
        <div>
          {paginadorData ? (
            <>
              {paginadorData.records.length === 0 ? (
                responseStatus === "204" ? (
                  <CertificatesFilters
                    form={form as any}
                    onSubmit={HandlerSubmit}
                    loading={loading}
                  />
                ) : (
                  ""
                )
              ) : (
                <CertificatesFilters
                  form={form as any}
                  onSubmit={HandlerSubmit}
                  loading={loading}
                />
              )}

              {paginadorData && (
                <>
                  <div className="col-12 d-none d-md-block d-lg-block  pb-4">
                    <VistaEscritorio
                      paginadorData={paginadorData}
                      setOnChangeNumberPage={setOnChangeNumberPage}
                      setOnChangeNumberRecords={setOnChangeNumberRecords}
                      handlerButtonDownloadCft={handlerButtonDonwnloadCft}
                      handlerButtonDownloadInvoice={
                        handlerButtonDownloadInvoice
                      }
                      handlerValidatePurpose={handlerValidatePurpose}
                      DownloadDocument={DownloadDocument}
                      DownloadDocumentID={DownloadDocumentID}
                      DownloadInvoice={DownloadInvoice}
                      DownloadReInvoice={DownloadReInvoice}
                      handlerButtonDonwnloadReInvoice={
                        handlerButtonDonwnloadReInvoice
                      }
                      loading={isLoadingData}
                    />
                  </div>
                  <div className="movil d-block d-md-none  pb-4">
                    <VistaMovil
                      Paginador={paginadorData}
                      setOnChangeNumberPage={setOnChangeNumberPage}
                      setOnChangeNumberRecords={setOnChangeNumberRecords}
                      handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                      handlerButtonDownloadInvoice={
                        handlerButtonDownloadInvoice
                      }
                      handlerValidatePurpose={handlerValidatePurpose}
                      DownloadDocument={DownloadDocument}
                      DownloadDocumentID={DownloadDocumentID}
                      handlerGridButtonReissueOnClick={
                        handlerGridButtonReissueOnClick
                      }
                      handlerButtonDonwnloadReInvoice={handlerButtonDonwnloadReInvoice}
                      loading={loading}
                    />
                  </div>
                </>
              )}
              {responseStatus === "Succes" &&
                paginadorData.records.length === 0 && (
                  <MessageCleanHistory
                    title="¡Tu historial esta limpio!"
                    description="Actualmente no tienes ningún certificado Vigente."
                    buttonBuy={false}
                  />
                )}
              {responseStatus === "204" && (
                <MessageNotData
                  title="¡Ups! No hemos encontrado nada"
                  description=""
                  buttonBuy={false}
                />
              )}
              {/* {paginadorData.records.length === 0 && (
                <MessageCleanHistory
                  title="¡Tu historial esta limpio!"
                  description="Actualmente no tienes ningún certificado Vigente."
                  buttonBuy={false}
                />
              )} */}
            </>
          ) : (
            <div className="col-12">
              <MLoading modal={false} />
            </div>
          )}
        </div>
      </div>
      {reissue && (
        <CertificateReissue
          nroCft={cft}
          showModal={reissue}
          setShowModal={setReissue}
          hanlerSetReissue={(e) => hanlerSetReissue(e)}
        ></CertificateReissue>
      )}
      {/* <SuccessMessage
        showModal={showSuccessMessage}
        setShowModal={setShowSuccessMessage}
        close={() => setShowSuccessMessage(false)}
      /> */}
    </>
  );
}

export default CurrentCertificates;
