import {
  ErrorQuoteEmit,
  ValidateOperations,
} from 'Models/Operation';

import {
  LineInsuranceCarrier,
  Purpose,
  VistaProveedorLineaAprobada,
} from '@redux/models/SessionModels';

export const getValueToShow = (
  currency: string | undefined,
  uf: number | undefined,
  usd: number | undefined
): string | undefined => {
  if (!currency) return undefined;
  switch (currency) {
    case "2":
      return uf?.toString();
    case "3":
      return usd?.toString();
    default:
      return undefined;
  }
};

//emision
export const getValueToShowText = (
  currency: string | undefined,
  uf: number | undefined,
  usd: number | undefined
): string | undefined => {
  if (!currency) return undefined;
  switch (currency) {
    case "UF":
      return uf?.toString();
    case "USD":
      return usd?.toString();
    default:
      return undefined;
  }
};

export const calculateValueInCLP = (
  amount: number,
  currency: string | undefined,
  uf: number | undefined,
  usd: number | undefined
): string | undefined => {
  let value = amount;
  let valueToShow: string | undefined;
  switch (currency) {
    case "2":
      valueToShow = (uf! * value).toString();
      break;
    case "3":
      valueToShow = (usd! * value).toString();
      break;
    default:
      valueToShow = undefined;
      break;
  }

  return valueToShow;
};
//emision
export const calculateValueInCLPText = (
  amount: number,
  currency: string | undefined,
  uf: number | undefined,
  usd: number | undefined
): string | undefined => {
  let value = amount;
  let valueToShow: string | undefined;
  switch (currency) {
    case "UF":
      valueToShow = (uf! * value).toString();
      break;
    case "USD":
      valueToShow = (usd! * value).toString();
      break;
    default:
      valueToShow = undefined;
      break;
  }

  return valueToShow;
};

export const calculateDaysBetweenDates = (
  dateFrom: any,
  dateTo: any
): number | undefined => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(dateFrom);
  const secondDate = new Date(dateTo);
  const differenceMs = Math.abs(firstDate.getTime() - secondDate.getTime());
  const daysDifference = Math.floor(differenceMs / oneDay);
  if (isNaN(daysDifference)) {
    return undefined;
  }

  return daysDifference;
};

export const convertErrorType = (
  quoteData: ValidateOperations[]
): ErrorQuoteEmit[] => {
  let newAlert: ErrorQuoteEmit[] = [];
  quoteData.forEach((x) => {
    newAlert.push({
      errorType: x.errorType,
      mensaje: x.mensaje,
      descripcionError: x.descripcionError!,
      codigoError: x.codigoError,
      success: x.success,
    });
  });

  return newAlert;
};

export function sumar31Meses(
  fecha: Date,
  fechaTermino: Date,
  plazoMaxAprobado: number
): boolean {
  var fechaInicioObj = new Date(fecha);
  var fechaTerminoObj = new Date(fechaTermino);

  // Calcular la diferencia en meses
  var diferenciaEnMeses =
    (fechaTerminoObj.getFullYear() - fechaInicioObj.getFullYear()) * 12;
  diferenciaEnMeses += fechaTerminoObj.getMonth() - fechaInicioObj.getMonth();

  // Verificar si la diferencia es mayor o igual a 31 meses
  return diferenciaEnMeses > plazoMaxAprobado;
}

export function validarMontoCertVSLinea(
  montoCertificado: any,
  finalidad: string,
  purposes: Purpose[] | undefined,
  arrVistaProveedorLineaAprobada: VistaProveedorLineaAprobada[] | undefined,
  linesInsurance: LineInsuranceCarrier[] | undefined // Modificado aquí
) {
  let montoNoUtilizado = 0;
  let montoDisponibleInsurance = 0;
  let indexPurposeSelected = purposes
    ? purposes.findIndex((x) => x.name === finalidad)
    : 0;
  if (arrVistaProveedorLineaAprobada) {
    montoNoUtilizado = arrVistaProveedorLineaAprobada[indexPurposeSelected].montoLineaNoUtilizado;
    if (linesInsurance?.length  !== 0) {

      montoDisponibleInsurance = linesInsurance![0].montoDisponible;
    }
  }
  return montoCertificado <= (montoNoUtilizado + montoDisponibleInsurance);
}

