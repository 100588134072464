import { MAxios, MAxiosFormData } from "../conf";
import GenericBaseModel from "../GenericBaseModel";

export interface ValidateProducts {
  idProducto: number;
  nombreProducto: string;
  descripcion: string;
  descripcion2: string;
  statusMatriz: string;
  informacionAdicional: string;
  statusCliente: boolean;
  statusContract: string;
  statusActivation: string;
  textButton: string;
  actionButton: number | null;
  outlineButton: boolean;
  enabledButton: boolean;
  url: string;
  urlLocalhost: string;
  urlQa: string;
  saleText: string;
  extraText: string;
  isClient: boolean;
  hasContract: boolean;
  hasMovs: boolean;
  montoLineaAprobado: number;
}

export interface EnabledProducts extends GenericBaseModel<ValidateProducts> {}
// eslint-disable-next-line
export const ValidateProducts = async (): Promise<EnabledProducts> => {
  const endpoint = "membresia/EnabledProducts/ValidateProducts";

  const res = await MAxios().post(endpoint);

  return res.data as EnabledProducts;
};

export const GetProductStatus = async (
  idProducto: number
): Promise<EnabledProducts> => {
  const endpoint = "membresia/EnabledProducts/GetProductStatus?idProducto=";

  const res = await MAxios().get(`${endpoint}${idProducto}`);

  return res.data as EnabledProducts;
};

export const GetOneUseToken = async (token: string) => {
  let formData = new FormData();
  formData.append("token", token);
  const endpoint = "membresia/AccountProducto/GetOneUseToken";

  const res = await MAxios().post(endpoint, formData);
  return res.data;
};

const ApiMembresiaEnabledProducs = {
  ValidateProducts,
  GetProductStatus,
  GetOneUseToken
};

export default ApiMembresiaEnabledProducs;
