import React, { useEffect, useState } from "react";
import { OriginIncome, ValidateOperations } from "Models/Operation";
import { convertErrorType } from "@helpers/QuoteEmit";
import ErrorAlert from "../ErrorAlerts/ErrorAlert";

interface PropsHeadCard {
  resultComission: ValidateOperations[];
  origen: OriginIncome;
}

/**
 * Titulo y bajada card
 */
const HeadCard = ({ resultComission, origen }: PropsHeadCard) => {
  const [error, setError] = useState(<></>);
  const [tittle, setTittle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  debugger;

  useEffect(() => {
    if (resultComission?.length > 0) {
      setError(<ErrorAlert quoteData={convertErrorType(resultComission)} />);
    }

    let newTitle = "";
    let newSubtitle = "";
    //let a = !resultComission.some(x => x.success);
    if (resultComission?.length === 1 && origen === "newQuote") {
      newTitle = "Resultado";
      newSubtitle =
        "Aquí podrás visualizar la Garantía disponible para emitir.";
    } else if (resultComission?.length === 1 && origen === "newCerificate") {
      newTitle = "Garantía";
      newSubtitle =
        "Aquí podrás visualizar la Garantía disponible para emitir.";
    } else if (resultComission?.length > 1 && origen === "newQuote") {
      newTitle = "Resultados";
      newSubtitle =
        "Aquí podrás visualizar y seleccionar el tipo de Garantía que necesitas para cubrir tu licitación.";
    } else if (resultComission?.length > 1 && origen === "newCerificate") {
      newTitle = "Garantía";
      newSubtitle =
        "Aquí podrás visualizar y seleccionar el tipo de Garantía que necesitas para cubrir tu licitación.";
    } else {
      newTitle = "Garantía seleccionada";
      newSubtitle = "";
    }

    setTittle(newTitle);
    setSubtitle(newSubtitle);
  }, [resultComission, origen]);

  return (
    <>
      {origen === "newQuote" && (
        <>
          <h2 className="titles-center fs-24">{tittle}</h2>
          <p className="text-center">{subtitle}</p>
        </>
      )}
      {origen === "newCerificate" && (
        <>
          <h3 className="subtitle-mini">{tittle}</h3>
          <p className="text-left">{subtitle}</p>
        </>
      )}
      {(origen === "verifyOperation" ||
        origen === "cart" ||
        origen === "addQuote") && <h3 className="subtitle-mini">{tittle}</h3>}

      <div className="col-12">
        <div className="container mb-2">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-sm-10">{error}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadCard;
