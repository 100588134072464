import React, { useState } from "react";

function SectionSelectData({
  nombreOrganismoMandante,
  rutUnidadMandante,
  regionUnidadMandante,
  comunaUnidadMandante,
  direccionUnidadMandante,
  showNombreOrganismoMandante,
  showRutUnidadMandante,
  showRegionUnidadMandante,
  showComunaUnidadMandante,
  showDireccionUnidadMandante,
  handlerButtonContinue,
}: PropsSectionSelectData) {
  const [checkNombreOrganismoMandante, setCheckNombreOrganismoMandante] =
    useState<boolean>(false);
  const [checkRutUnidadMandante, setCheckRutUnidadMandante] =
    useState<boolean>(false);
  const [checkRegionUnidadMandante, setCheckRegionUnidadMandante] =
    useState<boolean>(false);
  const [checkComunaUnidadMandante, setCheckComunaUnidadMandante] =
    useState<boolean>(false);
  const [checkDireccionUnidadMandante, setCheckDireccionUnidadMandante] =
    useState<boolean>(false);

  const SelectDataCheckElement = ({
    id,
    value,
    name,
    state,
    setState,
  }: PropsSelectDataCheckElement) => (
    <div className="col-12 col-md-6 sm-2">
      <div
        className={
          state
            ? " rounded-card-bg lborder-primary cursor-pointer SelectedCard"
            : "rounded-card-bg lborder-primary cursor-pointer"
        }
        onClick={() => {
          setState(!state);
        }}
      >
        <div className="floating-label">
          <div className="input-group">
            <div className="col-10">
              <p className="textolabel03">
                <span className="textolabel02">{name}</span>
                <br />
                {value}
              </p>
            </div>
            <div className="col-2 my-auto">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer"
                  id={id}
                  checked={state}
                  onChange={(event) => {
                    setState(event.target.checked);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="row">
      <div className="col-12">
        <div className="rounded-card-bg p-4">
          <div className="row">
            <div className="col-12">
              <h3 className="subtitle-mini">
                Selecciona los datos que deseas mantener.
              </h3>
              <p>
                Revisa que los datos estén correctos y seleccionalos para
                agregarlos a tu Garantía. Podrás editar los datos.
              </p>
            </div>
            {showRutUnidadMandante && (
              <SelectDataCheckElement
                id="checkRutMandante"
                value={rutUnidadMandante}
                name="RUT mandante"
                state={checkRutUnidadMandante}
                setState={setCheckRutUnidadMandante}
              />
            )}
            {showNombreOrganismoMandante && (
              <SelectDataCheckElement
                id="checkNombreMandante"
                value={nombreOrganismoMandante}
                name="Nombre mandante"
                state={checkNombreOrganismoMandante}
                setState={setCheckNombreOrganismoMandante}
              />
            )}
            {showRegionUnidadMandante && (
              <SelectDataCheckElement
                id="checkRegionMandante"
                value={regionUnidadMandante}
                name="Región"
                state={checkRegionUnidadMandante}
                setState={setCheckRegionUnidadMandante}
              />
            )}
            {showComunaUnidadMandante && (
              <SelectDataCheckElement
                id="checkComunaMandante"
                value={comunaUnidadMandante}
                name="Comuna"
                state={checkComunaUnidadMandante}
                setState={setCheckComunaUnidadMandante}
              />
            )}
            {showDireccionUnidadMandante && (
              <SelectDataCheckElement
                id="checkDireccionMandante"
                value={direccionUnidadMandante}
                name="Dirección"
                state={checkDireccionUnidadMandante}
                setState={setCheckDireccionUnidadMandante}
              />
            )}
          </div>
          <div className="row justify-content-center">
            <div className="col-md-3 pt-3">
              <button
                type="button"
                className="btn btn-primary sistema w-100"
                onClick={() => {
                  handlerButtonContinue({
                    checkNombreOrganismoMandante,
                    checkRutUnidadMandante,
                    checkRegionUnidadMandante,
                    checkComunaUnidadMandante,
                    checkDireccionUnidadMandante,
                  });
                }}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSelectData;

interface PropsSectionSelectData {
  nombreOrganismoMandante: string;
  rutUnidadMandante: string;
  regionUnidadMandante: string;
  comunaUnidadMandante: string;
  direccionUnidadMandante: string;
  showNombreOrganismoMandante: boolean;
  showRutUnidadMandante: boolean;
  showRegionUnidadMandante: boolean;
  showComunaUnidadMandante: boolean;
  showDireccionUnidadMandante: boolean;
  handlerButtonContinue: any;
}

interface PropsSelectDataCheckElement {
  id: string;
  value: string;
  name: string;
  state: boolean;
  setState: any;
}
