import './EnterData.scss';

import React, { useState } from 'react';

import { OriginIncome } from 'Models/Operation';
import { useForm } from 'react-hook-form';

import { MPopup } from '@inversiones-ma/finfast-react-controls';
import { useAppSelector } from '@redux/hooks';
import * as NewCertificateModels from '@redux/models/NewCertificateModels';

import AlertErrorReemision from './AlertErrorReemision';
import Breadcrumbs from './Breadcrumbs';
import DuplicateCft from './EnterData/DuplicateCft';
import EnterDataForm from './EnterData/EnterDataForm';
import PrincipalError from './EnterData/PrincipalError';
import TitleForm from './EnterData/TitleForm';
import { RestrictedMandante } from './Modals/RestrictedMandante';

interface IPropsEnterData {
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
  close?: any;
  showReturn?: boolean;
  Data?: any;
}

function EnterData(props: IPropsEnterData) {
  const { selectedData, tenderData, newCertificateFromQuote } = useAppSelector(
    (store) => store.newCertificate
  );

  //modal
  const [errorDuplicateCft, setErrorDuplicateCft] = useState<boolean>(false);
  const [showModalRestricted, setShowModalRestricted] =
    useState<boolean>(false);
  const [errorValidMandante, setErrorValidMandate] = useState<boolean>(false);

  const defaultData = {
    ...selectedData,
    ...tenderData,
    ...newCertificateFromQuote,
  };
  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: defaultData as any,
  });

  return (
    <>
      <div className="row">
        {props.addOrEdit === NewCertificateModels.Step.ingresar &&
          props.origen !== "cart" &&
          props.origen !== "addQuote" && (
            <Breadcrumbs
              paginaPrincipal="Comprar Garantías"
              paginaActual="Nueva Garantía"
              paginaInicial="/buy-certificate"
            ></Breadcrumbs>
          )}
      </div>
      <TitleForm addOrEdit={props.addOrEdit} origen={props.origen} />

      <AlertErrorReemision />

      <EnterDataForm
        form={form}
        setErrorDuplicateCft={setErrorDuplicateCft}
        setErrorValidMandate={setErrorValidMandate}
        setShowModalRestricted={setShowModalRestricted}
        addOrEdit={props.addOrEdit}
        origen={props.origen}
        close={props.close}
      />

      {showModalRestricted && (
        <RestrictedMandante
          setShowModal={setShowModalRestricted}
          showModal={showModalRestricted}
        />
      )}
      <MPopup
        size="md"
        showClose={true}
        showModal={errorValidMandante}
        setShowModal={setErrorValidMandate}
      >
        <PrincipalError
          setShowModal={setErrorValidMandate}
          showModal={errorValidMandante}
        ></PrincipalError>
      </MPopup>
      <MPopup
        size="md"
        showClose={true}
        showModal={errorDuplicateCft}
        setShowModal={setErrorDuplicateCft}
      >
        <DuplicateCft
          setShowModal={setErrorDuplicateCft}
          showModal={errorDuplicateCft}
        ></DuplicateCft>
      </MPopup>
    </>
  );
}

export default EnterData;
