import React from 'react';
import { MPopup, MButton } from '@inversiones-ma/finfast-react-controls';
import iconFilterDelete from "@img/icon-filter-delete.svg";

interface PropsSuccessMessage {
  showModal: boolean
  , setShowModal: any
}

function DeleteMessage({ showModal, setShowModal }: PropsSuccessMessage) {

  return (
    <MPopup
      size="md"
      showModal={showModal}
      setShowModal={setShowModal}
      showClose={true}
    >
      <div className="text-center">
        <img
          src={iconFilterDelete}
          alt="datos confirmados"
          className="icon-pop-up-el"
        />

        <h4 className="titles-center">Filtro eliminado</h4>
        <h6 className="py-3">Tu filtro ha sido eliminado exitosamente</h6>

        
          <MButton onClick={() => setShowModal(false)} type="button" className="btn btn-primary sistema">Entendido</MButton>
       
      </div>
    </MPopup>

  );
};

export default DeleteMessage;
