import React from "react";
import {
  MAlert,
  MCards,
  MInputDate,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import ApiInsurance from "@api/insurance/Insurance";
import { CreateOperation } from "Models/Operation";
import InfoInsurance from "../../Cards/InfoInsurance";

interface props {
  setValidationContractStartDate: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  setContractStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  infoCommission: CreateOperation;
}

const ValidationContractStartDate = (props: props) => {
  const form = useForm();
  //const { handleSubmit } = form;
  const currentDay = new Date();

  const validateContractDate = async (date: any) => {
    await ApiInsurance.ValidationContractStartDate(
      date as string,
      props.infoCommission.fechaInicio as string
    )
      .then((resp: boolean) => {
        props.setValidationContractStartDate(resp);
        props.setContractStartDate(date);
      })
      .catch((e) => console.error(e));
  };

  return (
    // <MCards
    //   className="nonBorder p-3"
    //   size="default"
    //   style={{ boxShadow: "0px 0px 0px" }}
    // >
    <form action="">
      <div className="px-3 pt-0">
        <div className="row justify-content-center">
          <div className="col-md-8">
            {
              window.innerWidth > 991 && <InfoInsurance />

              // <MAlert color="info" className="slim py-2">
              //   <span>Todas las <b>pólizas SURA</b> serán <b>visualizadas en UF en la Garantía emitida,</b> independiente del tipo de moneda que sea elegido.</span>
              // </MAlert>
            }
            <h3 className="subtitle-mini">Datos Póliza</h3>
            <div className="mb-2">
              <div className="row">
                <div className="col-lg-8 col-sm-10">
                  <MInputDate
                    form={form}
                    name="dateFrom"
                    label="Fecha inicio de contrato"
                    min={currentDay}
                    calendarPopperPosition="auto"
                    onChange={(e) => validateContractDate(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    // </MCards>
  );
};

export default ValidationContractStartDate;
