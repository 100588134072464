import { createSlice } from "@reduxjs/toolkit";
import { loadStepData, cleanState } from '../actions/StepActions';
import { SessionStep } from '../models/StepModels';

const inicialState: SessionStep = {
  stepState: { step: 1, subStep: undefined },
};

const slice = createSlice({
    name: 'step'
    , initialState: inicialState
    , reducers: {}
    , extraReducers: builder => {
        builder.addCase(cleanState, (state) => {
            state.stepState = inicialState.stepState
        })
        .addCase(loadStepData, (state, action) => {            
            state.stepState = action.payload;
        })
        
        .addDefaultCase((_state) => { })
    }
});

export default slice.reducer;