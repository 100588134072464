import React, { useState } from "react";

export const useCollapseOrHandler = () => {
    const [stateCollapse, setstateCollapse] = useState<string>("");

    const handlerClick = (
        newStateCollapse: string,
        index: number,
        e?: React.MouseEvent<HTMLElement>
    ) => {
        e?.preventDefault();
        let statecollapse = `${newStateCollapse}${index}`;
        if (stateCollapse === statecollapse) {
            setstateCollapse("");
        } else {
            setstateCollapse(statecollapse);
        }
    };
    return {
        handlerClick,
        stateCollapse,
    }
};
