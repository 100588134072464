import React from "react";
import { MPopup } from "@inversiones-ma/finfast-react-controls";
import checkImage from "@img/eliminar-licitaciones-icon.svg";
import ApiOperation from "@api/garantias/operation/Operation";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { setLoadSecondDataGrid } from "@redux/actions/QuoteActions";
import ReactGA from "react-ga4";

interface PropsMAlertPopupDeleteQuote {
  showModal: boolean;
  setShowModal: any;
}

const MAlertPopupDeleteQuote = (props: PropsMAlertPopupDeleteQuote) => {
  const dispatch = useAppDispatch();

  const { currentQuoteId } = useAppSelector((store) => store.quote);
  const { secondGridData } = useAppSelector((store) => store.quote);

  function handleDeleteOperation() {
    ApiOperation.DeleteOperation(currentQuoteId).then(() => {
      const secondGridDataFilter = secondGridData.filter(
        (c) => c.id !== currentQuoteId
      );
      dispatch(setLoadSecondDataGrid(secondGridDataFilter));
      props.setShowModal(false);
      ReactGA.event({
		action: "Eliminar cotización",
        category: "Cotizaciones",
        label: "Se ha eliminado una cotización",        
      });
    });
  }

  return (
    <MPopup
      size="lg"
      showClose={false}
      showModal={props.showModal}
      setShowModal={props.setShowModal}
    >
      <div className="exitmodal01">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => props.setShowModal(false)}
        ></button>
      </div>
      <div className="msg-validation-matriz error">
        <img
          src={checkImage}
          alt="Eliminar una cotización"
          className="icon-pop-up-el"
        />
        <h4 className="titles-center">
          Eliminar certificado <br />
          de cotización
        </h4>
        <p className="py-3">Esta acción es permanente y no se puede deshacer</p>
        <div className="d-flex flex-column flex-md-row justify-content-md-center">
          <button
            className="btn btn-primary sistema m-2 py-2 px-4"
            onClick={handleDeleteOperation}
          >
            Eliminar
          </button>
          <button
            className="btn btn-primary sistema outline m-2 py-2 px-4"
            onClick={() => props.setShowModal(false)}
          >
            Cancelar
          </button>
        </div>
      </div>
    </MPopup>
  );
};

export default MAlertPopupDeleteQuote;
