import imgGarantiasQuestions from "@img/icon-garantias-preguntas.svg";
import imgPagosQuestions from "@img/icon-compra-pagos-preguntas.svg";
import imgGeneralQuestions from "@img/icon-general-preguntas.svg";

export const arrCategory = [
  {
    id: "1",
    description: "Garantías",
    categoryCode: "garantias",
    img: imgGarantiasQuestions,
  },
  {
    id: "2",
    description: "Compras y pagos",
    categoryCode: "comprasypagos",
    img: imgPagosQuestions,
  },
  {
    id: "3",
    description: "General",
    categoryCode: "general",
    img: imgGeneralQuestions,
  },
];

export const arrFaqs = [
  {
    id: "1",
    question: `Tipo de garantías que puedes emitir con tu línea`,
    answer: `
    Podrás cubrir garantías para finalidades de Seriedad de la Oferta, Fiel Cumplimiento, Correcta ejecución y Anticipo, éste último deberá ser solicitado a tu ejecutiva asignada.
        `,
    categoryCode: "garantias",
  },
  {
    id: "2",
    question: `Monto máximo por certificado`,
    answer: `
    El monto máximo por certificado es informado al momento de firmar el contrato y aparecerá disponible en la plataforma.
        `,
    categoryCode: "garantias",
  },
  {
    id: "3",
    question: `Plazo máximo de cada certificado de fianza emitido`,
    answer: `
    Para las empresas clasificadas como estándar (todas aquellas que no pertenecen a rubro de la construcción) los certificados no poseen plazo máximo. Para las empresas de construcción el plazo es de 31 meses, siendo posible ampliarlo. En caso de que necesites ampliar este plazo, debes realizar la solicitud con tu ejecutiva asignada.
        `,
    categoryCode: "garantias",
  },
  {
    id: "4",
    question: `Fecha de caducidad del certificado`,
    answer: `
    El día ingresado como término del certificado es la fecha de caducidad. Tienes la posibilidad de solicitar la ampliación del plazo a través de una reemisión si así lo requieres.
        `,
    categoryCode: "garantias",
  },
  {
    id: "5",
    question: `Reemisión – Modificar Certificado`,
    answer: `
    Llamamos reemisión a las modificaciones solicitadas por el cliente sobre un certificado ya emitido. Se puede modificar: finalidad, plazo, dirección del mandante, rut mandante, nombre mandante, dirección cliente y monto. Estos cambios tienen un costo asociado. 
        `,
    categoryCode: "garantias",
  },
  {
    id: "6",
    question: `Medios de pago`,
    answer: `
    Los medios de pago por los cuales podrás pagar tus certificados son: WEBPAY, SERVIPAG, KIPHU, KIPHU (PAGO POR TERCEROS).
        `,
    categoryCode: "comprasypagos",
  },
  {
    id: "7",
    question: `Problemas con el pago del certificado`,
    answer: `
    Deberás contactarte con tu ejecutiva asignada, quien te ayudará a resolverlo.
        `,
    categoryCode: "comprasypagos",
  },
  {
    id: "8",
    question: `Cobro duplicado de un certificado`,
    answer: `
    Para poder solicitar la devolución del dinero o la nota de crédito en caso de que ya se haya facturado, deberás contactarte con tu ejecutiva, quien te ayudará a resolver este punto. 
        `,
    categoryCode: "comprasypagos",
  },
  {
    id: "9",
    question: `Facturación`,
    answer: `
    La facturación se realizará de manera electrónica, se enviará una copia a la casilla de correo registrada en tus datos de contacto.
        `,
    categoryCode: "comprasypagos",
  },
  {
    id: "10",
    question: `Actualización de datos personales`,
    answer: `
    Ingresando a Mi Cuenta/ Mis datos, podrás editar la información sobre dirección, contacto y cambiar contraseña. 
        `,
    categoryCode: "general",
  },
  {
    id: "11",
    question: `Vigencia de Contrato`,
    answer: `
    El Contrato de Garantía Recíproca tendrá duración indefinida a partir de la fecha en que se suscribe. No obstante, MULTIAVAL, también denominada FINFAST, podrá poner término anticipado al mismo en forma unilateral, a través de una comunicación escrita dirigida al Beneficiario, que se enviará al domicilio indicado en este instrumento, a su correo electrónico o comunicándolo directamente mediante la plataforma web. Se procederá a bloquear el acceso a su cuenta, a sola elección de MULTIAVAL.  
        `,
    categoryCode: "general",
  },
  {
    id: "12",
    question: `Solicitud de aumento de mi línea para emitir`,
    answer: `
    Deberás contactar a tu ejecutiva para generar la solicitud, quien te informará los requisitos para acceder al mismo.
        `,
    categoryCode: "general",
  },
  {
    id: "13",
    question: `Búsqueda de Licitaciones publicadas en Mercado Público`,
    answer: `
    Puedes generar filtros según tus necesidades y así acotar la búsqueda de licitaciones de acuerdo con tu interés. Esta lista de licitaciones llegará diariamente a los correos indicados.
        `,
    categoryCode: "general",
  },
];
