import React, { useEffect, useState } from "react";
import WarrantiesCardMobile from "./WarrantiesCardMobile";
import { OriginIncome, ValidateOperations } from "Models/Operation";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import WarrantiesCard from "./WarrantiesCard";

interface PropsMainCard {
  quoteData: ValidateOperations[];
  handlerSelect: (element: ValidateOperations) => void;
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
  warantySelected: ValidateOperations | undefined;
}

const MainCard = ({
  quoteData,
  handlerSelect,
  addOrEdit,
  origen,
  warantySelected,
}: PropsMainCard) => {
  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [filterQuote, setFilterQuote] = useState<ValidateOperations[]>([]);

  const viewquote = addOrEdit === NewCertificateModels.Step.cotizar;
  debugger;
  useEffect(() => {
    let quote: ValidateOperations[] = [];
    if (!viewquote) {
      quote = (quoteData?.filter((element) => element.success));
    } else {
      quote = quoteData;
    }

    setFilterQuote(quote);

  }, [viewquote]);

  useEffect(() => {
    const handleResize = () => {
      setResolution({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    // Limpia el listener del evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="col-12">
        <div className="container ">
          <div className="row justify-content-center">
            {resolution.width > 991 ? (
              <WarrantiesCard
                quoteData={filterQuote}
                handlerSelect={(e: ValidateOperations) => handlerSelect(e)}
                addOrEdit={NewCertificateModels.Step.ingresar}
                origen={origen}
                warantySelected={warantySelected}
              />
            ) : (
              <WarrantiesCardMobile
                quoteData={filterQuote}
                handlerSelect={(e) => handlerSelect(e)}
                addOrEdit={NewCertificateModels.Step.ingresar}
                origen={origen}
                warantySelected={warantySelected}               
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainCard;
