import { createAction } from "@reduxjs/toolkit";
import {
  NewCertificateFromQuote,
  ReissueStep,
  SelectedData,
  Step,
} from "../models/NewCertificateModels";
import { OperacionDTO, ValidateOperations } from "Models/Operation";

export const cleanState = createAction("SESSION_CLEAN_STATE");

export const setSelectedData = createAction(
  "SESSION_NEW_CERTIFICATE_SET_SELECTED_DATA",
  (selectedData?: SelectedData) => {
    return {
      payload: selectedData,
    };
  }
);

export const setCurrentStep = createAction(
  "SESSION_NEW_CERTIFICATE_SET_CURRENT_STEP",
  (step: Step) => {
    return {
      payload: step,
    };
  }
);

export const setTenderData = createAction(
  "SESSION_NEW_CERTIFICATE_SET_TENDER_DATA",
  (tenderData: OperacionDTO | undefined) => {
    return {
      payload: tenderData,
    };
  }
);

export const setTenderDataWithoutOriginal = createAction(
  "SESSION_NEW_CERTIFICATE_SET_TENDER_DATA_WITHOUT_ORIGINAL",
  (tenderData: any) => {
    return {
      payload: tenderData,
    };
  }
);

export const setTenders = createAction(
  "SESSION_NEW_CERTIFICATE_SET_TENDERS",
  (tenders: any[]) => {
    return {
      payload: tenders,
    };
  }
);

export const setQuote = createAction(
  "SESSION_NEW_CERTIFICATE_SET_QUOTE",
  (quote: NewCertificateFromQuote | undefined) => {
    return {
      payload: quote,
    };
  }
);
export const cleanQuote = createAction("SESSION_NEW_CERTIFICATE_CLEAN_QUOTE");

export const setCurrentStepReissue = createAction(
  "SESSION_NEW_CERTIFICATE_SET_CURRENT_STEPREISSUE",
  (stepReissue: ReissueStep) => {
    return {
      payload: stepReissue,
    };
  }
);

export const setValueCommissionSelected = createAction(
  "SESSION_NEW_CERTIFICATE_SET_VALUE_COMMISSION_SELECTED",
  (commissionSelected: ValidateOperations[]) => {
    return {
      payload: commissionSelected,
    };
  }
);
