import {
  cleanState,
  setInsurancePolice,
} from "../actions/InsuranceLineActions";
import { createSlice } from "@reduxjs/toolkit";
import { InsuranceLineState } from "../models/InsuranceLineModels";

const inicialState: InsuranceLineState = {
  aseguradora: [],
};

const slice = createSlice({
  name: "InsuranceLine",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cleanState, (state) => {
        state.aseguradora = inicialState.aseguradora;
      })
      .addCase(setInsurancePolice, (state, action) => {
        state.aseguradora = action.payload;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
