export interface SessionStep {
  stepState: StepState;
}

export interface StepState {
  step: number;
  subStep: StepPay | undefined;
}

export enum StepPay {
  errorPay = "error",
  successPay = "ok",
}
