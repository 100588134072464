import React, { useState } from "react";
import CurrentCertificates from "./CurrentCertificates/CurrentCertificates";
import CanceledCertificates from "./CanceledCertificates/CanceledCertificates";
import AccidentCertificates from "./AccidentCertificates/AccidentCertificates";
import ExpiredCertificates from "./ExpiredCertificates/ExpiredCertificates";
import { MInputSelect } from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import { enumOperationTypeDocument } from "@app/enumerator";
import CurrentCertificatesPolicy from "./CurrentCertificates/CurrentCertificatesPolicy";
import ExpiredCertificatesPolicy from "./ExpiredCertificates/ExpiredCertificatesPolicy";
import PendingCertificatesPolicy from "./PendingCertificates/PendingCertificatesPolicy";

interface Props {
  idOperationTypeDocument: number;
  title: string;
  tabsDefinition: { id: string; descripcion: string }[];
}
function MyCertificateContainer(props: Props) {
  const [tipoCertficates, setTipoCertificates] = useState("VIGENTES");
  const classNameTab =
    props.tabsDefinition.length === 4 ? "col-md-6" : "col-md-3";

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const { handleSubmit, setValue } = form;

  const handlerCambioEstadoMInputSelect = (data: any) => {
    if (data.estados === "") {
      setValue("estados", "VIGENTES");
    }
    setTipoCertificates(data.estados);
  };

  const handlerCambioEstado = (estado: string) => {
    setValue("estados", estado);
    setTipoCertificates(estado);
  };

  return (
    <>
      <div className="fs-18 color-secondary fw-bold p-0 mt-4 m-0 mb-3">
        {props.title}
      </div>
      <div className="generictab">
        <div
          className={`col-12 ${classNameTab} d-none d-sm-none d-md-block tabs-02`}
        >
          <ul
            style={{ borderRadius: "5px" }}
            className="nav nav-tabs02"
            id="myTab"
            role="tablist"
          >
            {props.tabsDefinition.map((e, index) => (
              <li
                key={index}
                className="nav-item col-md factura"
                role="presentation"
              >
                <button
                  className={
                    "nav-link" + (e.id === tipoCertficates ? " active" : "")
                  }
                  type="button"
                  role="tab"
                  onClick={() => handlerCambioEstado(e.id)}
                >
                  {e.descripcion}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {
        <div className="movil d-block d-md-none">
          <div className="col-12 offset-lg-1 col-lg-10">
            <div className="row buscador-tablas02">
              <div className="col-12 col-md-4 px-md-0">
                <MInputSelect
                  form={form}
                  className="form-select"
                  name="estados"
                  disableNoData
                  items={props.tabsDefinition}
                  valueItem="id"
                  label="Ver por"
                  descriptionItem="descripcion"
                  onChange={handleSubmit(handlerCambioEstadoMInputSelect)}
                />
              </div>
            </div>
          </div>
        </div>
      }
      {props.idOperationTypeDocument === enumOperationTypeDocument.Operation &&
        tipoCertficates === "VIGENTES" && (
          <CurrentCertificates
            idOperationTypeDocument={props.idOperationTypeDocument}
          />
        )}
      {props.idOperationTypeDocument === enumOperationTypeDocument.Operation &&
        tipoCertficates === "CADUCOS" && (
          <ExpiredCertificates
            idOperationTypeDocument={props.idOperationTypeDocument}
          />
        )}
      {props.idOperationTypeDocument === enumOperationTypeDocument.Operation &&
        tipoCertficates === "SINIESTRADOS" && (
          <AccidentCertificates
            idOperationTypeDocument={props.idOperationTypeDocument}
          />
        )}
      {props.idOperationTypeDocument === enumOperationTypeDocument.Operation &&
        tipoCertficates === "ANULADOS" && (
          <CanceledCertificates
            idOperationTypeDocument={props.idOperationTypeDocument}
          />
        )}
      {props.idOperationTypeDocument === enumOperationTypeDocument.Policy &&
        tipoCertficates === "PENDIENTES" && (
          <PendingCertificatesPolicy
            idOperationTypeDocument={props.idOperationTypeDocument}
          />
        )}
      {props.idOperationTypeDocument === enumOperationTypeDocument.Policy &&
        tipoCertficates === "VIGENTES" && (
          <CurrentCertificatesPolicy
            idOperationTypeDocument={props.idOperationTypeDocument}
          />
        )}
      {props.idOperationTypeDocument === enumOperationTypeDocument.Policy &&
        tipoCertficates === "CADUCOS" && (
          <ExpiredCertificatesPolicy
            idOperationTypeDocument={props.idOperationTypeDocument}
          />
        )}
    </>
  );
}

export default MyCertificateContainer;
