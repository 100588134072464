import React, { useState, useEffect } from "react";
import { TagBox } from "devextreme-react/tag-box";
// @ts-ignore
import { rutFormat } from "rutfunctions";

function MTagBox({
  name,
  placeholder,
  className,
  form,
  maxItems,
  maxLength,
  validate,
  isRut,
  acceptCustomValue,
  value,
  dataSource,
  valueExpr,
  displayExpr,
  label,
}: any) {
  const { register, errors, setValue, setError, clearErrors, watch } = form;
  const [textPlaceHolder, setTextPlaceHolder] = useState<string>(placeholder);
  const [internalValue, setInternalValue] = useState(value ? value : []);
  const valueInForm = watch(name);

  const onValueChanged = (e: any) => {
    if (maxItems && e.value.length > maxItems) {
      const newValue = e.value.slice(0, maxItems);
      setInternalValue(newValue);
      return;
    }

    if (errors?.[name]) {
      setInternalValue(e.value.slice(0, e.value.length - 1));
      return;
    }

    setInternalValue(e.value);
    let tempValue = Array.isArray(e.value) && e.value.length === 0 ? undefined : e.value;
    setValue(name, tempValue);
  };

  useEffect(() => {
    register(name);
    // eslint-disable-next-line
  }, [register]);

  useEffect(() => {
    if(valueInForm === undefined && internalValue.length !== 0) {
      setInternalValue([]);
      setTextPlaceHolder(placeholder);
    }
    //eslint-disable-next-line
  }, [valueInForm])

  useEffect(() => {}, [internalValue]);

  const onFocusOut = (e: any) => {
    let value = e.component.option("text");
    if (value !== "" && value.length !== 0) {
      if (errors?.[name]) {
        e.event.preventDefault();
      } else if (internalValue.includes(value)) {
        e.event.preventDefault();
      } else {
        setInternalValue((currentValues: any) => [...currentValues, value]);
        setValue(name, [...internalValue, value]);
      }
    }
  };

  const onKeyDown = (data: any) => {
    const currentValue = data.component.field().value;
    const keyCode = data.event.keyCode;
    if (currentValue.length > 0) {
      setTextPlaceHolder("");
      if ([188, 32, 9].includes(keyCode)) {
        if (errors?.[name]) {
          data.event.preventDefault();
          return;
        } else {
          data.component.field().value = "";
          setInternalValue((currentValues: any) => [
            ...currentValues,
            currentValue,
          ]);
          setValue(name, [...internalValue, currentValue]);
          data.event.preventDefault();
          return;
        }
      }

      if (maxLength && keyCode !== 8 && currentValue.length >= maxLength) {
        data.event.preventDefault();
        return;
      }
    } else {
      // Cuando es el primer caracter
      if ([188, 32, 9].includes(keyCode)) {
        data.component.field().value = "";
        data.event.preventDefault();
      }
    }

    let currentRut = "";
    if (isRut && data.event.key.length === 1) {
      data.component.field().style.width = "130px";
      let newValue = currentValue;
      newValue += data.event.key;
      let rut = rutFormat(newValue);
      rut = rut === "-" ? "" : rut;
      data.component.field().value = rut;
      currentRut = rut;
      data.event.preventDefault();
      let rutOnlyNumbers = rut.replace(/,/g, "").replace(/\./g, "");
      if (rut.length > 0) {
        data.component.option("placeholder", "");
      } else {
        data.component.option("placeholder", placeholder);
      }
      if (newValue.length > rutOnlyNumbers.length) data.event.preventDefault();
    }

    if (validate) {
      let newValue = currentValue;
      if (data.event.key.length === 1) {
        newValue += data.event.key;
      }
      newValue = isRut ? currentRut : currentValue;
      for (const property in validate) {
        let validationResponse = validate[property](newValue);
        if (validationResponse !== true) {
          setError(name, {
            type: "manual",
            message: validationResponse,
          });
          return;
        }
      }
      clearErrors(name);
    }
  };

  return (
    <>
      <div className="floating-label"> {label}</div>

      <TagBox
        className={className}
        placeholder={textPlaceHolder}
        acceptCustomValue={acceptCustomValue}
        // opened={false}
        openOnFieldClick={!acceptCustomValue}
        onKeyDown={acceptCustomValue ? onKeyDown : undefined}
        onFocusOut={acceptCustomValue ? onFocusOut : undefined}
        onValueChanged={onValueChanged}
        value={internalValue}
        valueExpr={valueExpr}
        displayExpr={displayExpr}
        items={dataSource}
      />
      {errors?.[name] && (
        <small className="m-invalid-feedback">{errors[name].message}</small>
      )}
    </>
  );
}

export default MTagBox;
