import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { convertToCurrency } from "@helpers/helpers";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import dayjs from "dayjs";
import {
  MButtonLoading,
  MCards,
  MPopup,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import SuccessMessage from "./VerifyData/SuccessMessage";
import MAlertPopupNewCertificateErrorSolicitudEmision from "../../../../controls/alertpopups/MAlertPopupNewCertificateErrorSolicitudEmision";
import TermsConditions from "./VerifyData/TermsConditions";
import Breadcrumbs from "./Breadcrumbs";
import OptionsCard from "../../Cards/OptionsCard";
import AlertErrorReemision from "./AlertErrorReemision";
import TitleVerifyData from "./VerifyData/TitleVerifyData";
import { useHandleVerifyData } from "./VerifyData/useHandleVerifyData";
import { OriginIncome } from "Models/Operation";
interface PropsVerifyData {
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
  AddToCart?: (added: boolean) => void;
}

function VerifyDataMobile(props: PropsVerifyData) {
  const dispatch = useAppDispatch();

  const { cart } = useAppSelector((store) => store.session);
  const { tenderData, valueCommissionSelected } = useAppSelector(
    (store) => store.newCertificate
  );

  const form = useForm();
  const { handleSubmit, watch } = form;
  const { verified } = watch();

  const [inRequest, setInRequest] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showRepeatMessage, setShowRepeatMessage] = useState<boolean>(false);

  const { handlerLabel, LoadTermsConditions, AddOperation } = useHandleVerifyData(
    form,
    HandlerModal,
    props.addOrEdit,
    setShowSuccessMessage,
  );

  const [termAndConditions, setTermAndConditions] = useState<boolean>(false);

  const editOnClick = () => {
    dispatch(
      NewCertificateActions.setCurrentStep(NewCertificateModels.Step.ingresar)
    );
  };

  async function HandlerModal() {
    if (tenderData?.idAseguradora) {
      LoadTermsConditions(tenderData?.idAseguradora);
    } else {
      setTermAndConditions(true);
    }
  }

  const addOnClick = async () => {
    setInRequest(true);
    //Se validad (temporalmente) que la licitacion no este repetida
    if (
      props.addOrEdit === NewCertificateModels.Step.ingresar ||
      props.origen === "tender"
    ) {
      let isRepeat = false;
      cart?.items &&
        cart?.items.forEach((item) => {
          if (
            item.rutUnidadMandante === tenderData?.rutUnidadMandante &&
            item.finalidad === tenderData.finalidad &&
            item.montoCertificado === tenderData.montoCertificado &&
            item.tipoMoneda === tenderData.tipoMoneda &&
            item.glosa === tenderData.glosa
          ) {
            setShowRepeatMessage(true);
            setInRequest(false);
            isRepeat = true;
          }
        });
      if (isRepeat) return;
    }

    await AddOperation();
    setInRequest(false);
    setShowSuccessMessage(true);
  };

  return (
    <>
      <div className="row">
        {props.addOrEdit === NewCertificateModels.Step.ingresar && (
          <Breadcrumbs
            paginaPrincipal="Comprar Garantías"
            paginaActual="Nueva Garantía"
            paginaInicial="/buy-certificate"
          ></Breadcrumbs>
        )}
        <TitleVerifyData addOrEdit={props.addOrEdit} />
        <AlertErrorReemision />
      </div>

      <div className="col-12 pb-4">
        <MCards
          className="px-4 pb-4"
          style={{ boxShadow: "0px 0px 0px" }}
          color="successHome"
        >
          <div className="col-12 pt-4">
            <h3 className="subtitle-sub-mini color-secondary fw-bold fs-18 mb-0">
              Datos licitación
            </h3>
          </div>
          <div className="row d-flex pt-2">
            <div className="col-12 pt-2 d-flex flex-column pb-1">
              <h3 className="fw-bold fs-14 mb-0">ID Licitación</h3>
              <p className="fs-14 mb-0 pt-1" style={{ color: "#7E33CC" }}>
                {tenderData?.codigoExterno}
              </p>
            </div>
            <hr />
            <div className="col-12 pt-2 pb-2">
              <h3 className="fw-bold fs-14 mb-0">RUT mandante</h3>
              <p className="fs-14 mb-0 pt-1">{tenderData?.rutUnidadMandante}</p>
            </div>
            <hr />
            <div className="col-12 mt-2 pb-2">
              <h3 className="fw-bold fs-14 mb-0">Nombre mandante</h3>
              <p className="fs-14 mb-0 pt-1">
                {tenderData?.nombreOrganismoMandante}
              </p>
            </div>
            <hr />
            <div className="col-12 pt-2 pb-2">
              <h3 className="fw-bold fs-14 mb-0">Región</h3>
              <p className="fs-14 mb-0 pt-1">
                {tenderData?.regionUnidadMandante}
              </p>
            </div>
            <hr />
            <div className="col-12 pt-2 pb-2">
              <h3 className="fw-bold fs-14 mb-0">Comuna</h3>
              <p className="fs-14 mb-0 pt-1">
                {tenderData?.comunaUnidadMandante}
              </p>
            </div>
            <hr />
            <div className="col-12 pt-2 pb-2">
              <h3 className="fw-bold fs-14 mb-0" style={{ height: "15px" }}>
                Dirección
              </h3>
              <p className="fs-14 mb-0 pt-2">
                {tenderData?.direccionUnidadMandante}
              </p>
            </div>
            <div className="col-sm-0 pt-sm-0 pt-lg-0"></div>
          </div>
        </MCards>
      </div>

      <div className="col-12 pb-4">
        <MCards
          className="px-4 pb-4"
          style={{ boxShadow: "0px 0px 0px" }}
          color="successHome"
        >
          <div className="col-12 pt-4">
            <h3 className="subtitle-sub-mini color-secondary fw-bold fs-18 mb-0">
              Datos garantía
            </h3>
          </div>
          <div className="row d-flex pt-2">
            <div className="col-12 pt-2 pb-2">
              <h3 className="fw-bold fs-14 mb-0">Finalidad</h3>
              <p className="fs-14 mb-0 pt-1">{tenderData?.finalidad}</p>
            </div>
            <hr />
            <div className="col-12 pt-1 pb-2">
              <h3 className="fw-bold fs-14 mb-0">Fecha inicial</h3>
              <p className="fs-14 mb-0 pt-1">
                {dayjs(tenderData?.fechaInicio).format("DD/MM/YYYY")}
              </p>
            </div>
            <hr />
            <div className="col-12 pt-1 pb-2">
              <h3 className="fw-bold fs-14 mb-0">Fecha término</h3>
              <p className="fs-14 mb-0 pt-1">
                {dayjs(tenderData?.fechaTermino).format("DD/MM/YYYY")}
              </p>
            </div>
            <hr />
            <div className="col-12 pt-1 pb-2">
              <h3 className="fw-bold fs-14 mb-0">Tipo moneda</h3>
              <p className="fs-14 mb-0 pt-1">{tenderData?.tipoMoneda}</p>
            </div>
            <hr />
            <div className="col-12 pt-1 pb-2">
              <h3 className="fw-bold fs-14 mb-0">Monto garantía</h3>
              <p className="fs-14 mb-0 pt-1">
                {convertToCurrency(
                  tenderData?.tipoMoneda,
                  tenderData?.montoCertificado
                )}
              </p>
            </div>
            <hr />
            <div className="col-12 col-lg-12 pt-1">
              <h3 className="fw-bold fs-14 mb-0" style={{ height: "15px" }}>
                Glosa
              </h3>
              <p className="fs-14 mb-0 pt-2">{tenderData?.glosa}</p>
            </div>
          </div>
        </MCards>
      </div>

      <OptionsCard
        resultComission={valueCommissionSelected!}
        handlerSelect={() => { }}
        addOrEdit={props.addOrEdit}
        origen={props.origen}
        selectedOption={undefined}
        setContractStartDate={undefined}
        infoCommission={undefined}
        setValidationContractStartDate={undefined}
      />

      <form onSubmit={handleSubmit(addOnClick)}>
        {props.addOrEdit === NewCertificateModels.Step.verificar && (
          <div className="row justify-content-center mt-2 pt-3">
            <div className="col-12 ">
              <div className="form-horizontal text-center">
                <div className="input-group text-center center">
                  <div className="col-lg-4 col-12">{handlerLabel()}</div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        )}

        <div className="row justify-content-center py-3">
          <div className="col-md-3 pt-3 col-12 order-1 order-md-0 my-auto py-2">
            <MButtonLoading
              type="button"
              className="btn btn-auxiliar sistema w-100"
              outline={true}
              onClick={editOnClick}
              disabled={inRequest}
            >
              Editar datos
            </MButtonLoading>
          </div>
          <div className="col-md-3 pt-3 col-12 order-0 order-md-1 py-2">
            <MButtonLoading
              type="submit"
              className="btn btn-auxiliar sistema w-100"
              onClick={addOnClick}
              loading={inRequest}
              disabled={!verified}
            >
              Añadir al carrito
            </MButtonLoading>
          </div>
        </div>
      </form>
      <SuccessMessage
        showModal={showSuccessMessage}
        setShowModal={setShowSuccessMessage}
      />
      <MAlertPopupNewCertificateErrorSolicitudEmision
        showModal={showRepeatMessage}
        setShowModal={setShowRepeatMessage}
      />
      <MPopup
        size="lg"
        showClose={true}
        showModal={termAndConditions}
        setShowModal={setTermAndConditions}
      >
        <TermsConditions
          setShowModal={setTermAndConditions}
          showModal={termAndConditions}
          category={null}
        ></TermsConditions>
      </MPopup>
    </>
  );
}

export default VerifyDataMobile;
