import React from "react";
import ButtonNewCertificate from "./ButtonNewCertificate";
import IconCertificates from "@img/sin-cotizados-icon.svg";
const NotResultsToday = () => {
  return (
    <div className="col-12 bg-white p-4 sin-certificados-content my-3">
      <div className="row">
        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
          <img
            src={IconCertificates}
            alt="No existen certificados válidos por hoy"
          />
        </div>
        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
          <div className="col-12 my-3">
            <h3 className="titles-left py-3">
              No tienes certificados válidos por hoy, ¿y si generamos uno?
            </h3>
            <div className="py-3">
              <ButtonNewCertificate />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotResultsToday;
