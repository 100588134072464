import { OperacionDTO, ValidateOperations } from "Models/Operation";


export enum SearchType {
  IdLicitacion = "IdLicitacion",
  OrdenDeCompra = "OrdenDeCompra",
}

export enum Step {
  cotizar = "cotizar",
  seleccionar = "seleccionar",
  ingresar = "ingresar",
  verificar = "verificar",
  aniadido = "aniadido",
}

export interface SelectedData {
  id?: number;
  searchTerm: string;
  searchType: SearchType;
  codigoExterno?: string;
  nombreOrganismoMandante?: string;
  rutUnidadMandante?: string;
  regionUnidadMandante?: string;
  comunaUnidadMandante?: string;
  direccionUnidadMandante?: string;
  carroDeCompras: boolean;
  licitacionValida: boolean;
}
export interface NewCertificateState {
    selectedData?: SelectedData | undefined
    , currentStep: Step
    , tenderData?: OperacionDTO | undefined
    , originalTenderData?: any | undefined
    , tenders: OperacionDTO[]
    , newCertificateFromQuote: NewCertificateFromQuote | undefined
    , currentStepReissue: ReissueStep
    , valueCommissionSelected: ValidateOperations[] | undefined
}

export interface NewCertificateFromQuote {
  finalidad: string | undefined;
  fechaInicio: any | undefined;
  fechaTermino: any | undefined;
  tipoMoneda: string | undefined;
  montoCertificado: number | undefined;
  mandante: string | undefined;
  rutMandante: string | undefined;
  idLicitacion: string | undefined;
  selectedOption : ValidateOperations | undefined
  tenderData: tenderData;
}

export interface tenderData {
  regionUnidadMandante: string | undefined;
  direccionUnidadMandante: string | undefined;
  nombreOrganismoMandante: string | undefined;
  comunaUnidadMandante: string | undefined;
  rutUnidadMandante: string | undefined;
}
export interface ReissueStep {
  action: string;
  stepReissue: StepReissue;
}

export enum StepReissue {
  seleccionar = "seleccionar",
  verificar = "verificar",
}

export enum ActionStepReissue {
  agregar = "agregar",
  editar = "editar",
}
export interface Tenders {
  id: number;
  codigoExterno: string;
  nroCertificado: string;
  estado: string;
  subEstado: string;
  fechaInicio: string | null;
  fechaTermino: string | null;
  montoCertificado: number | null;
  comision: number | null;
  comisionBruto: number | null;
  comisionOriginal: number | null;
  valorUf: number | null;
  factorUf: number | null;
  valorUsd: number | null;
  glosa: string;
  rutEmpresa: string;
  fechaIngreso: string | null;
  //verificaCertificado: boolean | null;
  codigoDec5: string;
  finalidad: string;
  cargadoEnMultigestion: boolean | null;
  comisionPricing: number | null;
  tipoMoneda: string;
  fechaPago: string | null;
  numeroFactura: number | null;
  factorComision: number | null;
  plazoMaximo: number | null;
  maximoTicket: number | null;
  empresaEmisora: number | null;
  fechaReemision: string | null;
  idTipoFacturacion: number;
  idCanalPago: number | null;
  codigoOrganismoMandante: string;
  nombreOrganismoMandante: string;
  rutUnidadMandante: string;
  nombreUnidadMandante: string;
  direccionUnidadMandante: string;
  comunaUnidadMandante: string;
  regionUnidadMandante: string;
  fechaSiniestro: string | null;
  montoSiniestro: number | null;
  montoPagadoSiniestro: number | null;
  fechaPagoSiniestro: string | null;
  fechaEstado: string | null;
  idTicketDescuento: number | null;
  descuento: number | null;
  idFondo: number | null;
  tokenTransbank: string;
  descuentoTicket: number | null;
  fechaMovimientoPagoSiniestro: string | null;
  mensaje: string;
  agregarGarantia: boolean;
  tieneFactura: boolean;
  idProveedorFirma: number | null;
  idCampania: number | null;
  descuentoCampania: number | null;
  origenMulti2: boolean | null;
  idEjecutivaVenta: number | null;
  tieneReemision: boolean;
  checkSumMd5: string;
  validarSiisaOperacionStatus: boolean | null;
  validarSiisaOperacionIdCuentaAccesoBackEnd: number | null;
  isReissue: boolean | null;
  fechaCreacion: string | null;
  usuarioCreacion: string;
  fechaModificacion: string | null;
  //usuarioModificacion: string;
  //oculta: boolean;
  carroDeCompras: boolean;
  idOperacionTipoDocumento: number | null;
  idAseguradora: number | null;
  folioInterno: string;
  //iva: number | null;
  emisor: string;
  descripcionEmisor: string;
  sobreCargoUf: number | null;
  primaNeta: number | null;
  primaBruta: number | null;
  porcentajeIva: number | null;
  TipoCertificado: string;
}
