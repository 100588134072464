import React, { useEffect, useState } from "react";
import {
  MButton,
  MCards,
  MInputCheckBox,
  MInputGeneric,
  MTooltip,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Filter } from "@redux/models/FilterModels";
import "../Filter.scss";
import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.common.css";
import * as validation from "@helpers/validationsRFH";
import ApiFilter from "@api/filter/Filter";
import * as FilterActions from "@redux/actions/FilterActions";
import MAlertPopupNewFilterAdd from "../../../../controls/alertpopups/MAlertPopupNewFilterAdd";
import MTagBox from "./MTagBox";
import * as helpers2 from "@helpers/helpers2";
import ReactGA from "react-ga4";

function CreateFilter() {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { run, loginDate, loginDateClient } = useAppSelector(
    (store) => store.account
  );
  const [inRequest, setInRequest] = useState(false);
  const dispatch = useAppDispatch();
  const { regions } = useAppSelector((store) => store.global);
  const { typeTender } = useAppSelector((store) => store.global);
  const { filters } = useAppSelector((store) => store.filter);

  const currentDate: Date =
    loginDate && loginDateClient
      ? helpers2.getDate(loginDate, loginDateClient)
      : new Date();

  const [habilita, setHabilita] = useState(true);

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  // eslint-disable-next-line
  const [popupEditItem, setPopupEditItem] = useState<any>([]);
  const formDefaultValues = {
    "licitacion-con-garantia": true,
    "licitacion-sin-garantia": true,
    enable: true,
    id: -1,
  };

  const internalDefaultValues = popupEditItem ?? formDefaultValues;
  function GetAllFiltersByRut() {
    ApiFilter.GetAll().then((response) => {
      dispatch(FilterActions.loadFilters(response.dataList));
    });
  }

  const {
    handleSubmit,
    watch,
    formState: { isValid },
  } = form;

  const onSubmit = (data: any, e: any) => {
    setInRequest(true);
    let garantia = null;
    if (data.conGarantia === data.sinGarantia) garantia = null;
    else garantia = data.conGarantia && !data.sinGarantia;

    let newFilter: Filter = {
      codigoProducto: data.codigoProducto ? data.codigoProducto.join() : "",
      descripcion: data.descripcion,
      excluir: data.excluir ? data.excluir.join() : "",
      preferencia: data.preferencia ? data.preferencia.join() : "",
      fechaCreacion: currentDate,
      habilitado: true,
      id: 0,
      nombreMandante: data.nombreMandante ? data.nombreMandante.join() : "",
      regionMandante: data.regionMandante ? data.regionMandante.join() : "",
      rubro: "",
      rutMandante: data.rutMandante ? data.rutMandante.join() : "",
      rutProveedor: run as string,
      sectorMandante: "",
      tipoLicitacion: data.tipoLicitacion ? data.tipoLicitacion.join() : "",
      garantia: garantia,
    };

    if (VerifyData()) {
      ApiFilter.Save(JSON.stringify(newFilter)).then((resp) => {
        setInRequest(false);
        if (resp.success) {
          setShowSuccessMessage(true);
          form.reset();
          GetAllFiltersByRut();
          ReactGA.event({
            action: "Crear filtro",
            category: "Filtros",
            label: "Se ha creado un nuevo filtro para las licitaciones",
          });
        }
      });
    }
  };

  function VerifyData(): boolean {
    let estadoForm: boolean =
      form.getValues("descripcion") &&
      (form.getValues("preferencia") ||
        form.getValues("excluir") ||
        form.getValues("codigoProducto") ||
        form.getValues("tipoLicitacion") ||
        form.getValues("rutMandante") ||
        form.getValues("nombreMandante") ||
        form.getValues("regionMandante") ||
        form.getValues("conGarantia") ||
        form.getValues("sinGarantia"));

    if (estadoForm) {
      setHabilita(false);
    } else {
      setHabilita(true);
    }
    return estadoForm;
  }

  useEffect(() => {
    VerifyData();
    // eslint-disable-next-line
  }, [watch()]);

  return (
    <>
      <MCards
        className="p-4"
        color="auxiliar"
        loadingText=""
        onClick={() => {}}
        size="default"
        softColor
        style={{}}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="subtitle-mini text-center">Nuevo filtro</h3>
              <p className="text-center">
                Antes de comenzar, escoge un nombre para tu filtro
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-6 sm-2">
              <div className="input-group">
                <div className="col-12">
                  <div className="floating-label">
                    <MInputGeneric
                      form={form}
                      name="descripcion"
                      label="Nuevo filtro"
                      type="text"
                      placeholder=""
                      maxLength={50}
                      //   rules={{
                      //     required: "Debes ingresar un nombre para el filtro",
                      //     maxLength: validation.maxLengthGeneral(50),
                      //   }}
                      rules={validation.validateFilterName(filters, true, 50)}
                    ></MInputGeneric>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row align-items-center">
            <div className="col-12 py-3">
              <h3 className="subtitle-mini">Configura los filtros</h3>
              <p>
                Mientras más campos uses, más especificos serán tus resultados.
                Ingresa a lo menos un campo.
              </p>
            </div>
          </div>
          <div className="row align-items-center py-3">
            <div className="col-12">
              <h3 className="subtitle-mini">Filtrar por palabra</h3>
              <h4 className="subtitle-small">
                Presiona <b>enter</b> para agregar o excluir palabras en los
                filtros
              </h4>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    name="preferencia"
                    placeholder="Ej: palabra1, palabra2"
                    form={form}
                    maxItems={4}
                    maxLength={100}
                    validate={true}
                    acceptCustomValue={true}
                    label="Incluidas"
                    isRut={false}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    name="excluir"
                    placeholder="Ej: palabra1, palabra2"
                    className=""
                    form={form}
                    maxItems={4}
                    maxLength={100}
                    validate={true}
                    acceptCustomValue={true}
                    value=""
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="Excluidas"
                    isRut={false}
                    required
                    rules={{
                      required: "Ingrese una palabra",
                    }}
                  />
                  <span className="col-8 ex tooltip-filter">
                    <MTooltip
                      iconClass="fas fa-question-circle fs-6"
                      content={
                        <h6>
                          Indica que palabras NO quieres que contengan las
                          licitaciones consideradas en los filtros.
                        </h6>
                      }
                    ></MTooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center py-3">
            <div className="col-12">
              <h3 className="subtitle-mini">
                Filtrar por producto o tipo de licitación
              </h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    name="codigoProducto"
                    placeholder="Ej: codigo1, codigo2"
                    form={form}
                    maxItems={4}
                    maxLength={100}
                    validate={{
                      minLength: (value: any) =>
                        !(value.length > 0 && value.length < 6) ||
                        "El valor es muy corto",
                      maxLength: (value: any) =>
                        !(value.length > 0 && value.length > 25) ||
                        "El valor es muy largo",
                    }}
                    acceptCustomValue={true}
                    value=""
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="Código del producto"
                    isRut={false}
                  />
                  <span className="col-4 col-md-7 tooltip-filter">
                    <MTooltip
                      iconClass="fas fa-question-circle fs-6"
                      content={
                        <h6 className="tooltip-filter">
                          Indica un producto por código especifico
                        </h6>
                      }
                    ></MTooltip>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div>
                  <MTagBox
                    name="tipoLicitacion"
                    form={form}
                    maxItems={4}
                    maxLength={200}
                    acceptCustomValue={false}
                    dataSource={typeTender}
                    valueExpr="id"
                    displayExpr="descripcion"
                    value={internalDefaultValues["types_of_tender"]}
                    placeholder={undefined}
                    className="form-select cursor-pointer"
                    validate={false}
                    isRut={false}
                    label="Tipo de licitación"
                  ></MTagBox>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center py-3">
            <div className="col-12">
              <h3 className="subtitle-mini">Filtrar por mandante</h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    name="rutMandante"
                    placeholder="Ej: rut1, rut2"
                    form={form}
                    maxItems={4}
                    maxLength={12}
                    validate={true}
                    acceptCustomValue={true}
                    value=""
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="Rut mandante"
                    isRut={true}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    className=""
                    name="nombreMandante"
                    placeholder="Ej: nombre1, nombre"
                    form={form}
                    maxItems={4}
                    maxLength={200}
                    validate={true}
                    acceptCustomValue={true}
                    value=""
                    dataSource=""
                    valueExpr=""
                    displayExpr=""
                    label="Nombre mandante"
                    isRut={false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 sm-2">
                <div className="floating-label">
                  <MTagBox
                    name="regionMandante"
                    form={form}
                    maxItems={4}
                    maxLength={200}
                    acceptCustomValue={false}
                    dataSource={regions}
                    valueExpr="id"
                    displayExpr="name"
                    value={internalDefaultValues["regions"]}
                    placeholder={undefined}
                    className="form-select cursor-pointer"
                    validate={false}
                    isRut={false}
                    label="Región"
                  ></MTagBox>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 py-3">
              <h3 className="subtitle-mini">Buscar según Garantía</h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="input-group">
                  <div className="col-8 my-auto">
                    <p className="textochecklicitacion01">
                      Licitación con Garantía
                    </p>
                  </div>
                  <div className="col-4">
                    <div className="check-box-position">
                      <MInputCheckBox name="conGarantia" form={form} label="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="input-group">
                  <div className="col-8 my-auto">
                    <p className="textochecklicitacion01">
                      Licitación sin Garantía
                    </p>
                  </div>
                  <div className="col-4">
                    <div className="col-4">
                      <div className="check-box-position">
                        <MInputCheckBox
                          name="sinGarantia"
                          form={form}
                          label=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center py-3">
            <div className="col-md-3 pt-3">
              <MButton
                className="btn btn-primary sistema w-100"
                type="submit"
                loading={inRequest}
                disabled={habilita || !isValid}
              >
                Crear filtro
              </MButton>
            </div>
          </div>
        </form>
      </MCards>
      <MAlertPopupNewFilterAdd
        showModal={showSuccessMessage}
        setShowModal={setShowSuccessMessage}
      />
    </>
  );
}

export default CreateFilter;
