import { MAxiosBlob } from '../conf';

export async function CallDownload(item: string) {
  let formData = new FormData();
  formData.append("item", item.toString());
  const endpoint = "ManualDeUsuario/DownloadUserGuide";

  const res = await MAxiosBlob().post(endpoint, formData);
  if (res.status !== 200) {
    console.error("error Download pdf");
  } else {
    return res;
  }
}

const ApiDownloadUserGuide = {
  CallDownload,
};

export default ApiDownloadUserGuide;
