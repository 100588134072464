import { MAxios } from "../conf";

export const setMailErrorValidation = async (
    cause:string,
    origin:string,
    error:string,
    aditionalInfo?:string
) => {
    let formData = new FormData();
    formData.append("emailCause", cause);
    formData.append("origin", origin);
    formData.append("error", error);
    if (aditionalInfo !== undefined)
        formData.append("aditionalInfo", aditionalInfo);

    const endpoint = "Mail/Mail/SetMailErrorValidation";

    const res = await MAxios().post(endpoint, formData);

    return res.data;
}

const ApiMail = {
    setMailErrorValidation
};

export default ApiMail;