 import React from "react";
 import { FinfastProductMainUrl } from "@config/environment";

 interface PropsClient {
  isMobile?: boolean
}
 const Client = (props: PropsClient = {isMobile: false}) => {
  let mainUrl : string = FinfastProductMainUrl();

  if(props.isMobile) {
    return (
      <>
        <a className="nav-link" href={mainUrl}>
        <i className="fa fa-user me-2"></i>Mi Cuenta
        </a>     
      </>
    );
  }
  
  return (
    <a className="nav-link" href={mainUrl}>
      Mi Cuenta <i className="fa fa-user ms-2"></i>
    </a>
  );
};

export default Client;