import React from 'react';

import { useCollapseOrHandler } from '../../hooks/useCollapseOrHandler';

const MessageLinePolicy = () => {

  const { handlerClick, stateCollapse } = useCollapseOrHandler();

  return (
    <>
      <div key={2} className="accordion-item-home ">
        <h2 className="accordion-header mb-3" id={`flush-heading${"Policy"}${2}`}>
          <button
            onClick={() => handlerClick("Policy", 2)}
            className={
              stateCollapse === `${"Policy"}${2}`.toString()
                ? "accordion-button"
                : "accordion-button collapsed"
            }
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#flush-collapse${"Policy"}${2}`}
            aria-expanded={
              stateCollapse === `${"Policy"}${2}`.toString() ? "true" : "false"
            }
            aria-controls={`flush-collapse${"Policy"}${2}`}
          >
            Más información sobre Póliza SURA
          </button>
        </h2>
        <div
          id={`flush-collapse${"Policy"}${2}`}
          className={
            stateCollapse === `${"Policy"}${2}`.toString()
              ? "accordion-collapse collapse show"
              : "accordion-collapse collapse"
          }
          aria-labelledby={`flush-heading${"Policy"}${2}`}
          data-bs-parent={`#accordionFlush${"Policy"}`}
        >
          <div className="accordion-body">
            <div className="row">
              <span className="fs-16 pb-3 px-4">Consideraciones sobre tu Línea de Pólizas de Garantía con SURA:</span>
              <div className="px-3">
                <ul>
                  <li className="fs-16"> Sólo utilizable para Finalidad Fiel Cumplimiento.</li>
                  <li className="fs-16"> Disponible sólo para garantías asociadas a nuevos contratos, no cubre renovaciones para contratos ya vigentes.</li>
                  <li className="fs-16">Proceso de facturación gestionado por SURA.</li>
                  <li className="fs-16">Pólizas son afectas a IVA.</li>
                  <li className="fs-16">Pólizas son emitidas en moneda UF o USD, si tu Garantía es en pesos, esta será convertida al UF del día de emisión y se reflejará monto en pesos dentro de la glosa.</li>
                  <li className="fs-16">Para dudas o comentarios sobre una emisión de Póliza comunicate con ejecutiva a cargo.</li>
                </ul>
              </div>
            </div >
          </div >
        </div >
      </div >
    </>
  );
};

export default MessageLinePolicy;
