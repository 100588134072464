import "./Reissue.scss";

import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { ValidateOperations } from "Models/Operation";
import { useForm } from "react-hook-form";

import GenericBaseModel from "@api/GenericBaseModel";
import ApiMandante from "@api/mandante/Mandante";
import ApiProveedor from "@api/proveedor/Proveedor";
import * as operation from "@app/operation/operation";
import * as helpers from "@helpers/helpers";
import * as helpers2 from "@helpers/helpers2";
import { comparableString } from "@helpers/helpers2";
import * as validation from "@helpers/validationsRFH";
import {
  MButton,
  MInputDate,
  MInputGeneric,
  MInputNumber,
  MInputRadioButtons,
  MInputRut,
  MInputSelect,
  MInputTextArea,
  MLoadingLight,
} from "@inversiones-ma/finfast-react-controls";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import * as GlobalModels from "@redux/models/GlobalModels";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import * as SessionModels from "@redux/models/SessionModels";

import { UserGuide } from "../Home/hooks/UserGuide";
import CommissionReissue from "./CommissionReissue";
import AlertReissue from "./partial/AlertReissue";
import AlertReissueBlock from "./partial/AlertReissueBlock";
import ErrorMandante from "./partial/ErrorMandante";
import ReissueCardView from "./partial/ReissueCardView";

interface PropsVerifyData {
  showModal?: boolean;
  setShowModal?: any;
  hanlerSetReissue?: (nrocft: string) => void;
  nroCft?: string;
  close?: any;
  action: string;
}

function EnterDataReissue(props: PropsVerifyData) {
  const dispatch = useAppDispatch();

  const { loginDate, loginDateClient } = useAppSelector(
    (store) => store.account
  );
  const { tenderData, originalTenderData } = useAppSelector(
    (store) => store.newCertificate
  );
  const { regions } = useAppSelector((store) => store.global);
  const { purposes, uf, usd } = useAppSelector((store) => store.session);
  debugger;
  const currentDay: Date =
    loginDate && loginDateClient
      ? helpers2.getDateWithoutTime(loginDate, loginDateClient)
      : new Date();

  const defaultData = {
    ...tenderData,
  };

  // eslint-disable-next-line
  const [preSaveData, setPreSaveData] = useState<any>(defaultData);
  const [dataLinea, setDataLinea] = useState<any>();
  const [dataFinalidad, setDataFinalidad] = useState<any>(null);
  const [showComsissionResult, setShowComissionResult] =
    useState<boolean>(false);
  const [inRequest, setInRequest] = useState<boolean>(false);

  const [arrComuna, setArrComuna] = useState<GlobalModels.Commune[]>(
    helpers2.getCommunes(tenderData?.regionUnidadMandante as string, regions)
  );

  //Control de mandante
  const [dataOrganismoMandante, setDataOrganismoMandante] = useState<any>();
  const [validatingMandante, setValidatingMandante] = useState<boolean>(false);
  const [validMandante, setValidMandante] = useState<boolean>(true);
  const [validateTicket, setValidateTicket] = useState<boolean>(false);

  //Errores que requieran volver al formulario con sus datos
  const [messageValidMandante, setMessageValidMandante] = useState<string>(
    "Para emitir esta garantía contáctese con tu ejecutiva"
  );
  const [lastMandanteSearched, setLastMandanteSearched] = useState<string>("");
  const [errorPopup, setErrorPopup] = useState<boolean>(false);

  //State de resultado de comisión de la reemision
  const [resultComission, setResultComission] = useState<
    ValidateOperations | undefined
  >(undefined);
  // const [viewErrorTerm, setViewErrorTerm] = useState<boolean>(false);
  const [blockButton, setBlockButton] = useState<boolean>(true);

  //Operación get
  const [operationInRequest, setOperationInRequest] = useState<boolean>(false);

  //Auxiliar
  const [lastErrors, setLastErrors] = useState("{}");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [purposeAnticipo, setPurposeAnticipo] = useState<boolean>(false);

  //Is modified
  const [isModified, setIsModified] = useState<boolean>(false);

  const { handleDownloadUserGuide, DownloadDocument } = UserGuide();

  const currencyItems = [
    { value: "CLP", label: "CLP" },
    { value: "UF", label: "UF" },
    { value: "USD", label: "USD" },
  ];

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: defaultData as any,
  });

  const {
    formState: { errors },
    formState,
    getValues,
    handleSubmit,
    trigger,
    watch,
  } = form;

  const reactiveWatch = watch();

  const {
    rutUnidadMandante,
    nombreOrganismoMandante,
    regionUnidadMandante,
    comunaUnidadMandante,
    direccionUnidadMandante,
    fechaInicio,
    fechaTermino,
    finalidad,
    montoCertificado,
    tipoMoneda,
  } = reactiveWatch;

  useEffect(() => {
    let comunas = helpers.getComunas(regionUnidadMandante, regions);
    setArrComuna(comunas);
  }, [regionUnidadMandante]);

  const loadDataMandante = () => {
    //Búsqueda en caso de cambiar el mandante
    if (tenderData?.rutUnidadMandante !== lastMandanteSearched) {
      setValidatingMandante(true);
      setLastMandanteSearched(rutUnidadMandante);

      ApiMandante.validateMandante(tenderData?.rutUnidadMandante!).then(
        (resp) => {
          setValidatingMandante(false);
          if (resp.success) {
            let dataMandante = resp.data;
            dataMandante["lineAmountAvailable"] = resp.lineAmountAvailable;

            //Se ocupa sólo para obtener lineAmountAvailable y COD Organismo, de todas formas se valida en el bknd
            setDataOrganismoMandante(dataMandante);

            setValidMandante(true);
          } else {
            setMessageValidMandante("RUT no habilitado");
            setErrorPopup(true);

            //Se deshabilita el formulario
            setValidMandante(false);
          }

          //Se muestra mensaje de error independiente de la ruta que se tome
          trigger("rutUnidadMandante");
        }
      );
    }
  };

  const handleOnClose = () => {
    if (props.hanlerSetReissue) {
      props.hanlerSetReissue("");
    } else {
      props.close();
    }
  };

  const handlerError = () => {
    setErrorPopup(false);
  };

  const onSubmit = (data: any) => {
    setOperationInRequest(true);
    const dataReissue = {
      idOperacion: tenderData?.id!,
      nombreOrganismoMandante: data.nombreOrganismoMandante,
      regionUnidadMandante: data.regionUnidadMandante,
      comunaUnidadMandante: data.comunaUnidadMandante,
      direccionUnidadMandante: data.direccionUnidadMandante,
      fechaInicio: dayjs(data.fechaInicio).format("YYYY-MM-DDT00:00:00.0"),
      fechaTermino: dayjs(data.fechaTermino).format("YYYY-MM-DDT00:00:00.0"),
      tipoMoneda: data.tipoMoneda,
      montoCertificado: parseFloat(data.montoCertificado),
      glosa: data.glosa,
      comision: resultComission?.valoresComision?.comision!,
      comisionBruto: 0,
      comisionOriginal: 0,
      isReissue: true,
      idFondo: tenderData?.idFondo!,
    };

    let tender = {
      ...tenderData,
      ...dataReissue,
    };

    let stepReissue = {
      action: NewCertificateModels.ActionStepReissue.editar,
      stepReissue: NewCertificateModels.StepReissue.verificar,
    } as NewCertificateModels.ReissueStep;

    //Se actualiza el state del popupEmitir para pasar a pasos posteriores
    dispatch(NewCertificateActions.setTenderDataWithoutOriginal(tender));
    dispatch(NewCertificateActions.setCurrentStepReissue(stepReissue));
  };

  // Llamado al arrancar el formulario
  useEffect(() => {
    if (tenderData?.finalidad !== "Anticipo") {
      loadDataMandante();
    }
    ApiProveedor.GetVistaProveedorLinea().then(
      (response: GenericBaseModel<any>) => {
        if (response.success) {
          // Se ocupa para saber el monto maximo aprobado
          const dataLineaResp = response.dataList;

          // Se ocupa para obtener la lista de finalidades
          const dataFinalidadResp = response.dataList.map((x) => ({
            id: x.idFinalidad,
            descripcion: x.descripcionFinalidad,
          }));
          setDataLinea(dataLineaResp);
          setDataFinalidad(dataFinalidadResp);
          trigger();
        }
      }
    );

    if (tenderData?.finalidad === "Anticipo") {
      setResultComission(undefined);
      setBlockButton(true);
      setValidMandante(false);
      setPurposeAnticipo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //FRL: 29-03-21 Ejecución al detectar errores en la edición y enviar correo de ser necesario
    const hasErrors = !!errors && Object.entries(errors).length !== 0;

    if (hasErrors) {
      //Se envía genera control para envíar correo
      operation.sendMailErrorValidationControl(
        form,
        { ...tenderData },
        resultComission,
        lastErrors,
        setLastErrors
      );
    }

    //Habilita o deshabilita el continuar
    let isFormComplete = true;
    const formValues = getValues();

    const toValidate = [
      "finalidad",
      "fechaInicio",
      "fechaTermino",
      "tipoMoneda",
      "montoCertificado",
      "glosa",
    ];

    for (const key in formValues) {
      if (Object.hasOwnProperty.call(formValues, key)) {
        if (toValidate.includes(key))
          isFormComplete = isFormComplete && !!formValues[key];
      }
    }

    setIsFormValid(!hasErrors && isFormComplete);

    // if (errors.hasOwnProperty() && !errors.fechaTermino) {
    //   setViewErrorTerm(false);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  // Bloquear reemision si no se modifica nada
  useEffect(() => {
    let tempIsModified: boolean =
      originalTenderData.rutUnidadMandante !==
        reactiveWatch.rutUnidadMandante ||
      originalTenderData.nombreOrganismoMandante !==
        reactiveWatch.nombreOrganismoMandante ||
      originalTenderData.regionUnidadMandante !==
        reactiveWatch.regionUnidadMandante ||
      originalTenderData.comunaUnidadMandante !==
        reactiveWatch.comunaUnidadMandante ||
      comparableString(originalTenderData.direccionUnidadMandante) !==
        reactiveWatch.direccionUnidadMandante ||
      originalTenderData.finalidad !== reactiveWatch.finalidad ||
      dayjs(new Date(originalTenderData.fechaInicio)).diff(
        dayjs(new Date(reactiveWatch.fechaInicio)),
        "day"
      ) !== 0 ||
      dayjs(new Date(originalTenderData.fechaTermino)).diff(
        dayjs(new Date(reactiveWatch.fechaTermino)),
        "day"
      ) !== 0 ||
      originalTenderData.tipoMoneda !== reactiveWatch.tipoMoneda ||
      originalTenderData.montoCertificado?.toString() !==
        reactiveWatch.montoCertificado?.toString() ||
      comparableString(originalTenderData.glosa) !== reactiveWatch.glosa;

    if (tempIsModified) {
      if (!isModified) {
        setIsModified(true);
      }
    } else {
      if (isModified) {
        setIsModified(false);
      }
    }
    // eslint-disable-next-line
  }, [reactiveWatch]);

  return (
    <>
      <div className="modal-reissues">
        <div className="p-4">
          <div className="row">
            <div className="col-12">
              <h2 className="titles-center">REEMITIR GARANTÍA</h2>
            </div>
          </div>
          <div className="row mb-4 mt-2">
            <div className="col-12">
              <h3 className="subtitle-section">
                Modifica los datos para tu certificado
              </h3>
              <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-6">
                  <label> Número certificado:</label>{" "}
                  <label className="label-reissue">
                    {tenderData?.nroCertificado}
                  </label>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-6">
                  <label>ID licitación: </label>{" "}
                  <label className="label-reissue">
                    {tenderData?.codigoExterno}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <AlertReissueBlock purposeAnticipo={purposeAnticipo} />

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`${!validMandante ? "disabledForm" : ""}`}
          >
            <div className="row">
              <div className="col-12">
                <div className="rounded-card-bg px-1 py-4 p-md-4">
                  <div className="row justify-content-center">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="subtitle-mini">Datos Licitación</h3>
                      </div>
                    </div>
                    <div className="row">
                      <p>
                        ¿Dudas sobre cómo reemitir tu Garantía?{" "}
                        <span
                          className="text-link text-decoration-underline"
                          onClick={() => handleDownloadUserGuide("REEMISION")}
                        >
                          Descarga nuestro manual aquí.
                        </span>
                        <MLoadingLight
                          style={{ marginLeft: "10px" }}
                          loading={DownloadDocument}
                        />
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 sm-2">
                        <div className="floating-label">
                          <MInputRut
                            form={form}
                            name="rutUnidadMandante"
                            label="RUT Mandante"
                            className="form-control enabled-control info-form input-tag"
                            placeholder="RUT Mandante"
                            loading={validatingMandante}
                            onBlur={loadDataMandante}
                            disabled
                            rules={{
                              required: "El RUT del mandante es requerido",
                              validate: () =>
                                validMandante ||
                                (messageValidMandante ??
                                  "Para emitir esta garantía contáctese con tu ejecutiva"),
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 sm-2">
                        <div className="floating-label">
                          <MInputGeneric
                            form={form}
                            name="nombreOrganismoMandante"
                            label="Nombre Mandante"
                            type="text"
                            className="form-control info-form input-tag"
                            placeholder="Nombre mandante"
                            disabled={!validMandante}
                            maxLength={100}
                            rules={{
                              required: "Este campo es requerido",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 sm-2">
                        <MInputSelect
                          form={form}
                          name="regionUnidadMandante"
                          className="form-select info-form input-tag"
                          items={regions as GlobalModels.Region[]}
                          valueItem="name"
                          label="Región"
                          descriptionItem="name"
                          disabled={!validMandante}
                          orderBy={{
                            by: "name",
                            desc: false,
                            isString: true,
                          }}
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6 sm-2">
                        <MInputSelect
                          form={form}
                          name="comunaUnidadMandante"
                          className="form-select info-form input-tag"
                          items={arrComuna}
                          valueItem="name"
                          descriptionItem="name"
                          disabled={!validMandante}
                          orderBy={{
                            by: "name",
                            desc: false,
                            isString: true,
                          }}
                          label="Comuna"
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="floating-label">
                          <MInputGeneric
                            name="direccionUnidadMandante"
                            className="form-control info-form input-group-border-right input-tag"
                            label="Calle y Número"
                            form={form}
                            disabled={!validMandante}
                            placeholder="Riquelme #1131"
                            rules={{
                              required: "Debes ingresar la dirección",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12">
                <div className="rounded-card-bg px-1 py-4 p-md-4">
                  <div className="justify-content-center">
                    <div className="">
                      <div className="col-12">
                        <h3 className="subtitle-mini">Datos Garantía</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 sm-2">
                        <MInputSelect
                          form={form}
                          name="finalidad"
                          className="form-select info-form input-tag"
                          items={purposes as SessionModels.Purpose[]}
                          valueItem="name"
                          descriptionItem="name"
                          label="Finalidad"
                          disabled={true}
                          rules={{
                            required: "Este campo es requerido",
                          }}
                          loading={!dataFinalidad}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 sm-2">
                        <div className="floating-label">
                          <MInputDate
                            form={form}
                            name="fechaInicio"
                            label="Desde"
                            placeholder="Desde"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 sm-2">
                        <div className="floating-label">
                          <MInputDate
                            form={form}
                            name="fechaTermino"
                            label="Hasta"
                            placeholder="Hasta"
                            min={tenderData?.fechaTermino as any}
                            rules={validation.vEndDateEmision(
                              fechaInicio,
                              finalidad,
                              dataLinea,
                              currentDay
                            )}
                            disabled={!finalidad || !validMandante}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6 sm-2">
                        <div className="col-lg-10 w-100">
                          <label className="rb-moneda mb-0"> Moneda</label>
                          <MInputRadioButtons
                            className="form-check-label"
                            labelPosition="left"
                            form={form}
                            name="tipoMoneda"
                            descriptionItem="Moneda"
                            items={currencyItems}
                            direction="row"
                            disabled={!finalidad || !validMandante}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 sm-2">
                        <div className="floating-label">
                          <MInputNumber
                            label="Monto"
                            labelSubText=""
                            form={form}
                            name="montoCertificado"
                            className="form-control info-form input-tag"
                            placeholder="Ingresa monto de la garantía"
                            mode={tipoMoneda}
                            disabled={
                              !tipoMoneda || !finalidad || !validMandante
                            }
                            rules={validation.vMontoCertificadoReissue(
                              dataOrganismoMandante,
                              finalidad,
                              tipoMoneda,
                              dataLinea,
                              uf?.value,
                              usd?.value,
                              defaultData.montoCertificado,
                              defaultData.tipoMoneda,
                              validateTicket
                            )}
                            loading={!dataFinalidad}
                            onChange={() => setValidateTicket(true)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="floating-label">
                          <MInputTextArea
                            form={form}
                            name="glosa"
                            rules={validation.vGlosa()}
                            label="Glosa"
                            maxLength={1000}
                            className="floating-input floating-textarea default mt-2"
                            disabled={!montoCertificado || !validMandante}
                          ></MInputTextArea>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!purposeAnticipo && (
                    <CommissionReissue
                      fechaInicio={tenderData?.fechaInicio!}
                      fechaTermino={fechaTermino}
                      finalidad={finalidad}
                      montoCertificado={montoCertificado}
                      tipoMoneda={tipoMoneda}
                      nroCertificado={tenderData?.nroCertificado!}
                      nombreOrganismoMandante={nombreOrganismoMandante}
                      regionUnidadMandante={regionUnidadMandante}
                      comunaUnidadMandante={comunaUnidadMandante}
                      direccionUnidadMandante={direccionUnidadMandante}
                      form={form}
                      resultComission={resultComission}
                      setResultComission={setResultComission}
                      setBlockButton={setBlockButton}
                      preDataTender={preSaveData}
                      type={props.action}
                      setInRequest={setInRequest}
                      setShowComissionResult={setShowComissionResult}
                    />
                  )}

                  <div className="row justify-content-center align-items-center">
                    <ReissueCardView
                      inRequest={inRequest}
                      setInRequest={setInRequest}
                      resultComission={resultComission}
                      showComsissionResult={showComsissionResult}
                    />

                    <AlertReissue />

                    <div className="col-md-4 pt-3 col-12 order-0 order-md-1 mb-2">
                      <MButton
                        className="w-100"
                        type="submit"
                        loading={operationInRequest}
                        disabled={
                          validatingMandante ||
                          !dataFinalidad ||
                          !validMandante ||
                          !isFormValid ||
                          blockButton ||
                          !isModified
                        }
                      >
                        Confirmar datos
                      </MButton>
                    </div>

                    <div className="col-md-4 pt-3 col-12 order-1 order-md-0 mb-2">
                      <MButton
                        type="button"
                        outline={true}
                        className="btn btn-primary sistema w-100"
                        onClick={handleOnClose}
                      >
                        Volver
                      </MButton>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </form>

          <ErrorMandante
            errorPopup={errorPopup}
            setErrorPopup={setErrorPopup}
            handlerError={handlerError}
          />
        </div>
      </div>
    </>
  );
}

export default EnterDataReissue;
