import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { RootState } from "@redux/store/store";

interface ApiResponse<T> {
  data?: any;
  success: boolean;
  dataList: T;
  message?: string;
  status?: string;
  subStatus?: string;
  code?: string;
  clientMessage?: string;
}

function useHandleApiResponse<T = any>() {
  const thunkDispatch = useDispatch<ThunkDispatch<RootState, void, AnyAction>>();

  return (resp: ApiResponse<T>, action: (data: T) => AnyAction, data: boolean = false) => {
    if (resp.success) {
      if (data) {
        thunkDispatch(action(resp.data));
      } else {
        thunkDispatch(action(resp.dataList));
      }
    }
  };
}

export default useHandleApiResponse;
