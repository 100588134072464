import React, { useState } from "react";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import { arrTerms } from "../../../../../../scripts/js/termsAndConditions";
import "../EnterData.scss"
interface PropsDuplicateCft {
  showModal?: boolean;
  setShowModal?: any;
  category: any;
}

const TermsConditions = (props: PropsDuplicateCft) => {
  const [stateCollapse, setstateCollapse] = useState<string>("");
  const handlerClick = (newStateCollapse: string, index: number, e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    let statecollapse = `${newStateCollapse}${index}`
    if (stateCollapse === statecollapse) {
      setstateCollapse("");
    }
    else {
      setstateCollapse(statecollapse);
    }
  }
  let arr = [];
  arr = arrTerms.filter((x) => x.categoryCode === "termsAndConditions");
  return (
    <>
      <h4 className="titles-center text-center">Términos y condiciones</h4>
      <div className="accordion accordion-flush" id={`accordionFlush${props.category}`}   >
        {arr?.map((x, index) => {
          return (
            <div key={index} className="accordion-item">
              <h2
                className="accordion-header"
                id={`flush-heading${props.category}${index}`}
              >
                <button
                  onClick={() => handlerClick(props.category, index)}
                  className={
                    stateCollapse === `${props.category}${index}`.toString()
                      ? "accordion-button"
                      : "accordion-button collapsed"
                  }

                  //"accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse${props.category}${index}`}
                  aria-expanded={
                    stateCollapse === `${props.category}${index}`.toString()
                      ? "true"
                      : "false"
                  }
                  aria-controls={`flush-collapse${props.category}${index}`}
                >
                  {x.question}
                </button>
              </h2>
              <div
                id={`flush-collapse${props.category}${index}`}
                className={
                  stateCollapse === `${props.category}${index}`.toString()
                    ? "accordion-collapse collapse show"
                    : "accordion-collapse collapse"
                }
                aria-labelledby={`flush-heading${props.category}${index}`}
                data-bs-parent={`#accordionFlush${props.category}`}
              //data-bs-parent="#accordionFlush"
              >
                <div className="accordion-body">{x.answer}</div>
              </div>
            </div>
          );
        })}
        <div className="row justify-content-center py-3">
          <div className="text-center">
            <MButton
              onClick={() => props.setShowModal(false)}
              type="button"
              className="btn btn-primary sistema">
              Aceptar</MButton>
          </div>
        </div>

      </div>
    </>
  );
};

export default TermsConditions;