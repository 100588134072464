import React, { useEffect, useState } from "react";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import ApiFilter from "@api/filter/Filter";
import MToggleSwitch from "./MToggleSwitch";
import InputBox from "./InputBox";
import { patternEmail } from "@helpers/helpers2";
import ReactGA from "react-ga4";

function Notifications() {
  const [emailPreference, setEmailPreference] = useState<string | null>(null);
  const [swithShowPreference, setSwithShowPreference] =
    useState<Boolean>(false);
  const [isDisableToggleSwitch, setIsDisableToggleSwitch] =
    useState<Boolean>(false);

  useEffect(() => {
    GetDataProvider();
    // eslint-disable-next-line
  }, []);

  function handlerToggleSwithShowPreference() {
    setSwithShowPreference(!swithShowPreference);

    saveSettingPreference(emailPreference, !swithShowPreference);

    ReactGA.event({
      action:
        "Filtro - " +
        (!swithShowPreference ?? false ? "Habilitar" : "Deshabilitar") +
        "recepción correo",
      category: "Garantias Filtros",
    });
  }

  function handlerInputBoxOnChangeValue(value: any) {
    ReactGA.event({
      category: "Garantias Filtros",
      action: "Filtro - Actualizar correo preferencia",
    });

    let showPreference = swithShowPreference;

    if (value.length === 0) {
      setSwithShowPreference(false);
      setIsDisableToggleSwitch(true);
      setEmailPreference(null);
      showPreference = false;
    } else if (value.length === 1 && !emailPreference) {
      setSwithShowPreference(true);
      setIsDisableToggleSwitch(false);
      setEmailPreference(value.join(","));
      showPreference = true;
    } else {
      setEmailPreference(value.join(","));
    }

    saveSettingPreference(value.join(","), showPreference);
  }

  async function saveSettingPreference(emailPreference: any, showPreference: any) {
    await ApiFilter.UpdateSettingPreference(
      emailPreference,
      showPreference
    ).then((result) => {
      if (result.success) {
        let data = {
          emailPreference: emailPreference === "" ? null : emailPreference,
          showPreference: emailPreference === "" ? false : showPreference,
        };
        setEmailPreference(data.emailPreference);
        if (data.emailPreference === "null" || data.emailPreference === null) {
          setIsDisableToggleSwitch(true);
          setSwithShowPreference(false);
        } else {
          setIsDisableToggleSwitch(false);
          setSwithShowPreference(data.showPreference);
        }
      }
    });
  }

  async function GetDataProvider() {
    await ApiFilter.GetDataProvider().then((response) => {
      let data = {
        emailPreference:
          response.data.correoFiltro === "" ? null : response.data.correoFiltro,
        showPreference: response.data.veFiltro,
      };
      setEmailPreference(data.emailPreference);
      if (data.emailPreference === "null" || data.emailPreference === null) {
        setIsDisableToggleSwitch(true);
        setSwithShowPreference(false);
      } else {
        setIsDisableToggleSwitch(false);
        setSwithShowPreference(data.showPreference);
      }
    });
  }

  return (
    <div className="parentNotifications">
      <MCards
        className="p-4"
        color="auxiliar"
        loadingText=""
        onClick={() => {}}
        size="default"
        softColor
        style={{}}
      >
        <div className="row">
          <div className="col-12">
            <h3 className="subtitle-mini text-center">Enviar notificación</h3>
          </div>
        </div>

        <div className="row py-2">
          <div className="col-10">
            <div className="form-group">
              <div>
                <label className="form-check-label textoswitch">
                  Recibir notificación
                </label>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div
              className={
                "acciones-tabla text-center" +
                (isDisableToggleSwitch ? " cursor-default" : "")
              }
            >
              <label className="switch">
                <span className="slider-switch round"></span>
                <MToggleSwitch
                  defaultValue={swithShowPreference}
                  onChange={handlerToggleSwithShowPreference}
                  fieldKey="key"
                  disable={isDisableToggleSwitch}
                ></MToggleSwitch>
              </label>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <p>
              Enviaremos a tu correo notificaciones
              <br />
              de las licitaciones que se ajustan a tus
              <br />
              filtros guardados.
              <br />
              <b>Ingresa máximo 2 correos:</b>
            </p>
          </div>
          <div className="col-12">
            <div className="row">
              <InputBox
                defaultValue={emailPreference?.split(",") || []}
                maxItem={2}
                onChangeValue={handlerInputBoxOnChangeValue}
                placeHolder={"Agregar nuevo correo"}
                pattern={{
                  value: patternEmail.value,
                  message: "Correo inválido",
                }}
                existsErrorMessage={"Este correo ya se encuentra ingresado"}
              ></InputBox>
            </div>
          </div>
        </div>
      </MCards>
    </div>
  );
}

export default Notifications;
