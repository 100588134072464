import React from "react";
import { useAppSelector } from "@redux/hooks";
import { Line } from "@redux/models/SessionModels";

export const useLine = (finalidad?: string) => {

    const { lines, linesInsurance } = useAppSelector((store) => store.session);

    const arrPairPurposeClassName: PairPurposeClassName[] = [
        { purpose: "Seriedad de Oferta", className: "secondary" },
        { purpose: "Fiel Cumplimiento", className: "primary-comp" },
        { purpose: "Correcta Ejecución", className: "auxiliar" },
        {
            purpose: "Fiel Cumplimiento y Correcta Ejecución",
            className: "primary",
        },
    ];

    const borderClassName =
        arrPairPurposeClassName.find((p) => p.purpose === finalidad)?.className ||
        "secondary";

    const lineSeriedadDeOferta = lines?.find(
        (l) => l.name.toLowerCase() === "seriedad de oferta"
    );

    const lineFielCumplimiento = lines?.find(
        (l) => l.name.toLowerCase() === "fiel cumplimiento"
    );

    const lineAnticipo = lines?.find(
        (l) => l.name.toLowerCase() === "anticipo"
    );

    const lineFielCumplimientoCorrectaEjecucion = lines?.find(
        (l) => l.name.toLowerCase() === "fiel cumplimiento y correcta ejecución"
    );

    const lineCorrectaEjecucion = lines?.find(
        (l) => l.name.toLowerCase() === "correcta ejecución"
    );

    let superaPorcentajeLineaCFT = lineFielCumplimiento?.usagePercentage
        ? lineFielCumplimiento?.usagePercentage >= 0
        : false;

    const filteredLines = lines?.filter(
        (l) => l.name !== "Línea Total"
    );

    const totalLine = lines?.find(
        (l) => l.name === "Línea Total"
    ) as Line;

    const sumPercentageReal = filteredLines?.reduce((accumulator, lines) => {
        const percentageReal = (lines.montoLineaUtilizadoPorFinalidad) / (totalLine.available + totalLine.used) * 100;
        return accumulator + percentageReal;
    }, 0);

    let sumTotalPercentage = filteredLines ? filteredLines[0].usagePercentageTotal ?? 100 : 100;

    return {
        lines,
        linesInsurance,
        lineSeriedadDeOferta,
        lineFielCumplimiento,
        lineAnticipo,
        lineFielCumplimientoCorrectaEjecucion,
        lineCorrectaEjecucion,
        superaPorcentajeLineaCFT,
        arrPairPurposeClassName,
        sumPercentageReal,
        sumTotalPercentage,
        totalLine,
        filteredLines,
        borderClassName
    }
};

interface PairPurposeClassName {
    purpose: string;
    className: string;
}