import React from "react";
import { convertToCurrency, toDate } from "@helpers/helpers";
import {
  MGridColumn,
  MGridPaginated,
} from "@inversiones-ma/finfast-react-controls";
import { InsuranceCarrier } from "@redux/models/SessionModels";

interface IProps {
  warratyData: any;
  setOnChangeNumberPage: any;
  setOnChangeNumberRecords: any;
  isLoadingData: boolean;
  handlerButtonDonwnloadCft: any;
  insuranceCarrier: InsuranceCarrier[] | undefined;
}

export const CurrentCertificatePolicyDesktop = ({
  warratyData,
  setOnChangeNumberPage,
  setOnChangeNumberRecords,
  isLoadingData,
  handlerButtonDonwnloadCft,
  insuranceCarrier,
}: IProps) => {
  return (
    <MGridPaginated
      paginated={warratyData}
      keyExpr="nroCertificado"
      key="nroCertificado"
      className="head-table-color p-2"
      onChangeNumberPage={setOnChangeNumberPage}
      onChangeNumberRecords={setOnChangeNumberRecords}
      isLoadingPaginated={isLoadingData}
    >
      <MGridColumn dataField="nroCertificado" label="Póliza" />
      <MGridColumn dataField="folioInterno" label="Folio" />
      <MGridColumn dataField="codigoExterno" label="Id Licitación" />
      <MGridColumn
        dataField="nombreOrganismoMandante"
        label="Mandante"
        headerClassName="center-header"
        className="text-center"
      />
      <MGridColumn
        dataField="idAseguradora"
        label="Aseguradora"
        cellRender={(_k: any, value: any, row: any): any => {
          return insuranceCarrier?.find((item) => item.id === value)
            ?.razonSocial;
        }}
      />
      <MGridColumn dataField="finalidad" label="Finalidad" />
      <MGridColumn
        dataField="montoCertificado"
        label="Monto"
        cellRender={(_k: any, value: any, row: any): any => {
          return (
            convertToCurrency(row.tipoMoneda, value) + " " + row.tipoMoneda
          );
        }}
      />
      <MGridColumn
        className="col "
        dataField="fechaTermino"
        label="Término"
        cellRender={(_k: any, value: any) => {
          return <> {toDate(value)} </>;
        }}
      />
      <MGridColumn
        className="col text-center"
        dataField="codigoDec5"
        label="Póliza"
        headerClassName="center-header"
        cellRender={(_k: any, value: any, row: any) => {
          return (
            <>
              {value && (
                <button
                  className="btn px-3 descarga-factura-tabla"
                  onClick={() => handlerButtonDonwnloadCft(value)}
                >
                  <i className="fas fa-file-alt fs-5" aria-hidden="true"></i>
                </button>
              )}
              {!value && (
                <button
                  className="btn px-3 descarga-factura-tabla"
                  onClick={() => {}}
                >
                  <i
                    className="fas fa-file-alt fs-5 disabled"
                    aria-hidden="true"
                  ></i>
                </button>
              )}
            </>
          );
        }}
      />
    </MGridPaginated>
  );
};
