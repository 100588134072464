import { createSlice } from "@reduxjs/toolkit";
import { cleanState, loadPreferences } from '../actions/PreferencesActions';
import { PreferencesState } from '../models/PreferencesModel';

const inicialState: PreferencesState = {
  emailPreference : undefined,
  showPreference  : undefined
};

const slice = createSlice({
  name: 'preferences'
  , initialState: inicialState
  , reducers: {}
  , extraReducers: builder => {
    builder.addCase(loadPreferences, (state, action) => {
      state.emailPreference = action.payload.emailPreference;
      state.showPreference = action.payload.showPreference;
    })
    .addCase(cleanState, (state) => {
      state.emailPreference = inicialState.emailPreference;
      state.showPreference = inicialState.showPreference;
    })
  }
});

export default slice.reducer;