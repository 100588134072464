import React, { useEffect, useState } from "react";
import ImgNotResults from "@img/dude_pensante.svg";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import { useHistory } from "react-router-dom";

interface PropsMessageNotData {
  title: string;
  description: string;
  buttonBuy: boolean;
}

const MessageNotData = (props: PropsMessageNotData) => {
  const history = useHistory();
  const redirecNewCertificate = () => {
    history.push("/new-certificate");
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="col-12 bg-white p-2 p-md-2  my-4">
        <div className="row p-3">
          {
            windowSize.width > 768 ?
              (<>
                <div className="col-9 col-lg-9 d-flex align-items-center justify-content-center">
                  <div className="row">
                    <div className="col-12 my-3">
                      <h3 className="titles-left">
                        {props.title} <br />
                        {props.description}
                      </h3>
                    </div>
                    {props.buttonBuy && (
                      <div className="col-sm-12">
                        <MButton
                          className="btn btn-primary sistema"
                          onClick={() => redirecNewCertificate()}
                        >
                          Comprar garantías{" "}
                          <i className="fas fa-arrow-right ms-2"></i>
                        </MButton>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-3 col-lg-3 d-flex align-items-center justify-content-center">
                  <img src={ImgNotResults} alt="" className="w-100" />
                </div>
              </>
              ) :
              (
                <>
                  <div className="col-12 d-flex align-items-center justify-content-center mt-4">
                    <img src={ImgNotResults} alt="" style={{minWidth:150}} className="" />
                  </div>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <div className="row mb-4">
                      <div className="col-12 mx-4">
                        <h3 className="titles-left mx-3 my-2">
                          {props.title} <br />
                          {props.description}
                        </h3>
                      </div>
                      {props.buttonBuy && (
                        <div className="col-sm-12 pt-4">
                          <MButton
                            className="btn btn-primary sistema"
                            onClick={() => redirecNewCertificate()}
                          >
                            Comprar garantías{" "}
                            <i className="fas fa-arrow-right ms-2"></i>
                          </MButton>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )
          }


        </div>
      </div>
    </>
  );
};

export default MessageNotData;
