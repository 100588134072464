import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { MInputSelect } from "@inversiones-ma/finfast-react-controls";
import * as TenderActions from "@redux/actions/TenderActions";
import { useForm } from "react-hook-form";

interface FilterOption {
  tenderField: string;
  label: string;
}

interface PropsSearchTender {
  typeTender: string;
  setIsFilter: any;
  isFilter: boolean;
}

const SearchTender = (props: PropsSearchTender) => {
  const filterOptions: FilterOption[] = [
    { tenderField: "codigoExterno", label: "Código" },
    { tenderField: "nombreMandante", label: "Mandante" },
  ];

  const dispatch = useAppDispatch();
  const { tendersAdjudicadas, tendersPublicadas } = useAppSelector(
    (store) => store.tender
  );

  const form = useForm();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentFilterOptionField, setCurrentFilterOptionField] =
    useState<string>("");

  const filterOptionOnChange = (result: any) => {
    setCurrentFilterOptionField(result);
    filterData(result, searchTerm);
  };

  const searchTempOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    filterData(currentFilterOptionField, e.target.value);
  };

  useEffect(() => {
    filterData(currentFilterOptionField, searchTerm);
    // eslint-disable-next-line
  }, [props.typeTender]);

  const filterData = (
    paramFilterOptionField: string,
    paramSearchTerm: string
  ) => {
    // si el termino de busqueda está vacio no filtrar
    if (paramSearchTerm.length === 0 || paramFilterOptionField.length === 0) {
      if (props.typeTender === "PUBLICADAS") {
        dispatch(
          TenderActions.loadFilteredTendersPublicadas(tendersPublicadas)
        );
        props.setIsFilter(!props.isFilter);
      } else if (props.typeTender === "ADJUDICADAS") {
        dispatch(
          TenderActions.loadFilteredTendersAdjudicadas(tendersAdjudicadas)
        );
        props.setIsFilter(!props.isFilter);
      } else {
        console.error(
          "tipo de licitacion seleccionada inválida: " + props.typeTender
        );
      }
      return;
    }

    if (props.typeTender === "PUBLICADAS") {
      const tempFiltered = tendersPublicadas?.filter((t) =>
        String((t as any)[paramFilterOptionField])
          .toLocaleLowerCase()
          .includes(paramSearchTerm.toLocaleLowerCase())
      );
      dispatch(TenderActions.loadFilteredTendersPublicadas(tempFiltered));
      props.setIsFilter(!props.isFilter);
    } else if (props.typeTender === "ADJUDICADAS") {
      const tempFiltered = tendersAdjudicadas?.filter((t) =>
        String((t as any)[paramFilterOptionField])
          .toLocaleLowerCase()
          .includes(paramSearchTerm.toLocaleLowerCase())
      );
      dispatch(TenderActions.loadFilteredTendersAdjudicadas(tempFiltered));
      props.setIsFilter(!props.isFilter);
    } else {
      console.error(
        "tipo de licitacion seleccionada inválida: " + props.typeTender
      );
    }
  };

  return (
    <div className="row buscador-tablas p-3 mt-4 pb-2">
      <div className="col-4 p-0 container-filter-option">
        <MInputSelect
          name="filter_option"
          form={form}
          className="form-select"
          items={filterOptions}
          valueItem="tenderField"
          descriptionItem="label"
          label="Buscar por"
          onChange={filterOptionOnChange}
        />
      </div>
      <div className="col-8 p-0">
        <div className="buscador-lupa">
          <input
            type="search"
            id="search"
            value={searchTerm}
            onChange={searchTempOnChange}
          />
          <button type="button" className="icon">
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchTender;
