import { StandardLineCard } from "./StandardLineCard";
import { useLine } from "../../hooks/useLine";
import React from "react";

const LineGuarantees = () => {

    const { lineSeriedadDeOferta, lineFielCumplimiento, lineAnticipo, lineFielCumplimientoCorrectaEjecucion, lineCorrectaEjecucion } = useLine();

    return (
        <div className="mb-0">
            <div className="row">
                <div className="col-12">
                    <h3 className="subtitle-mini mb-4">
                        Certificados de Garantía
                    </h3>
                </div>
                <div>
                    <div className="accordion-body">
                        <div className="row mb-3">
                            {lineSeriedadDeOferta && (
                                <StandardLineCard
                                    finalidad={lineSeriedadDeOferta.name}
                                    montoUtilizado={lineSeriedadDeOferta.used}
                                    cftEmtidos={lineSeriedadDeOferta.countOperation}
                                    montoDisponible={lineSeriedadDeOferta.available}
                                    porcentajeUtilizado={lineSeriedadDeOferta.usagePercentage}
                                    porcentajeUtilizadoTotal={lineSeriedadDeOferta.usagePercentageTotal}
                                    montoLineaAprobado={lineSeriedadDeOferta.montoLineaAprobado}
                                    maxTicket={lineSeriedadDeOferta.maxTicketFinfast >
                                        lineSeriedadDeOferta?.maxTicket
                                        ? lineSeriedadDeOferta.maxTicketFinfast
                                        : lineSeriedadDeOferta?.maxTicket} />
                            )}

                            {lineFielCumplimiento && (
                                <StandardLineCard
                                    finalidad={lineFielCumplimiento.name}
                                    montoUtilizado={lineFielCumplimiento.used}
                                    cftEmtidos={lineFielCumplimiento.countOperation}
                                    porcentajeUtilizado={lineFielCumplimiento.usagePercentage}
                                    porcentajeUtilizadoTotal={lineFielCumplimiento.usagePercentageTotal}
                                    montoDisponible={lineFielCumplimiento.available}
                                    montoLineaAprobado={lineFielCumplimiento.montoLineaAprobado}
                                    maxTicket={lineFielCumplimiento.maxTicketFinfast >
                                        lineFielCumplimiento?.maxTicket
                                        ? lineFielCumplimiento.maxTicketFinfast
                                        : lineFielCumplimiento?.maxTicket}
                                />
                            )}

                        </div>
                        <div className="row">
                            {lineAnticipo && (
                                <StandardLineCard
                                    finalidad={lineAnticipo.name}
                                    montoUtilizado={lineAnticipo.used}
                                    cftEmtidos={lineAnticipo.countOperation}
                                    porcentajeUtilizado={lineAnticipo.usagePercentage}
                                    porcentajeUtilizadoTotal={lineAnticipo.usagePercentageTotal}
                                    montoDisponible={lineAnticipo.available}
                                    montoLineaAprobado={lineAnticipo.montoLineaAprobado}
                                    maxTicket={lineAnticipo.maxTicketFinfast >
                                        lineAnticipo?.maxTicket
                                        ? lineAnticipo.maxTicketFinfast
                                        : lineAnticipo?.maxTicket}

                                />
                            )}
                            {lineFielCumplimientoCorrectaEjecucion && (
                                <StandardLineCard
                                    finalidad={lineFielCumplimientoCorrectaEjecucion.name}
                                    montoUtilizado={lineFielCumplimientoCorrectaEjecucion.used}
                                    cftEmtidos={
                                        lineFielCumplimientoCorrectaEjecucion.countOperation
                                    }
                                    porcentajeUtilizado={
                                        lineFielCumplimientoCorrectaEjecucion.usagePercentage
                                    }
                                    porcentajeUtilizadoTotal={
                                        lineFielCumplimientoCorrectaEjecucion.usagePercentageTotal
                                    }
                                    montoDisponible={
                                        lineFielCumplimientoCorrectaEjecucion.available
                                    }
                                    montoLineaAprobado={
                                        lineFielCumplimientoCorrectaEjecucion.montoLineaAprobado
                                    }
                                    maxTicket={lineFielCumplimientoCorrectaEjecucion.maxTicketFinfast >
                                        lineFielCumplimientoCorrectaEjecucion?.maxTicket
                                        ? lineFielCumplimientoCorrectaEjecucion.maxTicketFinfast
                                        : lineFielCumplimientoCorrectaEjecucion?.maxTicket}
                                />
                            )}
                            {lineCorrectaEjecucion && (
                                <StandardLineCard
                                    finalidad={lineCorrectaEjecucion.name}
                                    montoUtilizado={lineCorrectaEjecucion.used}
                                    cftEmtidos={lineCorrectaEjecucion.countOperation}
                                    porcentajeUtilizado={lineCorrectaEjecucion.usagePercentage}
                                    porcentajeUtilizadoTotal={
                                        lineCorrectaEjecucion.usagePercentageTotal
                                    }
                                    montoDisponible={lineCorrectaEjecucion.available}
                                    montoLineaAprobado={
                                        lineCorrectaEjecucion.montoLineaAprobado
                                    }
                                    maxTicket={lineCorrectaEjecucion.maxTicketFinfast >
                                        lineCorrectaEjecucion?.maxTicket
                                        ? lineCorrectaEjecucion.maxTicketFinfast
                                        : lineCorrectaEjecucion?.maxTicket}
                                />
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default LineGuarantees;
