import React from 'react';
import ImageTerm from "@img/plazo-verify.png";

function ErrorGeneral(props: PropsErrorGeneral) {
	if (props && props.resultTypeError === "G0001") {
		return (
			<div className="col-11 mb-4 mx-auto tarjeta-mensaje py-3">
				<div className="row align-center">
					<div className="col-12 col-lg-2 text-center">
						<img alt="imagen term" src={ImageTerm} />
					</div>
					<div className="col">
						{props.headerText}
						<br />
						{props.detailText}
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={props.className}>
				<div className="row mx-auto">
					<div className={props.iconClass}>
						<i className={props.faIcon}></i>
					</div>
					<div className="col-9 text-error-general-popup1 mt-2">
						<h4>{props.headerText}</h4>
						{props.detailText}
					</div>
				</div>
			</div>
		);
	}
};

export default ErrorGeneral;

interface PropsErrorGeneral {
	resultTypeError?: string;
	className?: string;
	iconClass?: string;
	faIcon?: string;
	headerText?:React.ReactNode;
	detailText?:React.ReactNode;
}