import React from "react";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import OptionsCard from "../../../Cards/OptionsCard";
import { EnumPrioridad } from "@redux/models/QuoteModels";
import SimpleLoading from "@controls/SimpleLoading";
import { CreateOperation, OriginIncome, ValidateOperations } from "Models/Operation";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";

interface PropsWarrantiesCardView {
  commissionInRequest: boolean;
  selectCardcommission: boolean;
  resultComission: ValidateOperations[];
  handlerSelect: (element: ValidateOperations) => void | undefined;
  addOrEdit: NewCertificateModels.Step;
  prioridadCertificado: EnumPrioridad | undefined;
  setPrioridadCertificado: React.Dispatch<
    React.SetStateAction<EnumPrioridad | undefined>
  >;
  origen: OriginIncome;
  selectedOption: ValidateOperations | undefined;
  setValidationContractStartDate: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setContractStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  infoCommission: CreateOperation | undefined;
}

const WarrantiesCardView = (props: PropsWarrantiesCardView) => {

  return (
    <>
      {props.commissionInRequest && (
        <>
          <div className="center">
            <div className="col-12">
              <MCards className="nonBorder px-1 mb-4 p-md-4" size="default">
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{
                    height: "190px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                >
                  <SimpleLoading className="orange"></SimpleLoading>
                </div>
              </MCards>
            </div>
          </div>
        </>
      )}

      {props.selectCardcommission && (
        <>
          <OptionsCard
            resultComission={props.resultComission}
            handlerSelect={(element) => props.handlerSelect(element)}
            addOrEdit={props.addOrEdit}
            PrioridadCertificado={props.prioridadCertificado}
            setPrioridadCertificado={props.setPrioridadCertificado}
            origen={props.origen}
            selectedOption={props.selectedOption}
            setValidationContractStartDate={props.setValidationContractStartDate}
            infoCommission={props.infoCommission}
            setContractStartDate={props.setContractStartDate}
          />
        </>
      )}
    </>
  );
};

export default WarrantiesCardView;
