import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import ApiReissue from "@api/reissue/Reissue";
import { convertToCurrency, convertToPeso } from "@helpers/helpers";
import {
  MAlert,
  MButtonLoading,
  MCards,
  MInputCheckBox,
} from "@inversiones-ma/finfast-react-controls";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as SessionActions from "@redux/actions/SessionActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import { BuyCartHook } from "../Cart/partial/Hook/BuyCartHook";
import { RestrictedMandante } from "../NewCertificate/partial/Modals/RestrictedMandante";
import GenericBaseModel from "@api/GenericBaseModel";
import { OperacionDTO } from "Models/Operation";
import SuccessMessage from "../NewCertificate/partial/VerifyData/SuccessMessage";
import VerifyDataReissueForm from "./partial/VerifyDataReissueForm";
import ReissueCardView from "./partial/ReissueCardView";
import ReissueCardData from "./partial/ReissueCardData";

interface PropsVerifyData {
  showModal?: boolean;
  setShowModal?: any;
  hanlerSetReissue?: (nrocft: string) => void;
  nroCft?: string;
  close?: any;
  action?: string;
}

function VerifyDataReissue(props: PropsVerifyData) {
  const dispatch = useAppDispatch();

  const { tenderData } = useAppSelector((store) => store.newCertificate);
  const { cart } = useAppSelector((store) => store.session);
  const [cartItems] = useState(cart?.items);

  const [inRequest, setInRequest] = useState(false);
  const [isReissueByCostCero, setIsReissueByCostCero] =
    useState<boolean>(false);
  const [showModalRestricted, setShowModalRestricted] =
    useState<boolean>(false);
  const [showAlertContactExecutive, setShowAlertContactExecutive] =
    useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const form = useForm();
  const { handleSubmit, watch, setValue } = form;
  const { verified } = watch();

  let comisionCertificado: number | undefined = cart?.items?.reduce(
    (sum: number, value: any) =>
      typeof value.comision == "number" ? sum + value.comision : sum,
    0
  );

  useEffect(() => {
    if (props.action === "edit") {
      setValue("verified", true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cart && cartItems && cart?.items.length > cartItems?.length) {
      setIsReissueByCostCero(
        cart?.items.length === 1 && comisionCertificado === 0
      );
      if (props.hanlerSetReissue) {
        props.hanlerSetReissue(tenderData?.nroCertificado!);
      } else {
        props.close();
      }
    } else {
      if (tenderData) setIsReissueByCostCero(tenderData.comision === 0 && true);
    }
    // eslint-disable-next-line
  }, [cart]);

  const editOnClick = () => {
    let stepReissue = {
      action: NewCertificateModels.ActionStepReissue.agregar,
      stepReissue: NewCertificateModels.StepReissue.seleccionar,
    } as NewCertificateModels.ReissueStep;

    dispatch(NewCertificateActions.setCurrentStepReissue(stepReissue));
  };

  const addOnClick = () => {
    setInRequest(true);
    AddReissue();
  };

  const { resetStepToPay } = BuyCartHook();

  const AddReissue = async () => {
    await ApiReissue.SendReissueRequest(tenderData).then(
      (resp: GenericBaseModel<OperacionDTO>) => {
        setInRequest(false);
        if (resp.success) {
          resetStepToPay();
          dispatch(
            SessionActions.addItemToCart({ ...resp.data, isReissue: true })
          );
          debugger;
          let stepReissue = {
            action: NewCertificateModels.ActionStepReissue.agregar,
            stepReissue: NewCertificateModels.StepReissue.seleccionar,
          } as NewCertificateModels.ReissueStep;

          dispatch(NewCertificateActions.setCurrentStepReissue(stepReissue));

          if (props.hanlerSetReissue) {
            props.hanlerSetReissue(tenderData?.nroCertificado!);
            setShowSuccessMessage(true);
          } else {
            props.close();
          }
          dispatch(SessionActions.loadDiscountValue(undefined));
          dispatch(SessionActions.cleanDiscountZone());
        } else {
          if (resp.code === "G0500") {
            setShowModalRestricted(true);
          } else if (resp.code === "EXCEDEPLAZOMAXIMO") {
            setShowAlertContactExecutive(true);
          }
        }
      }
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h2 className="titles-center pt-4">Confirmar Datos</h2>
          <div className="row">
            <div className="col-12 py-3">
              <h3 className="subtitle-section">
                Confirma que los datos ingresados sean correctos.
              </h3>
              <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-6">
                  <label> Número certificado:</label>{" "}
                  <label className="label-reissue">
                    {tenderData?.nroCertificado}
                  </label>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-6">
                  <label>ID licitación: </label>{" "}
                  <label className="label-reissue">
                    {tenderData?.codigoExterno}
                  </label>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      {showAlertContactExecutive && (
        <div className="row justify-content-center">
          <div className="col-12 center">
            <MAlert color="danger" className="">
              <p
                className="textAlert"
                style={{ fontSize: 16 + "px" } as React.CSSProperties}
              >
                <>
                  <span className="ingresa-alert02">
                    <b>
                      Por favor contacta a tu ejecutiva para continuar con esta
                      reemisión.
                    </b>
                  </span>
                </>
              </p>
            </MAlert>
          </div>
        </div>
      )}

      <VerifyDataReissueForm tenderData={tenderData} />

      <div className="VerifyDataReissue">
        <form onSubmit={handleSubmit(addOnClick)}>
          {props.action === "add" && (
            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <div className="form-horizontal text-center">
                  <div className="input-group text-center center">
                    <MInputCheckBox
                      name="verified"
                      form={form}
                      className="form-check-input checkbox-center"
                      label="He verificado que los datos son correctos"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <ReissueCardData comision={tenderData?.comision} />

          <div className="row justify-content-center py-3">
            <div className="col-12 col-md-10 center">
              <MAlert color="info" className="slim">
                <p
                  className="textAlert"
                  style={{ fontSize: 16 + "px" } as React.CSSProperties}
                >
                  <span className="ingresa-alert02">
                    El costo de reemisión es desde 1 UF{" "}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: 14 + "px" } as React.CSSProperties}
                    className="textAlert2"
                  >
                    Cambios menores como:{" "}
                    <span className="ingresa-alert02 ps-1 ">
                      glosa, razón social o dirección, en la primera reemisión
                      es $0
                    </span>
                  </span>
                </p>
              </MAlert>
            </div>
            <div className="col-md-3 pt-3 col-12 order-1 order-md-0 my-auto py-2">
              <MButtonLoading
                type="button"
                className="btn btn-primary sistema w-100"
                outline={true}
                onClick={editOnClick}
                disabled={inRequest}
              >
                Editar datos
              </MButtonLoading>
            </div>
            {isReissueByCostCero ? (
              <div className="col-md-3 pt-3 col-12 order-0 order-md-1 py-2">
                <MButtonLoading
                  type="submit"
                  className="btn btn-primary sistema w-100"
                  loading={inRequest}
                  disabled={!verified}
                >
                  Reemitir certificado
                </MButtonLoading>
              </div>
            ) : (
              <div className="col-md-3 pt-3 col-12 order-0 order-md-1 py-2">
                <MButtonLoading
                  type="submit"
                  className="btn btn-primary sistema w-100"
                  loading={inRequest}
                  disabled={!verified}
                >
                  {props.action === "edit" && "Entiendo"}
                  {props.action === "add" && "Añadir al carrito"}
                </MButtonLoading>
              </div>
            )}
          </div>
        </form>
      </div>
      {showModalRestricted && (
        <RestrictedMandante
          setShowModal={setShowModalRestricted}
          showModal={showModalRestricted}
        />
      )}

      {showSuccessMessage && (
        <SuccessMessage
          showModal={showSuccessMessage}
          setShowModal={setShowSuccessMessage}
          close={() => setShowSuccessMessage(false)}
        />
      )}
    </>
  );
}

export default VerifyDataReissue;
