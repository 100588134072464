import ApiFactoringNps from "@api/nps/nps";
import React, { useState } from "react";
import { MButton, MInputGeneric, MMInputRadio } from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import { DataNps, EncuestaValues } from "@redux/models/SessionModels";
import { useAppSelector } from "@redux/hooks";
import checkImage from "@img/icon-check-confirmacion.svg";
import "./Nps.scss";

interface Props {
  ClickHandler: () => void;
}

const Nps = (props: Props) => {
  const { surveyNps } = useAppSelector((store) => store.global);
  const [showThanks, setShowThanks] = useState<boolean>(false);
  const [inRequest, setInRequest] = useState<boolean>(false);


  const increaseNpsCounter = () => {
    ApiFactoringNps.UpdateNpsInteraccion("1") // idProducto = 1 Garantias
      .catch((err) => {
        console.error(err);
      });
      props.ClickHandler();
  };
  
              
  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = form;

  const SubmitForm = async (data: any) => {
    setInRequest(true);
    let dataNps: DataNps;
    let objEntries: EncuestaValues[] = [];

    for (let [idEncuestaPregunta, respuesta] of Object.entries(data)) {
      objEntries.push({ idEncuestaPregunta, respuesta });
    }

    dataNps = {
      encuestaValues: objEntries,
      idEncuesta: surveyNps?.idEncuesta,
    };
    // Para evitar que se cierre el mensaje de agradecimiento automaticamente
    // Se debe eliminar o comentar la linea que ejecuta la funcion que viene de las propiedades props.ClickHandler()
    await ApiFactoringNps.SetNps(dataNps).then((resp) => {
      setInRequest(false);
      //props.ClickHandler();
    });
    setShowThanks(true);
  };

  return (
    <div>
      {showThanks && (
        <div className="container">
          <div className="row">
            <div className="col-12 py-5 px-2 text-center">
              <img
                src={checkImage}
                alt="datos confirmados"
                className="icon-pop-up-el"
              />
              <h2 id="titles-thanks" className="titles-left text-center">
                Gracias por contestar nuestra encuesta
              </h2>
              <p className="text-center">
                Esta información es muy valiosa para nosotros, <br />
                agradecemos el tiempo que te tomaste para contestar.
              </p>
              <p className="text-center">
                Todas tus respuestas nos ayudaran a mejorar nuestros servicios.
              </p>
              <MButton color="primary" onClick={props.ClickHandler}>
                <i className="fas fa-arrow-left me-2"></i> Volver
              </MButton>
            </div>
          </div>
        </div>
      )}
      {!showThanks && (
        <form className="subscriptioncert" onSubmit={handleSubmit(SubmitForm)}>
          <div
            className="modal-body pt-0 text-center"
            style={{ maxWidth: "35.5rem" }}
          >
            <i
              className="far fa-star fa-2x"
              style={{ color: "var(--color-auxiliar)" }}
            ></i>
            <h4 className="subtitle-mini">¡Gracias por preferirnos!</h4>
            <p>Déjanos un comentario para saber cómo lo estamos haciendo.</p>

            <hr className="NPS-title-divider" />

            <div className="row mt-3 mini-NPS-survey">
              {surveyNps?.listDataSurvey?.map((x, index) => (
                <>
                  <div key={index} className="col-12 text-start p-3">
                    <p>{`${index + 1}. ${x.pregunta}`}</p>
                    {x.tipoDato === "Int" && (
                      <>
                        <MMInputRadio
                          type="radio"
                          name={x.idEncuestaPregunta.toString()}
                          form={form}
                          arrayInputList={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                          rules={{
                            required: x.preguntaRequerida
                              ? "Por favor, completa el campo solicitado."
                              : "",
                          }}
                        />
                      </>
                    )}
                    {x.tipoDato === "String" && (
                      <div className="col-12 text-start">
                        <MInputGeneric
                          type="text"
                          name={x.idEncuestaPregunta.toString()}
                          form={form}
                          label=""
                          maxLength={200}
                          placeholder=""
                          rules={{
                            required: x.preguntaRequerida
                              ? "Por favor, completa el campo solicitado."
                              : "",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>

            <div className="col-12 mt-4">
              <MButton outline color="primary" onClick={increaseNpsCounter}>
                {surveyNps?.openSurveyCount === surveyNps?.npsDisplayLimit ?? 0 - 1
                  ? "Preguntar el mes siguiente"
                  : "Preguntar más tarde"}
              </MButton>
              <MButton
                className="px-3 mx-3"
                color="primary"
                type="submit"
                loading={inRequest}
              >
                Enviar respuesta
              </MButton>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Nps;
