import React from "react";
import { useAppSelector } from "@redux/hooks";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import "./HomeBannerSii.scss";
import imgSII from "@img/sii-finfast.svg";
import { finfast } from "@config/environment";

const HomeBannerSII = () => {
  const { requireSiiCredentials, isNewClient } = useAppSelector(
    (store) => store.account
  );
  const idProduct = 1;
  const showBannerNew = requireSiiCredentials && isNewClient;

  const Redirect = () => {
    window.location.href =
      finfast() + `/MyAccount?origen=sii_data&product=${idProduct}`;
  };

  return (
    <>
      {showBannerNew ? (
        <div className="homeBanner-sii row justify-content-center pt-3 pb-4">
          <div className="col-12 banner-solicitar-sii">
            <div className="banner-solicitar-sii-bg1">
              <div className="row justify-content-around align-items-center banner-solicitar-sii-bg2 p-2">
                <div className="col-12 col-md-2 text-center">
                  <img src={imgSII} alt="SII y Finfast" />
                </div>
                <div className="col-12 col-lg-auto text-center text-lg-start">
                  <h4>Vincula tu cuenta de SII</h4>
                  <p className="fw-bold">
                    Para tener acceso completo a los servicios que te ofrecemos.
                  </p>
                </div>
                <div className="col text-center mt-3 mt-lg-0">
                  <MButton
                    type="button"
                    className="sistema col-6 col-lg-10"
                    onClick={Redirect}
                  >
                    Vincular
                  </MButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default HomeBannerSII;
