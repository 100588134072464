import React from "react";
import { Link, useLocation } from "react-router-dom";
import { arrMenu } from "../../../../scripts/js/menu";
import { useAppSelector } from "@redux/hooks";
interface PairPurposeClassName {
  HandlerNavbarCollapse: any;
}

const MenuPrincipal = (props: PairPurposeClassName) => {  
  const location = useLocation();
  const { enabledEmit } = useAppSelector((store) => store.account);
  const { cart } = useAppSelector((store) => store.session);

  return (
    <>
      {arrMenu?.map((x, index) => {
        return (
          <li
            key={index}
            className="nav-item menu-home-pr"
            onClick={() => props.HandlerNavbarCollapse}
          >
            {!enabledEmit &&
            (x.url === "/buy-certificate" || x.url === "/homecart") ? (
              <Link
                className={`nav-link disabled ${x.url === location.pathname}`}
                to={x.url}
              >
                {x.title} <i className={x.iclass} />
                {x.showShoppingCartQuantity &&
                  cart?.items &&
                  cart?.items.length > 0 && (
                    <span className="container-cart-counter">
                      <span className="cart-counter">{cart?.items.length}</span>
                    </span>
                  )}
              </Link>
            ) : (
              <Link
                className={`nav-link ${
                  x.url === location.pathname ? "active" : ""
                }`}
                to={x.url}
              >
                {x.title} <i className={x.iclass} />
                {x.showShoppingCartQuantity &&
                  cart?.items &&
                  cart?.items.length > 0 && (
                    <span className="container-cart-counter">
                      <span className="cart-counter">{cart?.items.length}</span>
                    </span>
                  )}
              </Link>
            )}
          </li>
        );
      })}
    </>
  );
};

export default MenuPrincipal;
