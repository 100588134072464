import './HomeQuote.scss';

import React from 'react';

import currency from 'currency.js';

interface Props {
  text: string;
  value: string;
  isCurrency?: boolean;
}
export default function LabelValue({ text, value, isCurrency }: Props) {
  return (
    <div className="border-div d-flex align-items-center">
      <label htmlFor="currency" className="fs-12 px-2 label-currency">
        {text} :{" "}
        <b>
          {" "}
          {isCurrency
            ? currency(value, {
                symbol: "$",
                decimal: ".",
                separator: ".",
                precision: 0,
              }).format()
            : value}
        </b>
      </label>
    </div>
  );
}
