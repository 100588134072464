import React from "react";
import { Link } from "react-router-dom";

interface PropsFrecuenQuestions {
  isMobile?: boolean
}
const FrecuenQuestions = (props: PropsFrecuenQuestions = {isMobile: false}) => {
  if(props.isMobile) {
    return (
      <Link className="nav-link" to="/faq">
        <i className="fas fa-question-circle me-2"></i>Preguntas frecuentes
      </Link>
    );
  }

  return (
    <Link className="nav-link " to="/faq">
        Preguntas frecuentes <i className="fas fa-question-circle ms-2"></i>
    </Link>
  );
};

export default FrecuenQuestions;
