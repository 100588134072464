import React from "react";
import { MButton, MPopup, MTitle } from "@inversiones-ma/finfast-react-controls";
import mainImage from "@img/icons/error-sign.svg";

export interface ModalRestrictedMandante{
    showModal : boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const RestrictedMandante = ({ showModal, setShowModal }: ModalRestrictedMandante) => {
    
    return (
        <MPopup
            size="lg"
            showClose={true}
            showModal={showModal}
            setShowModal={setShowModal}
        >
            <div className="text-center">
                <img src={mainImage} alt="Error generando Certificado de Fianza Técnica"  className="mb-2"/>
                <MTitle position="center" hr>
                    Ocurrio un problema 
                    <br/>
                    contacta a tu ejecutiva
                </MTitle>
                <p>No se puede generar el certificado, para obtener más información comunicate con tu ejecutiva</p>
                <MButton
                    className="my-2 w-25"
                    color="primary"
                    type="button"
                    onClick={()=> setShowModal(false)}
                >
                    {/* <i className="fa fa-refresh" aria-hidden="true"></i>  */}
                    Entiendo
                </MButton>
            </div>
        </MPopup>
    );
};

