import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { MButton } from '@inversiones-ma/finfast-react-controls';
import { loadCart } from '@redux/actions/SessionActions';
import { useAppDispatch } from '@redux/hooks';

import { BuyCartHook } from '../partial/Hook/BuyCartHook';

const PaymentError = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { resetStepToPay } = BuyCartHook();

  useEffect(() => {
    let newCart = { items: [], discountCode: undefined };
    dispatch(loadCart(newCart));
    // eslint-disable-next-line
  }, []);

  const handlerRetryPay = () => {
    resetStepToPay()
    history.push("/homecart");
  }

  return (
    <>
      <div className="col-12 col-lg-12 my-4">
        <div className="rounded-card-bg p-3 p-md-5 ">
          <div className="msg-validation-matriz error">
            <i
              className="fa fa-exclamation-triangle color-warning fa-lg mb-3"
              aria-hidden="true"
              style={{ fontSize: 70 }}
            ></i>
            <h4 className="titles-center">Tu pago no ha sido realizado</h4>
            <p className="py-2">
              Por favor, inténtalo nuevamente. Si el error persiste, <br />{" "}
              intenta usando otro medio de pago.
            </p>
            <div className="row justify-content-center">
              <div className="col-12 col-md-4  order-1 order-md-0 my-auto py-2">
                <MButton className="w-100" onClick={handlerRetryPay}>
                  Reintentar
                </MButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentError;
