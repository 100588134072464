import React, { useEffect } from "react";
import CreateFilter from "./partial/CreateFilter";
import MyFilters from "./partial/MyFilters";
import Notifications from "./partial/Notifications";
import ApiFilter from "@api/filter/Filter";
import { useAppDispatch } from "@redux/hooks";
import { loadFilters } from "@redux/actions/FilterActions";
import ApiTypeTender from "@api/global/TypeTender";
import * as GlobalActions from "@redux/actions/GlobalActions";
import "./Filter.scss";
import Breadcrumbs from "../NewCertificate/partial/Breadcrumbs";

function Filter() {
  const dispatch = useAppDispatch();

  async function GetAllFiltersByRut() {
    await ApiFilter.GetAll().then((response) => {
      dispatch(loadFilters(response.dataList));
    });
  }

  useEffect(() => {
    GetAllFiltersByRut();
    GetTypeTender();
    // eslint-disable-next-line
  }, []);

  async function GetTypeTender() {
    await ApiTypeTender.GetAll().then((response) => {
      dispatch(GlobalActions.loadTypeTender(response.dataList));
    });
  }

  return (
    <div className="parentFilter">
      <main className="bg-home">
        <div className="container">
          <div className="row">
            <Breadcrumbs
              paginaPrincipal="Filtrar licitaciones"
              paginaActual=""
              paginaInicial="/"
            ></Breadcrumbs>            
            <div className="col-12">
              <h2 className="titles-left">FILTRAR licitaciones</h2>
              <p>
                Crea tus filtros y recibe las licitaciones publicadas por el
                Mercado Público
              </p>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-12 col-lg-4">
              <Notifications />
              <div className="pt-4 pb-4">
                <MyFilters />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <CreateFilter />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Filter;
