import { useAppSelector, useAppDispatch } from "@redux/hooks";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ApiOperation from "@api/garantias/operation/Operation";
import GenericBaseModel from "@api/GenericBaseModel";
import {
  Discount,
  DiscountCode,
  DiscountValue,
} from "@redux/models/SessionModels";
import { isEqual } from "lodash";
import {
  loadCart,
  loadDiscountCode,
  loadDiscountColor,
  loadDiscountError,
  loadDiscountMessage,
  loadDiscountRequest,
  loadDiscountSuccess,
  loadDiscountValue,
} from "@redux/actions/SessionActions";
import { MButton, MInputGeneric } from "@inversiones-ma/finfast-react-controls";
import ReactGA from "react-ga4";
import { OperacionDTO } from "Models/Operation";

interface IFormInput {
  discount: string;
}

const DiscountZone = () => {
  const dispatch = useAppDispatch();

  const { color, isError, isSuccess, message } = useAppSelector(
    (state) => state.session.discountZone
  );
  const { cart, discountCode } = useAppSelector((store) => store.session);

  const form = useForm<IFormInput>({ mode: "onChange" });
  const { handleSubmit, setValue } = form;

  const [inRequest, setInRequest] = useState<boolean>(false);

  const reemision: boolean | undefined = cart?.items.some(
    (z: OperacionDTO) => z.isReissue !== null
  );

  // const validateDiscountCart = async () => {
  //   const result = await validateDiscount(
  //     discountCode?.codigo ?? "",
  //     cart!
  //   );
  //   if (result && result?.success) {
  //     dispatch(loadDiscountColor("#28a745"));
  //     setDisccountSuccess(true);
  //     setDisccounMessage("Tu descuento se ha aplicado exitosamente");
  //     dispatch(loadDiscountCode(result.dataDiscount));
  //     dispatch(loadDiscountValue(result.discountValue));
  //     dispatch(loadCart(result.newCart));
  //   } else {
  //     setDisccountError(true);
  //     setDisccountColor("#dc3545");
  //     setDisccounMessage("Tu código de descuento no es válido");
  //   }
  // };

  useEffect(() => {
    if (discountCode && discountCode?.codigo) {
      setValue("discount", discountCode.codigo);
    } else {
      setValue("discount", "");
    }
  }, [setValue, discountCode]);

  const onSubmit = async (data: IFormInput) => {
    if (!reemision) {
      setInRequest(true);
      dispatch(loadDiscountRequest(true));

      ReactGA.event({
        action: "Aplicar un descuento",
        category: "Carrito",
        label: "Ingresar un código de descuento",        
      });

      let licitacionIds = cart?.items.map((item) => item.id);

      await ApiOperation.ValidateDiscountOperation(        
        data.discount,
        licitacionIds
      )
        .then((response: GenericBaseModel<Discount>) => {
          if (response.success) {
            dispatch(loadDiscountColor("#28A745"));
            dispatch(loadDiscountSuccess(true));
            dispatch(
              loadDiscountMessage("Tu descuento se ha aplicado exitosamente")
            );
            const dataDiscount: DiscountCode = {
              codigo: response.data.ticketDescuento?.codigo,
              typeDiscount: !response.data.ticketDescuento?.descuentoPorcentaje
                ? "CLP"
                : "%",
              valDiscount: !response.data.ticketDescuento?.descuentoPorcentaje
                ? response.data.ticketDescuento?.descuentoCLP
                : response.data.ticketDescuento?.descuentoPorcentaje,
            };

            let validateDiscountAmount = 0;

            if (dataDiscount && isEqual(dataDiscount.typeDiscount, "%")) {
              validateDiscountAmount = response.data.listOperacion
                .map((op) => op.descuentoTicket)
                .reduce((acc, desc) => acc + desc);
            } else {
              let valdiscount = 0;
              if (typeof dataDiscount.valDiscount !== "undefined") {
                valdiscount = dataDiscount.valDiscount;
              }

              validateDiscountAmount =
                response.data.listOperacion
                  .map((op) => op.descuentoTicket)
                  .reduce((acc, desc) => acc + desc) > valdiscount
                  ? dataDiscount.valDiscount
                  : response.data.listOperacion
                      .map((op) => op.descuentoTicket)
                      .reduce((acc, desc) => acc + desc);
            }

            const newCart = {
              items: response.data.listOperacion,
              discountCode: cart?.discountCode,
            };

            const discountValue: DiscountValue = {
              couponCode: response.data.ticketDescuento?.codigo,
              discountCLP: response.data.ticketDescuento?.descuentoCLP,
              discountPercent:
                response.data.ticketDescuento?.descuentoPorcentaje,
              discountTicket: validateDiscountAmount,
            };

            dispatch(loadDiscountCode(dataDiscount));
            dispatch(loadDiscountValue(discountValue));
            dispatch(loadCart(newCart));
          } else {
            dispatch(loadDiscountError(true));
            dispatch(loadDiscountColor("#DC3545"));
            dispatch(
              loadDiscountMessage("Tu código de descuento no es válido")
            );
          }
        })
        .catch((error) => {
          console.error("error al consumir api: ", error);
        })
        .finally(() => {
          dispatch(loadDiscountRequest(false));
          setInRequest(false);
        });
    } else {
      dispatch(loadDiscountValue(undefined));
      dispatch(loadDiscountError(true));
      dispatch(loadDiscountColor("#DC3545"));
      dispatch(loadDiscountMessage("Tu código de descuento no es válido"));
    }
  };

  return (
    <>
      <div className="col-12 col-lg-7"></div>
      <div className="col-12 col-lg-5 my-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rounded-card-bg py-3 px-3 m-0 p-md-5">
            <div className="row">
              <div className="col-12">
                <h3 className="subtitle-mini text-center">Zona descuentos</h3>
              </div>
              <div className="col-12 text-center">
                <p>¿Tienes un código de descuento? Úsalo aquí</p>
              </div>
              <div className="col-12">
                <div className="input-group">
                  <div className="msj-detalle-descuento">
                    <div className="row">                    
                      <div
                        className="col-6"
                        style={{ color: color } as React.CSSProperties}
                      >
                        <MInputGeneric
                          form={form}
                          name="discount"
                          label="Codigo descuento"
                          type="text"
                          placeholder="Codigo descuento"
                          maxLength={30}
                          className="w-100"                          
                          rules={{
                            required: "Ingrese un código",
                          }}
                          pureInput={true}
                        ></MInputGeneric>
                      </div>
                      <div className="col-6">
                        <span className="bg-white border-left-0 input-border">
                          <MButton
                            type="submit"
                            className="btnAplicar"
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              boxShadow: "inherit",
                            }}
                            loading={inRequest}
                          >
                            Aplicar
                          </MButton>
                          {/* <input
                            name="discount"
                            className="btnAplicar"
                            type="submit"
                            value="Aplicar"
                          ></input> */}
                        </span>
                      </div>
                      <div className="col-12">
                        {isSuccess && (
                          <p className="textoSuccessDisccountZone">{message}</p>
                        )}
                        {isError && (
                          <p className="textoErrorDisccountZone">{message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DiscountZone;
