import {
  ActionStepReissue,
  NewCertificateState,
  SearchType,
  Step,
  StepReissue,
} from "./../models/NewCertificateModels";
import { createSlice } from "@reduxjs/toolkit";
import {
  cleanState,
  setCurrentStep,
  setSelectedData,
  setTenderData,
  setTenderDataWithoutOriginal,
  setTenders,
  setQuote,
  setCurrentStepReissue,
  setValueCommissionSelected,
  cleanQuote,
} from "../actions/NewCertificateActions";

const inicialState: NewCertificateState = {
  selectedData: {
    searchType: SearchType.IdLicitacion,
    searchTerm: "",
    carroDeCompras: false,
    licitacionValida: false,
  },
  currentStep: Step.cotizar,
  tenderData: undefined,
  originalTenderData: undefined,
  tenders: [],
  newCertificateFromQuote: undefined,
  currentStepReissue: {
    stepReissue: StepReissue.seleccionar,
    action: ActionStepReissue.agregar,
  },
  valueCommissionSelected: undefined,
};

const slice = createSlice({
  name: "new_certificate",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cleanState, (state) => {
        state.selectedData = inicialState.selectedData;
        state.currentStep = inicialState.currentStep;
        state.tenderData = inicialState.tenderData;
        state.originalTenderData = inicialState.originalTenderData;
        state.tenders = inicialState.tenders;
        state.newCertificateFromQuote = inicialState.newCertificateFromQuote;
      })
      .addCase(setSelectedData, (state, action) => {
        state.selectedData = action.payload;
      })
      .addCase(setCurrentStep, (state, action) => {
        state.currentStep = action.payload;
      })
      .addCase(setTenderData, (state, action) => {
        state.tenderData = action.payload;
        state.originalTenderData = action.payload;
      })
      .addCase(setTenderDataWithoutOriginal, (state, action) => {
        state.tenderData = action.payload;
      })
      .addCase(setTenders, (state, action) => {
        state.tenders = action.payload;
      })
      .addCase(setQuote, (state, action) => {
        state.newCertificateFromQuote = action.payload;
      })
      .addCase(cleanQuote, (state, action) => {
        state.newCertificateFromQuote = inicialState.newCertificateFromQuote;
      })
      .addCase(setCurrentStepReissue, (state, action) => {
        state.currentStepReissue = action.payload;
      })
      .addCase(setValueCommissionSelected, (state, action) => {
        state.valueCommissionSelected = action.payload;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
