import React, {
  useEffect,
  useState,
} from 'react';

import * as NewCertificateActions from '@redux/actions/NewCertificateActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import * as NewCertificateModels from '@redux/models/NewCertificateModels';

import EnterData from '../../NewCertificate/partial/EnterData';
import VerifyData from '../../NewCertificate/partial/VerifyData';
import EnterDataReissue from '../../Reissue/EnterDataReissue';
import VerifyDataReissue from '../../Reissue/VerifyDataReissue';

interface PropsEditItemCart {
  id: number;
  nroCft?: string;
  close: () => void;
  action?: string;
}

const AutoScrollInPopUp = () => {
  useEffect(() => {
    let modal = document.querySelector(".modal");
    if (modal) {
      modal.scrollTop = 0;
    }
  }, []);
  return <></>;
};

const EditItemCart = (props: PropsEditItemCart) => {
  const dispatch = useAppDispatch();

  const [isLoaded, setIsLoaded] = useState(false);

  const { cart } = useAppSelector((store) => store.session);

  const item = cart?.items.find((x) => x.id === props.id);
  useEffect(() => {
    dispatch(NewCertificateActions.setSelectedData(undefined));
    dispatch(NewCertificateActions.setTenderData(item));
    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { currentStep, currentStepReissue } = useAppSelector(
    (store) => store.newCertificate
  );
  return (
    <>
      <div className="p-4 modal-pago-certificado">
        {isLoaded && (
          <>
            {item?.isReissue ? (
              <>
                {currentStepReissue.stepReissue ===
                  NewCertificateModels.StepReissue.seleccionar && (
                  <>
                    <AutoScrollInPopUp />
                    <EnterDataReissue
                      nroCft={props.nroCft}
                      close={props.close}
                      action={currentStepReissue.action}
                    />
                  </>
                )}
                {currentStepReissue.stepReissue ===
                  NewCertificateModels.StepReissue.verificar && (
                  <>
                    <AutoScrollInPopUp />
                    <VerifyDataReissue
                      close={props.close}
                      action={props.action}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {currentStep === NewCertificateModels.Step.ingresar && (
                  <>
                    <AutoScrollInPopUp />
                    <EnterData
                      close={props.close}
                      addOrEdit={currentStep}
                      origen="cart"
                    />
                  </>
                )}
                {currentStep === NewCertificateModels.Step.verificar && (
                  <>
                    <AutoScrollInPopUp />
                    <VerifyData
                      addOrEdit={currentStep}
                      close={props.close}
                      origen="verifyOperation"
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EditItemCart;
