import React, { useState } from "react";
import { MButton, MLoadingLight, MPopup } from "@inversiones-ma/finfast-react-controls";
import deleteTenderIcon from "@img/icon-filter-delete.svg";
import ApiLicitacion from "@api/licitacion/Licitacion";
interface PropsModal {
  showModal: boolean;
  setShowModal: any;
  codigoExterno: string;
  origen: number;
  setLoadTenders: any;
  loadTenders: boolean;
}

function MAlertPopupDeleteTenderConfirm(props: PropsModal) {
  const [isLoading, setIsLoading] = useState(false);
  const deleteTender = (codigoExterno: string, origen: number) => {
    setIsLoading(true);
    const data = {
      codigoExterno,
      origen,
    };
    ApiLicitacion.deleteTender(data).then((response) => {
      if (response.success) {
        setIsLoading(false);
        props.setLoadTenders(!props.loadTenders);
        props.setShowModal(false);
      }
    });
  };

  return (
    <MPopup
      size="lg"
      showModal={props.showModal}
      setShowModal={props.setShowModal}
      showClose={true}
    >
      <div className="text-center clase-tender" style={{ marginTop: 100 + 'px' } as React.CSSProperties}>
        <img
          src={deleteTenderIcon}
          alt="datos confirmados"
          className="icon-pop-up-el"
        />
        <h4 className="titles-center">
          Eliminar Licitacion
        </h4>
        <p className="py-3">
          La licitación no volvera a aparecer en tus busquedas
        </p>

        <div className="row justify-content-center">
          <div className="col-12 col-md-4 order-1 order-md-0 my-auto py-2">
            <MButton
              outline={true}
              type="button"
              onClick={() => props.setShowModal(false)}
              className="w-100"
            >
              Cancelar
            </MButton>
          </div>
          <div className="col-12 col-md-4 order-0 order-md-1 py-2">
            <MButton
              type="button"
              className="w-100"
              onClick={() => deleteTender(props.codigoExterno, props.origen)}
            >
              <MLoadingLight loading={isLoading} className="mr-load" />
              Eliminar
            </MButton>
          </div>
        </div>
      </div>
    </MPopup>
  );
}

export default MAlertPopupDeleteTenderConfirm;
