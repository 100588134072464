import React from "react";
import { Router } from "react-router-dom";

import "./styles/app.scss";
import "./styles/custom.scss";
import "./styles/breadcrumb.css";
import "./styles/tabs.css";
// import './styles/alerts.css';
import "./styles/modals.css";
import "./styles/new-user.css";
import "./styles/tables.css";
import "./styles/check-radios.css";
import "./styles/status-icons-and-colors.css";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import parameters from "./scripts/config/parameters";
import { Provider } from "react-redux";
import store from "@redux/store/store";
import Routing from "./Routing";
import { History } from "history";
import AutoScrollToTop from "./components/features/AutoScrollToTop";
import imgArrowScroll from "./img/fecha_scroll.svg";
import ScrollToTop from "react-scroll-up";
import ReactGA from "react-ga4";

const App = (props: { history: History }) => {  
  props.history.listen((page) => {
    ReactGA.event({
      category: "App Garantias",
      label: "navegacion en sitio",
      action: `Navegación en ${page.pathname}`,
    });
  });

  return (
    <GoogleReCaptchaProvider reCaptchaKey={parameters.CODE_RECAPTCHA}>
      <Provider store={store}>
        <Router history={props.history}>
          <AutoScrollToTop />
          <ScrollToTop
            showUnder={160}
            style={{ bottom: 88, right: 12, zIndex: 1 } as React.CSSProperties}
          >
            <img alt="Flecha hacia arriba" src={imgArrowScroll}></img>
          </ScrollToTop>
          <Routing />
        </Router>
      </Provider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
