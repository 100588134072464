import React from "react";
import { MAlert } from "@inversiones-ma/finfast-react-controls";

interface PropsAlertReissueBlock {
  purposeAnticipo: boolean;
}

const AlertReissueBlock = (props: PropsAlertReissueBlock) => {
  return (
    <div className="mt-2 mb-2">
      {props.purposeAnticipo ? (
        <MAlert color="info" className="slim">
          <p className="textAlert">
            Si necesitas corregir alguno de los campos bloqueados o reemitir
            para la <strong>finalidad anticipo</strong>, contáctate con tu
            ejecutiva para revisar esta modificación.
            <br />
            Cambios menores como:{" "}
            <strong>
              glosa, razón socialo dirección, la primera reemisión es $0
            </strong>
            .
          </p>
        </MAlert>
      ) : (
        <MAlert color="info" className="slim">
          <p className="textAlert">
            Si necesitas corregir alguno de los campos bloqueados,
            <br />
            contáctate con tu ejecutivo para revisar esta modificación.
          </p>
        </MAlert>
      )}
    </div>
  );
};

export default AlertReissueBlock;
