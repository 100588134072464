import { createSlice } from "@reduxjs/toolkit";

import { loadFilters, setSelectedDataFilter } from "../actions/FilterActions";

import { FilterState } from "../models/FilterModels";

const inicialState: FilterState = {
  filters: undefined,
  selectedDataFilter: undefined,
};

const slice = createSlice({
  name: "filter",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadFilters, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(setSelectedDataFilter, (state, action) => {
        state.selectedDataFilter = action.payload;
      });
  },
});

export default slice.reducer;
