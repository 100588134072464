export interface TributaryInformation {
  rutProveedor?: string;
  usuario?: string;
  password: string;
  eCTESource: TypeCTESource;
}

export enum TypeCTESource {
  garantias,
  factoring_completeSubscription,
  myAccount,
}

export interface CTEResponse {
  exito: boolean;
  codigo: string;
  mensaje: string;
  cTEResponseAditionalData: CTEResponseAditionalData;
  cTECustomerSubscriptionResponse: CTECustomerSubscriptionResponse;
}

interface CTEResponseAditionalData {
  customerRutFormat: string;
  customerRznSocial: string;
  validateSubscriptionCTE: boolean;
  alreadySubscribedCTE: boolean;
  finalTimeInSeconds: number;
  subscriptionCTEMsg: string;
  lastStateCheck: string;

  lastStateCheckCTE: string;
  numberOfRetries: number;

  waitingTimeBetweenAttemptsCTESubscription: number;
  success: boolean;
  message: string;
}

interface CTECustomerSubscriptionResponse {
  /// <summary>
  /// Estado de la llamada a la Api. Codigo 0 = Respuesta satisfactoria
  /// </summary>
  codigo: string;

  mensaje: string;

  /// <summary>
  /// Identificador de la solicitud de subscripción (SOLO para Subscripcion Asíncrona)
  /// </summary>
  solicitudId: string;
}
