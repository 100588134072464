import './gridMobile.scss';

import React, { useState } from 'react';

import { convertToPeso } from '@helpers/helpers';
import {
  MButton,
  MPopup,
} from '@inversiones-ma/finfast-react-controls';

import { OperacionDTO } from '../../../../../../Models/Operation';
import {
  setSelectedIssuer,
} from '../../../../../../scripts/redux/actions/SessionActions';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../scripts/redux/hooks';
import { items } from '../BuyCart';
import DeleteItemCart from '../DeleteItemCart';
import EditItemCart from '../EditItemCart';

interface Iprops {
  cart?: OperacionDTO[];
  title: string;
  issuer: string;
  setEdit: (edit: boolean) => void;
  setStateItem: (item: Partial<OperacionDTO>) => void;
  stateItem: Partial<items>;
  typeGrid: string;
  resetStepToPay: Function
  handleEmisor : Function
}

const GridMobile = ({ cart, typeGrid, issuer, title, resetStepToPay, handleEmisor }: Iprops) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [delet, setDelete] = useState<boolean>(false);
  const [valueItem, setValueItem] = useState<number>(0);
  const { selectedIssuer } = useAppSelector((store) => store.session);

  const dispatch = useAppDispatch();

  const handlerEdit = (id: any) => {
    setValueItem(id);
    setEdit(!edit);
  };

  const handlerDelete = (id: any) => {
    setValueItem(id);
    setDelete(!delet);
  };

  const hanlerCloseEdit = () => {
    setEdit(!edit);
  };

  const handleOptionChange = (issuer: string) => {
    dispatch(setSelectedIssuer(issuer));
    resetStepToPay()
  };

  return (
    <>
      <div className="rounded-card-bg  px-md-3 py-md-1 m-2 p-4 grid-mobile">

        {typeGrid === 'both' && (<>
          <input
            type="radio"
            name={"selectMobile"}
            value={issuer}
            onChange={() => handleOptionChange(issuer)}
            defaultChecked={issuer === selectedIssuer ? true : false}
            />
          <label className='subtitle-mini fs-16 mx-1'>{title}</label></>)
        }
        {cart?.map((x, index) => (
          <div
            key={index}
            className="card-movil-tabla-garantias card-movil-tabla mx-0 carrito-movil my-2 m-3"
          >
            <div className="detalles-movil-tabla  pb-4">
              <div className="col-12">
                <h4>Certificado</h4>
                <p className="">{x.nroCertificado}</p>
                <p style={{marginTop: "-20px"}}>{handleEmisor(x)}</p>
              </div>
              <hr className="px-2"></hr>
              <div className="col-12">
                <h4>Mandante</h4>
                <p>{x.nombreOrganismoMandante}</p>
              </div>
              <hr className="px-2"></hr>
              <div className="col-12">
                <h4>Finalidad</h4>
                <p>{x.finalidad}</p>
              </div>
              <hr className="px-2"></hr>
              <div className="col-12">
                <h4>Comisión</h4>
                <p>{convertToPeso(x.comision)}</p>
              </div>
              <hr className="px-2"></hr>
              <div className="col-12">
                <h4>Monto</h4>
                <p>{convertToPeso(x.montoCertificado)}</p>
              </div>
            </div>
            <div className="movil-tablas-garantias botones-movil-carritos">
              <MButton
                type="button"
                outline
                aria-expanded="false"
                onClick={() => handlerEdit(x.id)}
                // disabled={
                //   x.idAseguradora === null || x.idAseguradora === 0 ? false : true
                // }
              >
                Editar
              </MButton>
              <MButton
                type="button"
                aria-expanded="false"
                onClick={() => handlerDelete(x.id)}
                outline
              >
                Eliminar
              </MButton>
            </div>
          </div>
        ))}
        <MPopup
          showClose={true}
          setShowModal={setEdit}
          showModal={edit}
          size="md"
        >
          <EditItemCart id={valueItem} close={hanlerCloseEdit}></EditItemCart>
        </MPopup>
        <MPopup
          showClose={true}
          setShowModal={setDelete}
          showModal={delet}
          size="md"
        >
          <DeleteItemCart id={valueItem} close={handlerDelete} />
        </MPopup>
      </div>
    </>
  );
};

export default GridMobile;
