import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import finfastLogo from "@img/finfast-logo.svg";
import FinfastProductSelector from "../../../pages/Private/FinfastProductSelector/FinfastProductSelector";
import Client from "./Client";
import Logout from "./Logout";
import FrecuenQuestions from "./FrecuentQuestions";
import Contact from "./Contact";
import MenuPrincipal from "./MenuPrincipal";
import { useAppSelector } from "@redux/hooks";
import { capitalizeTheFirstLetterOfEachWord } from "@helpers/helpers";

const MenuPartialSelector = () => {

  const { name } = useAppSelector((store) => store.account);
  const [collapseMenu, setcollapseMenu] = useState<boolean>(false)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    HandlerNavbarCollapseTrue();
  }, [collapseMenu]);

  function HandlerNavbarCollapse() {    
    setcollapseMenu(false)
  }
  function HandlerNavbarCollapseTrue() {
    setcollapseMenu(true)
  }

  function goMultiProduct(){
    window.open('https://www.finfast.com/home', '_self');
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link className="navbar-brand" onClick={goMultiProduct} to="https://www.finfast.com/home">
          <img
            src={finfastLogo}
            alt="Logo Finfast Servicios Financieros"
            className="logo-header"
          ></img>
        </Link>

        <FinfastProductSelector defaultName="GARANTÍAS" idProduct={1} />

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={!!collapseMenu ? "navbar-collapse collapse" : "navbar-collapse collapse show"} id="navbarNavDropdown">
          <ul className="navbar-nav navbar-right">
            <li className="nav-item dropdown menu-home-pr d-block d-lg-none mb-2 menu-login-name">
              <small className="p-0 m-0">Iniciaste sesión como: </small>
              <p className="fw-bold">{capitalizeTheFirstLetterOfEachWord(name)}</p>
            </li>
            <hr className="my-0"></hr>
            <MenuPrincipal HandlerNavbarCollapse={HandlerNavbarCollapse}></MenuPrincipal>
            <hr className="my-0"></hr>
            <li className="nav-item dropdown menu-home-pr d-block d-lg-none" >
              <Client isMobile={true} />
            </li>
            <hr className="my-0"></hr>
            <li className="nav-item menu-home-pr d-block d-lg-none" onClick={HandlerNavbarCollapse} >
              <FrecuenQuestions isMobile={true} />
            </li>
            <hr className="my-0"></hr>
            <li className="nav-item dropdown menu-home-pr d-block d-lg-none" >
              <Contact isMobile={true} />
            </li>
            <hr className="my-0"></hr>
            <li className="nav-item menu-home-pr d-block d-lg-none">
              <Logout isMobile={true} />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MenuPartialSelector;
