import React from "react";
import { MButton } from "@inversiones-ma/finfast-react-controls";

interface PropsPrincipalError {
  showModal?: boolean;
  setShowModal?: any;
}

const PrincipalError = (props: PropsPrincipalError) => {
  return (
    <>
      <div className="message-error-data-rep">
        <i className="fa fa-exclamation-triangle"></i>
        <h4>EL RUT INGRESADO NO ESTA HABILITADO</h4>
        <hr className="title-divider mx-auto" />
        <p className="my-4">
          No es posible emitir un certificado para este mandante. Contáctate con
          tu ejecutiva para mayor información.
        </p>
      </div>
      <div className="text-center col-12 bnt-user-no-register">
        <MButton color="primary" type="button" onClick={() => props.setShowModal(false)}>
          Entiendo
        </MButton>
      </div>
    </>
  );
};

export default PrincipalError;