import { createAction } from "@reduxjs/toolkit";

export const setLoadFirstDataGrid = createAction(
  "QUOTE_LOAD_FIRST_DATA_GRID",
  (data: any[]) => {
    return {
      payload: data,
    };
  }
);

export const setLoadSecondDataGrid = createAction(
  "QUOTE_LOAD_SECOND_DATA_GRID",
  (data: any[]) => {
    return {
      payload: data,
    };
  }
);

export const setCurrentQuoteId = createAction(
  "QUOTE_SET_CURRENT_QUOTE_ID",
  (id: number) => {
    return {
      payload: id,
    };
  }
);

export const cleanState = createAction("QUOTE_CLEAN_STATE");
