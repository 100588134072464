import {
  MGridColumn,
  MGridPaginated,
  MLoadingLight,
  MTooltip,
} from "@inversiones-ma/finfast-react-controls";
import React from "react";
import { convertToCurrency, toDate } from "@helpers/helpers";
import { Paginador } from "../../../../../../Models/Paginador";
import { OperacionDTO } from "../../../../../../Models/Operation";
interface Iprops {
  accidentCertificates: Paginador<OperacionDTO>;
  setOnChangeNumberPage: (b: number) => any;
  setOnChangeNumberRecords: (b: number) => any;
  handlerButtonDonwnloadCft: Function;
  handlerButtonDonwnloadInvoice: Function;
  DownloadDocument: any;
  DownloadDocumentID: any;
  DownloadInvoice: any;
  loading: boolean;
}
function VistaEscritorio({
  loading,
  accidentCertificates,
  setOnChangeNumberPage,
  setOnChangeNumberRecords,
  handlerButtonDonwnloadCft,
  DownloadDocumentID,
  DownloadDocument,
  handlerButtonDonwnloadInvoice,
  DownloadInvoice,
}: Iprops) {
  const handleChangeNumberPage = (e: number) => {
    if (e) {
      setOnChangeNumberPage(e);
    }
  };

  const handleChangeNumberRecord = (e: number) => {
    if (e) {
      setOnChangeNumberRecords(e);
    }
  };
  return (
    <div>
      {accidentCertificates && accidentCertificates.records.length > 0 && (
        <MGridPaginated
          paginated={accidentCertificates!}
          keyExpr="nroCertificado"
          key="nroCertificado"
          onChangeShowMore={() => {}}
          onChangeNumberPage={(e: number) => handleChangeNumberPage(e)}
          onChangeNumberRecords={(e: number) => handleChangeNumberRecord(e)}
          isLoadingPaginated={loading}
          className="head-table-color"
        >
          <MGridColumn
            dataField="nroCertificado"
            label="Certificado"
          ></MGridColumn>
          <MGridColumn
            dataField="codigoExterno"
            label="Id Licitación"
          ></MGridColumn>
          <MGridColumn
            dataField="nombreOrganismoMandante"
            label="Mandante"
            headerClassName="center-header"
            className="text-center"
          ></MGridColumn>
          <MGridColumn dataField="finalidad" label="Finalidad"></MGridColumn>
          <MGridColumn dataField="tipoMoneda" label="Moneda"></MGridColumn>
          <MGridColumn
            dataField="montoCertificado"
            label="Monto"
            cellRender={(_k: any, value: any, row: any): any => {
              return convertToCurrency(row.tipoMoneda, value);
            }}
          ></MGridColumn>
          <MGridColumn
            className="col "
            dataField="fechaTermino"
            label="Término"
            cellRender={(_k: any, value: any) => {
              return <> {toDate(value)} </>;
            }}
          ></MGridColumn>
          <MGridColumn
            className="col text-center"
            dataField="codigoDec5"
            label="Certificado"
            headerClassName="center-header"
            cellRender={(_k: any, value: any) => {
              return (
                <>
                  {DownloadDocument && _k === DownloadDocumentID && (
                    <>
                      <MLoadingLight />
                    </>
                  )}
                  {value && _k !== DownloadDocumentID && (
                    <>
                      <button
                        className="btn px-3 descarga-factura-tabla"
                        onClick={() => handlerButtonDonwnloadCft(_k)}
                      >
                        <i
                          className="fas fa-file-alt fs-5"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </>
                  )}
                  {!value && (
                    <>
                      <i
                        className="fas fa-file-alt fs-5 disabled"
                        aria-hidden="true"
                      ></i>
                    </>
                  )}
                </>
              );
            }}
          ></MGridColumn>
          <MGridColumn
            className="col text-center"
            dataField="tieneFactura"
            label="Factura Certificado"
            headerClassName="center-header"
            cellRender={(_k: any, value: any) => {
              return (
                <>
                  {DownloadInvoice && _k === DownloadDocumentID && (
                    <>
                      <MLoadingLight />
                    </>
                  )}
                  {value && (
                    <button
                      onClick={() => handlerButtonDonwnloadInvoice(_k)}
                      className="btn px-3 descarga-factura-tabla"
                    >
                      <i
                        className="fas fa-file-download fs-5 boton-certificado"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  {value === false && (
                    <>
                      <button className="btn px-3 descarga-factura-tabla sistema">
                        <MTooltip
                          title="Pendiente de Facturación"
                          content=""
                          iconClass="fas fa-file-download disabled cursor-default fs-5"
                        />
                      </button>
                    </>
                  )}
                </>
              );
            }}
          ></MGridColumn>
        </MGridPaginated>
      )}
    </div>
  );
}

export default VistaEscritorio;
