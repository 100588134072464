import { CreateOperation } from "Models/Operation";
import { MAxios, MAxiosFormData } from "../conf";

export const createTenderForIssuance = async () => {
  const endpoint = "Licitacion/CreateTender/CreateTenderForIssuance";

  const res = await MAxios().get(endpoint);

  return res.data;
};

export const AddTenders = async (data: CreateOperation) => {
  const endpoint = "Operacion/Operation/AddTenders";

  const res = await MAxios().post(endpoint, data);

  return res.data;
};

export const listTenders = async () => {
  const endpoint = "Licitacion/CargaListadoLicitaciones/ListTenders";

  const res = await MAxios().get(endpoint);

  return res.data;
};
export const deleteTender = async (dataJsonOperation: any) => {
  const endpoint = "Licitacion/LicitacionProveedorEliminadas/Add";
  let formData = new FormData();
  formData.append("CodigoExterno", dataJsonOperation.codigoExterno.toString());
  formData.append("Origen", dataJsonOperation.origen);

  const res = await MAxiosFormData().post(endpoint, formData);
  return res.data;
};

const ApiLicitacion = {
  createTenderForIssuance,
  listTenders,
  deleteTender,
};

export default ApiLicitacion;
