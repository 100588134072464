import { MAxios } from "../conf";

const GetAll = async () => {
  const endpoint = "Licitacion/TypeTender/GetAll";

  const res = await MAxios().get(endpoint);

  return res.data;
};

const ApiTypeTender = {
  GetAll,
};

export default ApiTypeTender;
