import ReactGA from "react-ga4";
import ApiHome from "@api/garantias/home";
import QuoteBaseModel, { EnumPrioridad } from "@redux/models/QuoteModels";
import { ValidateOperations } from "Models/Operation";

//Metodos Negocio
interface PropsCallApiQuote {
  quotation: any;
  setQuoteData: React.Dispatch<React.SetStateAction<ValidateOperations[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleQuote: React.Dispatch<React.SetStateAction<boolean>>;
  setPrioridadCertificado: React.Dispatch<
    React.SetStateAction<EnumPrioridad | undefined>
  >;
}
const callApiQuote = (props: PropsCallApiQuote) => {
  ApiHome.Cotizacion(props.quotation)
    .then((resp: QuoteBaseModel<ValidateOperations>) => {
      if (resp.success) {
        ReactGA.event({
          action: "Crear cotización",
          category: "Cotizaciónes",
          label: "Se ha creado una nueva cotización",
        });
        props.setQuoteData(resp.dataList);
        props.setVisibleQuote(true);
        props.setPrioridadCertificado(resp.prioridad);
      } else {
        props.setQuoteData(resp.dataList);
        props.setVisibleQuote(true);
      }
    })
    .catch((error) => {
      console.error("Error ApiHome.Cotizacion:", error);
    })
    .finally(() => {
      props.setIsLoading(false);
    });
};

export { callApiQuote };
