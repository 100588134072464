import React, { useState, useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { useAppSelector } from "@redux/hooks";
import GridTender from "./Partial/GridTender";
import SearchTender from "./Partial/SearchTender";
import ApiLicitacion from "@api/licitacion/Licitacion";
import GenericBaseModel from "@api/GenericBaseModel";
import { Tender } from "@redux/models/TenderModels";
import {
  loadTendersAdjudicadas,
  loadTendersPublicadas,
} from "@redux/actions/TenderActions";
import Breadcrumbs from "../NewCertificate/partial/Breadcrumbs";
import "./Tenders.scss";

function Tenders() {
  const dispatch = useAppDispatch();
  const { tendersPublicadas, tendersAdjudicadas } = useAppSelector(
    (store) => store.tender
  );

  const [tipoTenders, setTipoTenders] = useState("PUBLICADAS");
  const [isFilter, setIsFilter] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handlerCambioTipo = (estado: string) => {
    setTipoTenders(estado);
  };
  const tabsTenders = [
    { id: "PUBLICADAS", label: "Licitaciones publicadas" },
    { id: "ADJUDICADAS", label: "Licitaciones adjudicadas" },
  ];

  useEffect(() => {
    setIsLoading(true);
    ApiLicitacion.listTenders()
      .then((response: GenericBaseModel<Tender>) => {
        if (response.success) {
          const tendersAdjudicadas = response.dataList.filter(
            (t) => t.origenLicitacion === 2
          );
          const tendersPublicadas = response.dataList.filter(
            (t) => t.origenLicitacion === 1
          );
          dispatch(loadTendersAdjudicadas(tendersAdjudicadas));
          dispatch(loadTendersPublicadas(tendersPublicadas));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowSearchBar(false);
  }, [tipoTenders]);

  useEffect(() => {
    if (tipoTenders === "PUBLICADAS" && tendersPublicadas) {
      setShowSearchBar(tendersPublicadas.length > 0);
    } else if (tipoTenders === "ADJUDICADAS" && tendersAdjudicadas) {
      setShowSearchBar(tendersAdjudicadas.length > 0);
    }
    // eslint-disable-next-line
  }, [tendersPublicadas, tendersAdjudicadas]);

  return (
    <main className="bg-home clase-tender">
      <section className="buscar-licitaciones">
        <div className="container">
          <div className="row">
            <Breadcrumbs
              paginaPrincipal="Comprar certificados"
              paginaActual="Buscar licitaciones"
              paginaInicial="/buy-certificate"
            ></Breadcrumbs>
            <div className="col-12">
              <h2 className="titles-left">Buscar licitaciones </h2>
              <p>Ingresa los datos para buscar licitaciones</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 tabs-02">
              <ul
                className="nav nav-tabs02 tabs-garantias rounded"
                id="myTab"
                role="tablist"
              >
                {tabsTenders.map((e, index) => (
                  <li
                    key={index}
                    className="nav-item col-md factura rounded"
                    role="presentation"
                  >
                    <button
                      className={
                        "nav-link  rounded-0" +
                        (e.id === tipoTenders ? " active" : "")
                      }
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#licitaciones-publicadas"
                      type="button"
                      role="tab"
                      aria-controls="licitaciones-publicadas"
                      aria-selected="true"
                      onClick={() => handlerCambioTipo(e.id)}
                    >
                      {e.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            {showSearchBar && (
              <div className="col-12 col-lg-10">
                <SearchTender
                  typeTender={tipoTenders}
                  setIsFilter={setIsFilter}
                  isFilter={isFilter}
                />
              </div>
            )}
            <GridTender
              typeTender={tipoTenders}
              setTypeTender={setTipoTenders}
              isfilter={isFilter}
              isLoading={isLoading}
            ></GridTender>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Tenders;
