import React, { useState } from 'react';

import { OperacionDTO } from 'Models/Operation';

import BaseModel from '@api/BaseModel';
import ApiOperation from '@api/garantias/operation/Operation';
import GenericBaseModel from '@api/GenericBaseModel';
import deletecft from '@img/eliminar-licitaciones-icon.svg';
import { MButton } from '@inversiones-ma/finfast-react-controls';
import {
  cleanDiscountZone,
  loadCart,
  loadDiscountValue,
} from '@redux/actions/SessionActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import { Cart } from '@redux/models/SessionModels';

import { BuyCartHook } from './Hook/BuyCartHook';

interface PropsDeletedItemCart {
  id: number;
  isReissue?: boolean;
  close?: any;
}

const DeleteItemCart = (props: PropsDeletedItemCart) => {
  const dispatch = useAppDispatch();
  const { cart, providerCampaign } = useAppSelector((store) => store.session);

  const [valueItem] = useState<OperacionDTO | undefined>(
    cart?.items?.find((x) => x.id === props.id)
  );
  const [deleteOfCart, setDeleteOfCart] = useState<boolean>(false);
  const { resetStepToPay } = BuyCartHook();

  const handlerRemoveItems = async () => {
    setDeleteOfCart(true);

    await ApiOperation.DeleteOfCart(props?.id, props?.isReissue)
      .then((resp: BaseModel) => {
        if (resp.success) {
          let cart2 = cart?.items.filter(function (value) {
            return value !== valueItem;
          });

          let newCart = {
            items: cart2,
            discountCode: cart?.discountCode,
          };

          if (newCart?.items?.length === 0) {
            resetStepToPay();
            dispatch(loadCart(newCart as Cart));
            props.close();
          } else {
            dispatch(loadCart(newCart as Cart));
            props.close();
          }

          dispatch(loadDiscountValue(undefined));
          dispatch(cleanDiscountZone());
        }
      })
      .catch((error) => console.error("error DeleteOfCart: ", error))
      .finally(() => {
        setDeleteOfCart(false);
      });
  };

  return (
    <div className="msg-validation-matriz error">
      <img
        src={deletecft}
        alt="eliminar-licitacion"
        className="icon-pop-up-el"
      />
      <h4 className="titles-center">¿Quitar Garantía del carro de compra?</h4>
      <p>Podrás agregarlo nuevamente desde Cotizados</p>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 order-1 order-md-0 my-auto py-2">
          <MButton outline onClick={props.close} className="w-100">
            Cancelar
          </MButton>
        </div>

        <div className="col-12 col-md-4 order-0 order-md-1 py-2">
          <MButton
            onClick={handlerRemoveItems}
            className="w-100"
            loading={deleteOfCart}
          >
            Eliminar
          </MButton>
        </div>
      </div>
    </div>
  );
};

export default DeleteItemCart;
