import React, { useEffect, useState } from "react";

import { OperacionDTO } from "Models/Operation";
import { useHistory } from "react-router-dom";

import {
  MButton,
  MCards,
  MPopup,
} from "@inversiones-ma/finfast-react-controls";
import { setCurrentStep } from "@redux/actions/NewCertificateActions";
import * as SessionActions from "@redux/actions/SessionActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Step } from "@redux/models/NewCertificateModels";

import MLoading from "../../../../controls/MLoading";
import HomeBannerSIIClientesAntiguos from "../../SII/Clientes_Antiguos/Home/HomeBannerSIIClientesAntiguos";
import CartBannerCampaign from "./CartBannerCampaign";
import DeleteItemCart from "./DeleteItemCart";
import EditItemCart from "./EditItemCart";
import GridCart from "./Grid/GridCart";
import { BuyCartHook } from "./Hook/BuyCartHook";

const BuyCart = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { providerCampaign, cart, selectedIssuer } = useAppSelector(
    (store) => store.session
  );
  const { stepState } = useAppSelector((store) => store.steps);

  const { clientOmitSii, clientOmitSiiExtraInfo, requireSiiCredentials } =
    useAppSelector((store) => store.account);

  const [hasCampaign, setHasCampaign] = useState<boolean | undefined>(false);

  useEffect(() => {
    dispatch(SessionActions.cleanCartToPay());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoadingCart, setIsLoadingCart] = useState<boolean>(true);

  const {
    validarTipoGrilla,
    updateCart,
    typeGrid,
    gridFinfast,
    gridPolicy,
    setEdit,
    edit,
    setDelete,
    delet,
    stateItem,
    setStateItem,
    deleteShow,
    editShow,
    handlerCloseDelete,
    resetStepToPay,
  } = BuyCartHook();

  const showInfoSiiBuyCart =
    !clientOmitSii && clientOmitSiiExtraInfo && requireSiiCredentials;

  useEffect(() => {
    updateCart();
    validarTipoGrilla();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIssuer]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingCart(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (cart!.items.length > 0) {
      updateCart();
      validarTipoGrilla();
      setIsLoadingCart(false);
    }

    let campaign: boolean | undefined = cart?.items.some(
      (z: OperacionDTO) => z.idCampania !== null
    );
    setHasCampaign(campaign);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const hanlerCloseEdit = () => {
    setEdit(!edit);
  };

  const handlerReset = () => {
    dispatch(setCurrentStep(Step.seleccionar));
    history.push("/buy-certificate");
  };

  return (
    <>
      <div className="col-12 col-lg-7 my-2">
        <div>
          <div className="row">
            {!isLoadingCart && (
              <MCards className="nonBorder py-4 px-2 my-1 mx-0 p-md-5">
                <div className="col-12 text-center">
                  {cart && cart.items.length === 0 ? (
                    <>
                      <h3 className="subtitle-mini text-center">
                        Tu carro de compras está vacío
                      </h3>

                      <p>
                        Puedes recuperarlos desde{" "}
                        <span
                          onClick={() => history.push("/quotes")}
                          className="color-secondary fw-bold text-decoration-underline texto-alert-newCertificate"
                        >
                          Cotizados
                        </span>{" "}
                        de la sección Comprar Certificados, o iniciar una nueva
                        compra.
                      </p>

                      <div className="col-12 text-center">
                        <MButton
                          type="button"
                          className="btn btn-primary sistema external"
                          aria-expanded="false"
                          onClick={handlerReset}
                        >
                          Seguir comprando
                        </MButton>
                      </div>
                    </>
                  ) : (
                    <>
                      {typeGrid === "both" && (
                        <div>
                          <h3 className="subtitle-mini text-center">
                            Selecciona cual de tus Garantías quieres adquirir:
                          </h3>
                        </div>
                      )}

                      {providerCampaign && hasCampaign && (
                        <CartBannerCampaign />
                      )}

                      {["finfast", "sura"].map((issuer) => {
                        const isFinfast = issuer === "finfast";
                        const showGrid =
                          (typeGrid === issuer || typeGrid === "both") &&
                          (stepState.step !== 2 || selectedIssuer === issuer);

                        return (
                          showGrid && (
                            <GridCart
                              key={issuer}
                              cart={isFinfast ? gridFinfast : gridPolicy}
                              title={
                                isFinfast
                                  ? "Certificado FinFast"
                                  : "Póliza SURA"
                              }
                              issuer={issuer}
                              setEdit={setEdit}
                              setStateItem={setStateItem}
                              stateItem={stateItem}
                              typeGrid={typeGrid}
                              deleteShow={deleteShow}
                              editShow={editShow}
                              resetStepToPay={resetStepToPay}
                            />
                          )
                        );
                      })}
                    </>
                  )}
                </div>
              </MCards>
            )}

            {isLoadingCart && (
              <div>
                <MLoading modal={false} />
              </div>
            )}
          </div>
        </div>
        {/* mismo mensaje sii del home */}
        {showInfoSiiBuyCart && (
          <HomeBannerSIIClientesAntiguos showInHome={false} />
        )}
      </div>
      <MPopup
        showClose={true}
        setShowModal={setEdit}
        showModal={edit}
        size="lg"
      >
        <EditItemCart
          id={stateItem.id}
          nroCft={stateItem.nroCft}
          close={hanlerCloseEdit}
          action="edit"
        ></EditItemCart>
      </MPopup>

      <MPopup
        showClose={true}
        setShowModal={setDelete}
        showModal={delet}
        size="md"
      >
        <DeleteItemCart
          id={stateItem.id}
          isReissue={stateItem.reissue}
          close={handlerCloseDelete}
        />
      </MPopup>
    </>
  );
};

export default BuyCart;

export interface items {
  id: number;
  nroCft: string;
  reissue: boolean;
}
