import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import MasterPrivate from "./components/layouts/MasterPrivate";
import MasterPublic from "./components/layouts/MasterPublic";
import Home from "./components/pages/Private/Home/Home";
import Logout from "./components/pages/Private/Logout";
import LoadingScreen from "./components/pages/Public/LoadingScreen";
import { useAppSelector } from "./scripts/redux/hooks";
import NewCertificate from "./components/pages/Private/NewCertificate/NewCertificate";
import BuyCertificate from "./components/pages/Private/BuyCertificate/BuyCertificate";
import Quotes from "./components/pages/Private/Quotes/Quotes";
import Tenders from "./components/pages/Private/Tenders/Tenders";
import HomeCart from "./components/pages/Private/Cart/HomeCart";
import Filter from "./components/pages/Private/Filter/Filter";
import MyCertificates from "./components/pages/Private/MyCertificates/MyCertificates";
import QuestionsHome from "./components/pages/Private/Questions/QuestionsHome";
import HomeServipag from "./components/pages/Private/Home/partial/HomeServipag";

const Routing = () => {
  const { token } = useAppSelector((store) => store.account);

  //En caso de tener token acceder a las rutas del sistema  
  if (token) {
    return (
      <MasterPrivate>
        <Switch>
          <Route path="/home" exact component={Home} />
          <Route path="/buy-certificate" exact component={BuyCertificate} />
          <Route path="/new-certificate" exact component={NewCertificate} />
          <Route path="/my-certificates" exact component={MyCertificates} />
          <Route path="/quotes" exact component={Quotes} />
          <Route path="/search-tenders" exact component={Tenders} />
          <Route path="/homecart" exact component={HomeCart} />
          <Route path="/filter" exact component={Filter} />
          <Route path="/homecart/:children/:type?" exact component={HomeCart} />
          <Route path="/faq" exact component={QuestionsHome} />
          <Route path="/paymentVerification" exact component={HomeServipag} />
          <Route path="/logout" exact component={Logout} />
          <Route render={() => <Redirect to="/home" />} />
        </Switch>
      </MasterPrivate>
    );
  }

  //Rutas públicas
  return (
    <MasterPublic>
      <Switch>
        <Route path="/" exact component={LoadingScreen} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </MasterPublic>
  );
};

export default Routing;
