import { useState } from "react";

import ApiDownloadUserGuide from "@api/UserGuide/ApiDownloadUserGuide";

export const UserGuide = () => {
  let [DownloadDocument, setDownloadDocument] = useState<boolean>(false);

  async function handleDownloadUserGuide(item: string) {
    if (!DownloadDocument) {
      setDownloadDocument(true);
      try {
        const response = await ApiDownloadUserGuide.CallDownload(item);
        const blob = new Blob([response?.data]);

        if (blob.type === "text/plain") {
          return;
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Manual de usuario " + item.toLocaleLowerCase() + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error("Error al descargar el manual de usuario:", error);
      } finally {
        setDownloadDocument(false);
      }
    }
  }

  return {
    handleDownloadUserGuide,
    DownloadDocument,
  };
};
