
import React, { useEffect, useState, useRef } from "react";

const InputBox = ({ defaultValue, pattern, placeHolder, maxItem, onChangeValue, existsErrorMessage }: any) => {
    const [values, setValues] = useState<any>([])
    const [hasErrorPattern, setHasErrorPattern] = useState(false)
    const [hasErrorExists, setHasErrorExists] = useState(false)
    const newValueRef = useRef<any>(null)
    const [visible, setvisible] = useState(false)
    useEffect(() => {
        if (defaultValue === "") {
            return
        }

        if (typeof defaultValue === 'undefined') {
            return
        }


        if (typeof defaultValue === 'string') {
            if (defaultValue.trim().length !== 0) {
                setValues(defaultValue);
            }
        } else {
            let filteredValues = defaultValue.filter((value: any, index: any) => defaultValue.indexOf(value) === index);
            setValues(filteredValues);
        }
    }, [defaultValue]);

    const clearInputAdd = () => {
        newValueRef.current.value = '';
    }

    const buttonDeleteOnClick = (value: any) => {
        let valuesTemp = values.filter((iValue: any) => iValue !== value);
        setValues(valuesTemp);
        onChangeValue(valuesTemp);
    }

    const renderValue = (value: any) => (
            <div className="pt-2">
                <button type="button" className="btn fondomail btn-mail" onClick={() => buttonDeleteOnClick(value)}>
                    <span className="textmail">{value + " "}
                        <i className="fas fa-times"></i>
                    </span>
                </button>
            </div>
    )

    const handlerButtonAddOnClick = () => {
        let hasErrorPatternTemp = false;
        let hasErrorExistsTemp = false;
        let valueAdd = newValueRef.current.value;

        if (pattern) {
            hasErrorPatternTemp = !pattern.value.test(valueAdd);
        }

        if (existsErrorMessage && values.indexOf(valueAdd) !== -1) {
            hasErrorExistsTemp = true;
        }

        setHasErrorPattern(hasErrorPatternTemp);
        setHasErrorExists(hasErrorExistsTemp);
        if (!hasErrorPatternTemp && !hasErrorExistsTemp) {
            if (valueAdd !== '') {
                let valuesTemp = [...values, valueAdd];
                setValues([...values, valueAdd])
                clearInputAdd();
                onChangeValue(valuesTemp);
            }
        }
    }
    const handlerInputAddOnKeyUp = (e: any) => {
        let keyEsc = 27;
        let keyEnter = 13;

        let text = e.target.value.trim();
        let len = text.length;

        if (e.keyCode === keyEsc) {
            clearInputAdd();
        }
        else if (e.keyCode === keyEnter) {
            if (pattern && len > 0) {
                if (pattern.value.test(text)) {
                    setHasErrorPattern(false);
                } else {
                    setHasErrorPattern(true);
                }
            } else {
                setHasErrorPattern(false);
            }

            if (existsErrorMessage && len > 0) {
                if (values.indexOf(text) === -1) {
                    setHasErrorExists(false);
                } else {
                    setHasErrorExists(true);
                }
            } else {
                setHasErrorExists(false);
            }
        }
    }

    function changeVisibilityAddMail() {
        setvisible(!!!visible)
    }

    return (
        <div className="input-box row align-items-center px-2 ">
            {values.map((value: any) =>
                renderValue(value)
            )}
            {(values.length < (maxItem ?? 2) && (
                <>
                    <div className="col-12 pb-1 d-flex justify-content-center pt-5">
                        <button className="btn button-add text-btn" onClick={changeVisibilityAddMail}>Agregar nuevo correo</button>
                    </div>
                    {visible &&
                        <div className="input-group pt-3">
                            <div className="row">
                                <div className="col-10">

                                    <input
                                        ref={newValueRef}
                                        onKeyUp={handlerInputAddOnKeyUp}
                                        placeholder={placeHolder}
                                        type="text"
                                        className="form-control"
                                        id="inputNewMail" />
                                </div>
                                <div className="col-2">
                                    <button className="btn btn-primary " onClick={handlerButtonAddOnClick} type="submit" id="button-addon2"><i className="fas fa-plus"></i></button>
                                </div>
                            </div>
                            {hasErrorPattern && pattern && (
                                <small className="m-invalid-feedback pl-2">{pattern.message}</small>
                            )}
                            {hasErrorExists && existsErrorMessage && (
                                <small className="m-invalid-feedback pl-2">{existsErrorMessage}</small>
                            )}
                        </div>
                    }
                </>
            ))}
        </div>
    );
};

export default InputBox;
