import { useAppSelector } from "@redux/hooks";
import { StepReissue } from "@redux/models/NewCertificateModels";
import React from "react";
import { MPopup } from "@inversiones-ma/finfast-react-controls";
import EnterDataReissue from "./EnterDataReissue";
import VerifyDataReissue from "./VerifyDataReissue";

interface PropsData {
  nroCft: string;
  showModal: boolean;
  setShowModal: any;
  hanlerSetReissue: (nrocft: string) => void;
}

function CertificateReissue(props: PropsData) {

  const { currentStepReissue } = useAppSelector((store) => store.newCertificate);
  switch (currentStepReissue.stepReissue) {
    case StepReissue.seleccionar:
      return (
        <MPopup
          size="lg"
          showClose={true}
          showModal={props.showModal || false}
          setShowModal={props.setShowModal}
        >
          <EnterDataReissue
            nroCft={props.nroCft}
            showModal={props.showModal}
            setShowModal={props.setShowModal}
            hanlerSetReissue={props.hanlerSetReissue}
            action={currentStepReissue.action}
          ></EnterDataReissue>
        </MPopup>
      );
    case StepReissue.verificar:
      return (
        <MPopup
          size="lg"
          showClose={true}
          showModal={props.showModal}
          setShowModal={props.setShowModal}
        >
          <VerifyDataReissue
            nroCft={props.nroCft}
            showModal={props.showModal}
            setShowModal={props.setShowModal}
            hanlerSetReissue={props.hanlerSetReissue}
            action="add"
          ></VerifyDataReissue>
        </MPopup>
      );

    default:
      return <span>Error: invalid currentStep</span>
  }
}


export default CertificateReissue