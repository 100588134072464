import './SelectYourWarranty.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import dayjs from 'dayjs';
import { ValidateOperations } from 'Models/Operation';
import { useHistory } from 'react-router-dom';

import GenericBaseModel from '@api/GenericBaseModel';
import ApiLicitacion from '@api/licitacion/Licitacion';
import ApiMandante from '@api/mandante/Mandante';
import { MButton } from '@inversiones-ma/finfast-react-controls';
import * as NewCertificateActions from '@redux/actions/NewCertificateActions';
import { useAppDispatch } from '@redux/hooks';
import * as NewCertificateModels from '@redux/models/NewCertificateModels';
import {
  EnumErrorType,
  EnumPrioridad,
} from '@redux/models/QuoteModels';

import {
  IApiResponseMandante,
  IApiResponseTender,
} from "../NewCertificate/partial/SelectData";
import HeadCard from "./HeadCard";
import MainCard from "./MainCard";
import InfoInsurance from "./InfoInsurance";

interface Props {
  quoteData: ValidateOperations[];
  sendMailQuote?: any;
  setSendMailQuote?: any;
  prioridadCertificado: EnumPrioridad | undefined;
  quote: NewCertificateModels.NewCertificateFromQuote | undefined;
}

/**
 * componente creacion cards cotizacion
 */
function SelectYourWarranty({ quoteData, quote }: Props) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [selectOption, setSelectOption] = useState<
    ValidateOperations | undefined
  >(undefined);

  const [isLoadingTender, setIsLoadingTender] = useState<boolean>(false);
  const [disableSelect, setDisableSelect] = useState<boolean>(false);

  useEffect(() => {
    let filter =
      quoteData?.length === 1 && quoteData.find((x) => x.errorType === null);
    if (filter) {
      setSelectOption(quoteData[0]);
    }
  }, [quoteData]);

  //para seleccionar el tipo de garantía
  function HandlerSelect(option: ValidateOperations) {
    if (
      quoteData.find((x) => x.errorType?.toUpperCase() === EnumErrorType.DANGER)
    ) {
      setDisableSelect(true);
    }

    setSelectOption(option);
  }

  const findByTender = async (tenderId: string) => {
    try {
      setIsLoadingTender(true);

      const result: IApiResponseMandante =
        await ApiMandante.getMandanteByTender(tenderId);

      if (result.data !== null) {
        const tender = result.data;

        const tenderFormatted: NewCertificateModels.tenderData = {
          nombreOrganismoMandante: tender.nombreOrganismo.trim(),
          regionUnidadMandante: tender.regionUnidad.trim(),
          comunaUnidadMandante: tender.comunaUnidad.trim(),
          direccionUnidadMandante: tender.direccionUnidad.trim(),
          rutUnidadMandante: tender.rutUnidad,
        };

        return tenderFormatted;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTender(false);
    }
  };

  const getCodeByClient = async (): Promise<string> => {
    let codeTender = "";

    try {
      setIsLoadingTender(true);

      await ApiLicitacion.createTenderForIssuance().then(
        (result: GenericBaseModel<IApiResponseTender>) => {
          if (result.success) {
            codeTender = result.data.codigoExterno;
          }
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTender(false);
    }

    return Promise.resolve(codeTender);
  };

  const onClicNewCertificate = async (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    let idLicitacion: string = "";
    let tenderData: NewCertificateModels.tenderData =
      {} as NewCertificateModels.tenderData;

    if (quote?.idLicitacion === "") {
      idLicitacion = await getCodeByClient();
    } else {
      tenderData = (await findByTender(
        quote?.idLicitacion as string
      )) as NewCertificateModels.tenderData;
    }

    let dataToQuote: NewCertificateModels.NewCertificateFromQuote = {
      finalidad: quote?.finalidad as string,
      fechaTermino: dayjs(quote?.fechaTermino)
        .utc()
        .format("YYYY-MM-DDT00:00:00.0"),
      fechaInicio: dayjs(quote?.fechaInicio)
        .utc()
        .format("YYYY-MM-DDT00:00:00.0"),
      tipoMoneda: quote?.tipoMoneda as string,
      montoCertificado: quote?.montoCertificado as number,
      mandante: quote?.mandante as string,
      rutMandante: quote?.rutMandante,
      idLicitacion:
        quote?.idLicitacion === "" ? idLicitacion : quote?.idLicitacion,
      tenderData: tenderData,
      selectedOption: selectOption,
    };
    dispatch(NewCertificateActions.setQuote(dataToQuote));

    dispatch(
      NewCertificateActions.setCurrentStep(NewCertificateModels.Step.ingresar)
    );

    history.push("/new-certificate");
  };


  if (!!!quoteData || quoteData?.length === 0) {
    return <></>;
  }

  return (
    <div className="YourWarranty mt-3">
      <div className="bg-gris-cot py-3 justify-content-center">
        <div className="col-12">
          <div className="container mb-2">
            <HeadCard resultComission={quoteData} origen={"newQuote"} />
          </div>
        </div>

        <MainCard
          quoteData={quoteData}
          handlerSelect={HandlerSelect}
          addOrEdit={NewCertificateModels.Step.cotizar}
          origen={"newCerificate"}
          warantySelected={selectOption}
        />
        {quoteData?.length > 1 && <InfoInsurance />}
        <hr />
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <MButton
              className='col-11 col-md-4'
              color="secondary"
              size="large"
              disabled={!selectOption || disableSelect}
              loading={isLoadingTender}
              onClick={onClicNewCertificate}
            >
              Emitir Garantía
            </MButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectYourWarranty;
