import React, { useEffect, useState } from "react";
import {
  MGrid,
  MGridColumn,
  MPopup,
  MShowMore,
} from "@inversiones-ma/finfast-react-controls";
import { toDate } from "@helpers/helpers";
import { setCurrentTender } from "@redux/actions/TenderActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Tender } from "@redux/models/TenderModels";
import sinLicitaciones from "@img/amico.svg";
import MLoading from "../../../../controls/MLoading";
import MAlertPopupDeleteTenderConfirm from "./MAlertPopupDeleteTenderConfirm";
import FormBuyCertificateByTender from "./FormBuyCertificateByTender";
import { useHistory } from "react-router-dom";

export interface PropsGridTenders {
  typeTender: string;
  setTypeTender: any;
  isfilter: boolean;
  isLoading: boolean;
}

function GridTender({
  typeTender,
  setTypeTender,
  isfilter,
  isLoading,
}: PropsGridTenders) {
  const {
    filteredTendersAdjudicadas,
    filteredTendersPublicadas,
    tendersAdjudicadas,
    tendersPublicadas,
  } = useAppSelector((store) => store.tender);
  const [loadTenders, setLoadTender] = useState(false);
  const [codigoExterno, setCodigoExterno] = useState("");
  const [origen, setOrigen] = useState(0);
  const [showModalDeleteConfirm, setShowModalDeleteConfirm] = useState(false);
  const [showAddModalBuyTender, setShowAddModalBuyTender] = useState(false);
  const [tenders, setTenders] = useState<Tender[] | undefined>([]);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    switch (typeTender) {
      case "ADJUDICADAS":
        setTenders(tendersAdjudicadas);
        break;
      case "PUBLICADAS":
        setTenders(tendersPublicadas);
        break;
    }
  }, [loadTenders, typeTender]);

  const openConfirm = (codigoExterno: string, origen: number) => {
    setCodigoExterno(codigoExterno);
    setOrigen(origen);
    setShowModalDeleteConfirm(true);
  };
  const openBuyCertificate = (codigoExterno: string) => {
    let tender = tenders?.find((t) => t.codigoExterno === codigoExterno);
    dispatch(setCurrentTender(tender));
    if (tender?.idOperacion !== 0) {
      setShowAddModalBuyTender(true);
    } else {
      history.push("/new-certificate");
    }
  };

  useEffect(() => {
    if (!tenders) return;

    switch (typeTender) {
      case "ADJUDICADAS":
        setTenders(filteredTendersAdjudicadas);
        break;
      case "PUBLICADAS":
        setTenders(filteredTendersPublicadas);
        break;
    }
    // eslint-disable-next-line
  }, [isfilter]);

  return (
    <div>
      <div className="row clase-tender">
        {isLoading && (
          <div className="col-12">
            <MLoading modal={false} />
          </div>
        )}
        {isLoading === false && (
          <div className="row mt-1">
            <div className="col-12 d-none d-md-block d-lg-block">
              {tenders && tenders.length > 0 && (
                <MGrid
                  dataSource={tenders}
                  keyExpr="codigoExterno"
                  key="codigoExterno"
                  showMoreSize={10}
                  onChangeShowMore={() => {}}
                  className="head-table-color"
                >
                  <MGridColumn
                    dataField="codigoExterno"
                    label="Codigo"
                  ></MGridColumn>
                  <MGridColumn
                    dataField="nombreMandante"
                    label="Mandante"
                  ></MGridColumn>
                  <MGridColumn
                    dataField="fechaCierre"
                    label="Fecha Cierre"
                    cellRender={(_k: any, value: any) => {
                      return <> {toDate(value)} </>;
                    }}
                  ></MGridColumn>
                  <MGridColumn
                    className="col text-center"
                    dataField="origenLicitacion"
                    label="Eliminar"
                    headerClassName="center-header"
                    cellRender={(_k: any, value: any) => {
                      return (
                        <>
                          <button
                            className="btn icon-color"
                            onClick={() => openConfirm(_k, value)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </>
                      );
                    }}
                  ></MGridColumn>
                  <MGridColumn
                    className="col text-center"
                    dataField="codigoExterno"
                    label="Emitir cft"
                    headerClassName="center-header"
                    cellRender={(_k: any, value: any) => {
                      return (
                        <>
                          <button
                            className="btn icon-color"
                            onClick={() => openBuyCertificate(_k)}
                          >
                            <i className="fas fa-plus-circle"></i>
                          </button>
                        </>
                      );
                    }}
                  ></MGridColumn>
                </MGrid>
              )}
            </div>
            <div className="movil d-block d-md-none">
              {tenders && (
                <MShowMore size={3}>
                  {tenders.map((element, index) => (
                    <div key={index} className="movil d-block d-md-none shadow">
                      <div className="card-movil-tabla-garantias card-movil-tabla px-0">
                        <div className="detalles-movil-tabla">
                          <div className="col-12">
                            <h4>Certificado</h4>
                            <p>{element.codigoExterno}</p>
                          </div>
                          <div className="col-12">
                            <h4>Mandante</h4>
                            <p>{element.nombreMandante}</p>
                          </div>
                          <div className="col-12">
                            <h4>Fecha Cierre</h4>
                            <p>{toDate(element.fechaCierre)}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="movil-tablas-garantias">
                            <button
                              type="button"
                              className="btn btn-primary sistema outline external"
                              aria-expanded="false"
                              onClick={() =>
                                openConfirm(
                                  element.codigoExterno,
                                  element.origenLicitacion
                                )
                              }
                            >
                              Eliminar
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary sistema external"
                              aria-expanded="false"
                              onClick={() =>
                                openBuyCertificate(element.codigoExterno)
                              }
                            >
                              Emitir CFT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </MShowMore>
              )}
            </div>
            {tenders?.length === 0 && !isLoading && (
              <div className="col-12 bg-white  p-2 p-md-5  sin-certificados-content my-2">
                {typeTender === "PUBLICADAS" && (
                  <div className="row mt-1">
                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                      <img src={sinLicitaciones} alt="" />
                    </div>
                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                      <div className="col-12 my-3">
                        <h3 className="titles-left">
                          No tienes licitaciones publicadas, cuando tengas
                          alguna se mostrarán aquí
                        </h3>
                        <button
                          type="button"
                          className="btn btn-primary primary sistema py-2"
                          onClick={() => history.push("/filter")}
                        >
                          Ir a filtros
                          <i
                            className="fas fa-arrow-right ms-3"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {typeTender === "ADJUDICADAS" && (
                  <div className="row mt-1">
                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                      <img src={sinLicitaciones} alt="" />
                    </div>
                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                      <div className="col-12 my-3">
                        <h3 className="titles-left">
                          No tienes licitaciones adjudicadas, cuando tengas
                          alguna se mostrarán aquí
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <MAlertPopupDeleteTenderConfirm
        setShowModal={setShowModalDeleteConfirm}
        showModal={showModalDeleteConfirm}
        codigoExterno={codigoExterno}
        origen={origen}
        setLoadTenders={setLoadTender}
        loadTenders={loadTenders}
      />
      <MPopup
        showClose={true}
        setShowModal={setShowAddModalBuyTender}
        showModal={showAddModalBuyTender}
        size="lg"
      >
        <FormBuyCertificateByTender
          close={() => {
            setShowAddModalBuyTender(false);
          }}
        />
      </MPopup>
    </div>
  );
}
export default GridTender;
