import { createAction } from "@reduxjs/toolkit";
import { AccountState, SubscriptionStatus } from "../models/AccountModels";

export const loadLoginData = createAction(
  "ACCOUNT_LOAD_LOGIN_DATA",
  (loginData: AccountState) => {
    return {
      payload: loginData,
    };
  }
);

export const updateSiiData = createAction(
  "ACCOUNT_UPDATE_SII_DATA",
  (info: Boolean) => {
    return {
      payload: info,
    };
  }
);

export const updateSkipSiiData = createAction(
  "ACCOUNT_UPDATE_SII_SKIP",
  (infoOmite: any) => {
    return {
      payload: infoOmite,
    };
  }
);

export const loadSubscriptionStatus = createAction(
  "ACCOUNT_LOAD_SUBSCRIPTION_STATUS",
  (subscriptionStatus: SubscriptionStatus) => {
    return {
      payload: subscriptionStatus,
    };
  }
);

export const loadSubscriptionStatusOk = createAction(
  "ACCOUNT_LOAD_SUBSCRIPTION_STATUS_OK",
  (status: string) => {
    return {
      payload: status,
    };
  }
);

export const requireSiiCredentialsOk = createAction(
  "ACCOUNT_REQUIRE_CREDENTIALS_OK",
  (status: boolean) => {
    return {
      payload: status,
    };
  }
);

export const cleanState = createAction("ACCOUNT_CLEAN_STATE");
