import { MAxios } from "../conf";

export async function GetProviderCampaign() {

  const endpoint = "ProveedorCampania/ProveedorCampania/GetProviderCampaign";

  const res = await MAxios().post(endpoint);

  return res.data;
}

const ApiProviderCampaign = {
  GetProviderCampaign
};

export default ApiProviderCampaign;