import {
  MGridColumn,
  MGridPaginated,
  MLoadingLight,
  MTooltip,
} from "@inversiones-ma/finfast-react-controls";
import React from "react";
import { convertToCurrency, toDate } from "@helpers/helpers";
import { OperacionDTO } from "../../../../../../Models/Operation";
import { Paginador } from "../../../../../../Models/Paginador";

interface Iprops {
  paginadorData: Paginador<OperacionDTO>;
  setOnChangeNumberPage: (b: number) => any;
  setOnChangeNumberRecords: (b: number) => any;
  handlerButtonDownloadCft: Function;
  handlerButtonDownloadInvoice: Function;
  handlerValidatePurpose: Function;
  DownloadDocument: boolean;
  DownloadDocumentID: string;
  DownloadInvoice: boolean;
  DownloadReInvoice: boolean;
  handlerButtonDonwnloadReInvoice: Function;
  loading: boolean;
}

function VistaEscritorio({
  loading,
  paginadorData,
  setOnChangeNumberPage,
  setOnChangeNumberRecords,
  handlerButtonDownloadCft,
  handlerButtonDownloadInvoice,
  handlerValidatePurpose,
  DownloadDocument,
  DownloadDocumentID,
  DownloadInvoice,
  handlerButtonDonwnloadReInvoice,
  DownloadReInvoice,
}: Iprops) {
  return (
    <div>
      {paginadorData && paginadorData.records.length > 0 && (
        <MGridPaginated
          paginated={paginadorData}
          keyExpr="nroCertificado"
          key="nroCertificado"
          className="head-table-color"
          onChangeNumberPage={setOnChangeNumberPage}
          onChangeNumberRecords={setOnChangeNumberRecords}
          isLoadingPaginated={loading}
        >
          <MGridColumn
            dataField="nroCertificado"
            label="Certificado"
          ></MGridColumn>
          <MGridColumn
            dataField="codigoExterno"
            label="Id Licitación"
          ></MGridColumn>
          <MGridColumn
            dataField="nombreOrganismoMandante"
            label="Mandante"
            headerClassName="center-header"
            className="text-center"
          ></MGridColumn>
          <MGridColumn dataField="finalidad" label="Finalidad"></MGridColumn>
          <MGridColumn dataField="tipoMoneda" label="Moneda"></MGridColumn>
          <MGridColumn
            dataField="montoCertificado"
            label="Monto"
            cellRender={(_k: any, value: any, row: any): any => {
              return convertToCurrency(row.tipoMoneda, value);
            }}
          ></MGridColumn>
          <MGridColumn
            className="col "
            dataField="fechaTermino"
            label="Término"
            cellRender={(_k: any, value: any) => {
              return <> {toDate(value)} </>;
            }}
          ></MGridColumn>
          <MGridColumn
            className="col text-center"
            dataField="codigoDec5"
            label="Certificado"
            headerClassName="center-header"
            cellRender={(_k: string, value: string) => {       
              return (
                <>
                  {!value && (
                    <i
                      className="fas fa-file-alt fs-5 disabled"
                      aria-hidden="true"
                    ></i>
                  )}

                  {value && (
                    <button
                      className="btn px-3 descarga-factura-tabla"
                      onClick={() => handlerButtonDownloadCft(_k)}
                    >
                      {_k === DownloadDocumentID ? (
                        <>
                          <MLoadingLight />
                        </>
                      ) : (
                        <i
                          className="fas fa-file-alt fs-5"
                          aria-hidden="true"
                        ></i>
                      )}
                    </button>
                  )}
                </>
              );
            }}
          ></MGridColumn>

          <MGridColumn
            className="col text-center"
            headerClassName="center-header"
            dataField="tieneFactura"
            label="Factura Certificado"
            cellRender={(_k: any, value: any) => {
              return (
                <>
                  {DownloadInvoice && _k === DownloadDocumentID && (
                    <>
                      <MLoadingLight />
                    </>
                  )}

                  {value && (
                    <button
                      onClick={() => handlerButtonDownloadInvoice(_k)}
                      className="btn px-3 descarga-factura-tabla"
                    >
                      <i
                        className="fas fa-file-download fs-5 boton-certificado"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  {!value && (
                    <>
                      <button className="btn px-3 descarga-factura-tabla">
                        <MTooltip
                          title="Pendiente de Facturación"
                          content=""
                          iconClass="fas fa-file-download disabled cursor-default fs-5"
                        />
                      </button>
                    </>
                  )}
                </>
              );
            }}
          ></MGridColumn>
          <MGridColumn
            className="col text-center"
            headerClassName="center-header"
            dataField="nroCertificado"
            label="Reemisión"
            cellRender={(_k: any, value: any, row: any) => {
              const iconClassName = "fas fa-file-export fs-5";
              const buttonClassName = "btn px-3 descarga-factura-tabla";

              return (
                <>
                  {handlerValidatePurpose(
                    row,
                    _k,
                    iconClassName,
                    buttonClassName,
                    null
                  )}
                </>
              );
            }}
          ></MGridColumn>

          <MGridColumn
            className="col text-center"
            dataField="tieneReemision"
            label="Factura reemisión"
            headerClassName="center-header"
            cellRender={(_k: any, value: any, row: any) => {
              const downloadInProgress =
                DownloadReInvoice && _k === DownloadDocumentID;
              const hasNoReInvoice = !value;

              return (
                <>
                  {downloadInProgress && (
                    <>
                      <MLoadingLight />
                    </>
                  )}
                  {hasNoReInvoice && (
                    <>
                      <button className="btn px-3 descarga-factura-tabla">
                        <i
                          className="fas fa-file-download disabled cursor-default fs-5"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </>
                  )}
                  {value && (
                    <button
                      className="btn px-3 descarga-factura-tabla"
                      onClick={() => handlerButtonDonwnloadReInvoice(_k, row)}
                    >
                      <i
                        className="fas fa-file-download fs-5 boton-certificado"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </>
              );
            }}
          ></MGridColumn>
        </MGridPaginated>
      )}
    </div>
  );
}

export default VistaEscritorio;
