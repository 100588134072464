import * as Session from "./session";
const sessionGeneral = new Session.SessionCache("general");
const sessionAccount = new Session.SessionCache("account");

export const getSessionConfiguration = (key) => {
  const list = sessionGeneral.get("arrDataConfiguration");

  let resp = {
    bannerMulti2: "",
    fechaActualizacion: new Date(),
    llave: "No encontrado",
    valorAlfanumerico: null,
    valorBooleano: false,
    valorFecha: null,
    valorNumerico: null,
  };
  if (list) {
    const found = list.find((x) => x.llave === key);
    resp = { ...resp, ...found };
  }

  return resp;
};

export const getSession = (session, name) => {
  return new Session.SessionCache(session).get(name) ?? "";
};

export const setSession = (session, name, value) => {
  return new Session.SessionCache(session).add(name, value);
};

export const getSessionAccount = (name) => {
  return sessionAccount.get(name) ?? "";
};

export const setSessionAccount = (name, value) => {
  sessionAccount.add(name, value);
};

export const getSessionGeneral = (name) => {
  return sessionGeneral.get(name) ?? "";
};

export const setSessionGeneral = (name, value) => {
  sessionGeneral.add(name, value);
};

const helpersSession = {
  Get: getSession,
  Set: setSession,
  GetConfiguration: getSessionConfiguration,
  GetAccount: getSessionAccount,
  SetAccount: setSessionAccount,
  GetGeneral: getSessionGeneral,
  SetGeneral: setSessionGeneral,
};

export default helpersSession;
