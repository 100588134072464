import React from "react";
import { useHistory } from "react-router-dom";
const ButtonNewCertificate = () => {
  const history = useHistory();

  const onClickNewCertificate = () => {
    history.push("/new-certificate");
  };
  return (
    <button
      type="button"
      className="btn btn-primary primary sistema py-2"
      onClick={() => onClickNewCertificate()}
    >
      {" "}
      Comprar certificados{" "}
      <i className="fas fa-arrow-right ms-3" aria-hidden="true"></i>
    </button>
  );
};

export default ButtonNewCertificate;
