import React, { useEffect, useState } from "react";
import "./NewCertificate.scss";
import SelectData from "./partial/SelectData";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import EnterData from "./partial/EnterData";
import VerifyData from "./partial/VerifyData";
import AutoScrollToTop from "../../../features/AutoScrollToTop";
import { useHistory } from "react-router-dom";

function NewCertificate() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { currentStep } = useAppSelector((store) => store.newCertificate);

  const [isLoaded, setIsLoaded] = useState(false);
  const { enabledEmit } = useAppSelector((store) => store.account);

  useEffect(() => {
    if (!enabledEmit) {
      history.push("/home");
    }

    dispatch(NewCertificateActions.setSelectedData(undefined));
    dispatch(NewCertificateActions.setTenderData(undefined));
    //paso seleccionar
    //  if (currentStep !== NewCertificateModels.Step.cotizar) {
    //   dispatch(
    //     NewCertificateActions.setCurrentStep(
    //       NewCertificateModels.Step.seleccionar
    //     )
    //   )
    // }
    // else {
    //   dispatch(
    //     NewCertificateActions.setCurrentStep(
    //       NewCertificateModels.Step.ingresar
    //     )
    //   )
    // }

    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="bg-home newCertificate">
      <div className="container">
        {isLoaded && (
          <>
            {currentStep === NewCertificateModels.Step.seleccionar && (
              <>
                <AutoScrollToTop />
                <SelectData />
              </>
            )}
            {currentStep === NewCertificateModels.Step.ingresar && (
              <>
                <AutoScrollToTop />
                <EnterData addOrEdit={currentStep} origen="newCerificate" />
              </>
            )}
            {currentStep === NewCertificateModels.Step.cotizar && (
              <>
                <AutoScrollToTop />
                <EnterData addOrEdit={currentStep} origen="newCerificate" />
              </>
            )}
            {currentStep === NewCertificateModels.Step.verificar && (
              <>
                <AutoScrollToTop />
                <VerifyData addOrEdit={currentStep} origen="verifyOperation" />
              </>
            )}
          </>
        )}
      </div>
    </main>
  );
}

export default NewCertificate;
