import { ExecutiveState } from '@redux/models/AccountModels';
import ApiMembresiaAccount from "@api/membresia/Account";
import ApiRegion from "@api/global/Region";
import ApiProveedor from "@api/proveedor/Proveedor";
import * as createHistory from "history";
import * as AccountActions from '@redux/actions/AccountActions';
import * as SessionActions from '@redux/actions/SessionActions';
import * as GlobalActions from '@redux/actions/GlobalActions';
import { AccountState } from '@redux/models/AccountModels';
import { TDispatch } from '@redux/hooks';
import ApiCurrency from '@api/global/Currency';
import GenericBaseModel from '@api/GenericBaseModel';
import BaseModel from '@api/BaseModel';
import * as SessionModels from '@redux/models/SessionModels';
import { Commune, Configuracion, Region, SurveyNps } from '@redux/models/GlobalModels';
import { Currency } from '@redux/models/SessionModels';
import { PaymentMethods } from '@redux/models/GlobalModels';
import ApiPayment from "@api/global/Payment";
import ApiNps from '@api/nps/nps';
import ApiProviderCampaign from '@api/providerCampaign/providerCampaign';
import ApiConfiguration from '@api/configuration/Configuration';
import ApiOperation from '@api/garantias/operation/Operation';
import { OperacionDTO } from 'Models/Operation';
import store from '@redux/store/store';
import { saveToSessionStorage } from '@redux/store/localStorage';
import { finfast } from '@config/environment';
const history = createHistory.createBrowserHistory();

/**
 * 
 * @param rToken => token recibido como parametro en url
 * @param token => nuevo token guardado en store la primera vez que loguea  
 * @param dispatch 
 * @returns 
 */
export const AutoLogin = (
  rToken: string,
  token: string | undefined,
  dispatch: TDispatch
) => {
  return new Promise((resolve, reject) => {
    if (!token) {
      if (rToken) {
        ApiMembresiaAccount.autoLogin(rToken).then((data) => {
          if (data.success) {
            if (data.status === "ValidUser") {
              saveAuthData(data, dispatch);
              //Retorno false ya que no tiene token aún
              resolve(false);
            } else {
              //Usuario no valido, retorna al login sin sesión
              history.push("/");
              resolve(false);
            }
          } else {
            //Login no exitoso, retorna al login sin sesión
            //history.push("/");
            resolve(false);
            window.location.href = finfast() + "/logout";
          }
        });
      } else {
        //No viene variable en la URL ni hay token
        resolve(true);
      }
    } else {
      //Existe sesión de token, por ende sólo se procede a entrar a la app
      history.push("/home");
      resolve(true);
    }
  });
};

export const saveAuthData = async (  
  data: any
  , dispatch: TDispatch
) => {
  const executiveId = data.executives
  .filter((x: any) => x.idProducto === 1)[0]?.id ?? 0;
  const executive: ExecutiveState = {
    name: data.executiveName
    , phone: data.executivePhone
    , phoneAnnex: data.executivePhoneAnnex
    , email: data.executiveEmail
    , id: executiveId,
  };
  const accountData: AccountState = {
    token: data.token,
    run: data.run,
    name: data.name,
    telephone: data.telephone,
    email: data.email ?? "alertasit@finfast.com",
    idBillingType: data.idTipoFacturacion,
    idSalesLevel: data.idNivelVentas,
    idSalesLevelIntFactoring: data.idNivelVentasIntFactoring,
    idSalesLevelIntGarantias: data.idNivelVentasIntGarantias,
    executive: executive,
    idClassification: data.clasificacion,
    idActivity: data.idActividad,
    enabledEmit: data.enabledEmit,
    isAnonymous: false,
    showPreference: data.showPreference,
    emailPreference: data.emailPreference ?? data.email ?? "",
    validatePlazo: data.validatePlazo,
    blockedByDebt: false,
    loginDate: data.loginDate,
    loginDateClient: new Date(),
    requireSiiCredentials: data.requireSiiCredentials,
    isNewClient: data.isNewClient,
    clientOmitSii: data.clientOmitSii,
    clientOmitSiiExtraInfo: false,
    subscriptionStatus: data.SubscriptionStatus,
    idRoL: data.idRol,
    externalInstabilityProblem: data.validateExternalInstabilityProblem
  };

  accountData.discountCode = ""
  localStorage.setItem("access_token", data.token);
  dispatch(AccountActions.loadLoginData(accountData));

  let dataConfiguration = [{ llave: "fechaMaxSiiClientesAntiguos" }];

  Promise.all([
    ApiCurrency.GetUf()
    , ApiCurrency.GetUsd()
    , ApiCurrency.GetCurrency()
    , ApiPayment.GetPaymentMethods()
    , ApiRegion.GetRegionComuna()
    , ApiProveedor.GetVistaProveedorLinea()
    , ApiNps.GetSurvey("1")
    , ApiProviderCampaign.GetProviderCampaign()
    , ApiConfiguration.GetConfiguration(dataConfiguration)    
    , ApiOperation.ValidateOperationsInCart()
  ]).then((resp: any) => {
    const respUf = resp[0] as ValueUfExternalModel;
    const respUsd = resp[1] as ValueUsdExternalModel;
    const respCurrencies = resp[2] as GenericBaseModel<Currency>;
    const PaymentMethods = resp[3] as GenericBaseModel<PaymentMethods>;
    const respRegions = resp[4] as GenericBaseModel<RegionBaseExternalModel>;
    const respVistaProveedor = resp[5] as GenericBaseModel<SessionModels.VistaProveedorLineaAprobada>;
    const respShowSurvey = resp[6] as GenericBaseModel<SurveyNps>
    const respProviderCampaign = resp[7] as GenericBaseModel<SessionModels.ProviderCampaign>
    const respDataConfiguracion = resp[8] as GenericBaseModel<Configuracion>
    const respDataCartOperation = resp[9] as GenericBaseModel<OperacionDTO>  

    const cart = {
      items: respDataCartOperation.dataList,
      discountCode: undefined
    } as unknown as SessionModels.Cart | undefined;
  
    const regions = respRegions.dataList.map(r => {
      return {
        id: r.idRegion
        , name: r.nombreRegion
        , code: r.codigo
        , totalCost: r.costoTotal
        , order: r.orden
        , communes: r.comuna.map(c => {
          return {
            id: c.idComuna
            , name: c.nombreComuna
            , idRegion: c.idRegion
            , idCity: c.idCiudad
          } as Commune
        })
      } as Region;
    });

    const PayMethods = PaymentMethods.dataList.map(y => {
      return {
        id: y.id,
        descripcion: y.descripcion,
        estado: y.estado,
        mensajeContingencia: y.mensajeContingencia
      } as PaymentMethods;
    });

    if (respShowSurvey.success) {
      // guardar si debe responder encuesta
      dispatch(SessionActions.loadSurvey(respShowSurvey.data.showSurvey));
      dispatch(GlobalActions.loadDataSurvey(respShowSurvey.data));

    } else {
      dispatch(GlobalActions.loadDataSurvey(undefined));
      dispatch(SessionActions.loadSurvey(false));
    }

    // guardar actividades
    //dispatch(SessionActions.loadActivities(activities));
    // guardar regiones
    dispatch(GlobalActions.loadRegions(regions));
    // guardar uf
    dispatch(SessionActions.loadUf(respUf.valorUf, new Date(respUf.fecha)));
    // guardar usd
    dispatch(SessionActions.loadUsd(respUsd.valorUsd, new Date(respUsd.fecha)));
    // guardar monedas
    dispatch(SessionActions.loadCurrencies(respCurrencies.dataList));
    // guardar vistaProveedorLineaAprovada
    dispatch(SessionActions.loadVistaProveedorLineaAprovada(respVistaProveedor.dataList));
    // guardar Metodos de pagos
    dispatch(GlobalActions.loadPaymentMethods(PayMethods));
    // guardar accountData
    //dispatch(AccountActions.loadLoginData(accountData));
    // data campania
    dispatch(SessionActions.loadProviderCampaign(respProviderCampaign.data));
    // guardar info tabla configuracion
    dispatch(GlobalActions.loadConfiguration(respDataConfiguracion.dataList));
    // guardar datos del carrito
    dispatch(SessionActions.loadCart(cart));


    //guardar estado global en sessionstorage    
    saveToSessionStorage(store?.getState());
  });
};

interface ValueUfExternalModel extends BaseModel {
  valorUf: number;
  fecha: string;
}

interface ValueUsdExternalModel extends BaseModel {
  valorUsd: number;
  fecha: string;
}

interface RegionBaseExternalModel {
  idRegion: number;
  nombreRegion: string;
  codigo: string;
  costoTotal: number | null;
  orden: number | null;
  comuna: {
    idComuna: number;
    nombreComuna: string;
    idRegion: number;
    idCiudad: number | null;
  }[];
}
