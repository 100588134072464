import React from "react";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import { OriginIncome } from "Models/Operation";
interface PropsTitleForm {
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
}

const TitleForm = (props: PropsTitleForm) => {
  return (
    <>
      {" "}
      <div className="col-12">
        <h2
          className={
            props.addOrEdit === NewCertificateModels.Step.ingresar &&
            props.origen !== "cart" &&
            props.origen !== "addQuote"
              ? "titles-left"
              : "titles-center"
          }
        >
          {props.addOrEdit === NewCertificateModels.Step.ingresar &&
            props.origen !== "cart" &&
            props.origen !== "addQuote" &&
            "Nueva garantía"}
          {props.addOrEdit === NewCertificateModels.Step.verificar &&
            "Editar certificado"}
          {props.addOrEdit === NewCertificateModels.Step.ingresar &&
            (props.origen === "cart" || props.origen === "addQuote") &&
            "Editar certificado"}
        </h2>
        
        {props.addOrEdit === NewCertificateModels.Step.ingresar &&
          props.origen !== "cart" &&
          props.origen !== "addQuote" && (
            <p>Ingresa los datos para emitir tu Garantía</p>
          )}

        <div className="row">
          <div className="col-12 py-3">
            <h3 className="subtitle-section">
              Ingresa los datos para tu Garantía
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default TitleForm;
