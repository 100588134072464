import { MAxios } from "../../conf";

export const getProductsMenu = async () => {

    const endpoint = "membresia/EnabledProducts/ValidateProducts";

    const res = await MAxios().post(endpoint);

    return res.data;
}

const ApiProductsMenu = {
    getProductsMenu,
};

export default ApiProductsMenu;