import { MCards } from "@inversiones-ma/finfast-react-controls";
import React from "react";
import { convertToPeso } from "@helpers/helpers";
import './StandardLineCard2.scss'
import { useLine } from "../../hooks/useLine";

interface StandardCardProps {
  finalidad: string;
  montoUtilizado: number;
  montoDisponible: number;
  porcentajeUtilizado: number;
  porcentajeUtilizadoTotal: number;
  cftEmtidos: number;
  montoLineaAprobado: number;
  maxTicket: number;
}

export const StandardLineCard = ({
  finalidad,
  montoUtilizado,
  montoDisponible,
  porcentajeUtilizadoTotal,
  montoLineaAprobado,
  maxTicket
}: StandardCardProps): JSX.Element => {

  const { borderClassName } = useLine(finalidad);

  return (
    <div className="col-md-6 pt-2 standard-card-line-2">
      <MCards rounded={true} className={`p-4 lborder-${borderClassName}`}>
        <div className="row">
          <div className="col-12 col-md-6">
            <h3 className="subtitle-mini">{` ${finalidad}`}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-inline fs-14 ">
            <p className="margin-bottom-parrafo" style={{ whiteSpace: 'nowrap' }}>
              Total línea: <b>{convertToPeso(montoLineaAprobado)}</b>
            </p>
          </div>
          <div className="col-12 col-md-6 text-end fs-14">
            <p>
              Ticket Máximo: <b>{convertToPeso(maxTicket)}</b>
            </p>
          </div>
        </div>
        <div className="progress mb-2">
          <div
            className={`progress-bar bg-${borderClassName}`}
            style={{ width: `${porcentajeUtilizadoTotal}%` }}
            role="progressbar"
            aria-valuenow={porcentajeUtilizadoTotal}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
        <div className="row">
          <div className="col-12 col-md">
            <small>
              Utilizado:{" "}
              <b>{convertToPeso(montoUtilizado)}</b>
            </small>
          </div>
          <div className="col-12 col-md text-end">
            <small>
              Disponible:{" "}
              <b>{convertToPeso(montoDisponible)}</b>
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <small>
              % Utilizado: <b>{porcentajeUtilizadoTotal}%</b>
            </small>
          </div>
        </div>
      </MCards>
    </div>
  );
};
