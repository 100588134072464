import './HomeQuote.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs/plugin/utc';
import { ValidateOperations } from 'Models/Operation';
import { useForm } from 'react-hook-form';

import * as helpers2 from '@helpers/helpers2';
import * as validation from '@helpers/validationsRFH';
import {
  MButton,
  MInputDate,
  MInputGeneric,
  MInputNumber,
  MInputRadioButtons,
  MInputRut,
  MInputSelect,
  MLoadingLight,
} from '@inversiones-ma/finfast-react-controls';
import * as NewCertificateActions from '@redux/actions/NewCertificateActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import * as NewCertificateModels from '@redux/models/NewCertificateModels';
import { EnumPrioridad } from '@redux/models/QuoteModels';
import {
  Currency,
  Purpose,
} from '@redux/models/SessionModels';

import {
  calculateDaysBetweenDates,
  calculateValueInCLP,
  getValueToShow,
} from '../../../../../scripts/helpers/QuoteEmit';
import MLoading from '../../../../controls/MLoading';
import SelectYourWarranty from '../../Cards/SelectYourWarranty';
import { callApiQuote } from './HomeQuote/HomeQuote';
import LabelValue from './LabelValue';
import { UserGuide } from '../hooks/UserGuide';

dayjs.extend(dayjsPluginUTC);

type ModeInputNumber = "default" | "currency" | "CLP" | "USD" | "UF";

function HomeQuote() {
  const { run, loginDate, loginDateClient } = useAppSelector(
    (store) => store.account
  );
  const { uf, usd } = useAppSelector(
    (store) => store.session
  );
  const { purposes, currencies, lines } = useAppSelector(
    (store) => store.session
  );

  const currentDay: Date =
    loginDate && loginDateClient
      ? helpers2.getDateWithoutTime(loginDate, loginDateClient)
      : new Date();
  const [dateFromDinamic, setDateFromDinamic] = useState<Date>(currentDay);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quoteData, setQuoteData] = useState<ValidateOperations[]>([]);
  const [quote, setQuote] = useState<
    NewCertificateModels.NewCertificateFromQuote | undefined
  >();
  const [sendMailQuote, setSendMailQuote] = useState<boolean>(false);
  const [visibleQuote, setVisibleQuote] = useState<boolean>(false);

  const [daysDifference, setDaysDifference] = useState<number>(0);
  const [valueToShow, setValueToShow] = useState<string | undefined>("");
  const [valueInCLP, setValueInCLP] = useState<string | undefined>("");

  const [prioridadCertificado, setPrioridadCertificado] = useState<
    EnumPrioridad | undefined
  >(undefined);

  const { handleDownloadUserGuide, DownloadDocument } = UserGuide();

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { watch, getValues, setValue } = form;

  const { purpose, currency, amount, dateTo, dateFrom } = watch();

  useEffect(() => {
  setValue("currency", "1")
  }, [])
  

  const { handleSubmit } = form;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currency)
      setValueToShow(getValueToShow(currency, uf?.value, usd?.value));
  }, [currency]);

  useEffect(() => {
    dispatch(NewCertificateActions.cleanQuote(undefined));

    if (quoteData) {
      setQuoteData([]);
    }
    setVisibleQuote(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purpose, currency, amount, dateTo, dateFrom]);

  useEffect(() => {
    if (dateTo && dateFrom)
      setDaysDifference(() => calculateDaysBetweenDates(dateFrom, dateTo)!);
  }, [dateTo, dateFrom]);

  if (!purposes || purposes.length === 0) {
    return <></>;
  }

  const changeDateFrom = () => {
    const { dateFrom, dateTo } = getValues();

    const newDateFrom = new Date(dateFrom);
    const newDateTo = dateTo ? new Date(dateTo) : currentDay;

    // En caso de ser menor, actualiza la fecha de término
    if (newDateFrom > newDateTo) setValue("dateTo", newDateFrom);
    setDateFromDinamic(newDateFrom);
  };

  const onSubmit = (data: any) => {
    setSendMailQuote(false);
    setVisibleQuote(false);
    // setSelectWarranty(true);

    setIsLoading(true);
    let quotation = {
      fechaInicio: dayjs(data.dateFrom).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      fechaTermino: dayjs(data.dateTo).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      montoCertificado: data.amount,
      finalidad: purposes.find((p) => p.name === data.purpose)?.name!,
      tipoMoneda: currencies?.find((c) => c.id.toString() === data.currency)
        ?.description!,
      rutEmpresa: run,
      rutMandante: data.rutUnidadMandante,
      idLicitacion: data.LicitacionCode,
    };

    callApiQuote({
      quotation: quotation,
      setIsLoading: setIsLoading,
      setQuoteData: setQuoteData,
      setVisibleQuote: setVisibleQuote,
      setPrioridadCertificado: setPrioridadCertificado,
    });

    setQuote(
      quotation as unknown as NewCertificateModels.NewCertificateFromQuote
    );
  };
  return (
    <section id="quote-section" className="quote-section line-available pb-4">
      <div className="row pb-2 pt-2">
        <div className="col-12">
          <h3 className="subtitle-section">Cotiza tu siguiente Garantía</h3>
        </div>
      </div>
      <div className="row">
        <div id="cotizador" className="col-12">
          <div className="rounded-card-bg p-4">
            <div className="row">
              <div className="col-12">
                <h3 className="subtitle-mini">
                  Calcula el valor de tu próxima Garantía
                </h3>
                <p>
                  En esta sección podrás realizar el cálculo de tu emisión
                  ingresando los datos de la licitación y Garantía que
                  necesites.
                  Si requieres orientación sobre cómo cotizar tu siguiente Garantía,
                  puedes <span className="text-link text-decoration-underline" onClick={() => handleDownloadUserGuide("COTIZADOR")}>descargar nuestro manual aquí.</span>
                  <MLoadingLight style={{ marginLeft: "10px" }} loading={DownloadDocument} />
                </p>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row align-items-start">
                <div className={valueToShow ? "col-md-4 mt-2 " : "col-md-4"}>
                  <MInputSelect
                    form={form}
                    className="form-select"
                    name="purpose"
                    valueItem="id"
                    descriptionItem="name"
                    label="Finalidad"
                    items={purposes as Purpose[]}
                    rules={validation.validatePurpose()}
                  />
                </div>
                <div className="col-md-4">
                  {valueToShow && (
                    <LabelValue
                      text={"Valor actual"}
                      value={valueToShow}
                      isCurrency={true}
                    />
                  )}
                  <div className="col-lg-10 w-100 input-moneda">
                    <label className="mb-0 label-input"> Tipo de moneda:</label>
                    <MInputRadioButtons
                      className="form-check-label "
                      form={form}
                      labelPosition="right"
                      name="currency"
                      descriptionItem="description"
                      items={currencies as Currency[]}
                      direction="row"
                      //rules={validation.validateCurrencyType()}
                      valueItem="id"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  {valueToShow && (
                    <LabelValue
                      text={"Conversión a CLP"}
                      value={valueInCLP!}
                      isCurrency={true}
                    />
                  )}
                  <div className="floating-label">
                    <MInputNumber
                      form={form}
                      name="amount"
                      mode={
                        (currencies?.find((c) => c.id.toString() === currency)
                          ?.description || "CLP") as ModeInputNumber
                      }
                      label="Monto de Garantía"
                      maxLength={12}
                      rules={{
                        required: "El monto es requerido",
                      }}
                      onChange={() =>
                        setValueInCLP(
                          calculateValueInCLP(
                            amount,
                            currency,
                            uf?.value,
                            usd?.value
                          )
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  className={
                    daysDifference && daysDifference !== 0
                      ? "col-md-4 mt-2"
                      : "col-md-4"
                  }
                >
                  <MInputDate
                    form={form}
                    name="dateFrom"
                    label="Fecha inicio"
                    min={currentDay}
                    onChange={changeDateFrom}
                    calendarPopperPosition="auto"
                    rules={validation.vBeginDateEmision(purpose, currentDay)}
                  />
                </div>
                <div className="col-md-4">
                  {daysDifference && daysDifference !== 0 ? (
                    <LabelValue
                      text={"Plazo"}
                      value={
                        daysDifference === 1
                          ? "1 día"
                          : daysDifference + " días"
                      }
                    />
                  ) : (
                    <></>
                  )}
                  <MInputDate
                    form={form}
                    name="dateTo"
                    label="Fecha término"
                    calendarPopperPosition="auto"
                    min={dateFromDinamic}
                    rules={validation.vEndDateCotizacion(
                      dateFrom,
                      purpose,
                      lines,
                      currentDay
                    )}
                    onChange={() =>
                      setDaysDifference(
                        calculateDaysBetweenDates(dateFrom, dateTo)!
                      )
                    }
                  />
                </div>
                <div className="row">
                  <h3 className="subtitle-mini-custom">Datos opcionales</h3>
                  <div className="col-md-4">
                    <MInputGeneric
                      form={form}
                      name="LicitacionCode"
                      label="ID Licitación"
                    />
                  </div>
                  <div className="col-md-4">
                    <MInputRut
                      form={form}
                      name="rutUnidadMandante"
                      label="RUT mandante"
                      className="form-control enabled-control info-form input-tag"
                      placeholder="RUT mandante"
                    // loading={validatingMandante}
                    />
                  </div>
                </div>
                <div className="col-md-4 pt-3 mx-auto">
                  <MButton
                    className="btn btn-primary outline sistema w-100"
                    type="submit"
                    outline={true}
                    color="primary"
                    loading={isLoading}
                  >
                    Calcular Garantía
                  </MButton>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      </div>
      {isLoading && <MLoading modal={false} />}
      {visibleQuote && (
        <SelectYourWarranty
          quoteData={quoteData}
          sendMailQuote={sendMailQuote}
          setSendMailQuote={setSendMailQuote}
          prioridadCertificado={prioridadCertificado}
          quote={quote}
        />
      )}
    </section>
  );
}

export default HomeQuote;
