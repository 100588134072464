import { MAxios } from "../conf";

export const getApprovedLines = async () => {
    const endpoint = "Linea/Line/GetApprovedLines";
    const res = await MAxios().post(endpoint);

    return res.data;
}

export const getAllInsuranceCarrier = async () => {
    const endpoint = "Aseguradora/Aseguradora/GetAll";

    const res = await MAxios().post(endpoint);

    return res.data;
}

export const getLinesInsurance = async () => {
    const endpoint = "Aseguradora/Aseguradora/GetInsuranceLine";

    const res = await MAxios().post(endpoint);

    return res.data;
}

export const Cotizacion = async (quotation: any) => {

    const endpoint = "Cotizacion/Cotizacion/Cotizacion";

    const res = await MAxios().post(endpoint, quotation);

    return res.data;
}

// export const activateInsuranceLine = async (rutProveedor: string) => {
//     const endpoint = `Aseguradora/Aseguradora/GetAllActiveForProvider?rut=${rutProveedor}`;

//     const res = await MAxios().post(endpoint);

//     return res.data;
// }

const verifySkipOffer = async (insurance : number) => {
    const endpoint = `AseguradoraProveedor/AseguradoraProveedor/UpdateSkipOffer/?insurance=${insurance}`;

    const res = await MAxios().post(endpoint);

    return res.data;
}

const ApiHome = {
    getApprovedLines, 
    getAllInsuranceCarrier,
    Cotizacion,
    getLinesInsurance,
   // activateInsuranceLine,
    verifySkipOffer
};

export default ApiHome;

