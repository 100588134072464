import React from "react";
import ImgCleanHistory from "@img/mis-certificados-img-icon.svg";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import { useHistory } from "react-router-dom";

interface PropsMessageNotData {
  title: string;
  description: string;
  buttonBuy: boolean;
}

const MessageCleanHistory = (props: PropsMessageNotData) => {
  const history = useHistory();
  const redirecNewCertificate = () => {
    history.push("/new-certificate");
  };

  return (
    <>
      <div className="col-12 bg-white p-2 p-md-5 sin-certificados-content my-4">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <img src={ImgCleanHistory} alt="" />
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <div className="row">
              <div className="col-12 my-3">
                <h3 className="titles-left">
                  {props.title} <br />
                  {props.description}
                </h3>
              </div>
              {props.buttonBuy && (
                <div className="col-sm-12">
                  <MButton
                    className="btn btn-primary sistema"
                    onClick={() => redirecNewCertificate()}
                  >
                    Comprar garantías{" "}
                    <i className="fas fa-arrow-right ms-2"></i>
                  </MButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageCleanHistory;
