import { useState } from 'react';

import ReactGA from 'react-ga4';

import * as SessionActions from '@redux/actions/SessionActions';

import { OperacionDTO } from '../../../../../../Models/Operation';
import {
  loadStepData,
} from '../../../../../../scripts/redux/actions/StepActions';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../scripts/redux/hooks';
import { StepState } from '../../../../../../scripts/redux/models/StepModels';
import { items } from '../BuyCart';

export const BuyCartHook = (finalidad?: string) => {
    const { providerCampaign, cart, selectedIssuer } = useAppSelector((store) => store.session);

    const [typeGrid, setTypeGrid] = useState<'finfast' | 'sura' | 'both'>()
    const [gridFinfast, setGridFinfast] = useState<OperacionDTO[]>()
    const [gridPolicy, setGridPolicy] = useState<OperacionDTO[]>()
    const [edit, setEdit] = useState<boolean>(false);
    const [delet, setDelete] = useState<boolean>(false);

    const [stateItem, setStateItem] = useState<items>({
        id: 0,
        nroCft: "",
        reissue: false,
    });

    const dispatch = useAppDispatch();

    const updateCart = () => {
        let updateCart: OperacionDTO[];
        if (selectedIssuer === "finfast") {
            updateCart = cart!.items.filter(x => x.idAseguradora === null);
            dispatch(SessionActions.updateCartToPay(updateCart));
        }
        else if (selectedIssuer === "sura") {
            const updateCart: OperacionDTO[] = cart!.items.filter(x => x.idAseguradora !== null);
            dispatch(SessionActions.updateCartToPay(updateCart));
        }
    }


    const validarTipoGrilla = () => {
        //tiene registros
        const hasValues = cart?.items.length !== 0;
        //registros poliza
        const hasPolice = cart?.items.some(x => x.idAseguradora != null);
        //registros finfast
        const hasFinfast = cart?.items.some(x => x.idAseguradora === null);

        if (hasValues) {
            if (hasPolice && hasFinfast) {
                setTypeGrid('both');
                setGridPolicy(cart?.items.filter(x => x.idAseguradora != null))
                setGridFinfast(cart?.items.filter(x => x.idAseguradora === null))
            } else if (hasPolice) {
                setTypeGrid('sura');
                setGridPolicy(cart?.items.filter(x => x.idAseguradora != null));
                dispatch(SessionActions.setSelectedIssuer("sura"))
                setGridFinfast([]);
            } else {
                setTypeGrid('finfast');
                setGridPolicy([]);
                setGridFinfast(cart?.items.filter(x => x.idAseguradora === null));
                dispatch(SessionActions.setSelectedIssuer('finfast'))
            }
        }
    }

    const editShow = (rowvalue: any) => {
        stateItem.id = rowvalue.id;
        stateItem.nroCft = rowvalue.nroCertificado;
        setStateItem({ ...stateItem });
        setEdit(true);
    };

    const deleteShow = (rowvalue: any) => {
        stateItem.id = rowvalue.id;
        stateItem.reissue = rowvalue.isReissue ?? false;
        setStateItem({ ...stateItem });
        setDelete(true);
    };

    const handlerCloseDelete = () => {
        setDelete(!delet);
        ReactGA.event({
            category: "Carrito",
            label: "Borrar item del carrito",
            action: "Borrar item",
        });
    };

    const resetStepToPay = () => {
        const steps1: StepState = {
            step: 1,
            subStep: undefined,
        };
        dispatch(loadStepData(steps1));
    }
    return {
        updateCart,
        validarTipoGrilla,
        typeGrid,
        gridFinfast,
        gridPolicy,
        setEdit,
        edit,
        setDelete,
        delet,
        setStateItem,
        stateItem,
        editShow,
        deleteShow,
        handlerCloseDelete,
        resetStepToPay,
    }
}
