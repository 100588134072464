import React from "react";
import { AseguradoraTypes } from "@redux/models/InsuranceLineModels";
import iconCesce from "@img/icon-logo-cesce.png";
import iconSura from "@img/icono-poliza-garantia-SURA.png";
import iconCertificadoNew from "@img/logo-pl.png";
import iconFogape from "@img/icons/icon-fogape-logo.svg";
import { ValidateOperations } from "Models/Operation";


export const IconCard = (element: ValidateOperations): JSX.Element => {
  let ids = element.emisor.toLocaleLowerCase() === "fogape" ? AseguradoraTypes.fogape : element.idAseguradora
  switch (ids) {
    case AseguradoraTypes.cesce:
      return <img src={iconCesce} className="img-fluid pt-1" alt="icon-cesce" />;
    case AseguradoraTypes.sura:
      return (
        <>
          <img src={iconSura} className="img-fluid" alt="icon-cesce" />
        </>
      );
    case AseguradoraTypes.fogape:
      return <img src={iconFogape} className="img-fluid pt-2" alt="icon-fogape" />;
    default:
      return (
        <>
          <img
            src={iconCertificadoNew}
            className="img-fluid"
            alt="icon-garantias"
          />
        </>
      );
  }
};
