import { Tender } from "@redux/models/TenderModels";
import { createAction } from "@reduxjs/toolkit";

export const loadTendersPublicadas = createAction(
  "TENDERS_PUBLICADAS_LOAD",
  (tendersData: Tender[] | undefined) => {
    return {
      payload: tendersData,
    };
  }
);

export const loadTendersAdjudicadas = createAction(
  "TENDERS_ADJUDICADAS_LOAD",
  (tendersData: Tender[] | undefined) => {
    return {
      payload: tendersData,
    };
  }
);

export const loadFilteredTendersPublicadas = createAction(
  "TENDERS_FILTERED_PUBLICADAS_LOAD",
  (tendersData: Tender[] | undefined) => {
    return {
      payload: tendersData,
    };
  }
);

export const loadFilteredTendersAdjudicadas = createAction(
  "TENDERS_FILTERED_ADJUDICADAS_LOAD",
  (tendersData: Tender[] | undefined) => {
    return {
      payload: tendersData,
    };
  }
);
export const setCurrentTender = createAction(
  "SET_CURRENT_TENDER",
  (tender: Tender | undefined) => {
    return {
      payload: tender,
    };
  }
);
