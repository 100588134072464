import { useAppDispatch, useAppSelector } from "@redux/hooks";
import React, { useEffect, useState } from "react";
import { convertToPeso } from "@helpers/helpers";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import Pay from "@api/pay/Pay";
import { useHistory } from "react-router-dom";
import { StepPay, StepState } from "@redux/models/StepModels";
import { loadStepData } from "@redux/actions/StepActions";
import { setCurrentStep } from "@redux/actions/NewCertificateActions";
import { Step } from "@redux/models/NewCertificateModels";

const CostZeroPayment = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [request, setRequest] = useState<boolean>(false);
  const { cart } = useAppSelector((store) => store.session);
  const enable = useAppSelector((store) => store.session.validateToPay);
  const { idBillingType } = useAppSelector((store) => store.account);

  let comisionCertificado: number | undefined = cart?.items?.reduce(
    (sum: number, value: any) =>
      typeof value.comision == "number" ? sum + value.comision : sum,
    0
  );

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerReset = () => {
    dispatch(setCurrentStep(Step.seleccionar));
    history.push("/buy-certificate");
  };

  const handlerPayOnClick = async () => {
    setRequest(true);

    if (idBillingType === 2) {
      await Pay.monthlyPay(cart?.items)
        .then((resp: any) => {
          if (resp !== null && resp.success) {
            const steps: StepState = {
              step: 3,
              subStep: StepPay.successPay,
            };
            dispatch(loadStepData(steps));
          }
        })
        .catch((error) => console.error("error monthlyPay: ", error))
        .finally(() => {
          setRequest(false);
        });
    } else {
      await Pay.callPaymentCostZero(cart?.items)
        .then((resp: any) => {
          if (resp !== null && resp.success) {
            const steps: StepState = {
              step: 3,
              subStep: StepPay.successPay,
            };
            dispatch(loadStepData(steps));
          }
        })
        .catch((error) => console.error("error callPaymentCostZero: ", error))
        .finally(() => {
          setRequest(false);
        });
    }
  };

  return (
    <>
      <div className="col-12 col-lg-5 my-3">
        <div className="rounded-card-bg p-5">
          <div className="row">
            <div className="col-12">
              <h3 className="subtitle-mini text-center">TOTAL</h3>
              <hr />
            </div>
            <div className="col-6">
              <h3 className="subtitle-mini text-start">Total a pagar</h3>
            </div>
            <div className="col-6">
              <h3 className="subtitle-mini text-end">
                {convertToPeso(comisionCertificado)}
              </h3>
              <p></p>
            </div>
            <div className="col-12 text-center">
              <p>*Precio válido solo durante el día de la emisión</p>
            </div>
            <div className="col-12">
              <div className="msj-detalle-carrido">
                <p>
                  Una vez realizada la compra, tus certificados estarán
                  disponibles dentro de 15 minutos
                </p>
              </div>
            </div>

            <div className="col-12 text-center"></div>

            <>
              <div className="">
                <div className="text-center button-carro-compras">
                  <MButton
                    type="submit"
                    onClick={handlerPayOnClick}
                    disabled={!cart?.items || !enable ? true : false}
                    loading={request}
                  >
                    Emitir
                  </MButton>
                  <MButton outline onClick={handlerReset}>
                    Seguir comprando
                  </MButton>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default CostZeroPayment;
