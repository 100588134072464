import React from 'react';

import { ValidateOperations } from 'Models/Operation';

import { MButton } from '@inversiones-ma/finfast-react-controls';
import * as NewCertificateModels from '@redux/models/NewCertificateModels';

interface PropsBtnSelectCard {
  quoteData: ValidateOperations[];
  addOrEdit: NewCertificateModels.Step;
  handlerSelect: (element: ValidateOperations) => void;
  element: ValidateOperations;
  warantySelected: ValidateOperations | undefined;
}

const BtnSelectCard = ({
  quoteData,
  addOrEdit,
  handlerSelect,
  element,
  warantySelected,
}: PropsBtnSelectCard) => {
  
  function HandlerSelectElement(element: ValidateOperations) {
    handlerSelect(element);
  }

  return (
    <>
      {/* {((warantySelected === element.prioridad && element.status) ||
      (warantySelected !== element.prioridad && props?.quoteData?.length !== 1 &&
      (props.addOrEdit === NewCertificateModels.Step.verificar ||
      props.addOrEdit === NewCertificateModels.Step.ingresar ||
      props.addOrEdit === NewCertificateModels.Step.cotizar))) ? ( */}
      {quoteData?.length === 1 ? (
        <></>
      ) : (
        <>
          {addOrEdit === NewCertificateModels.Step.verificar ||
          addOrEdit === NewCertificateModels.Step.ingresar ||
          addOrEdit === NewCertificateModels.Step.cotizar ? (
            <div className="div-btn">
              <MButton
                className={`btn btn-primary ${
                  warantySelected?.emisor === element.emisor
                    ? "w-100 boton-seleccion-rc btn-sistema-card"
                    : "outline w-100 boton-seleccion-rc btn-sistema-card"
                }`}
                onClick={() => HandlerSelectElement(element)}
                disabled={!element.success}
                //key={element.emisor}
              >
                {warantySelected?.emisor === element.emisor
                  ? "Seleccionado "
                  : "Seleccionar "}
                <span className="fa fa-check-circle"></span>
              </MButton>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default BtnSelectCard;
