import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import EnterData from "../../NewCertificate/partial/EnterData";
import VerifyData from "../../NewCertificate/partial/VerifyData";
interface PropsFormAddOperation {
  close: () => void;
  AddToCart?: (added: boolean) => void;
}

const AutoScrollInPopUp = () => {
  useEffect(() => {
    let modal = document.querySelector(".modal");
    if (modal) {
      modal.scrollTop = 0;
    }
  }, []);
  return <></>;
};

const FormAddOperation = (props: PropsFormAddOperation) => {  
  const dispatch = useAppDispatch();
  const { currentQuoteId } = useAppSelector((store) => store.quote);
  const { currentStep } = useAppSelector((store) => store.newCertificate);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { firstGridData, secondGridData } = useAppSelector(
    (store) => store.quote
  );
  const gridData = [...firstGridData, ...secondGridData];

  useEffect(() => {
    const item = gridData.find((x) => x.id === currentQuoteId);
    dispatch(NewCertificateActions.setSelectedData(undefined));
    dispatch(NewCertificateActions.setTenderData(item));
    setIsLoaded(true);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="p-2 p-md-4 modal-pago-certificado">
        {isLoaded && (
          <>
            {currentStep === NewCertificateModels.Step.ingresar && (
              <>
                <AutoScrollInPopUp />
                <EnterData
                  addOrEdit={currentStep}
                  close={props.close}
                  origen="addQuote"
                />
              </>
            )}
            {currentStep === NewCertificateModels.Step.verificar && (
              <>
                <AutoScrollInPopUp />
                <VerifyData
                  addOrEdit={currentStep}                
                  AddToCart={props.AddToCart}
                  origen="addQuote"
                  close={props.close}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FormAddOperation;
