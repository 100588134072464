import axios from "axios";
import store from "@redux/store/store";
import { apiUrl, finfast } from "../config/environment";
import Cookies from "universal-cookie/cjs/Cookies";
import helpersSession from "@helpers/helpersSession";
import ApiMembresiaAccount from "../api/membresia/Account";
import * as AccountActions from "../redux/actions/AccountActions";

//** Conf **/

//API URL
const baseURL = `${apiUrl()}/api/`;
export const url = baseURL;

const baseTokenHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
});

const baseAxiosJsonHeaders = () => ({
  headers: {
    ...baseTokenHeaders(),
  },
});

const baseAxiosFormDataHeaders = () => ({
  headers: {
    ...baseTokenHeaders(),
    "Content-Type": "multipart/form-data",
  },
});

export const InjectTokenHeader = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const InjectTokenHeaderDownload = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  responseType: "blob",
});

export const MAxios = () => {
  let axiosCreated = axios.create({
    baseURL,
    ...baseAxiosJsonHeaders(),
  });

  axiosCreated = applyInterceptors(axiosCreated);

  return axiosCreated;
};

export const MAxiosFormData = () => {
  let axiosCreated = axios.create({
    baseURL,
    ...baseAxiosFormDataHeaders(),
  });

  axiosCreated = applyInterceptors(axiosCreated);

  return axiosCreated;
};

export const MAxiosBlob = () => {
  let axiosCreated = axios.create({
    baseURL,
    ...baseAxiosFormDataHeaders(),
    responseType: "blob",
  });

  axiosCreated = applyInterceptors(axiosCreated);

  return axiosCreated;
};

export const applyInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (request) => {
      const token = localStorage.getItem("access_token");

      if (token) {
        request.headers["Authorization"] = `Bearer ${token}`;
      }
      return request;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401) {
        const newToken = await ApiMembresiaAccount.RefreshToken(
          localStorage.getItem("access_token")
        );
        if (newToken) {
          localStorage.setItem("access_token", newToken);
          const account = store?.getState().account;
          store.dispatch(
            AccountActions.loadLoginData({ ...account, token: newToken })
          );
          prevRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axiosInstance(prevRequest);
        } else {
          //window.location.href = finfast() + "/logout";
        }
      } else {
        Promise.reject(error);
        //window.location.href = finfast() + "/logout";
      }
    }
  );
  return axiosInstance;
};

export const createCookie = (token) => {
  try {
    const environment =
      process.env.REACT_APP_SUBENVIRONMENT === "production" ? "prod" : "local";
    let date = new Date();
    date.setDate(date.getDate() + 1);

    const cookieOptions = {
      secure: true,
      sameSite: "strict",
      httpOnly: environment === "prod" ? true : false,
      domain: environment === "prod" ? "www.finfast.com" : "localhost",
      path: "/",
      expires: date,
    };

    const cookie = new Cookies();
    cookie.set("GvcEfA", token, cookieOptions);
  } catch (ex) {
    console.error("errorcokie", ex);
  }
};

export const FetchDownloadFile = async (endpoint, data, fileName) => {
  const respuesta = await fetch(url + endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: data,
  });
  if (respuesta.status === 200) {
    var resp = await respuesta.blob();
    if (resp.size === 0 || resp.type === "text/plain" || resp.type === "") {
      return false;
    } else {
      let url = window.URL.createObjectURL(resp);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      return true;
    }
  }
};
