import {
  MButton,
  MInputGeneric,
  MInputRut,
  MInputSelect,
} from "@inversiones-ma/finfast-react-controls";
import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Inputs } from "./Partial/AccidentCertificates/AccidentCertificates";
import { myPurposeTabs } from "@app/arrayData";

export interface CertificateFilters {
  form: UseFormReturn<Inputs, object>;
  onSubmit: any;
  loading: boolean;
}

export const CertificatesFilters = ({
  form,
  onSubmit,
  loading,
}: CertificateFilters) => {
  const { handleSubmit, setValue } = form;

  useEffect(() => {}, [setValue]);

  return (
    <>
      <div className="desplegable-gestiones rounded-card-bg lborder-secondary p-2 mt-3 mb-3">
        <div className="row">
          <div className="col-8">
            <p className="m-0 p-0 fs-16 color-secondary d-flex align-items-center">
              {" "}
              Filtrar Garantias
            </p>
          </div>
          <div className="col-4 text-end">
            <a
              data-bs-toggle="collapse"
              href="#collapsefiltros"
              role="button"
              aria-expanded="false"
              aria-controls="collapsefiltros"
              className="text-link"
            >
              <i className="fas fa-chevron-down p-0"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-white mt-n10" color="auxiliar">
        <div
          className="collapse detalle-desplegable-gsl mt-3"
          id="collapsefiltros"
        >
          <form className="row p-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12">
              <p className="fs-16">
                Acá podrás filtrar tus certificados por estado, N° certificado,
                RUT mandante, Estado certificado y Finalidad.
              </p>
            </div>
            <div className="col-12 col-lg-5">
              <MInputRut
                form={form}
                name="RutMandante"
                label={"Rut Mandante"}
              />
              <MInputGeneric
                // descriptionItem="id licitacion"
                form={form}
                name="IdLicitacion"
                label="id Licitación"
              />
            </div>
            <div className="col-12 col-lg-5">
              <MInputGeneric form={form} name="NroCertificado" label="N° CFT" />
              <MInputSelect
                valueItem="descripcion"
                descriptionItem="descripcion"
                form={form}
                name="Finalidad"
                items={myPurposeTabs}
                label="Finalidad"
              />
            </div>
            <div className="col-12 col-md-2 col-lg-2 mt-3">
              <MButton
                type="submit"
                className="btn btn-primary w-100 sistema external mb-2"
                loading={loading}
              >
                Buscar
              </MButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
