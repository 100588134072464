import GenericBaseModel from "@api/GenericBaseModel";
import ApiOperation from "@api/garantias/operation/Operation";
import {
  ApprovedLine,
  Cart,
  Discount,
  DiscountCode,
  DiscountValue,
} from "@redux/models/SessionModels";
import { isEqual } from "lodash";

export const validateLineAvailable = (
  cart: Cart,
  lineAvailable: ApprovedLine[] | undefined
): boolean => {
  let dataLine: any = [];
  let newItemLineAvailable: any = [];
  let validateToPay: boolean = false;

  if (cart.items.find((a) => a.idAseguradora === 0)) {
    // if (typeof lineAvailable === "undefined" || !lineAvailable) {
    //   return validateToPay;
    // }

    dataLine = validateLine(cart?.items, lineAvailable);

    if (dataLine.length > 0) {
      newItemLineAvailable = dataLine.filter(
        (x: any) => x.available < x.requestedLine
      );
      if (newItemLineAvailable.length > 0) {
        validateToPay = false;
      } else {
        validateToPay = true;
      }
    }
  } else {
    validateToPay = true;
  }

  return validateToPay;
};

const validateLine = (arr: any, approvedLine: ApprovedLine[] | undefined) => {
  let tempDataFinalidad: any = [];

  for (let x in arr) {
    let tempValue = arr[x];

    if (!tempDataFinalidad.some((z: any) => z.value === tempValue.finalidad)) {
      tempDataFinalidad.push({
        value: tempValue.finalidad,
      });
    }
  }

  let tempLine: ApprovedLine[] = [];
  let datalist = approvedLine;
  for (let x in tempDataFinalidad) {
    let tempValue = tempDataFinalidad[x];

    let tempFilter = datalist?.find((x) => x.name === tempValue.value);
    tempLine.push({
      available: tempFilter?.available ?? 0,
      countOperation: tempFilter?.countOperation ?? 0,
      name: tempFilter?.name ?? "",
      percentageAvailable: tempFilter?.percentageAvailable ?? 0,
      requestedLine: tempFilter?.requestedLine ?? 0,
      usagePercentage: tempFilter?.usagePercentage ?? 0,
      used: tempFilter?.used ?? 0,
    });
  }

  return tempLine;
};

export const validateDiscount = async (
  discount: string,
  cart: Cart
) : Promise<any> => {
  let licitacionIds = cart?.items.map((item) => item.id);

  await ApiOperation.ValidateDiscountOperation(discount, licitacionIds)
    .then((response: GenericBaseModel<Discount>) => {
      if (response.success) {
        // setDisccountColor("#28a745");
        // setDisccountSuccess(true);
        // setDisccounMessage("Tu descuento se ha aplicado exitosamente");
        const dataDiscount: DiscountCode = {
          codigo: response.data.ticketDescuento?.codigo,
          typeDiscount: !response.data.ticketDescuento?.descuentoPorcentaje
            ? "CLP"
            : "%",
          valDiscount: !response.data.ticketDescuento?.descuentoPorcentaje
            ? response.data.ticketDescuento?.descuentoCLP
            : response.data.ticketDescuento?.descuentoPorcentaje,
        };

        let validateDiscountAmount = 0;

        if (dataDiscount && isEqual(dataDiscount.typeDiscount, "%")) {
          validateDiscountAmount = response.data.listOperacion
            .map((op) => op.descuentoTicket)
            .reduce((acc, desc) => acc + desc);
        } else {
          let valdiscount = 0;
          if (typeof dataDiscount.valDiscount !== "undefined") {
            valdiscount = dataDiscount.valDiscount;
          }

          validateDiscountAmount =
            response.data.listOperacion
              .map((op) => op.descuentoTicket)
              .reduce((acc, desc) => acc + desc) > valdiscount
              ? dataDiscount.valDiscount
              : response.data.listOperacion
                  .map((op) => op.descuentoTicket)
                  .reduce((acc, desc) => acc + desc);
        }

        const newCart = {
          items: response.data.listOperacion,
          discountCode: cart?.discountCode,
        };

        const discountValue: DiscountValue = {
          couponCode: response.data.ticketDescuento?.codigo,
          discountCLP: response.data.ticketDescuento?.descuentoCLP,
          discountPercent: response.data.ticketDescuento?.descuentoPorcentaje,
          discountTicket: validateDiscountAmount,
        };

        const result: resulDiscount = {
          success: true,
          discountCode: dataDiscount,
          discountValue: discountValue,
          newCart: newCart,
        };

        return result;
        // dispatch(loadDiscountCode(dataDiscount));
        // dispatch(loadDiscountValue(discountValue));
        // dispatch(loadCart(newCart));
      } else {
        const result: resulDiscount = {
          success: false,
          discountCode: null,
          discountValue: null,
          newCart: null,
        };
        return result;
        // setDisccountError(true);
        // setDisccountColor("#dc3545");
        // setDisccounMessage("Tu código de descuento no es válido");
      }
    })
    .catch((error) => {
      console.error("error al consumir api: ", error);
    })
    .finally(() => {
      // setDisccountRequest(false);
    });
};

export interface resulDiscount {
  success: boolean;
  discountCode: DiscountCode | null;
  discountValue: DiscountValue | null;
  newCart: Cart | null;
}
