import React from "react";
import { MButton, MPopup } from "@inversiones-ma/finfast-react-controls";

interface PropsErrorMandante {
  errorPopup: boolean;
  setErrorPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handlerError: () => void;
}

const ErrorMandante = (props: PropsErrorMandante) => {
  return (
    <>
      <MPopup
        size="md"
        showClose={true}
        showModal={props.errorPopup}
        setShowModal={props.setErrorPopup}
      >
        <div className="message-error-data-rep">
          <i className="fa fa-exclamation-triangle"></i>
          <h4>EL RUT INGRESADO NO ESTA HABILITADO</h4>
          <hr className="title-divider mx-auto" />
          <p className="my-4">
            No es posible emitir un certificado para este mandante. Contáctate
            con tu ejecutiva para mayor información.
          </p>
        </div>
        <div className="text-center col-12 bnt-user-no-register">
          <MButton color="primary" type="button" onClick={props.handlerError}>
            Entiendo
          </MButton>
        </div>
      </MPopup>
    </>
  );
};

export default ErrorMandante;
