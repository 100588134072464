import React, {
  useEffect,
  useState,
} from 'react';

import ApiOperation from '@api/garantias/operation/Operation';
import GenericBaseModel from '@api/GenericBaseModel';
import {
  loadApprovedLine,
  loadCart,
  loadValidatePay,
} from '@redux/actions/SessionActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import {
  ApprovedLine,
  Cart,
} from '@redux/models/SessionModels';

import CartBannerSII from '../../SII/Cart/CartBannerSII';
import BuyCart from '../partial/BuyCart';
import DiscountZone from '../partial/DiscountZone';
import SummaryCart from '../partial/SummaryCart';
import { validateLineAvailable } from '../partial/ValidatePay';

const CartStep1 = () => {
  const dispatch = useAppDispatch();
  const {
    requireSiiCredentials,
    // clientOmitSiiExtraInfo,
    // idSalesLevel,
    // idSalesLevelIntGarantias,
    // subscriptionStatus,
    // clientOmitSii,
  } = useAppSelector((store) => store.account);
  const { cart } = useAppSelector((store) => store.session);
  // const showCartSii = requireSiiCredentials && !clientOmitSiiExtraInfo;
  // const showCartSiiUpdatedCredentials =
  //   !requireSiiCredentials &&
  //   !clientOmitSiiExtraInfo &&
  //   subscriptionStatus &&
  //   subscriptionStatus.estadoCredencial !== "OK";
  const [showMessageSii, setShowMessageSii] = useState<boolean>(false);
  // const saleLevel = idSalesLevelIntGarantias
  //   ? idSalesLevelIntGarantias
  //   : idSalesLevel;
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [auxValue, setAuxValue] = useState<boolean>(false);
  const [buttonOmit, setButtonOmit] = useState<boolean>(false);

  useEffect(() => {
    dispatch(loadValidatePay(undefined));
    ApiOperation.ValidateListOperation(cart?.items)
      .then((resp: GenericBaseModel<ApprovedLine>) => {
        if (resp.success) {
          dispatch(loadApprovedLine(resp.dataList));
          let result = validateLineAvailable(cart!, resp.dataList);
          dispatch(loadValidatePay(result));
        }
      })
      .catch((error) => console.error("error ValidateListOperation: ", error))
      .finally(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const loadCartHome = async () => {
    const resp = await ApiOperation.ValidateOperationsInCart();
    const cart = {
      items: resp.dataList,
      discountCode: undefined,
    } as Cart | undefined;
    dispatch(loadCart(cart));
  };

  useEffect(() => {
    if (cart !== undefined && cart.items.length === 0) {
      loadCartHome();
    }
  }, []);

  return (
    <>
      <BuyCart />

      {requireSiiCredentials ? (
        <CartBannerSII
          setShowMessageSii={setShowMessageSii}
          isUpdated={isUpdated}
          auxValue={auxValue}
          setButtonOmit={setButtonOmit}
        />
      ) : (
        <SummaryCart showMessageSii={showMessageSii} />
      )}

      <DiscountZone />
    </>
  );
};

export default CartStep1;
