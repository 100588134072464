import React from 'react';
import './SimpleLoading.css';

interface PropsSimpleLoading {
	className: string
}

function SimpleLoading(props:PropsSimpleLoading) {
	return (
		<div className={'sk-chase' + (props.className ? ` ${props.className}` : '')}>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
			<div className="sk-chase-dot"></div>
		</div>
	)
}

export default SimpleLoading