export interface Commune {
  id: number;
  name: string;
  idRegion?: number;
  idCity?: number | null;
}

export interface Region {
  id: number;
  name: string;
  code: string;
  totalCost: number | null;
  order: number | null;
  communes: Commune[];
}

export interface GlobalState {
  regions: Region[] | undefined;
  loaded: Boolean;
  currentDate: string | undefined; // mm/dd/yyyy
  paymentMethods: PaymentMethods[] | undefined;
  surveyNps: SurveyNps | undefined;
  typeTender: TypeTender[] | undefined;
  configuracion: Configuracion[] | undefined;
}

export interface PaymentMethods {
  id: number;
  descripcion: string;
  estado?: boolean;
  mensajeContingencia: string;
}

export interface TypeTender {
  id: number;
  codigo: string;
  descripcion: string;
}

export interface SurveyNps {
  showSurvey: boolean;
  idEncuesta: number;
  showPopupTimeSeg: number;
  openSurveyCount: number;
  npsDisplayLimit: number;
  listDataSurvey: ListDataSurvey[] | undefined;
}

export interface ListDataSurvey {
  idEncuestaPregunta: number;
  pregunta: string;
  preguntaRequerida: string;
  idTipoDato: number;
  tipoDato: string;
}

export interface Configuracion {
  llave: string;
  valorBooleano: boolean | null;
  valorNumerico: number | null;
  valorFecha: Date | null;
  valorAlfanumerico: string | null;
  fechaActualizacion: Date;
  bannerMulti2: string;
}

export enum EnumOriginPaymentChannels {
  Transbank = "Transbank",
  Servipag = "Servipag",
  Khipu = "Khipu",
  KhipuDiferido = "KhipuDiferido",
}