import React from "react";
import { MButton, MPopup, MTitle } from "@inversiones-ma/finfast-react-controls";
import Img from "@img/icon-certify-broke.svg";
import IconAlert from "@img/triangle-Exclamation-Icons.svg";
import "./PopupCertificate.scss";

interface propsErrorSii {
  icon: string | undefined;
  title: string | undefined;
  text: string | undefined;
  setShowModal: Function;
  showModal: boolean;
}

const PopupCertificate = (props:propsErrorSii) => {

  return (
    <>
      <MPopup
        setShowModal={props.setShowModal}
        showModal={props.showModal}
        size="lg"
      >
        <div className="popupCertificate">
          <div className="message-error-sii">
            <img
              className="pb-4"
              src={!!!props.icon || props.icon === "default" ? Img : IconAlert}
              alt=""
            />
            <MTitle hr position="center">
              {props.title}
            </MTitle>
            <p className="popup-certificate-paragraph mt-4">{props.text}</p>
            <MButton
              className="btn btn-primary sistema mt-4"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Entendido
            </MButton>
          </div>
        </div>
      </MPopup>
    </>
  );
};

export default PopupCertificate;
