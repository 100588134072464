
import { OperacionDTO } from "Models/Operation";
import { MAxiosFormData, MAxios } from "../conf";

const callTransbankPay = async (
  arrTenders: OperacionDTO[] = [],
  couponCode: string | undefined
) => {
  const endpoint = "Transbank/Transbank/Pay";

  try {
    let formData = new FormData();
    formData.append("couponCode", couponCode ?? "");
    formData.append("operationsStr", JSON.stringify(arrTenders));

    const res = await MAxiosFormData().post(endpoint, formData);

    return res.data;
  } catch {
    return null;
  }
};

const callKhipuPay = async (
  arrTenders: any = [],
  mailToDeferred: string | undefined
) => {
  const endpoint = "Khipu/Khipu/PayKhipu";

  try {
    let formData = new FormData();
    formData.append("operation", JSON.stringify(arrTenders));
    if (mailToDeferred) {
      formData.append("mailToDeferred", mailToDeferred);
    }

    const res = await MAxiosFormData().post(endpoint, formData);

    return res.data;
  } catch {
    return null;
  }
};

const callServipagPay = async (
  arrTenders: any = [],
  mailToDeferred: string | undefined
) => {
  const endpoint = "Servipag/PayServipag";

  try {
    let formData = new FormData();

    formData.append("operation", JSON.stringify(arrTenders));

    if (mailToDeferred) {
      formData.append("mailToDeferred", mailToDeferred);
    }

    const res = await MAxiosFormData().post(endpoint, formData);

    return res.data;
  } catch {
    return null;
  }
};

const callValidatePayServipag = async (
  trxServipag: number | undefined,
  previewValidation: string | undefined
) => {
  const endpoint = `Servipag/Result?idTrxServipag=${trxServipag}&previewValidation=${previewValidation}`;

  try {
    const res = await MAxiosFormData().get(endpoint);

    return res.data;
  } catch {
    return null;
  }
};

export async function monthlyPay(arrTenders: OperacionDTO[] | undefined) {
  try {
    const endpoint = "Operacion/Operation/MonthlyPayment";

    const res = await MAxios().post(endpoint, arrTenders);

    return res.data;
  } catch (e) {
    return null;
  }
}

const callPaymentCostZero = async (arrTenders: any = []) => {
  const endpoint = "Operacion/Operation/PaymentCostZero";

  try {
    const res = await MAxios().post(endpoint, arrTenders);

    return res.data;
  } catch {
    return null;
  }
};

const Pay = {
  callKhipuPay,
  callServipagPay,
  callPaymentCostZero,
  callTransbankPay,
  callValidatePayServipag,
  monthlyPay,
};

export default Pay;
