import {
    cleanState,
    loadLoginData,
    updateSiiData,
    updateSkipSiiData,
    loadSubscriptionStatus,
    loadSubscriptionStatusOk,
    requireSiiCredentialsOk
} from '../actions/AccountActions';
import {createSlice} from "@reduxjs/toolkit";
import {AccountState} from "../models/AccountModels";

const inicialState: AccountState = {
    token: undefined,
    run: undefined,
    name: undefined,
    email: undefined,
    idBillingType: undefined,
    executive: undefined,
    idClassification: undefined,
    idSalesLevel: undefined,
    telephone: undefined,
    idSalesLevelIntFactoring: undefined,
    idSalesLevelIntGarantias: undefined,
    idActivity: undefined,
    enabledEmit: undefined,
    emailPreference: undefined,
    showPreference: undefined,
    isAnonymous: undefined,
    validatePlazo: undefined,
    blockedByDebt: undefined,
    loginDate: undefined,
    loginDateClient: undefined,
    requireSiiCredentials: false,
    isNewClient: false,
    clientOmitSii: false,
    clientOmitSiiExtraInfo: false,
    subscriptionStatus: undefined,
    idRoL: undefined,
    externalInstabilityProblem: undefined
};

const slice = createSlice({
    name: 'account'
    , initialState: inicialState
    , reducers: {}
    , extraReducers: builder => {
        builder.addCase(loadLoginData, (state, action) => {
            state.token = action.payload.token;
            state.run = action.payload.run;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.idBillingType = action.payload.idBillingType;
            state.executive = action.payload.executive;
            state.idClassification = action.payload.idClassification;
            state.idSalesLevel = action.payload.idSalesLevel;
            state.idSalesLevelIntFactoring = action.payload.idSalesLevelIntFactoring;
            state.idSalesLevelIntGarantias = action.payload.idSalesLevelIntGarantias;
            state.idActivity = action.payload.idActivity;
            state.enabledEmit = action.payload.enabledEmit;
            state.emailPreference = action.payload.emailPreference;
            state.showPreference = action.payload.showPreference;
            state.isAnonymous = action.payload.isAnonymous;
            state.validatePlazo = action.payload.validatePlazo;
            state.blockedByDebt = action.payload.blockedByDebt;
            state.loginDate = action.payload.loginDate;
            state.loginDateClient = action.payload.loginDateClient;
            state.requireSiiCredentials = action.payload.requireSiiCredentials;
            state.isNewClient = action.payload.isNewClient;
            state.clientOmitSii = action.payload.clientOmitSii;
            state.clientOmitSiiExtraInfo = false;
            state.idRoL = action.payload.idRoL
            state.externalInstabilityProblem = action.payload.externalInstabilityProblem
        })
        builder.addCase(cleanState, (state) => {
            state.token = inicialState.token;
            state.run = inicialState.run;
            state.name = inicialState.name;
            state.email = inicialState.email;
            state.idBillingType = inicialState.idBillingType;
            state.executive = inicialState.executive;
            state.idClassification = inicialState.idClassification;
            state.idSalesLevel = inicialState.idSalesLevel;
            state.idSalesLevelIntFactoring = inicialState.idSalesLevelIntFactoring;
            state.idSalesLevelIntGarantias = inicialState.idSalesLevelIntGarantias;
            state.idActivity = inicialState.idActivity;
            state.enabledEmit = inicialState.enabledEmit;
            state.emailPreference = inicialState.emailPreference;
            state.showPreference = inicialState.showPreference;
            state.isAnonymous = inicialState.isAnonymous;
            state.validatePlazo = inicialState.validatePlazo;
            state.blockedByDebt = inicialState.blockedByDebt;
            state.loginDate = inicialState.loginDate;
            state.loginDateClient = inicialState.loginDateClient;
            state.requireSiiCredentials = inicialState.requireSiiCredentials;
            state.isNewClient = inicialState.isNewClient;
            state.clientOmitSii = inicialState.clientOmitSii;
            state.clientOmitSiiExtraInfo = inicialState.clientOmitSiiExtraInfo;
            state.externalInstabilityProblem = inicialState.externalInstabilityProblem
        })
        builder.addCase(updateSiiData, (state, action) => {
            state.requireSiiCredentials = action.payload;
            state.isNewClient = action.payload;
        })
        builder.addCase(updateSkipSiiData, (state, action) => {
            state.clientOmitSii = action.payload.clientOmitSii
            state.clientOmitSiiExtraInfo = action.payload.clientOmitSiiExtraInfo
        })
        builder.addCase(loadSubscriptionStatus, (state, action)=> {
            state.subscriptionStatus = action.payload
        })
        builder.addCase(loadSubscriptionStatusOk, (state, action)=> {
            state.subscriptionStatus!.estadoCredencial = action.payload
        })
        builder.addCase(requireSiiCredentialsOk, (state, action)=> {
            state.requireSiiCredentials = action.payload
        })
        builder.addDefaultCase((_state) => {
        })
    }
});

export default slice.reducer;