import React from "react";
import { useAppSelector } from "@redux/hooks";
import { MCards } from "@inversiones-ma/finfast-react-controls";
import dayjs from "dayjs";


const DatosAseguradora = () => {
  const { tenderData } = useAppSelector((store) => store.newCertificate);

  return (
    <>
      {!!tenderData?.fechaInicioContrato && (
        <div className="col-12 pb-4">
          <MCards
            className="px-4 pb-4"
            style={{ boxShadow: "0px 0px 0px" }}
            color="successHome"
          >
            <div className="col-12 pt-4">
              <h3 className="subtitle-sub-mini color-secondary fw-bold fs-18 mb-0">
                Datos Póliza
              </h3>
            </div>
            <div className="row d-flex pt-2">
              <div className="col-12 col-lg-6 pt-2 d-flex flex-row">
                <h3 className="fw-bold fs-14 mb-0">Fecha inicio de contrato</h3>
                <p
                  className="ms-auto fs-14 mb-0"
                  style={{ textAlign: "right" }}
                >
                  {dayjs(tenderData.fechaInicioContrato).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </MCards>
        </div>
      )}
    </>
  );
};

export default DatosAseguradora;
