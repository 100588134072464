import React from "react";
import { useAppDispatch } from "@redux/hooks";
import { MButton, MPopup } from "@inversiones-ma/finfast-react-controls";
import * as NewCertificateActions from "@redux/actions/NewCertificateActions";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";
import "./MAlertPopupNewCertificate.scss";

const MAlertPopupNewCertificateErrorSolicitudEmision = ({
  showModal,
  setShowModal,
}) => {
  const dispatch = useAppDispatch();
  const editOnClick = () => {
    dispatch(
      NewCertificateActions.setCurrentStep(NewCertificateModels.Step.ingresar)
    );
  };
  return (
    <MPopup
      size="lg"
      showClose={false}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="exitmodal01">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowModal(false)}
        ></button>
      </div>
      <div className="msg-validation-matriz error">
        <i className="fa fa-exclamation-triangle fs-36 color-warning "></i>
        <h4 className="titles-center">
          YA EMITISTE UN <br />
          CERTIFICADO CON LOS <br />
          MISMOS DATOS
        </h4>
        <p>Prueba modificando unos de los siguientes campos:</p>
        <div className="container listaerror">
          <div
            className="row align-items-center"
            style={{ marginBottom: "20px" }}
          >
            <div className="col-10 col-md-6">
              <ul className="textoerror">
                <li>RUT mandante</li>
                <li>Finalidad</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 order-1 order-md-0 my-auto py-2">
            <MButton
              href="#."
              className="btn btn-primary sistema outline w-100"
              onClick={() => setShowModal(false)}
            >
              Cancelar
            </MButton>
          </div>
          <div className="col-12 col-md-4 order-0 order-md-1 py-2">
            <MButton
              href="#."
              className="btn btn-primary sistema w-100"
              onClick={editOnClick}
            >
              Modificar datos
            </MButton>
          </div>
        </div>
      </div>
    </MPopup>
  );
};

export default MAlertPopupNewCertificateErrorSolicitudEmision;
