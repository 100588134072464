import dayjs from "dayjs";
import { getSessionGeneral } from "./helpersSession";
import ApiMembresiaAccount from "@api/membresia/EnabledProducts";
import currency from "currency.js";
import _ from "lodash";

const getDecimalPortionForNumber = (value) => {
  // Obtener decimales con precision maxima de 2
  let decimalPortion = (value % 1).toFixed(2).substring(2);
  // Quitar ceros de la derecha en decimales
  decimalPortion = _.trimEnd(decimalPortion, "0");

  return decimalPortion;
};

export const convertToPeso = (value) =>
  currency(value, {
    symbol: "$",
    decimal: ",",
    separator: ".",
    precision: 0,
  }).format();
export const convertToUsd = (value) =>
  currency(value, {
    symbol: "",
    decimal: ",",
    separator: ".",
    precision: getDecimalPortionForNumber(value).length,
  }).format();
export const convertToUf = (value) =>
  currency(value, {
    symbol: "",
    decimal: ",",
    separator: ".",
    precision: getDecimalPortionForNumber(value).length,
  }).format();

//#region "Regiones y comunas"

export function sonRegionesIguales(region1Nombre, region2Nombre) {
  // convertir a comparables (quitar tildes u otros adornos)
  region1Nombre = convertToComparableString(region1Nombre);
  region2Nombre = convertToComparableString(region2Nombre);

  // quitar signos y palabra ' chilena'
  region1Nombre = region1Nombre.replace(/('|´|`|-| chilena)/g, "");
  region2Nombre = region2Nombre.replace(/('|´|`|-| chilena)/g, "");

  // quitar palabra ' de ' y ' del '
  region1Nombre = region1Nombre.replace(/ de | del /g, " ");
  region2Nombre = region2Nombre.replace(/ de | del /g, " ");
  return region1Nombre === region2Nombre;
}

export const getComunas = (nombreRegion, arrRegion) => {
  if (!nombreRegion) return [];
  if (!arrRegion || arrRegion.length === 0) {
    if (!arrRegion || arrRegion.length === 0) return [];
  }
  const comunas =
    arrRegion.find((r) => sonRegionesIguales(nombreRegion, r.name))?.communes ??
    [];

  if (comunas.length === 0) return [];

  return comunas;
};

//#endregion

//#region "Fechas"

export function toDate(date) {
  let today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  return (today = dd + "/" + mm + "/" + yyyy);
}

export function toDate2(date) {
  let today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  return (today = dd + "-" + mm + "-" + yyyy);
}

export function convertDateToDateWithoutTime(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getCurrentDate(currentDay) {
  let dd = String(currentDay.getDate()).padStart(2, "0");
  let mm = String(currentDay.getMonth() + 1).padStart(2, "0");
  let yyyy = currentDay.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
}

export function dateFormatString(date) {
  return toString(new Date(date), "d", "es-CL"); // 1-5-2017
}

export const toMInputDate = (date) => {
  if (!date || typeof date === "undefined") return "";

  if (typeof date === "string") {
    let today = new Date(date);
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const respuesta = { year, month, day };
    return respuesta;
  } else {
    let today = dayjs(date);

    const day = today.date();
    const month = today.month() + 1;
    const year = today.year();
    const respuesta = { year, month, day };
    return respuesta;
  }
};

export const getDateNowZeroString = () => {
  const dateToday = dayjs();
  const d = new Date(dateToday.year(), dateToday.month(), dateToday.date());
  return d.toJSON();
};

export const getDateNowZero = () => {
  const dateToday = dayjs();
  return new Date(dateToday.year(), dateToday.month(), dateToday.date());
};
export const getDateZero = (date) => {
  const dateToday = dayjs(date);
  return new Date(dateToday.year(), dateToday.month(), dateToday.date());
};
//Usada para cargar datos al agregar nueva operación

export const getBeginEndDate = (selectedData, date) => {
  const fechaActual = getDateZero(date);

  if (!selectedData) {
    return {
      fechaInicio: fechaActual,
      fechaTermino: fechaActual,
    };
  }

  if (!selectedData.fechaInicio && !selectedData.fechaTermino) {
    return {
      fechaInicio: fechaActual,
      fechaTermino: fechaActual,
    };
  } else {
    const fechaActualDayJs = dayjs(fechaActual);

    const fechaInicioDayJs = dayjs(selectedData.fechaInicio);
    const fechaTerminoDayJs = dayjs(selectedData.fechaTermino);

    //Se compara la fecha de inicio con la actual para cambiar la fecha de ser necesario
    const isValid = 0 < fechaInicioDayJs.diff(fechaActualDayJs, "d");
    if (isValid) {
      return {
        fechaInicio: new Date(selectedData.fechaInicio).toJSON(),
        fechaTermino: new Date(selectedData.fechaTermino).toJSON(),
      };
    } else {
      // De no ser valida, se actualizan los valores manteniendo la diferencia
      const diferenciaFechas = fechaTerminoDayJs.diff(fechaInicioDayJs, "d");

      const fechaInicioActualizada = fechaActualDayJs;
      const fechaTerminoActualizada = fechaActualDayJs.add(
        diferenciaFechas,
        "d"
      );

      return {
        fechaInicio: new Date(fechaInicioActualizada).toJSON(),
        fechaTermino: new Date(fechaTerminoActualizada).toJSON(),
      };
    }
  }
};

//#endregion

//#region "Numbers"

export const roundNumber = (num, scale = 0) => {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, scale)) /
    Math.pow(10, scale)
  );
};

export const toMilesNumeros = (numero) => {
  return numero
    .toString()
    .replace(/(\.\d+)|\B(?=(\d{3})+(?!\d))/g, function (m, g1) {
      return g1 || ".";
    });
};

export const toDecimal = (numero) => {
  let resultado = numero.toString().replace(/[^0-9.]/g, function (m, g1) {
    return g1 || "";
  });
  let resultArray = resultado.split(".");

  if (resultArray.length > 2) resultado = resultado.replace(/\.+$/, "");
  if (resultArray.length > 1)
    resultado = `${resultArray[0]}.${resultArray[1].substring(0, 2)}`;
  return resultado;
};

export const toDecimalWithComa = (numero) => {
  let resultado = numero.toString().replace(/[^0-9,]/g, function (m, g1) {
    return g1 || "";
  });
  let resultArray = resultado.split(",");

  if (resultArray.length > 2) resultado = resultado.replace(/\.+$/, "");
  if (resultArray.length > 1)
    resultado = `${resultArray[0]},${resultArray[1].substring(0, 2)}`;
  return resultado;
};

//#endregion

//#region  "Strings"

export const simpleStringify = (object) => {
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
  return JSON.stringify(object, getCircularReplacer()); // returns cleaned up JSON
};

export const convertToComparableString = (value) => {
  return (value ?? "")
    .normalize("NFD")
    .replace(
      /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
      "$1"
    )
    .normalize()
    .toLowerCase();
};

export const capitalizeTheFirstLetterOfEachWord = (text) => {
  if (text === null || text === undefined) return "";

  var separateWord = text.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const ReplaceStringArray = (
  text,
  replaceArray = [{ text: "text", replace: "replace" }]
) => {
  let textResult = text ?? "";
  replaceArray.forEach((replaceObj) => {
    textResult = textResult
      .toString()
      .replaceAll(replaceObj.text, replaceObj.replace);
  });
  return textResult;
};

export const ReplaceStringByObject = (
  text,
  replaceProps = { text: "replace" }
) => {
  let textResult = text ?? "";
  for (const key in replaceProps) {
    textResult = textResult
      .toString()
      .replaceAll(`[${key}]`, replaceProps[key]);
  }
  return textResult;
};

export const generateAdicionalInfoErrorEmision = (formValues) => {
  let aditionalInfo = "";
  aditionalInfo +=
    "<br/> <strong>Licitación:</strong> " +
    (formValues.codigoExterno === undefined ? "NA" : formValues.codigoExterno);
  aditionalInfo +=
    "<br/> <strong>Fecha Inicio:</strong> " +
    (formValues.fechaInicio === undefined
      ? "NA"
      : dayjs(formValues.fechaInicio).format("DD/MM/YYYY"));
  aditionalInfo +=
    "<br/> <strong>Fecha Termino:</strong> " +
    (formValues.fechaTermino === undefined
      ? "NA"
      : dayjs(formValues.fechaTermino).format("DD/MM/YYYY"));
  aditionalInfo +=
    "<br/> <strong>Monto:</strong> " + (formValues.tipoMoneda === "")
      ? ""
      : formValues.tipoMoneda +
        " " +
        (formValues.montoCertificado === ""
          ? "0"
          : formValues.tipoMoneda === "CLP"
          ? convertToPeso(formValues.montoCertificado)
          : formValues.tipoMoneda === "UF"
          ? convertToUf(formValues.montoCertificado)
          : formValues.tipoMoneda === "USD"
          ? convertToUsd(formValues.montoCertificado)
          : formValues.montoCertificado);
  aditionalInfo +=
    "<br/> <strong>Comisión:</strong> " + (formValues.commission ?? "NA");
  aditionalInfo +=
    "<br/> <strong>Descuento:</strong> " + (formValues.discount ?? "NA");
  aditionalInfo +=
    "<br/> <strong>Mandante:</strong> " +
    (formValues.rutUnidadMandante === ""
      ? "NA"
      : formValues.rutUnidadMandante) +
    (formValues.nombreOrganismoMandante !== ""
      ? " " + formValues.nombreOrganismoMandante
      : "");
  aditionalInfo +=
    "<br/> <strong>Finalidad:</strong> " +
    (formValues.finalidad === "" ? "No seleccionada" : formValues.finalidad);
  aditionalInfo +=
    "<br/> <strong>Glosa:</strong> " +
    (formValues.glosa === "" ? "" : formValues.glosa);

  return aditionalInfo;
};

export const GetBodyStyleFromHtml = (content) => {
  let cssString = content.substring(
    content.indexOf('<style type="text/css">') +
      '<style type="text/css">'.length,
    content.indexOf("</style>") - 1
  );
  let indexBody = content.indexOf("<body>") + "<body>".length;
  let bodyString = content.substring(indexBody, content.indexOf("</body>") - 1);
  return { cssString, bodyString };
};

//#endregion

//#region "Archivos"

export const getFileExtension = (file) => {
  return /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name)[0] : undefined;
};

//#endregion

//#region "Objetos"

//Retorna un objeto con sus atributos con trim
export const getTrimObject = (obj) =>
  Object.keys(obj).map((k) => (obj[k] = obj[k].trim()));

//#endregion

//#region "Varios"

//Retorna una variable desde la url
export const getHelperVariableUrl = (nombreVariable = "var") => {
  let argUrl = window.location.search;
  let params = new URLSearchParams(argUrl);
  return params.get(nombreVariable);
};

export const getTimeElapsed = (date) => {
  const today = dayjs();
  const date1 = dayjs(date);
  const days = today.diff(date1, "day");
  const hours = today.diff(date1, "hour");
  const minutes = today.diff(date1, "minute");
  const seconds = today.diff(date1, "second");

  // dias
  if (days > 1) {
    return `${days} días`;
  }

  if (days === 1) {
    return "1 día";
  }

  // horas
  if (hours > 1) {
    return `${hours} hrs`;
  }

  if (hours === 1) {
    return "1 hr";
  }

  // minutos
  if (minutes > 0) {
    return `${minutes} min`;
  }
  // segundos
  if (seconds > 1) {
    return `${seconds} seg`;
  }

  return "Ahora";
};

export function copyToClipboard(str) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

//Ajusta el scroll a la referencia enviada de forma vertical
export const scrollTopRef = (reference, px = 90) => {
  const { current: objetivo } = reference;
  window.scrollTo({
    behavior: objetivo ? "smooth" : "auto",
    top: objetivo ? objetivo.offsetTop - px : 0,
  });
};

//id = idReferencia a la cual se quiere ir
//container = useRef de simple bar objetivo
export const scrollTopIdSimpleBarElement = (id, container) => {
  const myElement = document.getElementById(id);
  let to = 0;
  if (!!myElement) {
    to = myElement.offsetTop;
  }
  const { current: containerElement } = container;

  containerElement.getScrollElement().scrollTo(0, to);
};

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export const sendPostWithRedirect = (path, params, method = "post") => {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

export const addDaysToDate = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const finfastProductHelperToComparableString = (value) => {
  return (value ?? "")
    .normalize("NFD")
    .replace(
      /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
      "$1"
    )
    .normalize()
    .toLowerCase();
};

export const FinfastProductRedirectToProduct = (
  idProducto,
  accounttoken,
  accountproductsValidation
) => {
  const products = accountproductsValidation;
  try {
    ApiMembresiaAccount.GetOneUseToken(accounttoken).then((resp) => {
      if (resp.success) {
        const token = resp.token;
        const product = products.find(
          (p) => p.idProducto === parseInt(idProducto)
        );

        if (!!product) {
          const urlByEnvironment =
            FinfastProductUrlProductByEnvironment(product);
          if (!!urlByEnvironment) {
            let redirect = `${urlByEnvironment}?user=${token}`;

            window.location.href = redirect;
            return false;
          }
        }
        window.location.href = "/home";
      }
    });
  } catch {
    console.error("Problema con idProducto");
    window.location.href = "/home";
  }
};

const FinfastProductUrlProductByEnvironment = (product) => {
  if (process.env.REACT_APP_SUBENVIRONMENT === "production") {
    return product.url;
  }
  if (process.env.REACT_APP_SUBENVIRONMENT === "qa") {
    return product.urlQa;
  }
  if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      return product.url;
    } else if (process.env.REACT_APP_ENVIRONMENT === "qa") {
      return product.urlQa;
    } else {
      return product.urlLocalhost;
    }
  } else if (process.env.NODE_ENV === "development") {
    return product.urlLocalhost;
  } else {
    return "";
  }
};

//#endregion

//#region "sistema"
//Helper que indica si el usuario en sesión es ususario de prueba
export const isUserTest = () => getSessionGeneral("dataIsValidUserForTest");
//#endregion

export const clpToUf = (montoClp, valorUf) => {
  return montoClp / valorUf;
};

export const convertToCurrency = (tipoMoneda, value) => {
  if (tipoMoneda === "CLP") {
    return convertToPeso(value);
  }
  if (tipoMoneda === "USD") {
    return convertToUsd(value);
  }
  if (tipoMoneda === "UF") {
    return convertToUf(value);
  }
};
