import React from "react";
import { useAppSelector } from "@redux/hooks";
import { MCards } from "@inversiones-ma/finfast-react-controls";

const DatosLicitacion = () => {
  const { tenderData } = useAppSelector((store) => store.newCertificate);

  return (
    <>
      <div className="col-12 pb-4">
        <MCards
          className="px-4 pb-4"
          style={{ boxShadow: "0px 0px 0px" }}
          color="successHome"
        >
          <div className="col-12 pt-4">
            <h3 className="subtitle-sub-mini color-secondary fw-bold fs-18 mb-0">
              Datos licitación
            </h3>
          </div>
          <div className="row d-flex pt-2">
            <div className="col-12 col-lg-6 pt-lg-1 pt-sm-0 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">ID Licitación</h3>
              <p
                className="ms-auto fs-14 mb-0"
                style={{ textAlign: "right", color: "#7E33CC" }}
              >
                {tenderData?.codigoExterno}
              </p>
            </div>            
            <div className="col-sm-0 col-lg-6 pt-2 d-flex flex-row"></div>
            <div className="col-12 col-lg-6 pt-lg-1 pt-sm-0 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">RUT mandante</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {tenderData?.rutUnidadMandante}
              </p>
            </div>
            <div className="col-12 col-lg-6 pt-1 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">Nombre mandante</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {tenderData?.nombreOrganismoMandante}
              </p>
            </div>
            <div className="col-12 col-lg-6 pt-1 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">Región</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {tenderData?.regionUnidadMandante}
              </p>
            </div>
            <div className="col-12 col-lg-6 pt-1 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0">Comuna</h3>
              <p className="ms-auto fs-14 mb-0" style={{ textAlign: "right" }}>
                {tenderData?.comunaUnidadMandante}
              </p>
            </div>
            <div className="col-12 col-lg-6 pt-1 d-flex flex-row">
              <h3 className="fw-bold fs-14 mb-0" style={{ height: "15px" }}>
                Dirección
              </h3>
              <p className="ms-auto fs-14 mb-0 pt-0">
                {tenderData?.direccionUnidadMandante}
              </p>
            </div>
            <div className="col-sm-0 col-lg-6 pt-sm-0 pt-lg-0"></div>
          </div>
        </MCards>
      </div>
    </>
  );
};

export default DatosLicitacion;
