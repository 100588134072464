import React from "react";
import { MButton, MPopup } from "@inversiones-ma/finfast-react-controls";
import editImage from "@img/icon-filter-edit.svg";

const MAlertPopupFilterEdit = ({ showModal, setShowModal }) => {

    return (
        <MPopup
            size="md"
            showClose={true}
            showModal={showModal}
            setShowModal={setShowModal}
        >
          
            <div className="text-center">
                <img src={editImage} alt="datos confirmados" className="icon-pop-up-el" />
                <h4 className="titles-center" position="center">Filtro Editado</h4>
                <h6 className="py-3">Tu filtro ha sido editado exitosamente</h6>
                <MButton onClick={() => setShowModal(false)} type="button" className="btn btn-primary sistema">Entendido</MButton>
            </div>
        </MPopup>
    );
};

export default MAlertPopupFilterEdit;
