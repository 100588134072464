import "./WarrantiesCard.scss";

import React from "react";

import { OriginIncome, ValidateOperations } from "Models/Operation";

import { convertToPeso } from "@helpers/helpers";
import { MCards, MTooltip } from "@inversiones-ma/finfast-react-controls";
import * as NewCertificateModels from "@redux/models/NewCertificateModels";

import BtnSelectCard from "./BtnSelectCard";
import { IconCard } from "./IconCard";

interface Props {
  quoteData: ValidateOperations[];
  handlerSelect: (element: ValidateOperations) => void;
  addOrEdit: NewCertificateModels.Step;
  origen: OriginIncome;
  warantySelected: ValidateOperations | undefined;
}

function WarrantiesCardMobile(props: Props) {
  return (
    <div className="col-12">
      <div className="row justify-content-center pb-3 mt-4">
        {props?.quoteData && props?.quoteData?.length > 0 && (
          <>
            {props.quoteData.map((element, index) => {
              const esAseguradora =
                element.idAseguradora !== null && element.idAseguradora > 0;

              const existeAseguradoraConDescuento =
                element.idAseguradora > 0 &&
                element.valoresComision?.descuento! > 0;
              const existeAseguradoraConIva =
                element.idAseguradora > 0 &&
                element.valoresComision?.ivaClp! > 0;
              const cftConDescuento =
                element.idAseguradora === 0 &&
                element.valoresComision?.descuento! > 0;

              return (
                element && (
                  <div
                    key={index}
                    className={
                      props.addOrEdit === NewCertificateModels.Step.verificar
                        ? " col-lg-6 col-md-6 col-12 card-poliza-garantia"
                        : " col-lg-4 col-md-6 col-12 card-poliza-garantia pb-4 "
                    }
                  >
                    <MCards
                      className="contenedor border-0 h-100  margin-1"
                      color="primary"
                      size="default"
                    >
                      <div className="color-text-low action-card d-flex flex-column justify-content-between h-100">
                        {element.valoresComision?.porcentajeDescuento! > 0 && (
                          <div className="row mb-3 pt-0">
                            <div className="col-10 infoDiscount">
                              <div className="bg-success rounded-pill w-100 discountMessage p-1">
                                <p className="text-white m-0 mx-2">
                                  <b>
                                    {element.valoresComision?.porcentajeDescuento!.toFixed(
                                      0
                                    )}
                                    % <span className="fs-10">Descuento</span>
                                  </b>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className={
                            element.valoresComision?.porcentajeDescuento
                              ? "row margin align-items-center px-3 pt-3"
                              : "row margin align-items-center px-3 pt-4"
                          }
                        >
                          <div className="col-12 d-flex align-items-center">
                            <div className="h-100 col-lg-2 col-2 pr-2">
                              {IconCard(element)}
                            </div>
                            <div className="px-2">
                              <h3 className="pt-1 ff-kanit text-start m-0 color-primary">
                                <strong className="fs-22 text-uppercase">
                                  {element.idAseguradora > 0
                                    ? "Póliza"
                                    : "Certificado"}{" "}
                                </strong>
                              </h3>
                              <div className="d-flex align-items-baseline">
                                {/* <span className="garantia">Garantía</span> */}
                                <p className="garantia">
                                  GARANTÍA
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row px-3">
                          <div className="col-12 fs-14 color-text  text-description m-0 mb-3">
                            {element.descripcionEmisor}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <hr className="m-0" />
                          </div>
                          <div className="col-12 mt-2">
                            <div className="d-flex px-3 justify-content-between align-items-baseline">
                              <p className="fs-12  mb-1">Valor neto</p>
                              <p className="fs-16  mb-1">
                                <b>
                                  {convertToPeso(
                                    element.valoresComision?.comisionBruto
                                  )}
                                </b>
                              </p>
                            </div>
                          </div>

                          {/* DESCUENTO */}
                          {esAseguradora && (
                            <div className="col-12">
                              {/* <div className="d-flex px-3 justify-content-between align-items-baseline">
                                <p className="fs-12 text-danger  mb-1">
                                  Descuento
                                </p>
                                <p className="fs-16 text-danger mb-1">
                                  <b>
                                    -
                                    {convertToPeso(
                                      element.valoresComision?.descuento
                                    )}
                                  </b>
                                </p>
                              </div> */}
                            </div>
                          )}

                          {cftConDescuento && !esAseguradora && (
                            <div className="col-12">
                              <div className="d-flex px-3 justify-content-between align-items-baseline">
                                <p className="fs-12 text-danger  mb-1">
                                  Descuento
                                </p>
                                <p className="fs-16 text-danger mb-1">
                                  <b>
                                    -
                                    {convertToPeso(
                                      element.valoresComision?.descuento
                                    )}
                                  </b>
                                </p>
                              </div>
                            </div>
                          )}

                          {/* IVA */}
                          {existeAseguradoraConIva && esAseguradora ? (
                            <div className="col-12">
                              <div className="d-flex px-3 justify-content-between align-items-baseline">
                                <p className="fs-12 color-primary mb-1">IVA</p>
                                <p className="fs-16 color-primary mb-1">
                                  <b>
                                    {convertToPeso(
                                      element.valoresComision?.ivaClp
                                    )}
                                  </b>
                                </p>
                              </div>
                            </div>
                          ) : existeAseguradoraConDescuento ? (
                            <>
                              <p className="fs-12 color-primary mb-1"></p>
                              <p className="fs-16 color-primary mb-1">
                                <br />
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="col-12">
                            <hr className="mt-0" />
                          </div>

                          <div
                            className="d-flex justify-content-center"
                            style={{ paddingRight: "30%" }}
                          >
                            <div className="col-12 align-center">
                              <div className="d-flex justify-content-between align-items-baseline">
                                <div className="d-flex align-items-center justify-content-center col-12">
                                  <p className="fs-14">
                                    <b>Comisión a pagar</b>
                                  </p>
                                  <MTooltip
                                    iconClass="fas fa-question-circle fs-10"
                                    content={
                                      <h6 className="text-center">
                                        Este es el precio final de tu comisión.{" "}
                                        <br />
                                        {element.valoresComision?.ivaClp! > 0 &&
                                          element.valoresComision?.descuento! >
                                          0 ? (
                                          <>
                                            Incluye IVA y descuento{" "}
                                            <span>{element.emisor}</span>
                                          </>
                                        ) : element.valoresComision?.ivaClp! >
                                          0 ? (
                                          <>Incluye IVA </>
                                        ) : element.valoresComision
                                          ?.descuento! > 0 ? (
                                          <>
                                            Incluye descuento{" "}
                                            <span>{element.emisor}</span>
                                            {" "}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </h6>
                                    }
                                  ></MTooltip>
                                </div>
                                <br />
                              </div>
                              <div className="w-100">
                                <div className="d-flex comision-custom justify-content-center color-success">
                                  <b>
                                    {convertToPeso(
                                      element.valoresComision?.comision
                                    )}
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <BtnSelectCard
                          quoteData={props.quoteData}
                          addOrEdit={props.addOrEdit}
                          handlerSelect={props.handlerSelect}
                          element={element}
                          warantySelected={props.warantySelected}
                        />
                      </div>
                    </MCards>
                  </div>
                )
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default WarrantiesCardMobile;
