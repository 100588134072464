import React, { useEffect, useState } from "react";
import ApiDownloadCertificates from "@api/certificados/DownloadCertificados";
import { useAppSelector } from "@redux/hooks";
import { MLoadingLight } from "@inversiones-ma/finfast-react-controls";
import MLoading from "../../../../../controls/MLoading";
import MessageNotData from "./../MessageNotData";
import SuccessMessage from "../../../NewCertificate/partial/VerifyData/SuccessMessage";
import {
  BaseQueryPaginador,
  BaseResponsePaginador,
  Paginador,
} from "Models/Paginador";
import ApiCertificates from "@api/certificados/Certificados";
import { Filtros, OperacionDTO } from "Models/Operation";
import { CurrentCertificatePolicyDesktop } from "./CurrentCertificatePolicyDesktop";
import { CurrentCertificatePolicyMobile } from "./CurrentCertificatePolicyMobile";

function CurrentCertificatesPolicy(props: Props) {
  //Vars Redux
  const { insuranceCarrier } = useAppSelector((store) => store.session);

  //States
  const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);
  let [DownloadDocument, setDownloadDocument] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [warratyData, setWarratyData] = useState<Paginador<OperacionDTO>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [onChangeNumberPagePolicy, setOnChangeNumberPagePolicy] =
    useState<number>(1);
  const [onChangeNumberRecordsPolicy, setOnChangeNumberRecordsPolicy] =
    useState<number>(10);

  const [filters, setFilters] = useState<Filtros>({
    RutMandante: null,
    NroCertificado: null,
    IdLicitacion: null,
    Finalidad: null,
  });

  //Handlers
  async function handlerButtonDonwnloadCft(row: any) {
    if (!DownloadDocument) {
      ApiDownloadCertificates.DownloadPolicySuraAzure(row)
        .then((response) => {
          return new Blob([response?.data]);
        })
        .finally(() => {
          setDownloadDocument(false);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = row;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }
  }

  function handlerButtonDownloadExcelCurrents() {
    if (!downloadingExcel) {
      setDownloadingExcel(true);
      ApiDownloadCertificates.callOperacionOperationDownloadListCurrentsPolicy()
        .then((response) => {
          return new Blob([response?.data]);
        })
        .then((blob) => {
          if (blob.type === "text/plain") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "polizas_vigentes.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setDownloadingExcel(false);
        });
    }
  }

  function callLoadCurrentPolicy(Paginador: BaseQueryPaginador<Filtros>) {
    setIsLoadingData(true);
    setIsLoading(true);
    ApiCertificates.ListCurrentPolicy(Paginador)
      .then((response: BaseResponsePaginador<OperacionDTO>) => {
        if (response) {
          setWarratyData(response.paginador);
          //setResponseStatus(response.status);
        }
      })
      .catch((error) => {
        console.error("error api ListCurrents: ", error);
      })
      .finally(() => {
        setIsLoadingData(false);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (isNaN(onChangeNumberRecordsPolicy)) return;
    if (isNaN(onChangeNumberPagePolicy)) return;
    let QueryPaginador: BaseQueryPaginador<Filtros> = {
      page: onChangeNumberPagePolicy,
      pageSize: onChangeNumberRecordsPolicy,
      Filtros: [{ ...filters }],
    };
    callLoadCurrentPolicy(QueryPaginador);
  }, [filters, onChangeNumberPagePolicy, onChangeNumberRecordsPolicy]);

  return (
    <div className="pb-4">
      {warratyData && warratyData?.records.length > 0 && (
        <div className="row">
          <div className="boton-excel">
            <button
              type="button"
              className="col-12 col-lg-3 offset-lg-9 text-center  btn btn-primary outline sistema"
              onClick={handlerButtonDownloadExcelCurrents}
            >
              Descargar tabla en Excel
              {downloadingExcel && (
                <MLoadingLight style={{ marginLeft: "10px" }} />
              )}
              {!downloadingExcel && (
                <i
                  className="fas fa-file-excel"
                  style={{ marginLeft: "10px" }}
                ></i>
              )}
            </button>
          </div>
        </div>
      )}
      <div className="row">       
        {warratyData ? (
          <>
            {warratyData?.records.length === 0 && (
              <MessageNotData
                title="No tienes pólizas vigentes, ¿y si generamos una?"
                description=""
                buttonBuy={true}
              />
            )}
            <div className="col-12 d-none d-md-block d-lg-block pt-0">
              <CurrentCertificatePolicyDesktop
                handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                insuranceCarrier={insuranceCarrier}
                isLoadingData={isLoadingData}
                setOnChangeNumberPage={setOnChangeNumberPagePolicy}
                setOnChangeNumberRecords={setOnChangeNumberRecordsPolicy}
                warratyData={warratyData}
              />
            </div>
            <div className="movil d-block d-md-none pb-3 pt-2">
              <CurrentCertificatePolicyMobile
                handlerButtonDonwnloadCft={handlerButtonDonwnloadCft}
                insuranceCarrier={insuranceCarrier}
                setOnChangeNumberPage={setOnChangeNumberPagePolicy}
                setOnChangeNumberRecords={setOnChangeNumberRecordsPolicy}
                warratyData={warratyData}
              />
            </div>
          </>
        ) : (
          <div className="col-12">
            <MLoading modal={false} />
          </div>
        )}
      </div>
      {/* <SuccessMessage
        showModal={showSuccessMessage}
        setShowModal={setShowSuccessMessage}
        close={() => setShowSuccessMessage(false)}
      /> */}
    </div>
  );
}

interface Props {
  idOperationTypeDocument: number;
}
export default CurrentCertificatesPolicy;
